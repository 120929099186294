<div class="content">
  <iu-storekeeper-transfer-details class="details" [(data)]="data" />
  <iu-storekeeper-transfer-lines
    [lines]="lines()?.[tabActive()]"
    [(dataLines)]="dataLines"
    [isComplete]="isComplete()"
    [isLinesComplete]="isLinesComplete()"
    [create]="create()"
    (openNewLine)="openNewLine($event)" />
</div>

@if (!isComplete()) {
  @if (isLinesComplete()) {
    <iu-storekeeper-navbar-button
      [icon]="'icon-circle-check'"
      [label]="'ConfirmTransfer' | SKWTranslate"
      [type]="'confirm'"
      (onClick)="save($event)" />
  } @else if ((!isCreation() && !dataLines()) || dataLines()?.length > 0) {
    <iu-storekeeper-navbar class="navbar" [canExpand]="false">
      <iu-storekeeper-navbar-button
        [icon]="'icon-dolly'"
        [label]="'ToLoad' | SKWTranslate"
        [active]="tabActive() === 'TL'"
        [count]="lines()?.TL?.length"
        [hideCount]="!state.loaded() || lines()?.TL?.length === 0"
        (onClick)="changeActiveTab($event, 'TL')" />
      <iu-storekeeper-navbar-button
        [icon]="'icon-truck-ramp-box'"
        [label]="'ToUnload' | SKWTranslate"
        [active]="tabActive() === 'UL'"
        [count]="lines()?.UL?.length"
        [hideCount]="!state.loaded() || lines()?.UL?.length === 0"
        (onClick)="changeActiveTab($event, 'UL')" />
    </iu-storekeeper-navbar>
  }
}

import { NgClass, NgStyle } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CompiereDataGridRequestJSON } from '@compiere-ws/models/compiere-data-json';
import { DashboardService } from '@compiere-ws/services/dashboard/dashboard.service';
import {
  GridPreference,
  GridPreferenceType,
  GridPreferencesService,
} from '@compiere-ws/services/grid-preferences/grid-preferences.service';
import PrimeOverlayComponent from '@iupics-components/overrided/prime-overlay/prime-overlay.component';
import WidgetEditorUiComponent from '@iupics-components/specific/window/widget-editor-ui/widget-editor-ui.component';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import { AppConfig } from '@iupics-config/app.config';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { ApizGridUtils } from '@iupics-util/tools/apiz-grid.utils';
import { createComponent } from '@iupics-util/tools/component-cache-loader';
import {
  ColumnState,
  GridOptionsAppliedItems,
  PanelType,
  injectColumnApiService,
  injectGridApiService,
  injectPanelService,
} from '@iupics/apiz-grid';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { OverlayPanel } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { Subscription } from 'rxjs';
import { injectFilterCtxService } from '../filters/services/filter-ctx.service';
import GridTabInfinityScrollUiComponent from '../grid-tab-infinity-scroll-ui/grid-tab-infinity-scroll-ui.component';
import GridViewUiComponent from '../grid-view-ui/grid-view-ui.component';

@Component({
  selector: 'iu-favorite-filter',
  standalone: true,
  imports: [
    TooltipModule,
    PrimeOverlayComponent,
    NgStyle,
    TranslateModule,
    NgClass,
    ButtonModule,
    FormsModule,
    ModalUiComponent,
  ],
  templateUrl: './favorite-selector.component.html',
  styleUrl: './favorite-selector.component.scss',
})
export class FavoriteSelectorComponent implements AfterViewInit {
  //#region ViewChild
  @ViewChild('overlayFavourite') overlayFavorite: OverlayPanel;
  @ViewChild('buttonFavoriteFilter', { read: ElementRef }) buttonFavoriteFilter: ElementRef;
  //#endregion

  //#region DI
  #uiCreator = inject(UICreatorService);
  #messageManager = inject(MessageManagerService);
  #config = inject(AppConfig);
  #translator = inject(TranslateService);
  #gridPreferencesService = inject(GridPreferencesService);
  #dashboardService = inject(DashboardService);
  #cdr = inject(ChangeDetectorRef);
  #renderer = inject(Renderer2);
  #store = inject(DataStoreService);
  //#endregion

  @Input() gridView: GridViewUiComponent;

  #subscriptions: Subscription[] = [];

  displayFavoriteFilterListener: Function;
  isDisplayFavoriteFilter: boolean = false;

  #areDataLoaded = false;

  //#region Widget editor
  @ViewChild('vcrWidgetEditor', { read: ViewContainerRef, static: false }) vcrWidgetEditor: ViewContainerRef;
  processingWidget: { event: Event; gridPreference: GridPreference };
  gridPreferences: GridPreference[] = [];
  selectedGridPref: GridPreference;
  displayWidgetEditorModal = false;
  //#endregion

  //#region apiz-grid
  api = injectGridApiService();
  columnApi = injectColumnApiService();
  panelService = injectPanelService();
  filterCtx = injectFilterCtxService();
  //#endregion

  ngAfterViewInit(): void {
    if (this.gridView) {
      this.#subscriptions.push(this.gridView.gridPrefLoaded.subscribe((gridTab) => this.#loadData(gridTab)));
    }
  }

  #loadData(gridTab: GridTabInfinityScrollUiComponent) {
    if (!this.#areDataLoaded) {
      this.#getDataToDisplay(gridTab);
      this.#areDataLoaded = true;
    }
  }

  #getDataToDisplay(gridTab: GridTabInfinityScrollUiComponent) {
    const formDetailID = this.gridView?.data?.AD_FormDetail_ID ?? gridTab?.data?.AD_FormDetail_ID;
    const tabID = this.gridView?.data?.AD_Tab_ID ?? gridTab?.data?.AD_Tab_ID;
    const windowID = this.gridView?.data?.AD_Window_ID ?? gridTab?.data?.AD_Window_ID;
    const tableID = this.gridView?.data?.details?.entityID ?? gridTab?.data?.details?.entityID;
    this.#gridPreferencesService
      .getGridPreferences({ userGridPreferenceID: -1, formDetailID, tabID, windowID, tableID })
      .subscribe((gridPreferences) => {
        this.gridPreferences = gridPreferences.sort((a, b) => a.name.localeCompare(b.name));
        if (gridTab.gridPreference?.userGridPreferenceID) {
          this.selectedGridPref = gridPreferences.find(
            (gp) => gp.userGridPreferenceID === gridTab.gridPreference.userGridPreferenceID
          );
        }
      });
  }

  updatePanelFilter(event: any) {
    if (event?.stopPropagation) event.stopPropagation();
    this.isDisplayFavoriteFilter = !this.isDisplayFavoriteFilter;
    if (this.isDisplayFavoriteFilter) {
      if (this.displayFavoriteFilterListener === undefined) {
        this.displayFavoriteFilterListener = this.#renderer.listen(document.documentElement, 'mousedown', (e) => {
          const path = e.path ? e.path : e.composedPath();
          if (
            path.findIndex((target: any) => target.id === 'favorite-filter-panel') < 0 &&
            path.findIndex((target: any) => target.className === 'iu-modal-background') < 0 &&
            path.findIndex((target: any) => target.className?.includes('p-overlaypanel')) < 0 &&
            path.findIndex((target: any) => target?.className?.includes('p-autocomplete-panel')) < 0 &&
            path.findIndex((target: any) => target?.className?.includes('p-datepicker')) < 0
          ) {
            if (this.isDisplayFavoriteFilter) {
              this.overlayFavorite.hide();
              this.overlayFavorite.cd.markForCheck();
            }
            this.isDisplayFavoriteFilter = false;
            this.displayFavoriteFilterListener();
            this.displayFavoriteFilterListener = undefined;
          }
        });
      }
      this.overlayFavorite.show(event, this.buttonFavoriteFilter.nativeElement);
    } else {
      this.overlayFavorite.hide();
      if (this.displayFavoriteFilterListener !== undefined) {
        this.displayFavoriteFilterListener();
        this.displayFavoriteFilterListener = undefined;
      }
    }
  }

  saveFilter(event: Event, name: string) {
    name = name?.trim();
    if (!name && this.selectedGridPref.name) {
      name = this.selectedGridPref.name;
    }
    if (!name) {
      this.#messageManager.newMessage(
        new IupicsMessage(
          this.#translator.instant('universalFilter.saveTitle'),
          this.#translator.instant('universalFilter.favoriteNameMandatory'),
          'warning'
        )
      );
      return;
    }
    event.stopPropagation();
    let filterToSave: CompiereDataGridRequestJSON = { ...this.api.getRequest() };
    filterToSave.startRow = 0;
    filterToSave.endRow = this.#config.getConstant('GridTabInfinityScrollUiComponent#cacheBlockSize');
    filterToSave.label = name;

    filterToSave = ApizGridUtils.cleanDataRequestForCompiereRequest(filterToSave);

    const gridTab = this.gridView?.GridTabInfinityScrollUiComponent;
    const type = gridTab?.DOMParentComponent?.isGridCollapsed
      ? GridPreferenceType.REDUCED
      : GridPreferenceType.EXPANDED;
    const targetedGridPreference = this.gridPreferences.find((gp) => gp.name === name);
    const columnStates = gridTab.grid.columnApi.getColumnStates();
    const gridPreference: GridPreference = {
      userGridPreferenceID: targetedGridPreference ? targetedGridPreference.userGridPreferenceID : -1,
      name: filterToSave.label,
      formDetailID: gridTab.data?.AD_FormDetail_ID,
      gridRequest: JSON.stringify(filterToSave),
      gridState:
        type === GridPreferenceType.EXPANDED ? JSON.stringify(columnStates) : targetedGridPreference?.gridState,
      gridStateReduced:
        type === GridPreferenceType.REDUCED ? JSON.stringify(columnStates) : targetedGridPreference?.gridStateReduced,
      tabID: gridTab.data?.AD_Tab_ID,
      windowID: gridTab.data?.AD_Window_ID,
      tableID: gridTab.data?.details?.entityID,
      isDefault: targetedGridPreference ? targetedGridPreference.isDefault : false,
      widgetID: targetedGridPreference?.widgetID,
    };
    this.#gridPreferencesService.saveGridPreference(gridPreference).subscribe({
      next: (gridPreferenceResponse) => {
        this.#messageManager.newMessage(
          new IupicsMessage(
            this.#translator.instant('universalFilter.saveTitle'),
            this.#translator.instant('universalFilter.saveSuccess'),
            'success'
          )
        );
        if (gridPreferenceResponse) {
          if (
            gridTab.gridPreference &&
            gridTab.gridPreference.userGridPreferenceID === gridPreferenceResponse.userGridPreferenceID
          ) {
            gridTab.gridPreference = gridPreferenceResponse;
            this.selectedGridPref = gridPreferenceResponse;
          }
          if (
            this.gridPreferences.find((g) => g.userGridPreferenceID === gridPreferenceResponse.userGridPreferenceID)
          ) {
            this.gridPreferences = this.gridPreferences.map((g) => {
              if (g.name === gridPreferenceResponse.name) {
                return gridPreferenceResponse;
              } else {
                return g;
              }
            });
          } else {
            this.gridPreferences.push(gridPreferenceResponse);
          }
        } else {
          gridTab.loadGridPreferenceResponse(type, false, true);
        }
      },
      error: (err) => {
        this.#messageManager.newMessage(
          new IupicsMessage(
            this.#translator.instant('universalFilter.saveTitle'),
            this.#translator.instant('universalFilter.saveFailed'),
            'error',
            err
          )
        );
      },
    });
    // }
  }

  editFilter(event: Event, gridPreference: GridPreference) {
    event.stopPropagation();
    this.selectedGridPref = gridPreference;
    const request: CompiereDataGridRequestJSON = JSON.parse(gridPreference.gridRequest);
    const appliedItems: GridOptionsAppliedItems = ApizGridUtils.appliedItemsFromCompiereRequest(
      request,
      this.api,
      this.columnApi
    );
    this.api.updateAppliedItems(appliedItems, undefined);
    this.updatePanelFilter(event);
    this.panelService.open(this.panelService.isMergePanels() ? PanelType.ALL : PanelType.FILTERS);
  }

  selectAndApply(event: Event, gridPreference: GridPreference) {
    event.stopPropagation();
    this.selectedGridPref = gridPreference;
    const request: CompiereDataGridRequestJSON = JSON.parse(gridPreference.gridRequest);
    const appliedItems: GridOptionsAppliedItems = ApizGridUtils.appliedItemsFromCompiereRequest(
      request,
      this.api,
      this.columnApi
    );

    ApizGridUtils.updateAppliedItems(appliedItems, this.api, this.filterCtx, this.#store);

    const gridTab = this.gridView?.GridTabInfinityScrollUiComponent;
    const type = gridTab?.DOMParentComponent?.isGridCollapsed
      ? GridPreferenceType.REDUCED
      : GridPreferenceType.EXPANDED;
    let columnStates: ColumnState[] | undefined;
    if (type === GridPreferenceType.EXPANDED && gridPreference.gridState) {
      columnStates = JSON.parse(gridPreference.gridState);
    }
    if (type === GridPreferenceType.REDUCED && gridPreference.gridStateReduced) {
      columnStates = JSON.parse(gridPreference.gridStateReduced);
    }
    if (columnStates?.length) {
      this.columnApi.applyColumnState({ state: columnStates, applyOrder: true });
    }
    this.updatePanelFilter(event);
  }

  showWidgetEditor(event: Event, gridPreference: GridPreference) {
    event.stopPropagation();
    this.processingWidget = { event, gridPreference };

    const targetedGridPreference = this.gridPreferences.find((gp) => gp.name === gridPreference.name);
    this.selectedGridPref = targetedGridPreference;
    this.#subscriptions.push(
      this.#uiCreator.getWidgetEditorForm().subscribe((specificWindow) => {
        if (specificWindow.angularClass) {
          this.updatePanelFilter(event);
          this.displayWidgetEditorModal = true;
          this.#cdr.detectChanges();
          this.vcrWidgetEditor.clear();

          const componentRef = createComponent<WidgetEditorUiComponent>(
            this.vcrWidgetEditor,
            specificWindow.angularClass
          );

          // this.specificWindowTitle = specificWindow.name;
          componentRef.instance.name = specificWindow.name;
          componentRef.instance.title = specificWindow.title;
          componentRef.instance.description = specificWindow.description;
          componentRef.instance.help = specificWindow.help;
          componentRef.instance.componentRef = componentRef;
          componentRef.instance.isModal = true;
          componentRef.instance.formId = specificWindow.AD_Form_ID;
          componentRef.instance.vcrwindow = this.vcrWidgetEditor;
          componentRef.instance.parentComponent = this;
          componentRef.instance.index = this.vcrWidgetEditor.length - 1;
          componentRef.instance.sourceModal = null;
          componentRef.instance.sourceComponentData = this;
          componentRef.instance.sourceComponent =
            this; /**dupliqué si pas d'itemdata mais trop de refactoring sur scout */
          componentRef.instance.closeModalEmitter.subscribe((_event) => {
            this.displayWidgetEditorModal = false;
          });
          componentRef.instance.gridPref = this.selectedGridPref;
          componentRef.instance.updateInitialUFEmitter.subscribe((widgetId) => this.confirmWidgetAction(widgetId));
          componentRef.changeDetectorRef.detectChanges();
        }
      })
    );
  }

  confirmWidgetAction(widgetId: number) {
    if (widgetId > -1 && this.selectedGridPref.widgetID != widgetId) {
      this.processingWidget.gridPreference.widgetID = widgetId;
      this.updateWidgetPresence(null);
    } else {
      this.#dashboardService.resetCachingData();
      this.displayWidgetEditorModal = false;
    }
  }

  updateWidgetPresence(eventClick: Event) {
    if (eventClick) {
      eventClick.stopPropagation();
    }
    if (this.processingWidget) {
      let { event, gridPreference } = this.processingWidget;
      if (gridPreference.widgetID === 0) {
        gridPreference.widgetID = null;
      }

      this.saveGridPref(event, gridPreference);
      this.#dashboardService.resetCachingData();
      this.displayWidgetEditorModal = false;
    }
  }

  saveGridPref(event: Event, gridPreference: GridPreference) {
    let el = <HTMLElement>event.target;
    while (el !== undefined) {
      if (el.tagName === 'BUTTON') {
        el.blur();
        break;
      }
      el = el.parentElement;
    }
    const gridTab = this.gridView?.GridTabInfinityScrollUiComponent;
    const type = gridTab?.DOMParentComponent?.isGridCollapsed
      ? GridPreferenceType.REDUCED
      : GridPreferenceType.EXPANDED;
    const targetedGridPreference = this.gridPreferences.find((gp) => gp.name === gridPreference.name);
    this.#gridPreferencesService
      .saveGridPreference({
        userGridPreferenceID: targetedGridPreference?.userGridPreferenceID,
        name: targetedGridPreference?.name,
        formDetailID: gridTab.data?.AD_FormDetail_ID,
        gridRequest: targetedGridPreference?.gridRequest,
        gridState: targetedGridPreference?.gridState,
        gridStateReduced: targetedGridPreference?.gridStateReduced,
        tabID: gridTab.data?.AD_Tab_ID,
        windowID: gridTab.data?.AD_Window_ID,
        tableID: gridTab.data?.details?.entityID,
        isDefault: gridPreference.isDefault,
        widgetID: gridPreference.widgetID,
      })
      .subscribe({
        next: (gridPreferenceResponse) => {
          if (gridPreferenceResponse) {
            if (
              gridTab.gridPreference &&
              gridTab.gridPreference.userGridPreferenceID === gridPreferenceResponse.userGridPreferenceID
            ) {
              gridTab.gridPreference = gridPreferenceResponse;
            }
            this.gridPreferences = this.gridPreferences.map((g) => {
              if (g.name === gridPreferenceResponse.name) {
                return gridPreferenceResponse;
              } else {
                if (gridPreference.isDefault) {
                  g.isDefault = false;
                }
                return g;
              }
            });
          } else {
            gridTab.loadGridPreferenceResponse(type);
          }
        },
        error: (err) => {
          this.#messageManager.newMessage(
            new IupicsMessage(
              this.#translator.instant('universalFilter.saveTitle'),
              this.#translator.instant('universalFilter.saveFailed'),
              'error',
              err
            )
          );
        },
      });
  }

  updateDefault(event: Event, gridPreference: GridPreference) {
    event.stopPropagation();
    gridPreference.isDefault = !gridPreference.isDefault;
    this.saveGridPref(event, gridPreference);
  }

  deleteFilter(event: Event, gridPreference: GridPreference) {
    event.stopPropagation();
    const gridPrefFound = this.gridPreferences.find((g) => g.name === gridPreference.name);
    const gridTab = this.gridView?.GridTabInfinityScrollUiComponent;
    const type = gridTab?.DOMParentComponent?.isGridCollapsed
      ? GridPreferenceType.REDUCED
      : GridPreferenceType.EXPANDED;
    if (gridPrefFound) {
      this.#gridPreferencesService.deleteGridPreference(gridPrefFound).subscribe({
        next: (removed) => {
          if (removed) {
            this.#messageManager.newMessage(
              new IupicsMessage(
                this.#translator.instant('universalFilter.deleteTitle'),
                this.#translator.instant('universalFilter.deleteSuccess'),
                'success'
              )
            );
            this.gridPreferences = this.gridPreferences.filter((g) => g.name !== gridPrefFound.name);
            gridTab.gridPreference.userGridPreferenceID = -1;
            gridTab.loadGridPreferenceResponse(type, false, true);
          } else {
            this.#messageManager.newMessage(
              new IupicsMessage(
                this.#translator.instant('universalFilter.deleteTitle'),
                this.#translator.instant('universalFilter.deleteFailed'),
                'error'
              )
            );
          }
        },
        error: (err) =>
          this.#messageManager.newMessage(
            new IupicsMessage(
              this.#translator.instant('universalFilter.deleteTitle'),
              this.#translator.instant('universalFilter.deleteFailed'),
              'error',
              err
            )
          ),
      });
    }
  }
}

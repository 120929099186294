@if (source && source.columnFilters && source.operatorFilters) {
  <ng-container
    [ngTemplateOutlet]="favoriteFilter"
    [ngTemplateOutletContext]="{
      columnFilters: source.columnFilters,
      operatorFilters: source.operatorFilters,
      $implicit: selected
    }" />
} @else {
  <ng-container
    [ngTemplateOutlet]="favoriteFilter"
    [ngTemplateOutletContext]="{ columnFilters: { items: [] }, operatorFilters: { items: [] }, data: selected }" />
}

<ng-template #favoriteFilter let-columnFilters="columnFilters" let-operatorFilters="operatorFilters" let-selected>
  <div
    id="group-filter-panel"
    class="group-list ui-overlaypanel {{ gridView?.isTree ? 'hasTreeView' : '' }} {{ isMobile ? 'mobile' : '' }}">
    <!-- FILTERS -->
    @if (selected?.filters.length > 0) {
      <ul #filtersContainer cdkDropList (cdkDropListDropped)="moveItem($event, 'filters')" data-uf="filters">
        @for (filter of selected.filters; track filter) {
          <div cdkDrag data-cy="universal-filter-line" [attr.data-cy-index]="$index">
            <div class="ui-row-drag-preview" *cdkDragPreview>
              {{ 'universalFilter.filterPreview' | translate }}{{ filter.column.displayValue || '[ColumnName]' }}
              {{ filter.operator.displayValue || '[Operator]' }}
              {{ filter.filter || '[Filter]' }}
              {{
                filter.filterTo !== undefined && filter.filterTo !== null && filter.filterTo !== ''
                  ? ' - ' + filter.filterTo
                  : ''
              }}
            </div>
            <div class="ui-row-drag-placeholder" *cdkDragPlaceholder></div>
            <li class="ui-row" [attr.data-cy-row]="filter.column.id">
              <span cdkDragHandle class="icon-grab" style="font-size: 17px; cursor: move"></span>
              <div class="filtres">
                <div
                  class="lev-1 filter-field {{ filter?.column?.id === -1 ? 'valueEmpty' : '' }}"
                  data-cy="universal-filter-column-autocomplete">
                  <iu-autocomplete-ui
                    [fieldValue]="filter.column"
                    (fieldValueModified)="setColumnFilter($any($event), $index)"
                    [isStandalone]="true"
                    [data]="
                      columnFilters
                        | universalFilterColFilterType
                          : 0
                          : $index
                          : (gridView?.viewType === ViewType.CALENDAR
                              ? CompiereDataGridFilterType.DATE
                              : CompiereDataGridFilterType.NONE)
                        | autocompleteSuggestionsFilter: usedFilterColumnNames : filter.column.id
                    "
                    [isInUniversalFilter]="true"
                    [isInsideOverflow]="true" />
                </div>

                <div
                  class="lev-2 filter-field {{ filter?.column?.id === -1 ? 'valueEmpty' : '' }}"
                  data-cy="universal-filter-operator-autocomplete">
                  <iu-autocomplete-ui
                    [fieldValue]="filter.operator"
                    (fieldValueModified)="setOperatorFilter($any($event), $index)"
                    [isStandalone]="true"
                    [data]="operatorFilters | operatorFilter: filter?.column?.columnInfo?.filterType"
                    [isInUniversalFilter]="true"
                    [isInsideOverflow]="true" />
                </div>

                <div
                  class="lev-3 filter-field {{ filter?.operator?.operator?.isRange ? 'isRange' : '' }}"
                  data-cy="universal-filter-value">
                  <ng-template #vcrFilter />
                </div>
                <div
                  class="lev-4 filter-field {{ filter?.operator?.operator?.isRange ? 'isRange' : '' }}"
                  data-cy="universal-filter-value-to">
                  @if (filter?.operator?.operator?.isRange) {
                    @switch (filter?.column?.columnInfo?.filterType) {
                      @case ('number') {
                        <iu-input-number-ui
                          [isStandalone]="true"
                          [fieldValue]="filter.filterTo"
                          (fieldValueModified)="setFilterTo($event, $index)" />
                      }
                      @case ('date') {
                        <iu-calendar-ui
                          [hasTodayBtn]="true"
                          [isStandalone]="true"
                          [data]="{
                            needTime: filter.column.columnInfo.fieldEntity.field.AD_Reference_ID === 16
                          }"
                          [fieldValue]="
                            filter.filterTo | toDate: filter.column.columnInfo.fieldEntity.field.AD_Reference_ID === 16
                          "
                          [calendarConfig]="filter.configsTo?.calendarConfig || { todayMode: false }"
                          (calendarConfigChange)="onCalendarConfigChange($event, $index, true)"
                          (fieldValueModified)="setFilterTo($event, $index)" />
                      }
                      @case ('time') {
                        <iu-input-time-ui
                          [isStandalone]="true"
                          [fieldValue]="filter.filterTo | toDate: true"
                          [calendarConfig]="filter.configsTo?.calendarConfig || { todayMode: false }"
                          (calendarConfigChange)="onCalendarConfigChange($event, $index, true)"
                          (fieldValueModified)="setFilterTo($event, $index)" />
                      }
                    }
                  }
                </div>
              </div>
              <div class="delete" data-cy="universal-filter-remove-filter">
                <button
                  tooltipPosition="top"
                  [showDelay]="250"
                  [life]="3000"
                  [hideDelay]="250"
                  pTooltip="{{ 'generic.delete' | translate }}"
                  pButton
                  icon="icon-delete"
                  class="hover"
                  (click)="removeFilter($index, $event)"></button>
              </div>
            </li>
          </div>
        }
      </ul>
    }
    <ul class="buttonAdd">
      <li class="ui-row">
        <div class="p-col-12">
          <button
            pButton
            id="addrow"
            class="button p-button-secondary"
            style="cursor: pointer"
            (click)="addFilter($event)"
            label="{{ 'universalFilter.addFilter' | translate }}"
            icon="icon-add"
            data-cy="universal-filter-add-filter-one"></button>
        </div>
      </li>
    </ul>
  </div>
</ng-template>

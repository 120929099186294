import {
  CdkDrag,
  CdkDragDrop,
  CdkDragHandle,
  CdkDragPlaceholder,
  CdkDragPreview,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { AsyncPipe, NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import {
  Component,
  ComponentRef,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
  ViewEncapsulation,
  forwardRef,
  inject,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CompiereDataGridFilterType, DataStore } from '@compiere-ws/models/compiere-data-json';
import {
  ColumnFilterAutocomplete,
  OperatorFilterAutocomplete,
} from '@iupics-components/models/autocomplete-interfaces';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { ViewType } from '@iupics-components/models/view-type.enum';
import { CalendarConfig } from '@iupics-components/overrided/prime-calendar/prime-calendar.component';
import PrimeChipsComponent from '@iupics-components/overrided/prime-chips/prime-chips.component';
import PrimeOverlayComponent from '@iupics-components/overrided/prime-overlay/prime-overlay.component';
import { AutocompleteSuggestionsFilterPipe } from '@iupics-components/pipes/autocomplete-suggestions-filter/autocomplete-suggestions-filter.pipe';
import { ColumnGroupFilterPipe } from '@iupics-components/pipes/column-group-filter/column-group-filter.pipe';
import { OperatorFilterPipe } from '@iupics-components/pipes/operator-filter/operator-filter.pipe';
import { ToDatePipe } from '@iupics-components/pipes/to-date/to-date.pipe';
import { UniversalFilterColFilterTypePipe } from '@iupics-components/pipes/universal-filter-col-filter-type/universal-filter-col-filter-type.pipe';
import AutocompleteUiComponent from '@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component';
import CalendarUiComponent from '@iupics-components/standard/fields/calendar-ui/calendar-ui.component';
import InputNumberUiComponent from '@iupics-components/standard/fields/input-number-ui/input-number-ui.component';
import InputTimeUiComponent from '@iupics-components/standard/fields/input-time-ui/input-time-ui.component';
import {
  FilterCtxService,
  injectFilterCtxService,
} from '@iupics-components/standard/grid/filters/services/filter-ctx.service';
import GridTabInfinityScrollUiComponent from '@iupics-components/standard/grid/grid-tab-infinity-scroll-ui/grid-tab-infinity-scroll-ui.component';
import GridViewUiComponent from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import { AppConfig } from '@iupics-config/app.config';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorUtils } from '@iupics-manager/managers/ui-creator/utils/ui-creator.utils';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { IupicsData, NumberType } from '@iupics-manager/models/iupics-data';
import { createComponent } from '@iupics-util/tools/component-cache-loader';
import { SubscriptionsUtils } from '@iupics-util/tools/subscriptions.util';
import {
  AppliedItem,
  ColumnApiService,
  FilterProvider,
  GridApiService,
  PanelService,
  injectColumnApiService,
  injectGridApiService,
  injectPanelService,
} from '@iupics/apiz-grid';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { clone, has, isNil } from 'lodash';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { Subscription } from 'rxjs';
import { AZ_DataToDisplay, FilterToDisplay, UniversalFilterUtils } from '../utils/universal-filter-utils';

@Component({
  selector: 'iu-universal-filter-ui',
  templateUrl: './universal-filter-ui.component.html',
  styleUrls: ['./universal-filter-ui.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    // Angular
    AsyncPipe,
    FormsModule,
    NgClass,
    NgStyle,
    NgTemplateOutlet,
    // Angular Material
    CdkDrag,
    CdkDragHandle,
    CdkDragPlaceholder,
    CdkDragPreview,
    CdkDropList,
    // PrimeNG
    ButtonModule,
    SharedModule,
    TooltipModule,
    // NGTranslate
    TranslateModule,
    // Iupics
    CalendarUiComponent,
    forwardRef(() => AutocompleteUiComponent),
    InputNumberUiComponent,
    InputTimeUiComponent,
    ModalUiComponent,
    PrimeChipsComponent,
    PrimeOverlayComponent,
    AutocompleteSuggestionsFilterPipe,
    ColumnGroupFilterPipe,
    OperatorFilterPipe,
    ToDatePipe,
    UniversalFilterColFilterTypePipe,
  ],
})
export default class UniversalFilterUiComponent implements OnInit, OnDestroy, FilterProvider {
  //#region DI
  #config = inject(AppConfig);
  #translator = inject(TranslateService);
  #connectorService = inject(SecurityManagerService);
  #store = inject(DataStoreService);
  //#endregion

  //#region viewchild
  @ViewChild('filtersContainer', { read: ElementRef }) filtersContainer: ElementRef<HTMLUListElement>;
  @ViewChildren('vcrFilter', { read: ViewContainerRef }) vcrFilters: QueryList<ViewContainerRef>;
  //#endregion

  @Input() gridView: GridViewUiComponent;
  @Input() gridTab: GridTabInfinityScrollUiComponent;

  @Input() isMobile = false;

  // observables
  #columnNames: { items: ColumnFilterAutocomplete[] };
  #operators: { items: OperatorFilterAutocomplete[] };
  source: {
    columnFilters: { items: ColumnFilterAutocomplete[] };
    operatorFilters: { items: OperatorFilterAutocomplete[] };
  };

  /** selected favorite filter */
  selected: AZ_DataToDisplay;

  /** Subs */
  #subscriptions: Subscription[] = [];
  /** Default columns to display. */
  #selectionColumns: ColumnFilterAutocomplete[] = [];
  #localDatastore: DataStore;
  #initialDatastore: DataStore;
  #fieldToUpdates = [];
  #componentRefs: ComponentRef<any>[] = [];

  /** Columns names already used to be filtered out of the column selection autocomplete */
  usedFilterColumnNames = [];

  //#region template related
  readonly CompiereDataGridFilterType = CompiereDataGridFilterType;
  readonly ViewType = ViewType;
  //#endregion

  //#region apiz-grid
  @Input() api: GridApiService = injectGridApiService();
  @Input() columnApi: ColumnApiService = injectColumnApiService();
  @Input() panelService: PanelService = injectPanelService();
  @Input() filterCtx: FilterCtxService = injectFilterCtxService();
  #filters = this.panelService.filters;
  #ufUtils = inject(UniversalFilterUtils);
  //#endregion

  constructor() {
    this.panelService.registerFilterProvider(this);
  }

  ngOnInit() {
    this.#initUniversalFilter();
    this.#updatePanelFilter();
  }

  #initUniversalFilter() {
    this.#initLocalDatastore();
    this.#getColumnFilters();
    this.#getOperatorFilters();
    this.#getSource();
    this.#initData();
  }

  #initLocalDatastore() {
    this.#localDatastore = this.filterCtx.provideLocalDatastore();
    this.#initialDatastore = this.filterCtx.provideInitialDatastore();
  }

  ngOnDestroy() {
    this.panelService.unlockPanel();
    this.panelService.unRegisterFilterProvider();
    SubscriptionsUtils.unsubscribe(...this.#subscriptions);
    for (const cr of this.#componentRefs) cr?.destroy();
  }

  provideFilters(): AppliedItem<'filter'>[] {
    const appliedItems: AppliedItem<'filter'>[] = this.#ufUtils.dataToDisplayToAppliedItems(this.selected);
    return appliedItems;
  }

  resetFilters() {
    if (this.selected.filters) {
      for (const f of this.selected.filters) {
        if (f.column) {
          if (this.#fieldToUpdates) {
            this.#fieldToUpdates.push(f.column.id);
          }
          if (this.#localDatastore?.data) {
            this.#localDatastore.data[f.column.id] = null;
          }
        }
      }
    }
    this.#initData();
    this.usedFilterColumnNames = [];
    if (this.#selectionColumns.length > 1) {
      for (const column of this.#selectionColumns) {
        this.addFilter(document.createEvent('Event'));
        this.setColumnFilter(column, this.selected.filters.length - 1, false);
      }
    } else {
      this.addFilter(document.createEvent('Event'));
    }
    setTimeout(() => {
      this.#componentRefs = [];
      this.#checkVCR();
    }, 50);
  }

  /**
   * Change l'affichage d'une modal selon son nom et ajoute un listener de fermeture selon son id dans l'html
   * @param event
   * @param name
   * @param id
   * @param display
   */
  #updatePanelFilter() {
    // keep a copy of initial chips and store
    if (!this.#localDatastore) {
      this.#initLocalDatastore();
    }
    this.#fieldToUpdates = [];

    this.#handleViewChange();
    if (this.#selectionColumns.length) {
      for (const sc of this.#selectionColumns) {
        const index =
          this.selected.filters.length > 0
            ? this.selected.filters.findIndex((f) =>
                f.column.id !== -1 ? f.column.columnInfo.fieldEntity.field.ColumnName === sc.id : false
              )
            : -1;
        if (index === -1) {
          this.selected.filters = this.selected.filters.filter((f) => f.column.id !== -1);
          this.addFilter(document.createEvent('Event'));
          this.setColumnFilter(sc, this.selected.filters.length - 1, false);
        }
      }
    }

    // faire le changement applied-items vers selected ici
    const filtersToDisplay = this.#ufUtils.appliedItemsToFilterToDisplay(
      this.#columnNames.items,
      this.#operators.items,
      this.#filters()
    );

    let dataModified = {};
    for (let i = 0; i < filtersToDisplay.length ?? 0; i++) {
      const ftd = filtersToDisplay[i];
      const idx = this.selected.filters.findIndex((filter) => ftd.column.id === filter.column.id);
      if (idx === -1) this.selected.filters.push(ftd);
      else this.selected.filters[idx] = ftd;

      dataModified = {
        ...dataModified,
        ...this.filterCtx.setDatacontainerValue(ftd.column.id, ftd.filter, ftd.operator.operator.filterType),
      };
      if (ftd.filterTo !== undefined) {
        dataModified = {
          ...dataModified,
          ...this.filterCtx.setDatacontainerValue(
            ftd.column.id + '_To',
            ftd.filterTo,
            ftd.operator.operator.filterType
          ),
        };
      }
    }
    this.#store.syncDataChanges(this.#localDatastore, dataModified, true);

    setTimeout(() => {
      this.#updateUsedColumns();
      this.#componentRefs = [];
      this.#checkVCR();
    }, 0);
  }

  setColumnFilter(value: ColumnFilterAutocomplete, index: number, fromHTML = true) {
    if (value) {
      this.selected.filters[index].column = value;
      if (!this.#localDatastore) {
        this.#initLocalDatastore();
      }

      const filter =
        has(this.#localDatastore.data, value.id) && !isNil(this.#localDatastore.data[value.id])
          ? this.#localDatastore.data[value.id]
          : undefined;
      const filterTo =
        has(this.#localDatastore.data, value.id + '_To') && !isNil(this.#localDatastore.data[value.id + '_To'])
          ? this.#localDatastore.data[value.id + '_To']
          : undefined;

      this.selected.filters[index].filter = filter;
      this.selected.filters[index].filterTo = filterTo;

      if (fromHTML) {
        this.#setVCR(index);
      }

      this.#updateUsedColumns();

      const operatorPipe: OperatorFilterPipe = new OperatorFilterPipe();
      const operatorFilters = operatorPipe.transform(
        this.#operators,
        this.selected.filters[index].column.columnInfo.filterType
      );
      this.setOperatorFilter(operatorFilters.items[0], index);
    }
  }

  setOperatorFilter(value: OperatorFilterAutocomplete, index: number) {
    if (value) {
      const previousType = this.selected.filters[index]?.operator?.operator?.type ?? '';
      this.selected.filters[index].operator = value;
      if (!value.operator.isRange) {
        this.selected.filters[index].filterTo = undefined;
      }

      if (value.operator.filterType === CompiereDataGridFilterType.NUMBER) {
        const compoRef = this.#componentRefs[index];
        const isMultiple = value.operator
          ? value.operator.type === OperatorFilterType.EQUALS || value.operator.type === OperatorFilterType.NOT_EQUALS
          : true;
        if (compoRef && compoRef.instance && compoRef.instance.multiple !== isMultiple) {
          const fieldValue = compoRef.instance.fieldValue;
          compoRef.instance.multiple = isMultiple;
          if (fieldValue) {
            compoRef.instance.fieldValue = isMultiple ? [fieldValue] : fieldValue[0];
            this.selected.filters[index].filter = isMultiple
              ? [this.selected.filters[index].filter]
              : this.selected.filters[index].filter[0];
          }
        }
      }

      if (
        (value.operator.type === OperatorFilterType.IS_NULL && previousType !== OperatorFilterType.IS_NULL) ||
        (previousType === OperatorFilterType.IS_NULL && value.operator.type !== OperatorFilterType.IS_NULL) ||
        (value.operator.type === OperatorFilterType.IS_NOT_NULL && previousType !== OperatorFilterType.IS_NOT_NULL) ||
        (previousType === OperatorFilterType.IS_NOT_NULL && value.operator.type !== OperatorFilterType.IS_NOT_NULL)
      ) {
        this.setFilter('', index, index);
        this.#setVCR(index);
      }
    }
  }

  setFilter(value: any, filterIndex: number, compoRefIndex: number) {
    // init store because grid is completly rendered after universalfilter
    if (!this.#localDatastore) {
      this.#initLocalDatastore();
    }
    if (this.#localDatastore) {
      const dataModified = this.#setDatacontainerValue(this.selected.filters[filterIndex].column.id, value);
      if (value === undefined || value === null || (!(value instanceof Date) && value.length === 0)) {
        value = null;
      }
      this.selected.filters[filterIndex].filter = value;
      const compoRef = this.#componentRefs[compoRefIndex];
      if (compoRef?.instance?.fieldValue) {
        compoRef.instance.fieldValue = value;
      }
      this.#store.syncDataChanges(this.#localDatastore, dataModified, true);
    }
  }

  setFilterTo(value: any, index: number) {
    // init store because grid is completly rendered after universalfilter
    if (!this.#localDatastore) {
      this.#initLocalDatastore();
    }
    if (this.#localDatastore) {
      const dataModified = this.#setDatacontainerValue(this.selected.filters[index].column.id + '_To', value);
      if (value === undefined || value === null || (!(value instanceof Date) && value.length === 0)) {
        value = null;
      }
      this.selected.filters[index].filterTo = value;
      this.#store.syncDataChanges(this.#localDatastore, dataModified, true);
    }
  }

  #setDatacontainerValue(key: string, value: any) {
    const dataModified = {};
    dataModified[key] = value;
    if (this.gridView?.fromForm && this.gridView?.container?.dataStore) {
      this.gridView.container.setDatacontainerValue(key, dataModified[key]);
    }
    return dataModified;
  }

  addFilter(event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    const emptyFilter: FilterToDisplay = {
      column: { id: -1, displayValue: '' },
      operator: { id: -1, displayValue: '' },
      filter: '',
      filterTo: '',
    };
    this.selected.filters.push(emptyFilter);
    setTimeout(() => {
      if (this.filtersContainer) {
        this.filtersContainer.nativeElement.scrollTop =
          this.filtersContainer.nativeElement.scrollHeight - this.filtersContainer.nativeElement.clientHeight;
      }
    }, 50);
  }

  removeFilter(index: number, event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    if (this.gridView?.fromForm && this.selected.filters[index]) {
      this.removeFromGridForm(index);
    }
    if (this.selected.filters?.[index]?.column?.id) {
      // keep track of changed values to reset store later if localdatastore is shared with a specific window
      this.#fieldToUpdates.push(this.selected.filters[index].column.id);
      this.#localDatastore.data[this.selected.filters[index].column.id] = clone(
        this.#initialDatastore.data[this.selected.filters[index].column.id]
      );
    }
    this.selected.filters.splice(index, 1);
    this.#componentRefs.splice(index, 1);
    this.#updateUsedColumns();
  }

  removeFromGridForm(index: number) {
    let columnName = null;
    let shouldApply = false;
    if (this.selected.filters[index].filter) {
      columnName = this.selected.filters[index].column.id;
    }
    if (this.gridView?.container?.dataStore) {
      this.gridView.container.setDatacontainerValue(columnName, null);
      this.gridView.container.setDatacontainerValue(columnName + '_To', null);
      const dataContainerFound = this.gridView.container.getDatacontainer(columnName);
      shouldApply = !dataContainerFound?.data?.isLaunchSearchGrid;
      if (this.gridView.GridTabInfinityScrollUiComponent?.query?.filters) {
        delete this.gridView.GridTabInfinityScrollUiComponent.query.filters[columnName];
        delete this.gridView.GridTabInfinityScrollUiComponent.query.filters[columnName + '_To'];
      }
    }
    return shouldApply;
  }

  moveItem(event: CdkDragDrop<any[]>, type: keyof Pick<AZ_DataToDisplay, 'filters'>) {
    if (this.selected[type]) moveItemInArray(this.selected[type], event.previousIndex, event.currentIndex);
    if (type === 'filters') moveItemInArray(this.#componentRefs, event.previousIndex, event.currentIndex);
  }

  /*
   * *****************************************************************************
   * *****************************************************************************
   * *****************************************************************************
   * ***************************** PRIVATE METHODS *******************************
   * *****************************************************************************
   * *****************************************************************************
   * *****************************************************************************
   */

  #getSource() {
    this.source = {
      columnFilters: this.#columnNames,
      operatorFilters: this.#operators,
    };
  }

  #getColumnFilters() {
    this.#selectionColumns = this.filterCtx.provideSelectionColumns();
    this.#columnNames = this.filterCtx.provideColumnNames();
  }

  #getOperatorFilters() {
    this.#operators = this.filterCtx.provideOperators();
  }

  #initData() {
    this.selected = {
      favorite: '',
      isDefault: false,
      filters: [],
      notUFData: {},
    };
  }

  #apply() {
    this.panelService.apply();
    this.panelService.close();
  }

  #checkVCR() {
    for (let i = 0; i < this.vcrFilters.length ?? 0; i++) {
      this.#setVCR(i);
    }
  }

  #setVCR(index: number) {
    // init store because grid is completly rendered after universalfilter
    if (!this.#localDatastore) {
      this.#initLocalDatastore();
    }

    if (
      this.selected.filters[index]?.operator?.operator?.type === OperatorFilterType.IS_NULL ||
      this.selected.filters[index]?.operator?.operator?.type === OperatorFilterType.IS_NOT_NULL
    ) {
      this.vcrFilters.find((_, i) => i === index)?.clear();
    } else {
      let compoRef: ComponentRef<any>;
      if (this.selected.filters[index].column && this.selected.filters[index].column.id !== -1) {
        let componentName = UICreatorUtils.getComponentNameFromReference(
          this.selected.filters[index].column.columnInfo.fieldEntity.field.AD_Reference_ID
        );
        const componentNameToTest = new Set(['InputTextareaUiComponent', 'InputTextHtmlUiComponent']);
        if (componentNameToTest.has(componentName)) {
          componentName = 'InputTextUiComponent';
        } else if (componentName === 'ButtonUiComponent') {
          componentName = 'InputSwitchUiComponent';
        }
        const vcr = this.vcrFilters.find((_, i) => index === i);
        vcr.clear();
        compoRef = createComponent(vcr, componentName);
        compoRef.instance.isStandalone = true;
        compoRef.instance.isInUniversalFilter = true;
        compoRef.instance.isSetDefaultValue = true;
        if (componentName === 'AutocompleteUiComponent') {
          (<AutocompleteUiComponent>compoRef.instance).isInsideOverflow = true;
        }
        /* Set le fieldType utilisé pour le ws /DataField */
        const container = this.gridTab ? this.gridTab.container : this.gridView ? this.gridView.container : null;
        if (container?.windowType) {
          (<AbstractDynamicComponent>compoRef.instance).container = container;
          (<AbstractDynamicComponent>compoRef.instance).fieldType = this.filterCtx.getFieldType();
        } else {
          if (componentName === 'AutocompleteUiComponent') {
            compoRef.instance.isForInfoWindow = true;
          }
        }
        this.#subscriptions.push(
          compoRef.instance.fieldValueModified.subscribe((value: any) => {
            const compoRefIndex = this.#componentRefs.findIndex((c) => c === compoRef);
            const filterIndex = this.selected.filters.findIndex(
              (c) => c.column.id === compoRef.instance.data.columnName
            );
            this.setFilter(value, filterIndex, compoRefIndex);
          })
        );
        // ? by default enterKey is Faster than fieldValueModified due to the originalEvent used in both cases
        if (compoRef.instance.hasOwnProperty('enterKey')) {
          this.#subscriptions.push(
            compoRef.instance.enterKey.subscribe((e: Event) => {
              e.stopPropagation();
              const compoRefIndex = this.#componentRefs.findIndex((c) => c === compoRef);
              const filterIndex = this.selected.filters.findIndex(
                (c) => c.column.id === compoRef.instance.data.columnName
              );
              this.setFilter(compoRef.instance.inputRef.nativeElement.value, filterIndex, compoRefIndex);
              if (
                this.#componentRefs[compoRefIndex].instance.multiple === false &&
                componentName === 'InputNumberUiComponent'
              ) {
                return;
              }
              this.#apply();
            })
          );
        }
        if (componentName === 'CalendarUiComponent') {
          compoRef.instance.hasTodayBtn = true;
          (compoRef.instance as AbstractDataContainer).calendarConfig = this.selected.filters[index]?.configs
            ?.calendarConfig || {
            todayMode: false,
          };
          (compoRef.instance as AbstractDataContainer).calendarConfigChange.subscribe((calendarConfig) => {
            const i = this.#componentRefs.findIndex((c) => c === compoRef);
            this.onCalendarConfigChange(calendarConfig, i);
          });
        }
        if (componentName === 'InputSwitchUiComponent') {
          compoRef.instance.isTriState = true;
        }
        if (componentName === 'InputNumberUiComponent') {
          compoRef.instance.multiple = this.selected.filters[index].operator.operator
            ? this.selected.filters[index].operator.operator.type === OperatorFilterType.EQUALS ||
              this.selected.filters[index].operator.operator.type === OperatorFilterType.NOT_EQUALS
            : true;
          compoRef.instance.cssClass = 'UniversalFilterInputNumber';
        }
        // create internal datastore and use datamodified for that
        // it will be used as a local ctx for the universal filter
        if (componentName === 'AutocompleteUiComponent') {
          compoRef.instance.multiple = true;
        }
        const fieldEntity = this.selected.filters[index].column.columnInfo.fieldEntity;
        const field = fieldEntity ? fieldEntity.field : null;
        compoRef.instance.data = <IupicsData>{
          fieldId: field.AD_Field_ID,
          columnId: field.AD_Column_ID,
          formId: field.AD_Form_ID,
          fieldType: field.IsEncryptedColumn || field.IsEncryptedField ? 'password' : 'text',
          isCopy: field.IsCopy,
          items: fieldEntity.data,
          description: field.Description,
          columnName: field.ColumnName,
          details: fieldEntity.details,
          urlList: fieldEntity.urlList,
          urlSearch: fieldEntity.urlSearch,
          tableName: field.tableName,
          tabId: field.AD_Tab_ID,
          isSearch:
            field.AD_Reference_ID === 30 || (fieldEntity.urlSearch !== null && fieldEntity.urlSearch !== undefined),
          isAccount: field.AD_Reference_ID === 25,
          needTime: field.AD_Reference_ID === 16 || field.AD_Reference_ID === 24,
          defaultValue: field.DefaultValue,
          defaultValue2: field.DefaultValue2,
          searchColumns: fieldEntity.details ? fieldEntity.details.searchColumns : null,
          numberType:
            field.AD_Reference_ID === 12 || field.AD_Reference_ID === 37
              ? NumberType.AMOUNT
              : field.AD_Reference_ID === 11
                ? NumberType.INTEGER
                : field.AD_Reference_ID === 22
                  ? NumberType.FLOAT
                  : field.AD_Reference_ID === 29
                    ? NumberType.QUANTITY
                    : null,
          validationCode: field.ValidationCode ? field.ValidationCode.replace(/\n/g, ' ') : null,
          componentName: componentName,
        };
        if (this.#localDatastore && this.#localDatastore.data) {
          compoRef.instance.getCurrentContext = this.filterCtx.provideGetCurrentContext();
          if (compoRef.instance.setNewData) {
            compoRef.instance.setNewData(this.#localDatastore);
          }
        }
        compoRef.instance.lockFilterPanel = () => {
          this.panelService.lockPanel();
        };
        compoRef.instance.unlockFilterPanel = () => {
          this.panelService.unlockPanel();
        };
        this.#componentRefs.splice(index, 0, compoRef);
      }
    }
  }

  #handleViewChange() {
    if (this.gridView) {
      this.selected = this.#ufUtils.az_cleanDataToDisplay(this.selected);
      if (this.selected.filters.length === 0) {
        this.addFilter(document.createEvent('Event'));
      }
    } else if (this.gridTab) {
      if (this.selected.filters.length === 0) {
        this.addFilter(document.createEvent('Event'));
      }
    }
  }

  #updateUsedColumns() {
    this.usedFilterColumnNames = [];
    for (const f of this.selected.filters) {
      if (f && f.column) this.usedFilterColumnNames.push(f?.column?.id);
    }
  }

  onCalendarConfigChange(calendarConfig: CalendarConfig, index: number, configTo = false) {
    if (isNil(this.selected.filters[index].configs)) {
      this.selected.filters[index].configs = {};
    }
    if (isNil(this.selected.filters[index].configsTo)) {
      this.selected.filters[index].configsTo = {};
    }
    if (configTo) {
      this.selected.filters[index].configsTo.calendarConfig = calendarConfig;
    } else {
      this.selected.filters[index].configs.calendarConfig = calendarConfig;
    }
  }
}

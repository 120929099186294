<div class="app" (keydown)="handleKeyPressed($event)">
  @if (globalEnv.isActiveRequest()) {
    <div class="loader-spinner">
      <div>
        <i class="icon-spinner" aria-hidden="true"></i>
      </div>
    </div>
  }
  <router-outlet></router-outlet>
  @if (successMessage) {
    <div class="clearMessageBanner">
      <i class="icon-close" style="cursor: pointer" (click)="closeSuccessMessage()" aria-hidden="true"></i>
      <div class="iu-banner-message">
        <i class="iu-banner-icon fa-lg fas fa-check" aria-hidden="true"></i>
        <span class="iu-banner-message-text"> {{ successMessage.message }} </span>
      </div>
    </div>
  }
</div>

import { Routes } from '@angular/router';
import { AccesPortalGuard } from '@iupics-util/guards/acces-portal.guard';
import { AccesRessourceGuard } from '@iupics-util/guards/acces-ressource.guard';

const routes: Routes = [
  {
    path: 'dashboard',
    loadComponent: () => import('@web-desktop/components/workspace/components/desktop-ui/desktop-ui.component'),
    canActivate: [AccesPortalGuard],
  },
  {
    path: 'workspace',
    canActivate: [AccesPortalGuard],
    children: [
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
      {
        path: 'window',
        canActivateChild: [AccesRessourceGuard],
        children: [
          { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
          {
            path: ':windowId',
            loadComponent: () => import('@web-desktop/components/workspace/components/desktop-ui/desktop-ui.component'),
          },
          {
            path: ':windowId/:recordId',
            loadComponent: () => import('@web-desktop/components/workspace/components/desktop-ui/desktop-ui.component'),
          },
        ],
      },
      {
        path: 'splitviews',
        canActivateChild: [AccesRessourceGuard],
        children: [
          { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
          {
            path: ':viewdata',
            loadComponent: () => import('@web-desktop/components/workspace/components/desktop-ui/desktop-ui.component'),
          },
        ],
      },
      {
        path: 'process',
        canActivateChild: [AccesRessourceGuard],
        children: [
          { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
          {
            path: ':processId',
            loadComponent: () => import('@web-desktop/components/workspace/components/desktop-ui/desktop-ui.component'),
          },
        ],
      },
      {
        path: 'form',
        canActivateChild: [AccesRessourceGuard],
        children: [
          { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
          {
            path: ':formId',
            loadComponent: () => import('@web-desktop/components/workspace/components/desktop-ui/desktop-ui.component'),
          },
        ],
      },
      {
        path: 'report',
        canActivateChild: [AccesRessourceGuard],
        children: [
          { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
          {
            path: ':reportId',
            loadComponent: () => import('@web-desktop/components/workspace/components/desktop-ui/desktop-ui.component'),
          },
        ],
      },
    ],
  },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: 'accesUnauthorized',
    loadComponent: () => import('@iupics-util/components/page-not-found/page-not-found.component'),
  },
  { path: '**', loadComponent: () => import('@iupics-util/components/page-not-found/page-not-found.component') },
];

export default routes;
// @NgModule({
//   declarations: [],
//   imports: [
//     CommonModule,
//     RouterModule.forRoot(appRoutes, {
//       preloadingStrategy: PreloadAllModules,
//     }),
//   ],
//   exports: [RouterModule],
// })
// export class AppRoutingModule {}

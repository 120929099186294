import { ComponentRef } from '@angular/core';
import { CompiereDataFieldType } from '@compiere-ws/models/compiere-data-json';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { createComponent } from '@iupics-util/tools/component-cache-loader';
import { IupicsMenuType } from '@web-desktop/models/menu-item-ui';
export class WindowFactoryUtils {
  public static addContainerComponent({
    parent,
    item,
    isCssOnComponent = true,
  }: {
    parent: AbstractDynamicComponent;
    item: DynamicComponent;
    isCssOnComponent?: boolean;
  }): ComponentRef<any> {
    Global.startPerf(parent, 'addContainerComponent.' + item.component);
    const componentRef = createComponent<AbstractDynamicComponent>(parent.vcr, <string>item.component);
    this.addChildToParent(parent, componentRef, item);
    this.setContainerType(componentRef, item);
    this.setComponentProperties(componentRef, item);
    this.setCssClass(componentRef, item, isCssOnComponent);
    this.subscribeToComponentEvents(parent, componentRef);
    this.setChildren(componentRef, item);
    Global.endPerf(parent, 'addContainerComponent.' + item.component);
    return componentRef;
  }

  private static addChildToParent(
    parent: AbstractDynamicComponent,
    componentRef: ComponentRef<AbstractDynamicComponent>,
    item: DynamicComponent
  ): void {
    const children =
      parent.DOMComponent.componentType.name === 'AdditionalInfoUiComponent'
        ? parent.DOMParentComponent.DOMChildrenComponent
        : parent.DOMChildrenComponent;
    children.push(componentRef.instance);
    componentRef.instance.DOMParentComponent = parent;
    componentRef.instance.container = item.container || item.DOMParentComponent.container;
    componentRef.instance.DOMComponent = componentRef;
  }

  private static setContainerType(componentRef: ComponentRef<AbstractDynamicComponent>, item: DynamicComponent): void {
    const container = item.container || item.DOMParentComponent.container;
    if (container.windowType === IupicsMenuType.WINDOW) {
      componentRef.instance.fieldType = CompiereDataFieldType.FIELD;
    } else if (container.windowType === IupicsMenuType.PROCESS) {
      componentRef.instance.fieldType = CompiereDataFieldType.PROCESS_PARA;
    } else if (container.windowType === IupicsMenuType.FORM) {
      componentRef.instance.fieldType = CompiereDataFieldType.FORM_ITEM;
    }
  }

  private static setComponentProperties(
    componentRef: ComponentRef<AbstractDynamicComponent>,
    item: DynamicComponent
  ): void {
    componentRef.instance.data = item.data;
    componentRef.instance.gridPaginator = item.gridPaginator;

    if (item.tabId) {
      componentRef.instance.tabId = item.tabId;
    }

    if (item.gridTabFilter) {
      componentRef.instance.gridTabFilter = item.gridTabFilter;
    }

    if (item.gridTabValidator) {
      componentRef.instance.gridTabValidator = item.gridTabValidator;
    }

    if (item.initRequest) {
      componentRef.instance.initRequest = item.initRequest;
    }

    if (item.zoomInfo) {
      componentRef.instance.zoomInfo = item.zoomInfo;
    }

    if (item.parentStore) {
      componentRef.instance.parentStore = item.parentStore;
    }

    if (item.zoomTarget) {
      componentRef.instance.zoomTarget = item.zoomTarget;
    }

    if (item.parentTab || item?.DOMParentComponent?.parentTab) {
      componentRef.instance.parentTab = item.parentTab ? item.parentTab : item.DOMParentComponent.parentTab;
    }

    if (item.parentProcess || item?.DOMParentComponent?.parentProcess) {
      componentRef.instance.parentProcess = item.parentProcess
        ? item.parentProcess
        : item.DOMParentComponent.parentProcess;
      (<AbstractDataContainer>componentRef.instance).fieldType = CompiereDataFieldType.PROCESS_PARA;
    }

    if (item.zoomTargetData) {
      componentRef.instance.zoomTargetData = item.zoomTargetData;
    }

    if (item.linkedComponents) {
      for (const element of item.linkedComponents) {
        componentRef.instance.addSubscribeOnLinkedComponent(element, componentRef);
      }
    }

    if (item.component === 'GridViewUiComponent' || item.component === 'EditTabUiComponent') {
      componentRef.instance.isReadOnly = item.data.isReadOnly;
      componentRef.instance.isInsertRecord = item.data.isInsertRecord;
    }
  }

  private static setCssClass(
    componentRef: ComponentRef<AbstractDynamicComponent>,
    item: DynamicComponent,
    isCssOnComponent: boolean
  ): void {
    if (isCssOnComponent) {
      componentRef.location.nativeElement.className = item.cssClass;
    } else {
      componentRef.instance.cssClass = item.cssClass;
    }
  }

  private static subscribeToComponentEvents(
    parent: AbstractDynamicComponent,
    { instance }: ComponentRef<AbstractDynamicComponent>
  ): void {
    instance.componentEmitter.subscribe((event) => {
      parent.container.windowFactory.newEventHandler(event);
    });
  }

  private static setChildren(
    { instance }: ComponentRef<AbstractDynamicComponent>,
    { children }: DynamicComponent
  ): void {
    if (children) {
      instance.children = children.filter((child) => child !== undefined);
      for (const child of children) child.DOMParentComponent = instance;
    }
  }
}

import { Directive } from '@angular/core';
import EditTabUiComponent from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { IupicsEvent } from '@iupics-manager/models/iupics-event';

@Directive()
export abstract class AbstractDynamicView extends AbstractDynamicComponent {
  constructor() {
    super();
  }

  onChildUpdate(event: IupicsEvent) {}
  onSiblingUpdate(event: IupicsEvent) {}
  abstract addTabToEditView(editTabUi: EditTabUiComponent): void;
}

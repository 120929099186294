import { Directive, Input, OnDestroy } from '@angular/core';
import { SubscriptionsUtils } from '@iupics-util/tools/subscriptions.util';
import { Subscription } from 'rxjs';

@Directive()
export abstract class CardContent implements OnDestroy {
  @Input() content: any;

  subscriptions: Subscription[] = [];

  constructor() {}

  ngOnDestroy(): void {
    SubscriptionsUtils.unsubscribe(...this.subscriptions);
  }
}

import { Component, forwardRef } from '@angular/core';
import CalendarUiComponent from '@iupics-components/standard/fields/calendar-ui/calendar-ui.component';
import GridViewUiComponent from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { CellEditorComp, CellEditorParams } from '@iupics/apiz-grid';
import * as moment from 'moment';

@Component({
  selector: 'iu-calendar-editor',
  template: `
    <iu-calendar-ui
      [fieldValue]="this.fieldValue"
      [isLabelDisplay]="false"
      [cssClass]="'defaultDateCalendar p-col-12 calendarEditor'"
      [isStandalone]="true"
      [data]="{ needTime: this.templates.needTime }"
      [isGridEditor]="true"
      (fieldValueModified)="onFieldModified($event)"
      (checkGridEditorEmitter)="onCalendar($event)" />
  `,
  standalone: true,
  imports: [forwardRef(() => CalendarUiComponent)],
})
export default class CalendarEditorComponent implements CellEditorComp {
  params: CellEditorParams;
  label: string;
  templates: any;
  fieldValue = null;
  activeGridView: GridViewUiComponent;

  gridInit(params: CellEditorParams) {
    this.params = params;
    this.label = this.params.label || null;
    this.templates = this.params.templates;
    this.activeGridView = this.params.activeGridView;

    if (params.value) {
      this.fieldValue = moment(params.value).toDate();
    }
  }

  refresh(params: CellEditorParams): boolean {
    if (params.value) {
      this.fieldValue = moment(params.value).toDate();
    }
    return true;
  }

  getValue() {
    return this.fieldValue;
  }

  onFieldModified($event) {
    const oldValue = this.params.row.getData(this.templates.columnName);
    let newValue: string;
    if ($event) {
      newValue = moment($event).format('YYYY-MM-DDTHH:mm:ss.SSS');
      newValue = newValue.substring(0, 26) + newValue.substring(27, newValue.length);
    }
    this.params.row.setData({ [this.templates.columnName]: newValue });

    if (!this.params.row.isSelected()) {
      this.params.row.setSelected(true); // select row
    }

    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.row.getRowData(),
        columnName: this.params.colDef.field,
        oldValue,
        newValue,
        // ...something
      };
      this.params.onClick({ ...this.params, ...params });
    }
  }

  onCalendar(event: any) {
    this.params.onCalendar(event);
  }
}

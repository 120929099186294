import { NgClass, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation, forwardRef } from '@angular/core';
import { CompiereDataGridFilterType, CompiereDataGridRequestJSON } from '@compiere-ws/models/compiere-data-json';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import ButtonUiComponent from '@iupics-components/standard/fields/button-ui/button-ui.component';
import { MixChartUiComponent } from '@iupics-components/standard/grid/mix-chart-ui/mix-chart-ui.component';
import { AppConfig } from '@iupics-config/app.config';
import { Global } from '@iupics-manager/models/global-var';
import { DynamicContainerDirective } from '@iupics-util/directives/dynamic-container.directive';
import { FilterModel } from '@iupics/apiz-grid/lib/models/data-request.model';
import { TranslateModule } from '@ngx-translate/core';
import { IupicsMenuType } from '@web-desktop/models/menu-item-ui';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import SpecificWindowUiComponent from '../specific-window-ui/specific-window-ui.component';
import { getChartData, getManufacturingResourcesRequest } from './manufacturing-resource-chart-util';

@Component({
  selector: 'iu-manufacturing-resource-chart-ui',
  templateUrl: './manufacturing-resource-chart-ui.component.html',
  styleUrls: ['./manufacturing-resource-chart-ui.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [TranslateModule, MixChartUiComponent, ScrollPanelModule,
    DynamicContainerDirective,
    NgClass, NgIf,
    forwardRef(() => ButtonUiComponent),],
})
export default class ManufacturingResourceChartComponent extends SpecificWindowUiComponent implements OnInit, OnDestroy {

  chartData: any[] = [];
  chartType: string[] = ["bar", "line"];
  adFormDetailValue: string = "Manufacturing-Resource-Chart-Child";
  paginationStep = 50;
  pageNumber = 1;
  selectedResourceID: number;
  selectedResourceName: string;
  showCharts: boolean = false;
  dateFilterFrom: string;
  dateFilterTo: string;
  selectedResourceDate: string;
  ofWindowID: number;
  isCheckLimit: boolean = false;
  isDrafted : boolean = true; 

  constructor(private appConfig: AppConfig) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.ofWindowID = this.appConfig.getConstant("ManufacturingOperationResourceWindow");
  }

  getChartData() {

    const request = getManufacturingResourcesRequest(this.fields.find((f) => f.component === 'GridViewUiComponent' && f.name === this.adFormDetailValue)
      .formDetailId,
      this.parentFormID,
      this.pageNumber,
      this.paginationStep,
      this.selectedResourceID,
      this.dateFilterFrom,
      this.dateFilterTo,
      this.isDrafted
    );

    this.store.getDataGrid(request).subscribe(data => {
      this.showCharts = true;
      this.chartData = getChartData(data["data"], this.isCheckLimit);
    });
  }

  notifyFromDataChange(item?: any) {

    const columnName: string = item["data"]["columnName"];
    let refreshData: boolean = true;

    switch (columnName) {

      case "DateFilterFrom":
        
        if (this.dateFilterFrom == this.dataStore.data[columnName])
          refreshData = false;
        else {
          this.dateFilterFrom = this.dataStore.data[columnName];
          refreshData = true;
        }
        
        break;
      
      case "DateFilterTo":
        if (this.dateFilterTo == this.dataStore.data[columnName])
          refreshData = false;
        else {
          this.dateFilterTo = this.dataStore.data[columnName];
          refreshData = true;
        }
        break;
      
      case "filterWHLimit":
        this.isCheckLimit = !this.isCheckLimit;
        break;
      
      case "IsDraft":
        this.isDrafted = !this.isDrafted;
        refreshData = true;
        break;

      case "Z_ProductionRessource_ID":
        if (this.dataStore.data[columnName]) {

          if (this.selectedResourceID == this.dataStore.data[columnName]["id"]) {
            refreshData = false;
          } else {
            this.selectedResourceID = this.dataStore.data[columnName]["id"];
            this.selectedResourceName = this.dataStore.data[columnName]["displayValue"];
            refreshData = true;
          }
          
        } else {
          this.selectedResourceID = null;
          refreshData = false;
        }
        break;
      
      default:
        break;
    }

    if (refreshData) {
      this.showCharts = false;
      this.chartData = [];
      this.refreshGrids();
    }
  }

  refreshGrids() {
    if (this.selectedResourceID) {
      this.getChartData();
    }
  }


  getFilterDate(): FilterModel {
    
    let filterModel: FilterModel = {};

    if (!this.dateFilterFrom)
      this.dateFilterFrom = this.dataStore.data["DateFilterFrom"];

    if (!this.dateFilterTo)
      this.dateFilterTo = this.dataStore.data["DateFilterTo"];
    
    filterModel["XXA_startDate"] = {
      filterType: CompiereDataGridFilterType.DATE,
      values: [this.selectedResourceDate],
      operators: [OperatorFilterType.LESSTHANOREQUAL],
      configs: [undefined],
            
    };

    filterModel["XXA_endDate"] = {
      filterType: CompiereDataGridFilterType.DATE,
      values: [this.selectedResourceDate],
      operators: [OperatorFilterType.BIGGERTHANOREQUAL],
      configs: [undefined]
    };

    filterModel["Z_ProductionRessource_ID"] = {
      filterType: CompiereDataGridFilterType.SET,
      values: [this.dataStore.data["Z_ProductionRessource_ID"]["id"]],
      operators: [OperatorFilterType.EQUALS],
      configs: [undefined]
    };

    return filterModel;
  }

  getDataGridRequestFilter(): CompiereDataGridRequestJSON {
    
    let dataGridRequestFilter: CompiereDataGridRequestJSON = {};
    dataGridRequestFilter.filterModel = this.getFilterDate();
    return dataGridRequestFilter;
  }


  openNewTab(selectedResourceDate) {
    this.selectedResourceDate = selectedResourceDate;
    this.customZoom();
    
  }

  customZoom() {
    
    const source = {
      id: null,
      menuType: IupicsMenuType.WINDOW,
    };

    Global.workspace.urlParams.dataGridRequest = this.getDataGridRequestFilter();
    Global.workspace.openTargetSearch({
      zoomInfo: this.zoomInfo,
      cat: { id: parseInt(this.ofWindowID + "", 10) },
      source,
      ctx: null,
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
import { Injectable } from '@angular/core';
import { AppConfig } from '@iupics-config/app.config';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import * as shajs from 'sha.js';

export enum LocalStorageIupics {
  crypto_key = 'iu-lafka',
  default_language = 'iu-kawada',
  device_id = 'iu-Raruk',
}

@Injectable({
  providedIn: 'root',
})
export class IupicsCookieService {
  readonly cookieSeparator = '|=';

  constructor(
    private appConfig: AppConfig,
    private cookieService: CookieService
  ) {}

  generateRandomKeyCookie(): string {
    const randKey: string = shajs('sha256')
      .update(
        Math.random()
          .toString(36)
          .substring(2, 2 + 9) + moment().valueOf()
      )
      .digest('hex');
    localStorage.setItem(LocalStorageIupics.crypto_key, randKey);
    return randKey;
  }

  setAndEncryptCookie(cookieName: string, value: string, expires?: Date) {
    const key = localStorage.getItem(LocalStorageIupics.crypto_key);
    const encodedCookie = CryptoJS.AES.encrypt(value, key);
    if (!expires) {
      expires = new Date();
      expires.setTime(expires.getTime() + this.appConfig.getConstant('cookieLifeTimeSecond') * 1000);
    }
    this.cookieService.set(cookieName, encodedCookie, expires, '/');
  }

  getDecryptedCookie(cookieName: string): string {
    const key = localStorage.getItem(LocalStorageIupics.crypto_key);
    const cookie = this.cookieService.get(cookieName);
    let decryptedCookie = '';
    try {
      decryptedCookie = CryptoJS.AES.decrypt(cookie, key).toString(CryptoJS.enc.Utf8);
    } catch (e) {
      this.clearLsCookies();
      location.reload();
    }
    return decryptedCookie;
  }

  setAndEncryptLocalStorage(keyName: string, value: string) {
    const key = localStorage.getItem(LocalStorageIupics.crypto_key);
    const encodedValue = CryptoJS.AES.encrypt(value, key);
    localStorage.setItem(keyName, encodedValue);
  }

  getDecryptedLocalStorage(keyName: string): string {
    const key = localStorage.getItem(LocalStorageIupics.crypto_key);
    const encodedValue = localStorage.getItem(keyName);
    let decryptedValue = '';
    try {
      decryptedValue = CryptoJS.AES.decrypt(encodedValue, key).toString(CryptoJS.enc.Utf8);
    } catch (e) {
      this.clearLsCookies();
      location.reload();
    }
    return decryptedValue;
  }

  check(cookieName: string): boolean {
    return this.cookieService.check(cookieName);
  }

  checkLS(keyName: string): boolean {
    return localStorage.getItem(keyName) !== null;
  }

  delete(cookieName: string) {
    this.cookieService.delete(cookieName);
  }
  clearLsCookies() {
    this.cookieService.deleteAll('/');
    localStorage.clear();
  }

  deleteLS(keyName: string) {
    localStorage.removeItem(keyName);
  }
}

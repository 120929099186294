import { Pipe, PipeTransform } from '@angular/core';
import { ProcessPingInfoPara } from '@compiere-ws/models/process-ping-info';

@Pipe({
    name: 'parseInstanceParams',
    standalone: true,
})
export class ParseInstanceParamsPipe implements PipeTransform {
  transform(value: ProcessPingInfoPara): any {
    let returnValue = '';
    if (value.P_DATE !== undefined && value.P_DATE !== null) {
      returnValue += value.P_DATE;
      if (value.P_DATE_TO !== undefined && value.P_DATE_TO !== null) {
        returnValue += ` à ${value.P_DATE_TO}`;
      }
    } else if (value.P_NUMBER !== undefined && value.P_NUMBER !== null) {
      returnValue += value.P_NUMBER;
      if (value.P_NUMBER_TO !== undefined && value.P_NUMBER_TO !== null) {
        returnValue += ` à ${value.P_NUMBER_TO}`;
      }
    } else if (value.P_STRING !== undefined && value.P_STRING !== null) {
      returnValue += value.P_STRING;
      if (value.P_STRING_TO !== undefined && value.P_STRING_TO !== null) {
        returnValue += ` à ${value.P_STRING_TO}`;
      }
    } else {
      returnValue += ' null';
    }
    return returnValue;
  }
}

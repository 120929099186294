import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import * as pdfjs from 'pdfjs-dist';
import workerSrc from 'pdfjs-dist/build/pdf.worker.js';
@Component({
  selector: 'iu-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
  standalone: true,
})
export default class PdfViewerComponent implements OnInit {
  @Input() dataUrl;
  @ViewChild('pdfViewer', { read: ElementRef, static: false }) pdfViewer: ElementRef<HTMLDivElement>;
  constructor() {}

  ngOnInit(): void {
    if (this.dataUrl) this.displayPdf(this.dataUrl);
  }
  displayPdf(pdfDataUrl: string) {
    // Use the PDFJS worker from the pdfjs-dist library
    pdfjs.GlobalWorkerOptions.workerSrc = workerSrc;
    // Load the PDF from the data URL
    pdfjs.getDocument(pdfDataUrl).promise.then((pdf) => {
      // Get the first page of the PDF
      for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
        pdf.getPage(pageNum).then((page) => {
          const scale = window.devicePixelRatio + 1.5 || 2.5;
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          const viewport = page.getViewport({ scale: scale });

          canvas.width = viewport.width;
          canvas.style.width = '100%';
          canvas.height = viewport.height;

          const renderContext = {
            canvasContext: context!,
            viewport: viewport,
          };

          page.render(renderContext);
          this.pdfViewer.nativeElement.appendChild(canvas);
        });
      }
    });
  }
}

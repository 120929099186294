<div
  class="text-field-wrapper"
  [life]="3000"
  [hideDelay]="250"
  pTooltip="{{ tooltip }}"
  [escape]="false"
  tooltipPosition="top"
  [showDelay]="500"
  tooltipZIndex="2100">
  <span class="text-field-label" iuLoading [dataToWatch]="label">
    {{ label }}
  </span>

  @if (withColorIndicator && colorIndicatorCondition !== null) {
    <div class="text-field-value-wrapper">
      <span class="text-field-value">{{ value?.displayValue ?? value ?? 'textField.noValue' | translate }}</span>
      <i
        [ngClass]="{
          'text-field-icon': true,
          'indicator-good icon-checkmark': colorIndicatorCondition,
          'indicator-bad icon-cross': !colorIndicatorCondition
        }"
        aria-hidden="true"></i>
    </div>
  } @else {
    <span class="text-field-value" iuLoading [dataToWatch]="value" [timeout]="loadingAnimationTimeout">
      {{ value?.displayValue ?? value ?? 'textField.noValue' | translate }}
    </span>
  }
</div>

import { NgClass } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  ViewRef,
} from '@angular/core';
import { CompiereDataGridRequestJSON } from '@compiere-ws/models/compiere-data-json';
import { ViewType } from '@iupics-components/models/view-type.enum';
import { AppConfig } from '@iupics-config/app.config';
import { Global } from '@iupics-manager/models/global-var';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Tab } from '@web-desktop/models/Tab';
import { IupicsMenuType } from '@web-desktop/models/menu-item-ui';
import { TooltipModule } from 'primeng/tooltip';
import { KeybindingDirective } from '../../../../../iupics-components/directives/keybinding.directive';

@Component({
  selector: 'iu-tab-ui',
  templateUrl: './tab-ui.component.html',
  styleUrls: ['./tab-ui.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [TooltipModule, NgClass, KeybindingDirective, TranslateModule],
})
export default class TabUiComponent implements OnInit, Tab {
  static cpt = 0;

  @ViewChild('tab', { static: true })
  ref: ElementRef;
  @ViewChild('removeBtn', { static: true })
  removeBtn: ElementRef;

  actionID: number;
  angularClass?: string;
  appName: string;
  ctx: any;
  dataGridRequest: CompiereDataGridRequestJSON;
  domWinId: string;
  iconClass: string;
  id: string;
  isActive = false;
  isLoading = false;
  menuType: IupicsMenuType;
  name: string;
  openedRecordId: string;
  othersRecordId: { tabId: string; recordId: string }[];
  parentId: string;
  processParamsMap: Map<string, any>;
  splitViewRef: ViewRef = null;
  tabWidth: number;
  viewType: ViewType;
  windowId: number;
  zoomInfo: any;

  destroyTabEmitter: EventEmitter<any> = new EventEmitter();
  setActiveTabEmitter: EventEmitter<any> = new EventEmitter();
  closeWindowEmitter: EventEmitter<any> = new EventEmitter();
  moveTabEmitter: EventEmitter<any> = new EventEmitter();
  createTabContainerEmitter: EventEmitter<any> = new EventEmitter();

  constructor(
    private translateService: TranslateService,
    private config: AppConfig
  ) {}
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    event.stopPropagation();
    this.tabWidth = Global.isMobileWidth()
      ? this.config.getConstant('TabUiComponent#tabWidth_mobile')
      : this.config.getConstant('TabUiComponent#tabWidth_not-mobile');
  }

  ngOnInit() {
    this.tabWidth = Global.isMobileWidth()
      ? this.config.getConstant('TabUiComponent#tabWidth_mobile')
      : this.config.getConstant('TabUiComponent#tabWidth_not-mobile');
  }

  /* methods used by tabs*/
  handleDragStart(ev: DragEvent): void {
    ev.dataTransfer.setData('fireEvent', 'draggend');
    Global.draggedComponent = this;
    this.ref.nativeElement.parentElement.classList.add('dragged');
    ev.stopPropagation();
  }

  handleDragEnd(ev: DragEvent): void {
    this.ref.nativeElement.parentElement.classList.remove('dragged');
    Global.draggedComponent = null;
  }

  handleDrop(ev: DragEvent): boolean {
    ev.preventDefault();
    if (Global.draggedComponent === null || Global.draggedComponent.id.startsWith('tabContainer')) {
      return false;
    } else {
      // cannot drop in current tab if it's inside a tab container or dragged tab is from a tab container or dragged tab is dropped on itself
      if (this.id !== Global.draggedComponent.id && !this.parentId.startsWith('tabContainer')) {
        ev.stopPropagation();
        this.createTabContainerEmitter.emit({ draggedTab: Global.draggedComponent, droppedInTab: this });
        Global.draggedComponent = null;
      }
    }
  }

  handleDragOver(ev: any): boolean {
    // drop zone is the dragged component itself
    ev.preventDefault();
    if (Global.draggedComponent === null) {
      return false;
    }
    if (
      Global.draggedComponent.id !== this.id &&
      !this.parentId.startsWith('tabContainer') &&
      (Global.draggedComponent.id.startsWith('tabContainer') ||
        !Global.draggedComponent.parentId.startsWith('tabContainer'))
    ) {
      // checking on which side dropzone should be displayed
      const bounding = Global.draggedComponent.ref.nativeElement.getBoundingClientRect();
      const boundingOver = this.ref.nativeElement.getBoundingClientRect();
      const x = (ev.layerX / this.ref.nativeElement.clientWidth) * 100;
      if (bounding.x > boundingOver.x && x < 40) {
        this.moveTabEmitter.emit({ tab: this, move: 0 });
      }
      if (bounding.x < boundingOver.x && x > 60) {
        this.moveTabEmitter.emit({ tab: this, move: 0 });
      }
    }
  }

  setActive(ev?: MouseEvent): void {
    if (!this.isLoading && !this.isActive) {
      this.setActiveTabEmitter.emit(this);
    }
  }

  handleRemove(): void {
    if (this.name !== this.translateService.instant('tabUi.loading')) {
      this.closeWindowEmitter.emit({ tabId: this.id, tabUI: this });
    }
  }

  destroyTab(): void {
    this.destroyTabEmitter.emit(this);
  }

  handleKeybind(event: KeyboardEvent): void {
    event.stopPropagation();
  }
}

import { Injectable } from '@angular/core';
import { AppConfig } from '@iupics-config/app.config';
import { IupicsDashboardCategory, IupicsDashboardItem } from '@iupics-manager/models/iupics-dashboard';
import { Observable, map, shareReplay } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private readonly dashboardURL: string;
  private readonly dashboardCatsURL: string;
  private readonly dashboardItemsURL: string;
  private widgetDashboardCats$: Observable<IupicsDashboardCategory[]>;
  private widgetDashboardCats: IupicsDashboardCategory[];
  constructor(
    private http: ApiService,
    private config: AppConfig
  ) {
    this.dashboardURL = this.config.getBackendResource('dashboard');
    this.dashboardCatsURL = this.config.getBackendResource('dashboardCats');
    this.dashboardItemsURL = this.config.getBackendResource('dashboardItems');
  }

  private getDashboardCats(): Observable<IupicsDashboardCategory[]> {
    return this.http.get<IupicsDashboardCategory[]>(this.dashboardCatsURL);
  }
  saveDashboardCat(dashboardCat: IupicsDashboardCategory): Observable<IupicsDashboardCategory> {
    return this.http.post<IupicsDashboardCategory>(this.dashboardCatsURL, dashboardCat);
  }
  saveDashboardCats(dashboardCats: IupicsDashboardCategory[]): Observable<IupicsDashboardCategory[]> {
    return this.http.post<IupicsDashboardCategory[]>(this.dashboardCatsURL + '/bulk', dashboardCats);
  }
  updateDashboardCat(dashboardCat: IupicsDashboardCategory): Observable<IupicsDashboardCategory> {
    return this.http.put<IupicsDashboardCategory>(this.dashboardCatsURL, dashboardCat);
  }
  deleteDashboardCat(dashboardCatId: number): Observable<boolean> {
    return this.http.delete<boolean>(this.dashboardCatsURL + '/' + dashboardCatId);
  }
  resetDashboard(): Observable<boolean> {
    return this.http.delete<boolean>(this.dashboardURL + '/reset');
  }
  setAsDefaultRoleDashboard(): Observable<boolean> {
    return this.http.get<boolean>(this.dashboardURL + '/default');
  }

  saveDashboardItemsOrders(
    dashboardCatId: number,
    dashboardItems: IupicsDashboardItem[]
  ): Observable<IupicsDashboardItem[]> {
    return this.http.post<IupicsDashboardItem[]>(
      `${this.dashboardItemsURL.replace('{catId}', '' + dashboardCatId)}/bulk`,
      dashboardItems
    );
  }
  saveDashboardItem(dashboardCatId: number, dashboardItem: IupicsDashboardItem): Observable<IupicsDashboardItem> {
    return this.http.post<IupicsDashboardItem>(
      this.dashboardItemsURL.replace('{catId}', '' + dashboardCatId),
      dashboardItem
    );
  }
  updateDashboardItem(dashboardCatId: number, dashboardItem: IupicsDashboardItem): Observable<IupicsDashboardItem> {
    return this.http.put<IupicsDashboardItem>(
      this.dashboardItemsURL.replace('{catId}', '' + dashboardCatId),
      dashboardItem
    );
  }
  deleteDashboardItem(dashboardCatId: number, dashboardItemId: number): Observable<boolean> {
    return this.http.delete<boolean>(
      this.dashboardItemsURL.replace('{catId}', '' + dashboardCatId) + '/' + dashboardItemId
    );
  }

  resetCachingData() {
    this.widgetDashboardCats$ = undefined;
    this.widgetDashboardCats = [];
  }

  getIupicsDashboardCats(forceRefresh = false): Observable<IupicsDashboardCategory[]> {
    if (!forceRefresh && this.widgetDashboardCats$) {
      return this.widgetDashboardCats$;
    } else {
      this.widgetDashboardCats$ = this.getDashboardCats().pipe(
        map((widgetDashboardCats) => {
          this.widgetDashboardCats = widgetDashboardCats;
          return this.widgetDashboardCats;
        }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      );
      return this.widgetDashboardCats$;
    }
  }
}

import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IupicsComponentType, IupicsContextMenuComponent } from '@web-desktop/models/iupics-context-menu';
import { MenuItem } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class ContextMenuService {
  listValuePreferenceComponents = [
    'InputLocationUiComponent',
    'AutocompleteUiComponent',
    'InputImageUiComponent',
    'InputNumberUiComponent',
    'InputSwitchUiComponent',
    'InputTextUiComponent',
    'InputTimeUiComponent',
  ];
  shortcutContextMenu: MenuItem[] = [
    {
      label: 'contextmenu.deleteshortcut',
      icon: 'fa fa-trash',
      title: 'contextmenu.deleteshortcut',
    },
    {
      label: 'contextmenu.personalizeshortcut',
      icon: 'fa fa-wrench',
      title: 'contextmenu.personalizeshortcut',
    },
  ];

  fieldContextMenu: MenuItem[] = [
    {
      label: 'contextmenu.zoom',
      icon: 'fa fa-search-plus',
      title: 'contextmenu.zoom',
      id: 'zoom',
    },
  ];

  fieldZoomContextMenuWithRefresh: MenuItem[] = [
    {
      label: 'contextmenu.zoom',
      icon: 'fa fa-search-plus',
      title: 'contextmenu.zoom',
      id: 'zoom',
    },
    {
      label: 'contextmenu.refresh',
      icon: 'fa fa-sync',
      title: 'contextmenu.refresh',
      id: 'refresh',
    },
  ];
  fieldZoomContextMenu: MenuItem[] = [
    {
      label: 'contextmenu.zoom',
      icon: 'fa fa-search-plus',
      title: 'contextmenu.zoom',
      id: 'zoom',
    },
  ];
  fieldZoomFormContextMenu: MenuItem[] = [
    {
      label: 'contextmenu.new',
      icon: 'fa fa-plus',
      title: 'contextmenu.new',
      id: 'new',
    },
  ];
  fieldZoomFormContextMenuWithRefresh: MenuItem[] = [
    {
      label: 'contextmenu.refresh',
      icon: 'fa fa-refresh',
      title: 'contextmenu.refresh',
      id: 'refresh',
    },
    {
      label: 'contextmenu.new',
      icon: 'fa fa-plus',
      title: 'contextmenu.new',
      id: 'new',
    },
  ];
  addressFieldContextMenu: MenuItem[] = [
    {
      label: 'contextmenu.zoom',
      icon: 'fa fa-search-plus',
      title: 'contextmenu.zoom',
      id: 'zoom',
    },
  ];
  widgetContextMenu: MenuItem[] = [
    {
      label: 'contextmenu.deletewidget',
      icon: 'fa fa-trash',
      badge: '5',
      title: 'contextmenu.deletewidget',
    },
  ];

  menuUiContextMenu: MenuItem[] = [
    {
      label: 'contextmenu.add',
      icon: 'fa fa-external-link',
      title: 'contextmenu.add',
    },
  ];

  pinDialogUiContextMenu: MenuItem[] = [
    {
      id: 'pin-close-window',
      label: 'contextmenu.closewindow',
      icon: 'icon-close',
      title: 'contextmenu.closewindow',
    },
  ];

  breadcrumbUiContextMenu: MenuItem[] = [
    {
      id: 'close-view',
      label: 'contextmenu.close',
      title: 'contextmenu.close',
    },
  ];

  workspaceUiContextMenu: MenuItem[] = [
    {
      id: 'change-theme',
      label: 'contextmenu.changetheme',
      icon: 'fa fa-paint-brush',
      title: 'contextmenu.changetheme',
    },
  ];

  stepperUiContextMenu: MenuItem[] = [
    {
      id: 'show-process-in-progress',
      icon: 'fa fa-external-link',
      label: 'contextmenu.showProcessInProgress',
      title: 'contextmenu.showProcessInProgress',
    },
  ];

  emitter: EventEmitter<any> = new EventEmitter();

  constructor(private translate: TranslateService) {}

  getContextMenu(component: any): MenuItem[] {
    switch ((<IupicsContextMenuComponent>component).componentType) {
      case IupicsComponentType.SHORTCUT:
        this.shortcutContextMenu.forEach((item: MenuItem) => {
          item.label = this.translate.instant(item.label);
          item.title = this.translate.instant(item.title);
        });
        return this.shortcutContextMenu;
      case IupicsComponentType.WIDGET:
        this.widgetContextMenu.forEach((item: MenuItem) => {
          item.label = this.translate.instant(item.label);
          item.title = this.translate.instant(item.title);
        });
        return this.widgetContextMenu;
      case IupicsComponentType.MENU_UI:
        this.menuUiContextMenu.forEach((item: MenuItem) => {
          item.label = this.translate.instant(item.label);
          item.title = this.translate.instant(item.title);
        });
        return this.menuUiContextMenu;
      case IupicsComponentType.PIN_DIALOG_UI:
        this.pinDialogUiContextMenu.forEach((item: MenuItem) => {
          item.label = this.translate.instant(item.label);
          item.title = this.translate.instant(item.title);
        });
        return this.pinDialogUiContextMenu;
      case IupicsComponentType.BREADCRUMB:
        this.breadcrumbUiContextMenu.forEach((item: MenuItem) => {
          item.label = this.translate.instant(item.label);
          item.title = this.translate.instant(item.title);
        });
        return this.breadcrumbUiContextMenu;
      case IupicsComponentType.WORKSPACE:
        this.workspaceUiContextMenu.forEach((item: MenuItem) => {
          item.label = this.translate.instant(item.label);
          item.title = this.translate.instant(item.title);
        });
        return this.workspaceUiContextMenu;
      case IupicsComponentType.FIELD:
        this.fieldContextMenu.forEach((item: MenuItem) => {
          item.label = this.translate.instant(item.label);
          item.title = this.translate.instant(item.title);
        });
        return this.fieldContextMenu;
      case IupicsComponentType.ADDRESSFIELD:
        this.addressFieldContextMenu.forEach((item: MenuItem) => {
          item.label = this.translate.instant(item.label);
          item.title = this.translate.instant(item.title);
        });
        return this.addressFieldContextMenu;
      case IupicsComponentType.FIELDZOOM:
        if (component.data) {
          if (component.data.validationCode) {
            this.fieldZoomContextMenu.forEach((item: MenuItem) => {
              item.label = this.translate.instant(item.label);
              item.title = this.translate.instant(item.title);
            });
            return this.fieldZoomContextMenu;
          } else {
            this.fieldZoomContextMenuWithRefresh.forEach((item: MenuItem) => {
              item.label = this.translate.instant(item.label);
              item.title = this.translate.instant(item.title);
            });
            return this.fieldZoomContextMenuWithRefresh;
          }
        } else {
          return undefined;
        }
      case IupicsComponentType.FIELDFORM:
        if (component.data) {
          if (component.data.isSearch || component.data.validationCode) {
            this.fieldZoomFormContextMenu.forEach((item: MenuItem) => {
              item.label = this.translate.instant(item.label);
              item.title = this.translate.instant(item.title);
            });
            return this.fieldZoomFormContextMenu;
          } else {
            this.fieldZoomFormContextMenuWithRefresh.forEach((item: MenuItem) => {
              item.label = this.translate.instant(item.label);
              item.title = this.translate.instant(item.title);
            });
            return this.fieldZoomFormContextMenuWithRefresh;
          }
        } else {
          return undefined;
        }
      case IupicsComponentType.STEPPER:
        this.stepperUiContextMenu.forEach((item: MenuItem) => {
          item.label = this.translate.instant(item.label);
          item.title = this.translate.instant(item.title);
        });
        return this.stepperUiContextMenu;
      default:
        return undefined;
    }
  }

  showContextMenu(component: any, callback: any, originalEvent) {
    let contextMenu = this.getContextMenu(component);
    if (
      component &&
      component.data &&
      !['DocumentNo', 'Value'].includes(component.data.columnName) &&
      !component.data.readOnlyLogic &&
      !component.isReadOnly
    ) {
      if (component.fieldValue) {
        if (contextMenu) {
          contextMenu = [
            ...contextMenu,
            {
              label: this.translate.instant('contextmenu.valuePreference'),
              icon: 'far fa-bookmark',
              title: this.translate.instant('contextmenu.valuePreference'),
              id: 'valuePreference',
            },
          ];
        } else {
          contextMenu = [
            {
              label: this.translate.instant('contextmenu.valuePreference'),
              icon: 'far fa-bookmark',
              title: this.translate.instant('contextmenu.valuePreference'),
              id: 'valuePreference',
            },
          ];
        }
      }
    }
    if (contextMenu) {
      for (const item of contextMenu) {
        item.command = (event) => {
          callback(event);
        };
      }
      this.emitter.emit({
        menuItems: contextMenu,
        originalEvent: originalEvent,
      });
    }
  }
}

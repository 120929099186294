import { NgClass, NgIf } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import EditTabUiComponent from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { AbstractDataContainer, AbstractDataContainerCallout } from '@iupics-manager/models/abstract-datacontainer';
import { IupicsDataField } from '@iupics-manager/models/iupics-data';
import { IupicsEvent } from '@iupics-manager/models/iupics-event';
import { TextLimitPipe } from '@iupics-util/pipes/text-limit/text-limit.pipe';
import { TooltipModule } from 'primeng/tooltip';
import PrimeOverlayComponent from '../../../overrided/prime-overlay/prime-overlay.component';
import ValuePreferencePanelComponent from '../../value-preference-panel/value-preference-panel.component';

@Component({
  selector: 'iu-input-textarea-ui',
  templateUrl: './input-textarea-ui.component.html',
  styleUrls: ['./input-textarea-ui.component.css'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    TooltipModule,
    NgIf,
    NgClass,
    FormsModule,
    PrimeOverlayComponent,
    forwardRef(() => ValuePreferencePanelComponent),
    TextLimitPipe,
  ],
})
export default class InputTextareaUiComponent extends AbstractDataContainer implements OnInit, AfterViewInit {
  @Input() data: IupicsDataField;

  cssClassMandatory: string;
  @Input()
  columnName: string;
  @ViewChild('input', { static: true })
  inputRef: ElementRef;
  dataContainers: AbstractDataContainerCallout;

  @Input()
  resize: 'vertical' | 'horizontal' | 'auto' | 'none' = 'vertical';
  @Input()
  shouldSyncHeightWithLine = false;
  @Input()
  maxRows = 5;

  get rows() {
    const split = (this.fieldValue as string)?.split(/\r*\n/).length;
    if (split > this.maxRows) {
      return this.maxRows;
    }
    return split || 1;
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.cssClass !== undefined) {
      this.cssGrid = this.cssClass;
    }
    this.cssClass = ' ' + this.cssGrid;
    this.setFieldMandatory();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    let parentComp = this.DOMParentComponent;
    while (parentComp && !(parentComp instanceof EditTabUiComponent)) {
      parentComp = parentComp.DOMParentComponent;
    }
    if (parentComp instanceof EditTabUiComponent) {
      this.dataContainers = new AbstractDataContainerCallout(parentComp.dataContainers);
    }
  }

  onChildUpdate(event: IupicsEvent) {}
  onSiblingUpdate(event: IupicsEvent) {}
  onRemoveComponent(event: IupicsEvent) {}
}

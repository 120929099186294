import { Component, inject } from '@angular/core';
import { SKWTranslatePipe } from '../../pipes/storekeeper-window-translate.pipe';
import { SKWMessageService } from '../../services/storekeeper-window-message.service';

@Component({
  selector: 'iu-storekeeper-window-messages',
  standalone: true,
  imports: [SKWTranslatePipe],
  templateUrl: './storekeeper-window-messages.component.html',
  styleUrl: './storekeeper-window-messages.component.scss',
  host: {
    class: 'messages',
  },
})
export class StorekeeperWindowMessagesComponent {
  #SKWMessageService = inject(SKWMessageService);
  messages = this.#SKWMessageService.messages;
}

import { NgClass, NgIf, NgStyle } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  forwardRef,
  inject,
} from '@angular/core';
import { Global } from '@iupics-manager/models/global-var';
import { ZIndexAutoDirective, ZIndexHandlerService } from '@iupics/apiz-grid';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DomHandler } from 'primeng/dom';
import { ProgressBarModule } from 'primeng/progressbar';
import { ZIndexUtils } from 'primeng/utils';
import ButtonUiComponent from '../../fields/button-ui/button-ui.component';

@Component({
  selector: 'iu-modal-ui',
  templateUrl: './modal-ui.component.html',
  styleUrls: ['./modal-ui.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgStyle,
    NgIf,
    ProgressBarModule,
    ButtonModule,
    TranslateModule,
    forwardRef(() => ButtonUiComponent),
    ZIndexAutoDirective,
  ],
})
export default class ModalUiComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() title: string;
  @Input() hasCloseBtn = false;
  @Input() hasFooter = false;
  @Input() closeBtnLabel = 'generic.close';
  @Input() angularStyle: any;
  @Input() isSearch = false;
  @Input() isModalDisplay = true;
  @Input() modalClass = 'p-col-10 p-md-10 p-lg-10';
  @Input() isLoading = false;
  @Input() appendTo = null;
  @ViewChild('container', { static: true }) containerViewChild: ElementRef;
  @Output() closeModalEmitter = new EventEmitter<any>();
  @Input() contentType: ModalContentType;
  #zIndexHandlerService = inject(ZIndexHandlerService);
  isCloseInit = false;
  baseZIndex = 0;
  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    ZIndexUtils.set('modal', this.containerViewChild.nativeElement, 0);
    this.#zIndexHandlerService.zIndexes.set(this.containerViewChild.nativeElement, {
      key: this.containerViewChild.nativeElement,
      value: ZIndexUtils.get(this.containerViewChild.nativeElement),
    });
  }

  ngAfterViewInit() {
    if (this.appendTo) {
      if (this.appendTo === 'body') {
        Global.activeModals.push(this);
        document.body.appendChild(this.containerViewChild.nativeElement);
      } else {
        DomHandler.appendChild(this.containerViewChild.nativeElement, this.appendTo);
      }
    }
  }

  ngOnDestroy() {
    if (Global.activeModals && Global.activeModals.length > 0) {
      const index = Global.activeModals.indexOf(this);
      if (index > -1) {
        Global.activeModals.splice(index, 1);
      }
    }
    if (this.isModalDisplay) {
      this.isModalDisplay = false;
      this.closeModalEmitter.emit();
    }

    ZIndexUtils.clear(this.containerViewChild.nativeElement);
    this.#zIndexHandlerService.clear(this.containerViewChild.nativeElement);
    if (this.appendTo) {
      this.el.nativeElement.appendChild(this.containerViewChild.nativeElement);
    }
  }

  close($event: Event = null, forceClose = false) {
    if (forceClose || !$event || ($event.target as any).classList.contains('iu-modal-background') === true) {
      if ($event) {
        $event.stopPropagation();
      }
      if (this.isModalDisplay) {
        this.isModalDisplay = false;
      }
      ZIndexUtils.clear(this.containerViewChild.nativeElement);
      this.closeModalEmitter.emit();
    }
  }

  initClose($event: MouseEvent) {
    if (($event.target as any).classList.contains('iu-modal-background') === true) {
      this.isCloseInit = true;
      const a = this.renderer.listen(document.documentElement, 'mouseup', ($e: MouseEvent) => {
        this.close($e);
        this.isCloseInit = false;
        a();
      });
      const b = this.renderer.listen(document.documentElement, 'touchend', ($e: TouchEvent) => {
        this.close($e);
        this.isCloseInit = false;
        b();
      });
    }
  }
}

export enum ModalContentType {
  WINDOW = 'window',
  PROCESS = 'process',
  FORM = 'form',
  JOINFILE = 'joinFiles',
}

<span
  #spanRef
  [style.display]="displayCss"
  (contextmenu)="onContextMenu($event)"
  (press)="onContextMenu($any($event))"
  [style.backgroundColor]="backgroundColorCss"
  [style.margin-top.px]="data && data.marginTop > 0 ? data.marginTop : null">
  <iu-prime-autocomplete
    [componentType]="componentType"
    (onLabelClickEmitter)="zoomAcross()"
    (completeMethod)="search($event)"
    (mousedown)="onMouseDown($event)"
    [isLabelDisplay]="this.isLabelDisplay"
    (onFocus)="search($event)"
    (click)="onInputClick($event)"
    (onBlur)="blurEmitter.emit($event)"
    (onSelect)="dataChange(autoComplete.value)"
    (onUnselect)="dataChange(autoComplete.value)"
    (showFormPanelEmitter)="showFormPanel($event)"
    (showSearchPanelEmitter)="showSearchPanel()"
    [suggestions]="suggestions"
    [data]="data"
    [columnName]="columnName"
    [dataStored]="dataStored"
    [description]="tooltip"
    [readonly]="isReadOnly"
    [disabled]="isReadOnly"
    [dropdown]="true"
    [iconClass]="iconClass"
    [label]="label"
    [multiple]="multiple"
    [ngClass]="[hasConflict ? 'iu-field-conflict' : '']"
    [scrollHeight]="scrollHeight"
    [suggestionsFilter]="suggestionsFilter"
    [tabID]="data?.tabId"
    [isEditorView]="isEditorView"
    [isInsideOverflow]="isInsideOverflow"
    [isStandalone]="isStandalone"
    [overridedCSS]="overridedCSS"
    [appendTo]="appendTo"
    [placeholder]="placeholder"
    [styleLess]="styleLess()"
    #autoComplete />
  @if (hasConflict && dataStored) {
    <i
      #spanInput
      class="rtconflict fas fa-exclamation-triangle iconClass iu-field-conflict-icon"
      (click)="showConflictPanel($event)"
      aria-hidden="true"></i>
  }
  @if (showValuePrefPanel) {
    <iu-prime-overlay #opValuePref [dismissable]="true" appendTo="body">
      <iu-value-preference-panel
        [overlayPanel]="opValuePref"
        [fieldValue]="fieldValue"
        [dataStored]="this.dataStored"
        [sourceComponent]="this"
        [data]="this.data"
        (closeEmitter)="toggleValuePreferencePanel($event)" />
    </iu-prime-overlay>
  }
</span>

<iu-prime-overlay #opConflict>
  <a class="data-conflict" (click)="chooseData($event, (dataStored?.data)[data.columnName], true); opConflict.hide()">
    {{ (dataStored?.data)[data.columnName]?.displayValue }}
  </a>
  |
  <a class="data-conflict" (click)="chooseData($event, conflictedData, false); opConflict.hide()">
    {{ conflictedData?.displayValue }}
  </a>
</iu-prime-overlay>

@if (displaySearch) {
  <iu-modal-ui
    data-cy="search-panel"
    [attr.data-cy-columnName]="data.columnName"
    [title]="label"
    [hasCloseBtn]="true"
    (closeModalEmitter)="hideSearchPanel()"
    [appendTo]="'body'">
    <ng-template #autocompleteUniversalFilter>
      <div class="autocomplete-universal-filter" style="--az-panel-z-index: 10000">
        <iu-universal-filter-ui [gridTab]="gridTab" />
      </div>
    </ng-template>
    <apiz-toolbar [filterTplRef]="autocompleteUniversalFilter" />
    <iu-grid-tab-infinity-scroll-headless-ui
      #gridTab
      (searchEmitter)="this.setSearchSelectItem($event); searchPanelValidation = undefined"
      [data]="data"
      [container]="parentContainer"
      [isSearch]="true"
      [dataStored]="dataStored"
      [validation]="searchPanelValidation"
      [windowType]="windowType"
      [getCurrentContext]="getCurrentContext"
      [isInUniversalFilter]="isInUniversalFilter" />
  </iu-modal-ui>
}
@if (displayFormUI) {
  <div [ngClass]="[!displayFormUI ? 'hidden' : '', 'specificModal']">
    <iu-modal-ui
      #specificModal
      [title]="specificWindowTitle"
      [isModalDisplay]="displayFormUI"
      [angularStyle]="customFormModalBodyCss"
      (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false })"
      [appendTo]="'body'">
      <ng-template #vcrSpecific />
    </iu-modal-ui>
  </div>
}

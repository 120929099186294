<div class="iu-specific-window iu-specific-window-container" [attr.data-cy-specific-window-name]="name">
  @if (!isModal && isLoading) {
    <p-progressBar [mode]="'indeterminate'" [value]="0" [showValue]="false" [styleClass]="'loading-progressbar'" />
  }

  <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
    <div class="container">
      <div class="row">
        <div
          class="col-md-12"
          style="text-align: center; vertical-align: middle; font-size: 40px; flex: 0 0 100%; max-width: 100%">
          {{ 'specificWindow.pointOfSale.title' | translate }}
        </div>
      </div>
      <div class="row">
        <div class="p-col-6 orderDetail">
          <div class="row" style="overflow: auto; height: 565px; max-height: 565px; background: var(--bg-color)">
            <table class="table table-striped" aria-label="order details table">
              <thead class="thead-dark">
                <tr>
                  <th scope="col" class="text-left">{{ 'specificWindow.pointOfSale.article' | translate }}</th>
                  <th scope="col" class="text-right">{{ 'specificWindow.pointOfSale.price' | translate }}</th>
                </tr>
              </thead>
              <tbody id="basket">
                @for (orderLine of orderLines; track $index) {
                  <tr>
                    <td class="text-left">{{ orderLine.product['NAME'] }}</td>
                    <td class="text-right">{{ orderLine.price | currency: 'EUR' }}</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="totalItem" style="width: 100%; margin-bottom: 5px; margin-right: 5px">
              <div class="totalLabel">{{ 'specificWindow.pointOfSale.total' | translate }} :</div>
              <div class="totalAmount">
                {{ !grandtotal ? (grandtotal | number: '1.2') : (grandtotal | currency: 'EUR') }}
              </div>
            </div>
          </div>
          <div class="row">
            <button
              id="validOrderButton"
              type="button"
              class="btn btn-success"
              style="width: 100%; margin-bottom: 5px; margin-right: 5px; font-size: 16px"
              (click)="validateOrder()">
              {{ 'specificWindow.pointOfSale.validate' | translate }}
            </button>
          </div>
        </div>
        <div id="productList" class="p-col-6 productList">
          @for (product of products$ | async; track $index) {
            <button (click)="addToBasket(product)" type="button" class="btn btn-info product" style="font-size: 16px">
              {{ product['NAME'] }}<br />{{ getPrice(product['M_PRODUCT_ID']) | currency: 'EUR' }}
            </button>
          }
        </div>
      </div>
    </div>
  </p-scrollPanel>
</div>

import { NgClass } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import EditTabUiComponent from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { IupicsEvent } from '@iupics-manager/models/iupics-event';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'primeng/tooltip';
import { LoadingDirective } from '../../../directives/loading.directive';

@Component({
  selector: 'iu-text-field-ui',
  templateUrl: './text-field-ui.component.html',
  styleUrls: ['./text-field-ui.component.scss'],
  standalone: true,
  imports: [TooltipModule, LoadingDirective, NgClass, TranslateModule],
})
export default class TextFieldUiComponent extends AbstractDataContainer implements OnInit, AfterViewInit {
  @Input() parentTab: EditTabUiComponent;

  @Input() loadingAnimationTimeout: number = null;

  @Input() withColorIndicator = false;
  @Input() colorIndicatorCondition: boolean = null;

  @Input() data: any;
  @Input() value: any;

  adLanguage: string;
  @Input() formatter: Intl.NumberFormat = undefined;

  ngOnInit(): void {
    super.ngOnInit();
    if (this.container?.DOMChildrenComponent) {
      this.container.DOMChildrenComponent.push(this);
    }

    if (this.data?.data?.label) {
      this.label = this.data?.data?.label;
    }

    this.adLanguage = (this.data?.data?.locale ?? this.connectorService.getIupicsUserContext()['#AD_Language']).slice(
      0,
      2
    );
    this.setValue();
  }

  ngAfterViewInit(): void {
    // TODO: check if this is necessary
    super.ngAfterViewInit();
  }

  onSiblingUpdate(event: IupicsEvent): void {
    this.setValue();
  }

  setValue() {
    const valueNotFormatted = this.parentTab?.dataStored.data[this.data.data?.columnName] ?? this.data?.value ?? null;
    if (typeof valueNotFormatted === 'number') {
      if (this.formatter === undefined) {
        this.formatter = Intl.NumberFormat(this.adLanguage);
      }

      if (this.formatter) {
        this.value = this.formatter?.format(valueNotFormatted ?? 0);
        return;
      }
    }

    this.value = valueNotFormatted;
  }
}

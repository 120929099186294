import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, model, output, viewChild } from '@angular/core';
import { CompiereDataFieldType, CompiereDataJSON } from '@compiere-ws/models/compiere-data-json';
import { CompiereDataService } from '@compiere-ws/services/compiere-data/compiere-data.service';
import { IAutocomplete } from '@iupics-components/models/autocomplete-interfaces';
import { map, tap } from 'rxjs';
import {
  SKWStateActionType,
  SKWTransferLineData,
  SKWTransferLineFormData,
} from '../../models/storekeeper-window.model';
import { SKWTranslatePipe } from '../../pipes/storekeeper-window-translate.pipe';
import { SKWContextService } from '../../services/storekeeper-window-context.service';
import { SKWMessageService, SKWMessageType } from '../../services/storekeeper-window-message.service';
import { SKWNavigationService } from '../../services/storekeeper-window-navigation.service';
import { StorekeeperInputComponent } from '../storekeeper-input/storekeeper-input.component';

@Component({
  selector: 'iu-storekeeper-window-input-scan',
  standalone: true,
  imports: [StorekeeperInputComponent, NgClass, SKWTranslatePipe],
  templateUrl: './storekeeper-window-input-scan.component.html',
  styleUrl: './storekeeper-window-input-scan.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StorekeeperWindowInputScanComponent {
  #SKWCtxService = inject(SKWContextService);
  #SKWNavigationService = inject(SKWNavigationService);
  #SKWMessageService = inject(SKWMessageService);

  #dataService = inject(CompiereDataService);

  parsedField = {};

  data = model<SKWTransferLineFormData>();

  scanData = model<string>();

  search = input(false);
  lines = input<SKWTransferLineData[]>();

  inventory = input(false);
  inventoryChange = output<{ type: string; data: IAutocomplete }>();

  callback = input<(value: IAutocomplete) => void>(undefined);

  input = viewChild<StorekeeperInputComponent>('input');

  validation = output<string>();

  constructor() {
    for (const field of this.#SKWCtxService.container.fields) {
      this.parsedField[field.data.columnName] = field;
    }
  }

  scanDataChange(value: string) {
    if (!value) return;

    if (this.search()) {
      return this.#search(value);
    }

    // TODO: handle palette
    /* if (value.startsWith('P')) {
      // this.#verify('Palette', 'palette', value);
    } else */ if (value.startsWith('A')) {
      this.#verify('M_Product_ID', this.inventory() ? 'M_Product_ID' : 'Product', value);
    } else if (value.startsWith('E')) {
      this.#verify('M_Locator_ID', this.inventory() ? 'M_Locator_ID' : 'locator', value);
    } else if (value.startsWith('V')) {
      this.validation.emit(this.#removeIdentifier(value));
    } else {
      this.#noMatches();
    }
  }

  #search(value: string) {
    const line = this.lines()?.find((l) => '' + l.M_Product_ID.id === this.#removeIdentifier(value));

    if (line) {
      this.#SKWNavigationService.selectLine(line, { Product: line.M_Product_ID });
      return;
    }

    this.#noMatches();
  }

  #verify(columnName: string, property: string, value: string) {
    this.#SKWCtxService.newAction({
      type: SKWStateActionType.GET,
      isLoading: true,
      source: this.#getData(this.parsedField[columnName], this.#removeIdentifier(value)).pipe(
        tap((result) => this.#handleResult(result, property, value)),
        map(() => undefined)
      ),
    });
  }

  #getData(field: any, query: string = null) {
    return this.#dataService.getDataForAutocomplete(
      CompiereDataFieldType.FORM_ITEM,
      field.formDetailItemId,
      true,
      null,
      `${field.data.details.keyColumn}=${query}`
    );
  }

  #handleResult(result: CompiereDataJSON, property: string, value: string) {
    if (!result?.[0]) {
      this.#noMatches();
    } else if (!this.inventory()) {
      this.#update(property, result[0]);
      this.callback()?.(<any>this.data()[property]);
    } else {
      this.inventoryChange.emit({ type: property, data: result[0] });
    }

    const input = this.input();
    if (input) {
      input.value.set('');
    }
  }

  #update(key: string, value: { id: string; displayValue: string } | string) {
    this.data.update((v) => ({ ...v, [key]: value }));
  }

  #removeIdentifier(value: string) {
    return value.slice(1);
  }

  #noMatches() {
    this.#SKWMessageService.addMessage({
      type: SKWMessageType.ERROR,
      key: 'ScanError',
      title: 'ScanError',
      content: 'ScanErrorMsg',
    });
  }
}

<div
  [ngClass]="{
    'p-steps p-component': true,
    'p-readonly': readonly,
    'p-steps-doc': !isIndexNumber,
    'p-steps-process': isIndexNumber
  }"
  [ngStyle]="style"
  [class]="styleClass">
  <ul role="tablist" data-cy="workflow-stepper">
    @for (item of model; track i; let i = $index) {
      <li
        class="p-steps-item"
        #menuitem
        [ngStyle]="item.style"
        [class]="item.styleClass"
        role="tab"
        [attr.aria-selected]="i === activeIndex"
        [attr.aria-expanded]="i === activeIndex"
        [ngClass]="{
          'p-highlight p-steps-current': isActive(item, i),
          'p-disabled': item.disabled || (readonly && !isActive(item, i))
        }">
        <ng-container [ngTemplateOutlet]="isIndexNumber ? processStepItem : docStepItem" />
        <ng-template #processStepItem>
          @if (item.routerLink) {
            <a
              [routerLink]="item.routerLink"
              [queryParams]="item.queryParams"
              [routerLinkActive]="'p-highlight'"
              [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }"
              class="p-menuitem-link"
              (click)="itemClick($event, item, i)"
              [attr.target]="item.target"
              [attr.id]="item.id">
              <span class="p-steps-number">{{ i + 1 }}</span>
              @if (item.escape !== false) {
                <span class="p-steps-title">{{ item.label }}</span>
              } @else {
                <span class="p-steps-title" [innerHTML]="item.label"></span>
              }
            </a>
          } @else {
            <a
              [href]="item.url || '#'"
              class="p-menuitem-link"
              (click)="itemClick($event, item, i)"
              [attr.target]="item.target"
              [attr.id]="item.id"
              (contextmenu)="showCMChecker(item, i) ? onContextMenu($event) : null">
              <span class="p-steps-number">{{ getDisplayIndex(i) }}</span>
              <span class="p-steps-title">{{ item.label }}</span>
            </a>
          }
        </ng-template>

        <ng-template #docStepItem>
          @if (item.routerLink) {
            <button
              pButton
              [routerLink]="item.routerLink"
              [queryParams]="item.queryParams"
              [routerLinkActive]="'p-highlight'"
              [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }"
              [ngClass]="['p-menuitem-link', !isActive(item, i) ? 'p-button p-button-secondary' : '']"
              (click)="itemClick($event, item, i)"
              [attr.target]="item.target"
              [attr.id]="item.id">
              @if (item.escape !== false) {
                <span class="p-steps-title p-button-label">
                  {{ item.label }}
                </span>
              } @else {
                <span class="p-steps-title p-button-label" [innerHTML]="item.label"></span>
              }
            </button>
          } @else {
            <button
              pButton
              [routerLink]="item.url || '.'"
              [ngClass]="{ 'p-menuitem-link': true, 'p-button p-button-secondary': !isActive(item, i) }"
              [disabled]="readonly"
              (click)="itemClick($event, item, i)"
              [attr.target]="item.target"
              [attr.id]="item.id"
              (contextmenu)="showCMChecker(item, i) ? onContextMenu($event) : null"
              label="{{ item.label }}"></button>
          }
        </ng-template>
      </li>
    }
  </ul>
</div>

import { NgClass } from '@angular/common';
import { Component, OnInit, input, model } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { SKWInputType } from '../../models/storekeeper-input.model';

@Component({
  selector: 'iu-storekeeper-input',
  standalone: true,
  imports: [NgClass],
  templateUrl: './storekeeper-input.component.html',
  styleUrl: './storekeeper-input.component.scss',
})
export class StorekeeperInputComponent implements OnInit {
  uuid = uuid();

  type = input.required<SKWInputType>();
  label = input.required<string>();
  value = model.required<string | number>();
  readOnly = input<boolean>(false);
  placeholder = input<string>('');

  validation = input<boolean>(undefined);

  ngOnInit(): void {
    if (this.type() === SKWInputType.TEXT && this.value() === undefined) {
      this.value.set('');
    }
  }

  updateValue(event: InputEvent) {
    const input = <HTMLInputElement>event.target;
    this.value.set(this.type() === SKWInputType.NUMBER ? input.valueAsNumber : input.value);
  }
}

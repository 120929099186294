<div #container class="pingInfo-container">
  <table class="pingInfo-table" aria-label="ping info table">
    <thead class="pingInfo-header">
      <tr class="pingInfo-header-line">
        <th
          [ngClass]="[pingInfos.length > 0 ? 'bordered' : '', 'pingInfo-name pingInfo-header-column-header']"
          scope="col">
          {{ 'specificWindow.processInProgress.name' | translate }}
        </th>
        <th
          [ngClass]="[pingInfos.length > 0 ? 'bordered' : '', 'pingInfo-status pingInfo-header-column-header']"
          scope="col">
          {{ 'specificWindow.processInProgress.status' | translate }}
        </th>
      </tr>
    </thead>
    <tbody>
      @for (pingInfo of pingInfos; track i; let i = $index; let isLast = $last) {
        <tr [ngClass]="[i % 2 === 0 ? 'even' : '', 'pingInfo-line']">
          <td class="pingInfo-column">
            <div style="width: fit-content; cursor: pointer" (click)="showDetail(pingDetail)">
              <i class="icon-process-cogs-3" style="padding-right: 10px; padding-left: 5px" aria-hidden="true"></i>
              {{ pingInfo.Name }}
            </div>
          </td>
          <td class="pingInfo-column">
            <p-progressBar
              [mode]="pingInfo.Status === 'finish' || pingInfo.Status === 'pending' ? 'determinate' : 'indeterminate'"
              [value]="pingInfo.Status === 'finish' ? 100 : 0"
              [showValue]="false"
              [styleClass]="'custom-progressbar'"
              [style]="{ height: '1em' }" />
            <div>
              <!-- TODO: maybe we should think a way to retrieve username if no displayValue -->
              {{
                'specificWindow.processInProgress.launchedAgo'
                  | translate
                    : {
                        user:
                          pingInfo?.AD_User_ID?.displayValue !== ''
                            ? pingInfo?.AD_User_ID?.displayValue
                            : pingInfo?.AD_User_ID?.id ?? 'unknown',
                        period: getCreated(pingInfo.Created, pingInfo.averageTime)
                      }
              }}

              @if (
                pingInfo.Status !== 'finish' &&
                pingInfo.averageTime &&
                getAverageTime(pingInfo.averageTime, pingInfo.Created)
              ) {
                <p class="processTooLong">
                  {{
                    'specificWindow.processInProgress.processTooLong'
                      | translate
                        : {
                            averageTime: getAverageTime(pingInfo.averageTime, pingInfo.Created)
                          }
                  }}
                </p>
              }
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" [ngClass]="[!isLast ? 'bordered' : '']">
            <div #pingDetail class="pingInfo-item-detail">
              <table class="pingInfo-detail-table" aria-label="ping info detail table">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="pingInfo-detail-table-main">
                    <td class="pingInfo-detail-table-main-line" colspan="2">
                      {{ 'specificWindow.processInProgress.colName' | translate }}: {{ pingInfo.Name }}
                    </td>
                    <td class="pingInfo-detail-table-main-line" colspan="2">
                      {{ 'specificWindow.processInProgress.colUser' | translate }}:
                      {{ pingInfo?.AD_User_ID?.displayValue ?? pingInfo?.AD_User_ID?.id ?? 'unknown' }}
                    </td>
                    <td class="pingInfo-detail-table-main-line" colspan="2">
                      {{ 'specificWindow.processInProgress.colCreated' | translate }}: {{ pingInfo.Created }}
                    </td>
                  </tr>
                  @for (param of pingInfo.params; track $index) {
                    <tr class="pingInfo-detail-param-line">
                      <td colspan="2">{{ param.PARAMETERNAME }}</td>
                      <td colspan="4">{{ param | parseInstanceParams: 'value' }}</td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      }
    </tbody>
  </table>
</div>

import { NgClass } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import ButtonUiComponent from '@iupics-components/standard/fields/button-ui/button-ui.component';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import { DynamicContainerDirective } from '@iupics-util/directives/dynamic-container.directive';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import SpecificWindowUiComponent from '../specific-window-ui/specific-window-ui.component';
@Component({
  selector: 'iu-create-from-shipment-window-ui',
  templateUrl: './create-from-shipment-window-ui.component.html',
  styleUrls: ['./create-from-shipment-window-ui.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    ProgressBarModule,
    ScrollPanelModule,
    DynamicContainerDirective,
    ButtonUiComponent,
    ModalUiComponent,
    // UniversalFilterUiComponent,
    // GridTabInfinityScrollUiComponent,
    TranslateModule,
  ],
})
export default class CreateFromShipmentWindowUiComponent extends SpecificWindowUiComponent implements OnInit {
  orderGrid: any;
  blanketOrderGrid: any;
  invoiceGrid: any;
  orderSelections: any;
  blanketOrderSelections: any;
  invoiceSelections: any;
  isModal: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.dataStore.data['C_Order_ID'] = null;
    this.dataStore.data['C_Invoice_ID'] = null;
    this.dataStore.data['C_BlanketOrder_ID'] = null;
  }

  notifyFromDataChange(item: any) {
    if (item.data.columnName === 'C_Order_ID') {
      this.dataStore.data['C_Invoice_ID'] = null;
      this.dataStore.data['C_BlanketOrder_ID'] = null;
      this.clearSelections();
    } else if (item.data.columnName === 'C_BlanketOrder_ID') {
      this.dataStore.data['C_Invoice_ID'] = null;
      this.dataStore.data['C_Order_ID'] = null;
      this.clearSelections();
    } else if (item.data.columnName === 'C_Invoice_ID') {
      this.dataStore.data['C_Order_ID'] = null;
      this.dataStore.data['C_BlanketOrder_ID'] = null;
      this.clearSelections();
    }
    super.notifyFromDataChange(item);
  }

  clearSelections() {
    this.dataStore.data['selections'].forEach((grid) => {
      grid['selection'] = [];
    });
    this.gridViews.forEach((grid) => {
      grid.GridTabInfinityScrollUiComponent.grid.api.deselectAll();
    });
  }
}

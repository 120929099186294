import { NgClass } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import AutocompleteUiComponent from '@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SidebarModule } from 'primeng/sidebar';
import SpecificWindowUiComponent from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-direct-printing-documents',
  templateUrl: './direct-printing-documents.component.html',
  styleUrls: ['./direct-printing-documents.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    ProgressBarModule,
    ScrollPanelModule,
    AutocompleteUiComponent,
    SidebarModule,
    ModalUiComponent,
    // UniversalFilterUiComponent,
    // GridTabInfinityScrollUiComponent,
    NgClass,
    TranslateModule,
  ],
})
export default class DirectPrintingDocumentsComponent
  extends SpecificWindowUiComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('vcrParameters', { read: ViewContainerRef, static: true })
  vcrParameters: ViewContainerRef;

  @ViewChild('vcrProcess', { read: ViewContainerRef, static: true })
  vcrProcess: ViewContainerRef;

  @ViewChild('printerNameAutocomplete', { static: true })
  autoComplete: AutocompleteUiComponent;

  printerNames = { items: [] };
  printProcessID;

  ngOnInit() {
    this.uiCreator.getCupsPrinters().subscribe((cups) => {
      cups.forEach((printer) => {
        this.printerNames.items.push({ id: printer.name, displayValue: printer.name });
      });
    });
    this.uiCreator.getSpecificWindow(this.formId).subscribe((specificWindow) => {
      specificWindow.items.forEach((item) => {
        if (item.data.columnName === 'FileName') {
          this.customDesignArray.push({
            vcr: 'vcrParameters',
            type: CustomDesignItemType.FIELD,
            columnName: 'FileName',
            cssClass: 'p-col-12 p-md-6',
          });
        } else if (item.data.columnName === 'StartDirectImpression') {
          this.printProcessID = item.processId;
          this.customDesignArray.push({
            vcr: 'vcrProcess',
            type: CustomDesignItemType.FIELD,
            columnName: 'StartDirectImpression',
            cssClass: 'p-col-12 p-md-6',
          });
        }
      });
    });
    const item: DynamicComponent = {
      container: this,
      DOMParentComponent: this,
      component: 'SpecificWindowUiComponent',
      cssClass: 'p-col-12',
      isCssOnComponent: false,
      tabId: this.formId,
      gridPaginator: false,
    };
    this.windowFactory.newEventHandler({
      type: IupicsTypeEvent.showSpecificWindow,
      item: item,
    });
  }

  processStartDirectImpression() {
    if (this.autoComplete && this.autoComplete.fieldValue && this.autoComplete.fieldValue.displayValue) {
      this.executeProcess(this.printProcessID, { PrinterName: this.autoComplete.fieldValue.displayValue });
    } else {
      this.messageManager.newMessage(
        new IupicsMessage(
          'Error',
          this.translateService.instant('specificWindow.DirectPrintingDocumentsComponent.toolTipSelectPrinter')
        )
      );
    }
  }
}

import { NgClass } from '@angular/common';
import { Component, input, output } from '@angular/core';

@Component({
  selector: 'iu-storekeeper-button',
  standalone: true,
  imports: [NgClass],
  templateUrl: './storekeeper-button.component.html',
  styleUrl: './storekeeper-button.component.scss',
})
export class StorekeeperButtonComponent {
  type = input<'normal' | 'outlined'>('normal');
  label = input.required<string>();
  disabled = input(false);

  onClick = output<MouseEvent>();
}

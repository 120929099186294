import { CompiereDataGridRequestJSON } from './compiere-data-json';

export interface CompiereUINotificationWS {
  nbNotifs: number;
  nbAlerts: number;
  nbChats: number;
}
export interface CompiereNotification {
  additionalProperties?: any;
  title: string;
  summary: string;
  description: string;
  created: string;
  processChannelId: string;
  request_id: number;
  closed: boolean;
  isError?: boolean;
  priority: CompiereNotificationPriority;
  target: string;
  type?: string;
  sourceType: string;
  sourceTypeId: number;
  sourceRecordId: string;
  sourceTableNameTargeted: string;
  processParams?: any;
  zoomInfo?: ZoomInfo;
  fileLinks: CompiereNotificationFileInfo[];
  notificationType?: CompiereNotificationType;
  userName?: string;
  data: CompiereNotificationData;
}
export interface CompiereNotificationData {
  priorityData: CompiereNotificationDataPriority;
  readAbleDate: string;
  icons: CompiereNotificationDataIcon[];
}
export interface CompiereNotificationDataPriority {
  priorityTitle: string;
  priorityClass: string;
}
export interface CompiereNotificationDataIcon {
  fileLink: string;
  icon: string;
  fileName: string;
}
export interface ZoomInfo {
  zoomType?: string;
  zoomTypeId?: number;
  zoomUrl?: string;
  recordId?: string;
  request?: CompiereDataGridRequestJSON;
  tableNameTargeted?: string;
  colValues?: any;
}

export interface CompiereNotificationFileInfo {
  path: string;
  fileName: string;
  extension: string;
}

export enum CompiereNotificationStatus {
  TREATED = 'Traitée',
  TERMINATED = 'Terminée',
  UNTREATED = 'Non traitée',
}

export enum CompiereNotificationPriority {
  IMPORTANT = 'IMPORTANT',
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export enum CompiereNotificationType {
  NOTIF = 'N',
  ALERT = 'A',
  CHAT = 'C',
  ALL = 'ALL',
}

export enum NotificationType {
  NOTIF = 'notifications',
  ALERT = 'alerts',
  CHAT = 'chats',
}

<div class="treeContainer">
  <p-tree
    #tree
    [loading]="loading"
    [value]="treeData?.treeNodes"
    [droppableNodes]="true"
    [draggableNodes]="true"
    [style]="{ overflow: 'auto', 'max-height': maxTreeHeight }"
    (onNodeDrop)="onNodeDrop($event)"
    (onNodeSelect)="nodeSelect($event)"></p-tree>
</div>

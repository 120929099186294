import { Pipe, PipeTransform } from '@angular/core';
import { OperatorFilterAutocomplete } from '@iupics-components/models/autocomplete-interfaces';
import { FilterModelFilterType } from '@iupics/apiz-grid';

@Pipe({
  name: 'operatorFilter',
  standalone: true,
})
export class OperatorFilterPipe implements PipeTransform {
  transform(
    operators: { items: OperatorFilterAutocomplete[] },
    filterType: FilterModelFilterType
  ): { items: OperatorFilterAutocomplete[] } {
    if (!filterType) {
      return operators;
    }
    return { items: operators.items.filter((operator) => operator.operator.filterType === filterType) };
  }
}

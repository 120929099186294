// xml.service.ts
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class XmlParser {
  constructor() {}

  readBlobAsText(blob: Blob): Observable<string> {
    return new Observable<string>((observer) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        observer.next(reader.result as string);
        observer.complete();
      };

      reader.onerror = (error) => {
        observer.error(error);
      };

      reader.readAsText(blob);
    });
  }

  parseXml(xmlText: string): Document {
    const parser = new DOMParser();
    const xml = parser.parseFromString(xmlText, 'application/xml');
    return xml.documentElement.tagName === 'html' ? null : xml;
  }
}

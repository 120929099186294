import { Pipe, PipeTransform } from '@angular/core';
import { DocUtils } from '@iupics-util/tools/doc.utils';

@Pipe({
  name: 'docIcon',
  standalone: true,
})
export class DocIconPipe implements PipeTransform {
  transform(value: string, args?: any): any {
    return DocUtils.getFileIcon(value);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { Global } from '@iupics-manager/models/global-var';
import * as moment from 'moment';

@Pipe({
    name: 'toDate',
    standalone: true,
})
export class ToDatePipe implements PipeTransform {
  transform(value: any, needTime: boolean = false, format?: string): Date {
    if (!format) {
      format = Global.dateFormat;
    }
    return this.getDateValue(value, needTime);
  }

  private getDateValue(value: any, needTime: boolean = false): Date {
    let tmpValue = value;
    if (value) {
      if (!(value instanceof Date)) {
        value = moment(value).format('YYYY-MM-DDTHH:mm:ss.SSS');
        value = value.substring(0, 26) + value.substring(27, value.length);
        tmpValue = new Date(value);
      }
      if (Object.prototype.toString.call(tmpValue) === '[object Date]' && !isNaN(tmpValue.getTime())) {
        if (needTime === false) {
          tmpValue.setHours(0);
          tmpValue.setMinutes(0);
        }
        tmpValue.setSeconds(0);
        tmpValue.setMilliseconds(0);
      } else {
        tmpValue = null;
      }
    }
    return tmpValue;
  }
}

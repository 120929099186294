import { Injectable } from '@angular/core';
import { SpecificWindowCompiereWS } from '@compiere-ws/models/specific-window-json';
import { CompiereField, CompiereTab } from '@compiere-ws/models/window-json';
import { AppConfig } from '@iupics-config/app.config';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class WindowsService {
  private url: string;
  private specificWindow_url: string;

  constructor(
    private http: ApiService,
    private config: AppConfig
  ) {}

  getWindow(windowId: number): Observable<any> {
    this.url = this.config.getBackendResource('window');
    return this.http.get<any>(`${this.url}/${windowId}`);
  }
  getWindowUI(windowId: number): Observable<any> {
    this.url = this.config.getBackendResource('windowUI');
    return this.http.get<any>(`${this.url}/${windowId}`);
  }

  getProductAttribute(tab_id: number, ctx: any): Observable<any> {
    this.url = this.config.getBackendResource('productAttribute');
    return this.http.post<any>(`${this.url}/get?tab_ID=${tab_id}`, ctx);
  }
  saveProductAttributeLot(product_ID: number): Observable<any> {
    this.url = this.config.getBackendResource('productAttributeLot');
    return this.http.post<any>(`${this.url}`, {
      attributeSetInstance_ID: 0,
      productId: product_ID,
    });
  }
  saveProductAttributeSerie(product_ID: number): Observable<any> {
    this.url = this.config.getBackendResource('productAttributeSerie');
    return this.http.post<any>(`${this.url}`, {
      attributeSetInstance_ID: 0,
      productId: product_ID,
    });
  }

  saveProductAttribute(
    attributeInstance_id: number,
    product_ID: number,
    tab_id: number,
    fieldId: number,
    params: any,
    ctx: any
  ): Observable<any> {
    this.url = this.config.getBackendResource('productAttribute');
    return this.http.post<any>(`${this.url}`, {
      attributeSetInstance_ID: attributeInstance_id,
      productId: product_ID,
      tabId: tab_id,
      fieldId: fieldId,
      params: params,
      ctx: ctx,
    });
  }

  getFields(tab_id: number): Observable<CompiereField[]> {
    this.url = this.config.getBackendResource('fields');
    return this.http.get<CompiereField[]>(`${this.url}/${tab_id}`);
  }

  getTab(tab_id: number): Observable<CompiereTab> {
    this.url = this.config.getBackendResource('tab');
    return this.http.get<CompiereTab>(`${this.url}/${tab_id}`);
  }

  getZoomAcross(
    tableName: string,
    columnKey: string,
    record_id: number,
    isSOTrx?: boolean,
    window_id?: number
  ): Observable<any> {
    this.url = this.config.getBackendResource('ZoomAcross');
    const query =
      `?tablename=${tableName}` +
      `&whereclause=${columnKey}=${record_id}` +
      (window_id !== undefined ? `&adwindowid=${window_id}` : '') +
      (isSOTrx !== undefined ? `&IsSOTrx=${isSOTrx}` : '');
    return this.http.get<any>(`${this.url}${query}`);
  }

  getZoomTarget(tab_id: number, columnKey: string, record_id: number): Observable<any> {
    this.url = this.config.getBackendResource('ZoomTarget');
    const query = '?tab_id=' + tab_id + '&whereclause=' + columnKey + '	%3D' + record_id;
    return this.http.get<any>(`${this.url}${query}`);
  }

  getSpecificWindow(ad_form_id: number): Observable<SpecificWindowCompiereWS> {
    if (this.specificWindow_url === undefined) {
      this.specificWindow_url = this.config.getBackendResource('specificWindow');
    }
    return this.http.get<SpecificWindowCompiereWS>(`${this.specificWindow_url}/${ad_form_id}`);
  }
}

<div class="container-vertical">
  @if (description && description !== '-') {
    <p class="title">{{ description }}</p>
  }

  @if (help) {
    <div class="description">
      <span [innerHTML]="isHelpShown ? help : help.substr(0, 200)" (click)="isHelpShown = false"></span>
      @if (!isHelpShown && help.length > 200) {
        <span class="help-more" (click)="isHelpShown = true"> (...) </span>
      }
    </div>
  }
  <div class="container-horizontal mainPanel">
    <div class="container-vertical panelLeft">
      <iu-prime-fieldset
        legend="{{ 'process.parameters' | translate }}"
        [toggleable]="true"
        [collapsed]="collapsed"
        [style.display]="isDisplay">
        <div class="fields apiz-p-grid">
          <div class="p-col-12">
            <ng-template iuDynamicContainer />
          </div>
          @if (isReport && exportDataOptions) {
            @if (hasFormat) {
              <iu-autocomplete-ui
                #printFormatsAutocomplete
                (fieldValueModified)="onPrintFormatChange($any($event))"
                [attr.data-cy]="'exportDataOptions-printFormats'"
                [cssClass]="'p-col-12 p-offset-0'"
                [data]="exportDataOptions.printFormats"
                [isStandalone]="true"
                label="{{ 'exportData.printFormats' | translate }}"
                tooltip="{{ 'exportData.printFormats' | translate }}" />
            }
            <iu-autocomplete-ui
              #fileTypesAutocomplete
              [attr.data-cy]="'exportDataOptions-fileTypes'"
              [cssClass]="'p-col-12 p-offset-0'"
              [data]="exportDataOptions.fileTypes"
              [fieldValue]="defaultFileType"
              [isStandalone]="true"
              label="{{ 'exportData.fileTypes' | translate }}"
              tooltip="{{ 'exportData.fileTypes' | translate }}" />
          }
        </div>
      </iu-prime-fieldset>
      <div class="iu-process-btn-container">
        <button
          pButton
          class="p-button p-button-primary {{ isProcessing ? 'iu-btn-disabled' : '' }}"
          (click)="executeProcess($event)"
          [disabled]="isProcessing"
          [attr.data-cy-channelId]="channel_id"
          [attr.data-cy-processId]="windowId"
          label="{{ 'process.execute' | translate }}"
          icon="fa fa-check"
          data-cy="process-button"></button>
      </div>
    </div>
    <div class="panelRight">
      <iu-prime-steps
        [model]="steps"
        [isIndexNumber]="true"
        [(activeIndex)]="activeIndex"
        styleClass="steps-custom"
        [readonly]="true"
        [showCMChecker]="showCMChecker"
        [cmEventHandler]="cmStepperEventHandler" />
      <iu-process-logs-ui #logContainer />
    </div>
  </div>
</div>

<div [ngClass]="[!displayFormUI ? 'hidden' : '', 'specificModal']">
  <iu-modal-ui
    #specificModal
    [title]="specificWindowTitle"
    [isModalDisplay]="displayFormUI"
    [angularStyle]="customFormModalBodyCss"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false })">
    <ng-template #vcrSpecific />
  </iu-modal-ui>
</div>

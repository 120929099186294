import { NgClass, NgIf } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ProcessPingInfo } from '@compiere-ws/models/process-ping-info';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import ButtonUiComponent from '@iupics-components/standard/fields/button-ui/button-ui.component';
import GridViewUiComponent from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { DynamicContainerDirective } from '@iupics-util/directives/dynamic-container.directive';
import { CellEditorParams } from '@iupics/apiz-grid';
import { TranslateModule } from '@ngx-translate/core';
import * as moment from 'moment';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { InfoDialogType } from '../info-dialog/info-dialog.component';
import SpecificWindowUiComponent from '../specific-window-ui/specific-window-ui.component';

export enum EditableCell {
  AppliedAmt = 'AppliedAmt',
  WriteOffAmt = 'WriteOffAmt',
  DiscountAmt = 'DiscountAmt',
}
export enum AdForm {
  FormDetailID = 'AD_FormDetail_ID',
  MultiCurrency = 'MultiCurrency',
}

@Component({
  selector: 'iu-payment-allocation-window-ui',
  templateUrl: './payment-allocation-window-ui.component.html',
  styleUrls: ['./payment-allocation-window-ui.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    ProgressBarModule,
    ScrollPanelModule,
    DynamicContainerDirective,
    ButtonUiComponent,
    ModalUiComponent,
    // UniversalFilterUiComponent,
    // GridTabInfinityScrollUiComponent,
    TranslateModule,
  ],
})
export default class PaymentAllocationWindowUiComponent
  extends SpecificWindowUiComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('left', { read: ViewContainerRef, static: true }) vcrLeft: ViewContainerRef;
  @ViewChild('middle', { read: ViewContainerRef, static: true }) vcrMiddle: ViewContainerRef;
  @ViewChild('right', { read: ViewContainerRef, static: true }) vcrRight: ViewContainerRef;
  @ViewChild('bot', { read: ViewContainerRef, static: true }) vcrBot: ViewContainerRef;

  #paymentGridID: number;
  #invoiceGridID: number;
  #sumOfPayments = 0;
  #sumOfInvoices = 0;
  #indexOfRows: any[] = [];
  #appliedCellIndex = -1;
  index = -1;

  constructor() {
    super();

    this.customDesignArray.push(
      {
        vcr: 'vcrLeft',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_BPartner_ID',
        cssClass: 'p-col-12',
      },
      {
        vcr: 'vcrMiddle',
        type: CustomDesignItemType.FIELD,
        columnName: 'AD_Org_ID',
        cssClass: 'p-col-12',
      },
      {
        vcr: 'vcrRight',
        type: CustomDesignItemType.FIELD,
        columnName: 'Currency_To_ID',
        cssClass: 'p-col-12',
      },
      {
        vcr: 'vcrLeft',
        type: CustomDesignItemType.FIELD,
        columnName: 'MultiCurrency',
        cssClass: 'p-col-12',
      },
      {
        vcr: 'vcrMiddle',
        type: CustomDesignItemType.FIELD,
        columnName: 'IsSOTrx',
        cssClass: 'p-col-12',
      },
      {
        vcr: 'vcrBot',
        type: CustomDesignItemType.GRID,
        tableName: 'Affectation paiements Table_Paiement',
        cssClass: 'p-col-12',
        shouldSelectFirst: false,
        isFitResize: true,
      },
      {
        vcr: 'vcrBot',
        type: CustomDesignItemType.GRID,
        tableName: 'Affectation paiements Table_Facture',
        cssClass: 'p-col-12',
        shouldSelectFirst: false,
        isFitResize: true,
      },
      {
        vcr: 'vcrBot',
        type: CustomDesignItemType.FIELD,
        columnName: 'DifferenceAmt',
        cssClass: 'p-col-12 p-md-4',
      },
      {
        vcr: 'vcrBot',
        type: CustomDesignItemType.FIELD,
        columnName: 'DateAcct',
        cssClass: 'p-col-12 p-md-4',
      },
      {
        vcr: 'vcrButtons',
        type: CustomDesignItemType.FIELD,
        columnName: 'Processing',
        cssClass: 'p-col-12 p-md-3 p-lg-2',
      }
    );
  }

  ngOnInit() {
    super.ngOnInit();
    const item: DynamicComponent = {
      container: this,
      DOMParentComponent: this,
      component: 'SpecificWindowUiComponent',
      cssClass: 'p-col-12',
      isCssOnComponent: false,
      tabId: this.formId,
      gridPaginator: false,
    };
    this.windowFactory.newEventHandler({
      type: IupicsTypeEvent.showSpecificWindow,
      item: item,
    });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    // * Récupération des ID des formDetail
    this.#paymentGridID = this.fields.find(
      (el) => el.component === 'GridViewUiComponent' && el.name === 'Affectation paiements Table_Paiement'
    ).formDetailId;
    this.#invoiceGridID = this.fields.find(
      (el) => el.component === 'GridViewUiComponent' && el.name === 'Affectation paiements Table_Facture'
    ).formDetailId;
  }

  initialize() {
    this.#sumOfInvoices = 0;
    this.#sumOfPayments = 0;
    this.dataStore.data.DifferenceAmt = 0;
    if (this.#indexOfRows) {
      this.#indexOfRows.splice(0, this.#indexOfRows.length);
    }
    this.index = -1;
    this.#appliedCellIndex = -1;
  }

  roundAmount(value: any): number {
    return isNaN(parseFloat(value)) ? 0 : parseFloat(value.toFixed(2));
  }

  onBtnClick(event: CellEditorParams) {
    switch (event.colDef.cellEditorParams.templates.formDetailId) {
      case this.#paymentGridID:
        if (event.colDef.cellEditorParams.templates.columnName === 'AppliedAmt') {
          if (event.row.getRowData().AppliedAmt != 0) {
            event.row.setSelected(true);
          } else {
            event.row.setSelected(false);
          }
          event.row.setDataValue('AppliedAmt', this.roundAmount(event.row.getRowData().AppliedAmt));
          this.#processChanges();
        }
        break;
      case this.#invoiceGridID:
        let totalAmt = 0;
        if (this.#indexOfRows) {
          this.#appliedCellIndex = this.#indexOfRows.find((rowIndex) => rowIndex === event.rowIndex);
          this.index = this.#indexOfRows.indexOf(this.#appliedCellIndex);
        }

        if (
          event.row.getRowData().AppliedAmt != 0 ||
          event.row.getRowData().DiscountAmt != 0 ||
          event.row.getRowData().WriteOffAmt != 0
        ) {
          event.row.setSelected(true);
        } else {
          event.row.setSelected(false);
          if (this.index !== -1) {
            this.#indexOfRows.splice(this.index, 1);
          }
        }

        if (event.colDef.cellEditorParams.templates.columnName === 'DiscountAmt') {
          event.row.setDataValue('DiscountAmt', this.roundAmount(event.row.getRowData().DiscountAmt));
          totalAmt =
            event.row.getRowData().AppliedAmt + event.row.getRowData().DiscountAmt + event.row.getRowData().WriteOffAmt;
          if (
            this.#appliedCellIndex === -1 ||
            (this.roundAmount(totalAmt) != event.row.getRowData().ConvertedOpenAmt && this.#appliedCellIndex !== -1)
          ) {
            this.#resetAppliedAmtOfRow(event);
          }
          this.#processChanges();
        } else if (event.colDef.cellEditorParams.templates.columnName === 'WriteOffAmt') {
          const col = event.columnApi.getColumns().find((c) => {
            return c.getColId() == 'WriteOffAmt';
          });
          event.api.setFocusedCell(event.rowIndex, col);
          event.row.setDataValue('WriteOffAmt', this.roundAmount(event.row.getRowData().WriteOffAmt));
          totalAmt =
            event.row.getRowData().AppliedAmt + event.row.getRowData().DiscountAmt + event.row.getRowData().WriteOffAmt;
          if (
            this.#appliedCellIndex === -1 ||
            (this.roundAmount(totalAmt) != event.row.getRowData().ConvertedOpenAmt && this.#appliedCellIndex !== -1)
          ) {
            this.#resetAppliedAmtOfRow(event);
          }
          this.#processChanges(false);
        } else if (event.colDef.cellEditorParams.templates.columnName === 'AppliedAmt') {
          event.row.setDataValue('AppliedAmt', this.roundAmount(event.row.getRowData().AppliedAmt));
          this.#processChanges();
          if (this.roundAmount(event.row.getRowData().AppliedAmt) !== 0 && this.#appliedCellIndex !== event.rowIndex) {
            this.#indexOfRows.push(event.rowIndex);
          }
        }
        break;
      default:
        break;
    }
  }

  notifyFromDataChange(item: any) {
    if (item.data.columnName === 'MultiCurrency') {
      const datacontainer = this.dataContainers.find((elem) => elem.data.columnName === 'Currency_To_ID');
      if (datacontainer !== undefined) {
        if (
          datacontainer.fieldValue.id !== parseInt(this.connectorService.getIupicsUserContext()['$C_Currency_ID'], 10)
        ) {
          datacontainer.updateStore(parseInt(this.connectorService.getIupicsUserContext()['$C_Currency_ID'], 10));
        }
      }
    }

    if (item.data['isLaunchSearchGrid']) {
      this.initialize();
      if (item.data.isLaunchSearchGrid) {
        this.refreshGrids(this.dataStore, false, item.data);
      }
      this.#refreshDateAcct();
    }
  }

  notifySelect(gridView: GridViewUiComponent, rowSelected: any) {
    if (
      gridView.data['AD_FormDetail_ID'] === this.#invoiceGridID /*&&
      !gridView.GridTabInfinityScrollUiComponent.grid.pivotMode*/
    ) {
      gridView.GridTabInfinityScrollUiComponent.grid.api.forEachNode((row) => {
        if (!row.isSelected()) {
          const updated = row.getRowData();
          if (updated) {
            updated.DiscountAmt = updated.WriteOffAmt = updated.AppliedAmt = 0;
            row.setData(updated);

            if (parseInt(row.id, 10) === this.#appliedCellIndex) {
              this.#indexOfRows.splice(this.index, 1);
            }
          }
        }
      });
      gridView.GridTabInfinityScrollUiComponent.grid.api.getSelectedNodes().forEach((row) => {
        const updated = row.getRowData();
        if (updated) {
          if (updated.AppliedAmt === 0) {
            updated.AppliedAmt = updated.ConvertedOpenAmt - updated.DiscountAmt - updated.WriteOffAmt;
          }
          updated.AppliedAmt = this.roundAmount(updated.AppliedAmt);
          row.setData(updated);
        }
      });
    } else if (
      gridView.data['AD_FormDetail_ID'] === this.#paymentGridID /* &&
      !gridView.GridTabInfinityScrollUiComponent.grid.pivotMode */
    ) {
      gridView.GridTabInfinityScrollUiComponent.grid.api.forEachNode((row) => {
        if (!row.isSelected()) {
          const updated = row.getRowData();
          if (updated) {
            updated.AppliedAmt = 0;
            row.setData(updated);
          }
        }
      });
      gridView.GridTabInfinityScrollUiComponent.grid.api.getSelectedNodes().forEach((row) => {
        const updated = row.getRowData();
        if (updated) {
          // 134972
          if (updated.AppliedAmt === 0) {
            updated.AppliedAmt = updated.ConvertedOpenAmt;
          }
          row.setData(updated);
        }
      });
    }
    this.#refreshDateAcct();
    this.#processChanges();
  }

  processProcessing(item) {
    const SelectedNodesPay = this.gridViews[0].GridTabInfinityScrollUiComponent.grid.api.getSelectedNodes();
    const SelectedNodesInv = this.gridViews[1].GridTabInfinityScrollUiComponent.grid.api.getSelectedNodes();
    if (
      this.dataStore.data.DifferenceAmt !== null &&
      this.dataStore.data.DifferenceAmt !== 0 &&
      (SelectedNodesPay.length > 0 || SelectedNodesInv.length > 0)
    ) {
      const field = this.fields.find((f) => f.data.columnName === 'DifferenceAmt');
      if (!field) {
        return;
      }
      Global.infoDialog.message = {
        summary: this.translateService.instant('specificWindow.paymentAllocation.difference', {
          label: field.data.label,
          amt: this.dataStore.data.DifferenceAmt,
        }),
        detail: this.translateService.instant('specificWindow.paymentAllocation.differenceQuestion'),
      };
      const button = this.getDatacontainer('Processing');
      const confirm = Global.infoDialog.confirm.subscribe((e: any) => {
        this.#checkWriteOffAmt();
        this.executeProcess(item.processId);
        if (button) {
          button.isReadOnly = true;
        }
        if (confirm !== undefined) {
          confirm.unsubscribe();
        }
        if (cancel !== undefined) {
          cancel.unsubscribe();
        }
      });

      const cancel = Global.infoDialog.cancel.subscribe((e) => {
        if (button) {
          button.isReadOnly = false;
        }

        if (confirm !== undefined) {
          confirm.unsubscribe();
        }
        if (cancel !== undefined) {
          cancel.unsubscribe();
        }
      });
      Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
      Global.infoDialog.showInfoDialog();
    } else {
      this.executeProcess(item.processId);
    }
  }

  onEndProcess(ping: ProcessPingInfo) {
    super.onEndProcess(ping);
    this.initialize();
  }

  #resetAppliedAmtOfRow(event: CellEditorParams) {
    const appliedAmt =
      event.row.getRowData().ConvertedOpenAmt - event.row.getRowData().DiscountAmt - event.row.getRowData().WriteOffAmt;
    event.row.getRowData().AppliedAmt = this.roundAmount(appliedAmt);
    event.row.setData(event.row.getRowData());
  }

  #processChanges(checkWriteOff = true) {
    this.#calcDiff();
  }

  #calcDiff() {
    const reducer = (accumulator: number, current: any) => accumulator + current.AppliedAmt;
    const selectedRowPayment = this.dataStore.data.selections.find(
      (gridSel) => gridSel.AD_FormDetail_ID === this.#paymentGridID
    ).selection as any[];
    const selectedRowInvoice = this.dataStore.data.selections.find(
      (gridSel) => gridSel.AD_FormDetail_ID === this.#invoiceGridID
    ).selection as any[];

    this.#sumOfPayments = selectedRowPayment.length > 0 ? this.roundAmount(selectedRowPayment.reduce(reducer, 0)) : 0;
    this.#sumOfInvoices = selectedRowInvoice.length > 0 ? this.roundAmount(selectedRowInvoice.reduce(reducer, 0)) : 0;
    this.#refreshDifference();
  }

  #refreshDateAcct() {
    const datacontainer = this.dataContainers.find((elem) => elem.data.columnName === 'DateAcct');
    const selections = [].concat.apply(
      [],
      this.dataStore.data.selections.map((item: any) => item.selection)
    ) as any[];
    if (selections.length > 0) {
      const t = selections.map((s) => moment(s.DateAcct).valueOf()) as number[];
      const maxDate = Math.max(...t);
      datacontainer.updateStore(maxDate);
    } else {
      datacontainer.updateStore(this.connectorService.getIupicsUserContext()['#Date']);
    }
  }

  #refreshDifference() {
    this.dataStore.data.DifferenceAmt = 0;
    this.dataStore.data.DifferenceAmt = this.#sumOfPayments - this.#sumOfInvoices;
    const datacontainer = this.dataContainers.find((elem) => elem.data.columnName === 'DifferenceAmt');
    if (datacontainer !== undefined && datacontainer.fieldValue !== this.dataStore.data.DifferenceAmt) {
      datacontainer.updateStore(this.roundAmount(this.dataStore.data.DifferenceAmt));
    }
  }

  #checkWriteOffAmt() {
    const reducer = (accumulator: number, current: any) => accumulator + current.AppliedAmt;
    const selectedRowPayment = this.dataStore.data.selections.find(
      (gridSel) => gridSel.AD_FormDetail_ID === this.#paymentGridID
    ).selection as any[];
    let sumOfOpenAmtPayments = selectedRowPayment.length > 0 ? selectedRowPayment.reduce(reducer, 0) : 0;
    const selectedRowInvoice = this.dataStore.data.selections.find(
      (gridSel) => gridSel.AD_FormDetail_ID === this.#invoiceGridID
    ).selection as any[];

    let sumOfInvoices = selectedRowInvoice.length > 0 ? selectedRowInvoice.reduce(reducer, 0) : 0;

    let remainingAmt = sumOfOpenAmtPayments - sumOfInvoices;
    if (this.dataStore.data['IsSOTrx'] === 'N') {
      remainingAmt = remainingAmt * -1;
    }

    if (remainingAmt != 0) {
      const gridFound = this.gridViews.find((grid) => grid.data.AD_FormDetail_ID === this.#invoiceGridID);
      const otherNodes = [];
      const priorityNodes = [];
      gridFound.GridTabInfinityScrollUiComponent.grid.api.forEachNode((node) => {
        const data = node.getRowData();
        if (node.isSelected()) {
          if (data.ConvertedOpenAmt === this.roundAmount(data.AppliedAmt + data.DiscountAmt + data.WriteOffAmt)) {
            priorityNodes.push(node);
          } else {
            otherNodes.push(node);
          }
        }
      });
      otherNodes.sort((a, b) => Math.abs(b.data.AppliedAmt) - Math.abs(a.data.AppliedAmt));
      priorityNodes.sort((a, b) => Math.abs(b.data.AppliedAmt) - Math.abs(a.data.AppliedAmt));
      let nodeToUpdate = otherNodes[0];
      if (priorityNodes.length > 0) {
        nodeToUpdate = priorityNodes[0];
      }
      if (this.dataStore.data['IsSOTrx'] === 'N') {
        nodeToUpdate.data.AppliedAmt -= remainingAmt;
        nodeToUpdate.data.WriteOffAmt += remainingAmt;
      } else {
        {
          nodeToUpdate.data.AppliedAmt += remainingAmt;
          nodeToUpdate.data.WriteOffAmt -= remainingAmt;
        }
      }
      nodeToUpdate.setData(nodeToUpdate.data);
    }
    this.#calcDiff();
  }
}

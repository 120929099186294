type Grouped<T> = {
  [key: string]: T[];
};

export function groupBy<T>(array: T[], key: keyof T): Grouped<T> {
  return array.reduce((result, currentItem) => {
    const groupKey = currentItem[key] as unknown as string;
    if (!result[groupKey]) {
      result[groupKey] = [];
    }
    result[groupKey].push(currentItem);
    return result;
  }, {} as Grouped<T>);
}

<div
  #window
  class="draggable-window"
  iuDraggable
  [iuDraggable-has-drag-handle]="true"
  (iuDraggable-dragEnd)="onDragEnd($event)"
  (iuDraggable-dragStart)="onDragStart($event)">
  <div class="header" iuDragHandle>
    <div class="header-content">
      <i [class]="iconClass" aria-hidden="true"></i><span class="title">{{ title }}</span>
    </div>
    <div class="buttons">
      <button pButton icon="fa fa-window-minimize" class="button" (click)="onHide()"></button>
      <button pButton *ngIf="expanded" icon="fa fa-window-restore" class="button" (click)="onMinimize()"></button>
      <button pButton *ngIf="!expanded" icon="fa fa-expand" class="button" (click)="onExpand()"></button
      ><button pButton icon="icon-close" class="button" (click)="onClose()"></button>
    </div>
  </div>
  <div #content class="content">
    <ng-template #contentVcr> </ng-template>
    <ng-content></ng-content>
  </div>
</div>

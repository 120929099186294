import { NgClass } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import ButtonUiComponent from '@iupics-components/standard/fields/button-ui/button-ui.component';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { DynamicContainerDirective } from '@iupics-util/directives/dynamic-container.directive';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import ProcessUiComponent from '../process-ui/process-ui.component';
import SpecificWindowUiComponent from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-payment-selection-window-ui',
  templateUrl: './payment-selection-window-ui.component.html',
  styleUrls: ['./payment-selection-window-ui.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    ProgressBarModule,
    ScrollPanelModule,
    DynamicContainerDirective,
    ButtonUiComponent,
    ModalUiComponent,
    // UniversalFilterUiComponent,
    // GridTabInfinityScrollUiComponent,
    TranslateModule,
  ],
})
export default class PaymentSelectionWindowUiComponent extends SpecificWindowUiComponent implements OnInit {
  SumSelectedRows = 0;
  processUiComponent: ProcessUiComponent;
  ButtonUiComponent: ButtonUiComponent;

  isModal: boolean = false;

  notifyFromRowSelected(rowSelected: any) {
    this.SumSelectedRows = 0;
    if (this.dataStore.data.selections[0].selection.length > 0) {
      for (let i = 0; i < this.dataStore.data.selections[0].selection.length; i++) {
        this.SumSelectedRows += parseFloat(this.dataStore.data.selections[0].selection[i].AmountPay);
      }
      this.dataStore.data.Selected = this.SumSelectedRows;
      this.dataStore.data.Remaining = this.dataStore.data.CurrentBalance - this.SumSelectedRows;
    } else {
      this.dataStore.data.Selected = 0;
      this.dataStore.data.Remaining = 0;
    }
    this.setDataContainersValueWithChangedStore();
  }

  checkBeforeProcessing() {
    if (this.gridViews[0].GridTabInfinityScrollUiComponent.grid.api.getSelectedRows().length === 0) {
      this.messageManager.newMessage(
        new IupicsMessage(this.translateService.instant('specificWindow.paymentSelection.rowSelected'), 'error')
      );
    } else {
      return true;
    }
  }
}

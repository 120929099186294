<span
  class="inputSwitchlabel"
  [style.display]="displayCss"
  [style.margin-top.px]="data && data.marginTop > 0 ? data.marginTop : null"
  [life]="3000"
  [hideDelay]="250"
  pTooltip="{{ tooltip }}"
  [escape]="false"
  tooltipPosition="top"
  [showDelay]="500"
  tooltipZIndex="2100">
  <iu-checkbox-ui
    (contextmenu)="onContextMenu($event)"
    (press)="onContextMenu($any($event))"
    [(checked)]="checked"
    [attr.data-cy]="isTriState ? 'input-switch-tri' : 'input-switch'"
    [disabled]="isReadOnly"
    [tristate]="isTriState"
    [ngClass]="{ 'iu-field-conflict': hasConflict }"
    [attr.data-cy-columnName]="data ? data.columnName : columnName"
    [attr.data-cy-value]="checked">
  </iu-checkbox-ui>
  <span
    *ngIf="this.isLabelDisplay"
    style="height: 100%"
    (contextmenu)="onContextMenu($event)"
    (press)="onContextMenu($any($event))"
    [ngClass]="[hasConflict ? 'iu-field-conflict-icon' : '']"
    #spanRef>
    <p style="width: fit-content" (click)="!isReadOnly && labelClick($event)">{{ label }}</p>
    <i
      style="float: none"
      *ngIf="hasConflict && dataStored"
      #spanInput
      class="rtconflict fas fa-exclamation-triangle iconClass iu-field-conflict-icon"
      (click)="showConflictPanel($event)"
      aria-hidden="true">
    </i>
  </span>
  <iu-prime-overlay #opValuePref *ngIf="showValuePrefPanel" [dismissable]="false" appendTo="body">
    <iu-value-preference-panel
      [overlayPanel]="opValuePref"
      [fieldValue]="fieldValue"
      [dataStored]="this.dataStored"
      [sourceComponent]="this"
      [data]="this.data"
      (closeEmitter)="toggleValuePreferencePanel($event)"></iu-value-preference-panel>
  </iu-prime-overlay>
</span>

<iu-prime-overlay #opConflict>
  <a
    class="data-conflict"
    (click)="chooseData($event, (dataStored?.data)[data?.columnName], true); opConflict.hide()"
    >{{ (dataStored?.data)[data?.columnName] }}</a
  >
  |
  <a class="data-conflict" (click)="chooseData($event, conflictedData, false); opConflict.hide()">{{
    conflictedData
  }}</a>
</iu-prime-overlay>

@if (displayFileUploaded) {
  <div class="file_list">
    @if (uploadedFiles.length === 0) {
      <div [style.font-size]="overridedCSS?.inputFontSize">
        @if (isLoaderActive) {
          <p-progressSpinner />
        } @else {
          {{ uploadedFileList_noFiles | translate }}
        }
      </div>
    } @else {
      <table class="uploadedFiles" aria-label="uploaded files table">
        <thead>
          <tr class="file_list_header">
            <th colspan="2" scope="colgroup">
              <span [style.font-size]="overridedCSS?.labelFontSize">{{ uploadedFileList_title | translate }}</span>
            </th>

            <th scope="col">{{ fileList_createdDate | translate }}</th>

            @for (tag of displayedTags | slice: 0 : 4; track $index) {
              <th scope="col">
                {{ tag.colLabel }}
              </th>
            }

            <th scope="col">
              <input
                #filterInput
                type="text"
                class="file_upload_input_search"
                (keyup)="updateFilterOptions($event, filterInput.value, 'files')"
                placeholder="{{ uploadedFileList_search | translate }}"
                value=""
                width="10vw" />
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container
            [ngTemplateOutlet]="tableBody"
            [ngTemplateOutletContext]="{ files: uploadedFiles | filterList: filterListOptions }" />
        </tbody>
      </table>
    }
  </div>

  @if (linkedFiles.length > 0) {
    <div class="file_list linked_file" data-cy="fileupload-linkedfile-list">
      <table class="linkedFiles" aria-label="linked files table">
        <thead>
          <tr class="file_list_header">
            <th colspan="2" scope="colgroup">
              <span [style.font-size]="overridedCSS?.labelFontSize">{{ linkedFileList_title | translate }}</span>
            </th>

            <th scope="col">{{ fileList_createdDate | translate }}</th>

            @for (tag of displayedTags | slice: 0 : 4; track $index) {
              <th scope="col">
                {{ tag.colLabel }}
              </th>
            }

            <th scope="col">
              <input
                #filterLinkedInput
                type="text"
                class="file_upload_input_search"
                (keyup)="updateFilterOptions($event, filterLinkedInput.value, 'linkedFiles')"
                placeholder="{{ uploadedFileList_search | translate }}"
                value=""
                width="10vw" />
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container
            [ngTemplateOutlet]="tableBody"
            [ngTemplateOutletContext]="{ files: linkedFiles | filterList: filterLinkedListOptions }" />
        </tbody>
      </table>
    </div>
  }
}

<iu-prime-overlay
  #op
  appendTo="body"
  baseZIndex="2000"
  styleClass="fileUpload-overlayPanel"
  [showCloseIcon]="true"
  [style]="{ width: '450px' }">
  <ng-template pTemplate>
    <p-table [value]="displayedTags | slice: 4" [paginator]="true" [rows]="5">
      <ng-template pTemplate="header">
        <tr>
          <th scope="col">{{ 'fileupload.tagName' | translate }}</th>
          <th scope="col">{{ 'fileupload.tagValue' | translate }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-displayedTag>
        <tr [pSelectableRow]="rowData">
          <td>{{ displayedTag.colLabel }}</td>
          <td>
            {{
              (selectedFile.tags['META|' + displayedTag.columnName.toUpperCase() + '$']
                ? selectedFile.tags['META|' + displayedTag.columnName.toUpperCase() + '$']
                : selectedFile.tags['META|' + displayedTag.columnName.toUpperCase()]
              ) | docServerFormatValue: displayedTag.ad_reference_id
            }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-template>
</iu-prime-overlay>

@if (isReadOnly || canUpload) {
  <div>
    <h3 class="upload-list-title" style="margin-top: 0px">{{ uploadingFileList_title | translate }}</h3>
    <div [ngClass]="['ui-fileupload', styleClass || '']" [ngStyle]="style">
      <div
        #content
        [ngClass]="['ui-fileupload-content ui-corner-bottom']"
        (dragenter)="onDragEnter($event)"
        (dragleave)="onDragLeave($event)"
        (drop)="onDrop($event)"
        (click)="addFile($event)"
        data-cy="fileupload-click-or-drop-zone">
        <p-messages [value]="msgs" [enableService]="false" />
        <input
          #advancedfileinput
          type="file"
          style="display: none"
          (change)="onFileSelect($event)"
          [multiple]="multiple"
          [readonly]="isReadOnly"
          (focus)="!isReadOnly ? onFocus() : null"
          [tabindex]="isReadOnly ? '-1' : null"
          (blur)="onBlur()"
          data-cy="fileupload-input-file" />
        @if (!hasFiles()) {
          <div class="ui-fileupload-label">
            <i class="fas fa-cloud-upload-alt fa-4x" aria-hidden="true"></i>
            <p>{{ uploadingFileList_drag | translate }}</p>
          </div>
        } @else {
          <div class="ui-fileupload-files">
            <table class="ui-fileupload-table" aria-label="files upload table">
              @for (file of files; track $index; let i = $index) {
                <tr>
                  @if (uploadingFiles.indexOf(file) !== -1) {
                    <td>
                      <p-progressSpinner />
                    </td>
                  }
                  <td>
                    <ng-container
                      [ngTemplateOutlet]="fileLogo"
                      [ngTemplateOutletContext]="{ fileItem: file | fileUploadLogoFile: fromDocServer : isImage }" />
                  </td>
                  <th scope="row">
                    <div
                      [ngStyle]="{
                        width: content | getPercentInPxCss: { of: 'clientWidth', many: 40 }
                      }"
                      [style.font-size]="overridedCSS?.inputFontSize"
                      class="prime-fileupload-filename">
                      {{ file.name }}
                    </div>
                  </th>
                  <td>
                    <iu-tags-editor-ui [(tags)]="tags[i]" />
                  </td>
                  <td>
                    @if (uploadingFiles.indexOf(file) === -1) {
                      <button type="button" icon="fa fa-trash" pButton (click)="remove($event, i)"></button>
                    }
                  </td>
                </tr>
              }
            </table>
          </div>
        }
      </div>
    </div>
    @if (!auto) {
      <div class="ui-fileupload-button-container">
        @if (displayButtons) {
          <button
            pButton
            (click)="onClickUploadFiles()"
            [disabled]="uploadingFiles.length > 0"
            label="{{ uploadBtn_confirm | translate }}"
            class="p-button p-button-secondary"
            data-cy="fileupload-btn-confirm"></button>

          <button
            pButton
            (click)="clear()"
            [disabled]="uploadingFiles.length > 0"
            label="{{ uploadBtn_cancel | translate }}"
            class="p-button p-button-alert"
            data-cy="fileupload-btn-cancel"></button>
        }
      </div>
    }
  </div>
}

<iu-preview-doc [modal]="true" [fromDocServer]="fromDocServer" />

<ng-template #fileLogo let-fileItem="fileItem">
  @if (fileItem && fileItem.isUrl) {
    <img
      class="fileUpload-logo"
      [src]="fileItem.url | docServerUrl | async"
      [width]="previewWidth"
      (click)="onPreview($event, file, i)"
      data-cy="previewFile"
      alt="file preview" />
  } @else if (fileItem) {
    <i
      [ngClass]="['fileUpload-icon', fileItem.iconClass ?? '']"
      aria-hidden="true"
      data-cy="previewFile"
      alt="file preview"></i>
  }
</ng-template>

<ng-template #tableBody let-files="files">
  @for (file of files; track $index; let i = $index) {
    <tr [attr.data-cy-index]="i" data-cy="fileupload-linkedfile">
      <td>
        <ng-container
          [ngTemplateOutlet]="fileLogo"
          [ngTemplateOutletContext]="{ fileItem: file | fileUploadLogoFile: fromDocServer : isImage }" />
      </td>
      <td class="fileUpload-name" [style.font-size]="overridedCSS?.inputFontSize" (click)="onPreview($event, file, i)">
        {{ file.name }}
      </td>
      <td>
        @if (file.createdDate) {
          <div class="taggedValue">
            <span>{{ file.createdDate }}</span>
          </div>
        }
      </td>

      @for (tag of displayedTags | slice: 0 : 4; track $index) {
        <td class="taggedValue">
          @if (file?.tags?.['META|' + tag.columnName.toUpperCase()]) {
            <div class="taggedValue">
              <span
                [title]="
                  (file.tags['META|' + tag.columnName.toUpperCase() + '$']
                    ? file.tags['META|' + tag.columnName.toUpperCase() + '$']
                    : file.tags['META|' + tag.columnName.toUpperCase()]
                  ) | docServerFormatValue: tag.ad_reference_id
                ">
                {{
                  (file.tags['META|' + tag.columnName.toUpperCase() + '$']
                    ? file.tags['META|' + tag.columnName.toUpperCase() + '$']
                    : file.tags['META|' + tag.columnName.toUpperCase()]
                  ) | docServerFormatValue: tag.ad_reference_id
                }}</span
              >
            </div>
          }
        </td>
      }

      <td>
        @if (displayedTags?.length > 4) {
          <button
            pButton
            icon="fas fa-info"
            [title]="'fileupload.moreTags' | translate"
            (click)="selectedFile = file; op.toggle($event)"></button>
        }

        @if (canDownload) {
          <button
            pButton
            icon="fa fa-download"
            [title]="'generic.download' | translate"
            (click)="downloadFile($event, file, i)"
            class=""></button>
        }
        <button
          pButton
          icon="fa fa-table-columns"
          [title]="'generic.openFileOnSide' | translate"
          (click)="openFileOnSide($event, $any(file), i)"
          data-cy="openFileOnSide"></button>
        <button
          pButton
          class="fileUpload-copyLink"
          icon="fa fa-link"
          [title]="'generic.copyLink' | translate"
          (click)="copyLinkFile($event, file, i)"
          data-cy="copyLinkFile"></button>
        @if (canDelete && (file.isDeletable === undefined || file.isDeletable)) {
          <button
            pButton
            icon="fa fa-trash"
            (click)="isReadOnly ? null : deleteFile($event, file, i)"
            [title]="'generic.delete' | translate"
            data-cy="deleteFile"
            class="p-button p-button-alert"></button>
        }
      </td>
    </tr>
  }
</ng-template>

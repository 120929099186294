<div class="content">
  <iu-storekeeper-transfer-details [(data)]="headerData" />

  <div class="title-bar">
    <!-- @if (tabType() === 'TL' && isCreation()) { -->
    @if (isCreation()) {
      <h3 class="title">{{ 'NewLine' | SKWTranslate }}</h3>
      <iu-storekeeper-button [label]="'Cancel' | SKWTranslate" (onClick)="cancelNewLine($event)" />
    } @else if (tabType() === 'TL') {
      <h3 class="title">{{ 'Loading' | SKWTranslate }}</h3>
    } @else {
      <h3 class="title">{{ 'Unloading' | SKWTranslate }}</h3>
    }
  </div>

  <iu-storekeeper-window-input-scan [(data)]="inputData" (validation)="save($event)" />

  <iu-storekeeper-input
    [type]="'text'"
    [label]="'Location' | SKWTranslate"
    [placeholder]="
      (tabType() === 'TL' ? data()?.locator_source?.displayValue : data()?.locator_destination?.displayValue) ?? ''
    "
    [value]="inputData()?.locator?.displayValue ?? ''"
    (valueChange)="changeValue('locator', $event)"
    [validation]="isLocatorCorrect()"
    [readOnly]="!isCreation()" />
  <!-- !isCreation() ? scannedEmplacement() === data().locator_source : undefined -->
  @if (data()?.palette) {
    <iu-storekeeper-input
      [type]="'text'"
      [label]="'Pallet' | SKWTranslate"
      [placeholder]="
        (tabType() === 'TL' ? data()?.palette_source?.displayValue : data()?.palette_destination?.displayValue) ?? ''
      "
      [value]="inputData()?.palette?.displayValue ?? ''"
      (valueChange)="changeValue(tabType() === 'TL' ? 'palette_source' : 'palette_destination', $event)"
      [validation]="isPaletteCorrect()"
      [readOnly]="!isCreation()" />

    <!-- !isCreation() ? scannedPalette() === data().palette_source : undefined -->
  }
  <iu-storekeeper-input
    [type]="'text'"
    [label]="'Product' | SKWTranslate"
    [placeholder]="data()?.M_Product_ID?.displayValue ?? ''"
    [value]="inputData()?.Product?.displayValue ?? ''"
    (valueChange)="changeValue('Product', $event)"
    [validation]="isProductCorrect()"
    [readOnly]="!isCreation()" />
  <!-- !isCreation() ? scannedArticle() === data().M_Product_ID : undefined -->
  <iu-storekeeper-input
    [type]="'number'"
    [label]="'Quantity' | SKWTranslate"
    [value]="inputData()?.Qty ?? undefined"
    [placeholder]="data()?.Qty ?? ''"
    (valueChange)="changeValue('Qty', $event)" />
</div>

<iu-storekeeper-navbar-button
  [icon]="'icon-circle-check'"
  [label]="'Confirm' | SKWTranslate"
  [type]="'confirm'"
  (onClick)="save($event)" />

<iu-storekeeper-window-panel
  [title]="'DifferentLocation' | SKWTranslate"
  [(isOpen)]="isOpen"
  (confirm)="save($event, true)"
  (cancel)="cancel($event)">
  <i class="icon icon-triangle-exclamation" aria-hidden="true" icon></i>
  <p class="text" content> {{ 'DifferentLocationMsg' | SKWTranslate }}</p>
</iu-storekeeper-window-panel>

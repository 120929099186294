/* eslint-disable deprecation/deprecation */
import { Directive, ElementRef, effect, inject, input, output } from '@angular/core';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';

@Directive({
  selector: '[iuNumberAnimation]',
  standalone: true,
})
export class NumberAnimationDirective {
  el: ElementRef<HTMLElement> = inject(ElementRef);
  connectorService = inject(SecurityManagerService);

  duration = input(1500);
  data = input<number | string>();
  playAnimation = input<boolean | undefined>(undefined);
  outputText = input(true);
  formatter = input<Intl.NumberFormat | ((value: number) => number) | undefined>(undefined);

  numberEmitter = output<number>();

  constructor() {
    effect(() => {
      const data = this.data();
      if (this.playAnimation()) {
        this.animateValue(data);
      }
    });
  }

  animateValue(data: number | string) {
    const number = parseInt(`${data ?? 0}`, 10);

    if (number === 0) {
      this.setText(number);
      return;
    }

    let startTimestamp = null;
    const step = (timestamp: number) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / this.duration(), 1);
      const n = Math.floor(progress * (number ?? 0));

      this.setText(isNaN(n) ? 0 : n);
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };

    window.requestAnimationFrame(step);
  }

  setText(number: number) {
    if (this.outputText()) {
      const formatter =
        this.formatter() ??
        Intl.NumberFormat(this.connectorService.getIupicsUserContext()['#AD_Language'].slice(0, 2), {
          notation: 'compact',
        });
      this.el.nativeElement.innerHTML =
        typeof formatter === 'function' ? `${formatter(number)}` : formatter.format(number);
    }

    this.numberEmitter.emit(number);
  }
}

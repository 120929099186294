import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class KeybindStateManagerService {
  public splitViewEmitter = new EventEmitter<boolean>();
  public focusEmitter = new EventEmitter<string>();

  private isSplitView: boolean;
  private tabFocus: string;

  constructor() {}

  setSplitView(value: boolean) {
    this.isSplitView = value;
    this.splitViewEmitter.emit(value);
  }

  setFocus(value: string) {
    this.tabFocus = value;
    this.focusEmitter.emit(value);
  }

  getFocusedTab() {
    return this.tabFocus;
  }
}

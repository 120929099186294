<div class="inputs">
  <iu-storekeeper-input
    [type]="'text'"
    [label]="'Products' | SKWTranslate"
    [value]="data()?.M_Product_ID?.displayValue ?? ''"
    [placeholder]="'ScanProduct' | SKWTranslate"
    [readOnly]="true" />
  <iu-storekeeper-input
    [type]="'number'"
    [label]="'Quantity' | SKWTranslate"
    [placeholder]="'EnterQty' | SKWTranslate"
    [value]="data()?.Quantity"
    (valueChange)="updateValue('Quantity', $event)" />
  <iu-storekeeper-input
    class="full-width"
    [type]="'text'"
    [label]="'Location' | SKWTranslate"
    [placeholder]="'ScanLocator' | SKWTranslate"
    [readOnly]="true"
    [value]="data()?.M_Locator_ID?.displayValue ?? ''" />
</div>

<div class="buttons">
  @if (complete()) {
    <iu-storekeeper-button
      class="button button-wide"
      [label]="'Delete' | SKWTranslate"
      (onClick)="remove.emit($event)" />
  } @else {
    <iu-storekeeper-button
      class="button button-left"
      [label]="'Cancel' | SKWTranslate"
      (onClick)="cancel.emit($event)" />
    <iu-storekeeper-button
      class="button button-right"
      [label]="'Add' | SKWTranslate"
      [type]="'outlined'"
      (onClick)="add.emit($event)" />
  }
</div>

<iu-storekeeper-window-panel
  [(isOpen)]="isOpen"
  [cancelButtonLabel]="'Erase' | SKWTranslate"
  [confirmButtonLabel]="'Apply' | SKWTranslate"
  (cancel)="erase($event)"
  (confirm)="apply($event)">
  <div class="filters" content>
    @for (filter of availableFilters(); track filter) {
      <div class="filter">
        <iu-checkbox-ui
          [tristate]="false"
          [checked]="filter | isFilterActivePipe: filters()"
          (checkedChange)="filterChange(filter, $event)" />
        <label [for]="filter">{{ filter.label | SKWTranslate }}</label>
      </div>
    }
  </div>
</iu-storekeeper-window-panel>

import { NgClass, NgIf } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import EditTabUiComponent from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { AbstractDataContainer, AbstractDataContainerCallout } from '@iupics-manager/models/abstract-datacontainer';
import { IupicsDataField } from '@iupics-manager/models/iupics-data';
import { TextLimitPipe } from '@iupics-util/pipes/text-limit/text-limit.pipe';
import { ColorPickerModule } from 'primeng/colorpicker';
import { OverlayPanel } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import PrimeOverlayComponent from '../../../overrided/prime-overlay/prime-overlay.component';
import ValuePreferencePanelComponent from '../../value-preference-panel/value-preference-panel.component';

@Component({
  selector: 'iu-input-color-ui',
  templateUrl: './input-color-ui.component.html',
  styleUrls: ['./input-color-ui.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    TooltipModule,
    NgIf,
    NgClass,
    FormsModule,
    PrimeOverlayComponent,
    forwardRef(() => ValuePreferencePanelComponent),
    ColorPickerModule,
    TextLimitPipe,
  ],
})
export default class InputColorUiComponent extends AbstractDataContainer implements OnInit, AfterViewInit {
  @Input()
  columnName: string;
  @ViewChild('input', { static: true })
  inputRef: ElementRef<HTMLInputElement>;
  @ViewChild('opConflict', { static: true })
  opConflict: OverlayPanel;
  dataContainers: AbstractDataContainerCallout;
  @Input() data: IupicsDataField;

  @Output() fieldValueChange = new EventEmitter<any>();

  ngOnInit() {
    super.ngOnInit();
    if (this.cssClass !== undefined) {
      this.cssGrid = this.cssClass;
    }
    this.cssClass = ' ' + this.cssGrid;
    this.setFieldMandatory();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    let parentComp = this.DOMParentComponent;
    while (parentComp && !(parentComp instanceof EditTabUiComponent)) {
      parentComp = parentComp.DOMParentComponent;
    }
    if (parentComp instanceof EditTabUiComponent) {
      this.dataContainers = new AbstractDataContainerCallout(parentComp.dataContainers);
    }
  }

  showConflictPanel(ev) {
    ev.target.getBoundingClientRect = function () {
      return { top: this.offsetTop, left: this.offsetLeft };
    };
    this.opConflict.toggle(ev);
  }
}

import { NgClass, NgStyle } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  Type,
  ViewChild,
  ViewContainerRef,
  forwardRef,
} from '@angular/core';
import {
  CompiereDataGridFilterType,
  CompiereDataGridType,
  DataStore,
  DataStoreKey,
  DataStoreName,
  DataStoreRequest,
  DataStoreStatus,
} from '@compiere-ws/models/compiere-data-json';
import { DocserverEntry } from '@compiere-ws/models/docserver-entry-json';
import { DocserverWsResponse } from '@compiere-ws/models/docserverWsResponse';
import { CallInProgressService } from '@compiere-ws/services/call-in-progress/call-in-progress.service';
import { DocServerService } from '@compiere-ws/services/doc-server/doc-server.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import BannerComponent from '@iupics-components/message/banner/banner.component';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { PrimeSpeedDialComponent } from '@iupics-components/overrided/prime-speed-dial/prime-speeddial.component';
import EmailEditorUiComponent from '@iupics-components/specific/window/email-editor-ui/email-editor-ui.component';
import ExportDataUiComponent from '@iupics-components/specific/window/export-data-ui/export-data-ui.component';
import { InfoDialogType } from '@iupics-components/specific/window/info-dialog/info-dialog.component';
import JoinFileUiComponent from '@iupics-components/specific/window/join-file-ui/join-file-ui.component';
import MergetoolComponent from '@iupics-components/specific/window/mergetool/mergetool.component';
import ProcessUiComponent from '@iupics-components/specific/window/process-ui/process-ui.component';
import SpecificWindowUiComponent from '@iupics-components/specific/window/specific-window-ui/specific-window-ui.component';
import DraggableWindowComponent from '@iupics-components/standard/draggable-window/draggable-window.component';
import StepperUiComponent from '@iupics-components/standard/fields/stepper-ui/stepper-ui.component';
import GridTabInfinityScrollUiComponent from '@iupics-components/standard/grid/grid-tab-infinity-scroll-ui/grid-tab-infinity-scroll-ui.component';
import GridViewUiComponent from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import EditTabUiComponent from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import GridUiComponent from '@iupics-components/standard/layouts/grid-ui/grid-ui.component';
import MenuBarDetailUiComponent from '@iupics-components/standard/menu/menu-bar-detail-ui/menu-bar-detail-ui.component';
import MenuSmartbuttonsUiComponent from '@iupics-components/standard/menu/menu-smartbuttons-ui/menu-smartbuttons-ui.component';
import UniversalFilterUiComponent from '@iupics-components/standard/menu/universal-filter-ui/universal-filter-ui.component';
import {
  EditViewGetFormIDByTabID,
  EditViewGetFormIDByWindowID,
  EditViewGetProcessIDByTabID,
  EditViewGetProcessIDByWindowID,
} from '@iupics-components/standard/menu/utils/menu.utils';
import NotesPanelUiComponent from '@iupics-components/standard/notes/notes-panel-ui/notes-panel-ui.component';
import PreviewDocComponent from '@iupics-components/standard/preview-doc/preview-doc.component';
import { AppConfig } from '@iupics-config/app.config';
import { CacheManagerService } from '@iupics-manager/managers/cache-manager/cache-manager.service';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { DynamicComponentManagerService } from '@iupics-manager/managers/dynamic-component-manager/dynamic-component-manager.service';
import { KeybindStateManagerService } from '@iupics-manager/managers/keybind-state-manager/keybind-state-manager.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { PrintReportManager } from '@iupics-manager/managers/print-report/print-report-manager.service';
import { RecentItemsManagerService } from '@iupics-manager/managers/recent-items-manager/recent-items-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryUtils } from '@iupics-manager/managers/ui-creator/window-factory/window-factory-utils';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { AbstractDynamicView } from '@iupics-manager/models/abstract-dynamic-view';
import { DataConflict } from '@iupics-manager/models/data-conflict';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsZoomTarget } from '@iupics-manager/models/iupics-data';
import { IupicsCellEvent, IupicsEvent, IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { MongoSearchQueryOperator, MongoSearchQueryPart } from '@iupics-manager/models/mongo-search';
import { DynamicContainerDirective } from '@iupics-util/directives/dynamic-container.directive';
import { createComponent } from '@iupics-util/tools/component-cache-loader';
import { LogicEvaluator } from '@iupics-util/tools/logic-evaluator';
import { ApizEventType } from '@iupics/apiz-grid';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IupicsMenuType } from '@web-desktop/models/menu-item-ui';
import { environment } from 'environments/environment';
import { cloneDeep, debounce, has } from 'lodash';
import * as moment from 'moment';
import { MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanel, ScrollPanelModule } from 'primeng/scrollpanel';
import { Sidebar, SidebarModule } from 'primeng/sidebar';
import { TooltipModule } from 'primeng/tooltip';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import AdditionalInfoUiComponent from '../additional-info-ui/additional-info-ui.component';
import BladeUiComponent from '../blade-ui/blade-ui.component';
import ModalUiComponent, { ModalContentType } from '../modal-ui/modal-ui.component';
import { EditViewUtils } from './utils/edit-view.utils';
@Component({
  selector: 'iu-edit-view-ui',
  templateUrl: './edit-view-ui.component.html',
  styleUrls: ['./edit-view-ui.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgStyle,
    ButtonModule,
    GridViewUiComponent,
    MenuBarDetailUiComponent,
    ProgressBarModule,
    MenuSmartbuttonsUiComponent,
    StepperUiComponent,
    BannerComponent,
    ScrollPanelModule,
    DynamicContainerDirective,
    MergetoolComponent,
    SidebarModule,
    NotesPanelUiComponent,
    forwardRef(() => ModalUiComponent),
    JoinFileUiComponent,
    EmailEditorUiComponent,
    ExportDataUiComponent,
    ProcessUiComponent,
    UniversalFilterUiComponent,
    GridTabInfinityScrollUiComponent,
    TranslateModule,
    DraggableWindowComponent,
    PreviewDocComponent,
    PrimeSpeedDialComponent,
    TooltipModule,
  ],
})
export default class EditViewUiComponent extends AbstractDynamicView implements OnInit, AfterViewInit, OnDestroy {
  /**
   * utilisé pour modifier le style quand une formModal est ouverte par un composant autre que bouton
   */
  customFormModalBodyCss = undefined;
  submitted: boolean;
  editTabs: EditTabUiComponent[] = [];
  noData = false;
  scrollPanelid: string;
  isLoading = false;
  private _conflictsResult: DataConflict = {
    refreshAuto: false,
    mustRefresh: false,
    hasConflicts: false,
    dataChanged: {},
    dataConflict: {},
  };
  public get conflictsResult(): DataConflict {
    return this._conflictsResult;
  }
  public set conflictsResult(conflictsResult: DataConflict) {
    this._conflictsResult = conflictsResult;
    if (conflictsResult.refreshAuto) {
      setTimeout(() => {
        this.refreshData();
      }, 2000);
    }
  }
  get nbConflicts() {
    const nb = Object.keys(this.conflictsResult.dataConflict).length;
    if (nb <= 0) {
      this.conflictsResult.hasConflicts = false;
    }
    return nb;
  }
  wantToMergeConflicts = false;
  oldDataStoreKey: DataStoreKey;
  displayJoinFilesPanel = false;
  isZoomEditView = false;
  isStepperVisible = false;
  additionalInfoComponent: AdditionalInfoUiComponent;
  nbUploadedFiles = 0;
  windowType = IupicsMenuType.WINDOW;
  /*keep last event to update the state of additionalInfo if he isnt created yet*/
  lastEvent: IupicsEvent;
  @ViewChild('vcrSpecific', { read: ViewContainerRef, static: true })
  vcrSpecific: ViewContainerRef;
  @ViewChild('specificModal', { read: ModalUiComponent, static: true })
  specificModal: ModalUiComponent;
  @ViewChild('exportDataModal')
  exportDataModal: ModalUiComponent;
  @ViewChild('vcrAudit', { read: ViewContainerRef })
  vcrAudit: ViewContainerRef;
  @Input()
  linkedComponents;
  @Input()
  isAdditionalGenerated = false;
  /*permet de savoir si l'edit attend un scroll call par le resizede la grid */
  isWaitingScroll = false;
  isAdditionalOpened: boolean;
  zoomTarget: IupicsZoomTarget;
  isZoomTarget = false;
  filterZoomTarget = [];
  zoomTargetData: any[];
  GridViewVisibility = 'hidden';
  //   displaySearch = false;
  searchLinkedComponent;

  //   specificDisplaySearch = false;
  specificSearchLinkedComponent;

  isSidebarOpen = false;
  notes: any[];

  @ViewChild(MenuBarDetailUiComponent, { static: true })
  menuBarDetailComponent: MenuBarDetailUiComponent;
  @ViewChild('closeButton')
  closeButtonElement: ElementRef;
  @ViewChild('openButton')
  openButton: ElementRef;
  @ViewChild('editViewElement', { static: true })
  editViewElement: ElementRef;
  @ViewChild('gridUi', { static: true })
  gridUiElement: ElementRef;
  @ViewChild('additionalInfoVcr', { read: ViewContainerRef })
  additionalInfoVcr: ViewContainerRef;

  @ViewChild(GridUiComponent, { static: true })
  editTabcomponent: GridUiComponent;

  @ViewChild(ScrollPanel, { static: true })
  scrollPanel: ScrollPanel;

  @ViewChild(MenuSmartbuttonsUiComponent, { static: true })
  smartButtons: MenuSmartbuttonsUiComponent;

  @ViewChild('gridViewUi', { static: false })
  gridViewUi: GridViewUiComponent;
  @ViewChild('sideBar') sideBar: Sidebar;

  @ViewChild('sideBarVcr', { read: ViewContainerRef })
  sideBarVcr: ViewContainerRef;

  @ViewChild('sideBladeVcr', { read: ViewContainerRef })
  sideBladeVcr: ViewContainerRef;
  @ViewChild('sideBladeContainer', { static: false })
  sideBladeContainerEl: ElementRef<HTMLDivElement>;
  sideBladeTitle;
  sideBladeIconClass;
  @ViewChild('stepperDiv', { static: false }) stepperElt: ElementRef<HTMLDivElement>;

  changingStepper: Subject<any> = new Subject();
  changingMenuBar: BehaviorSubject<any> = new BehaviorSubject(DataStoreStatus.NEWRECORD);

  sideComponentRef: ComponentRef<any> = null;
  private _currentDataStoreKey: DataStoreKey;
  get currentDataStoreKey(): DataStoreKey {
    return this._currentDataStoreKey;
  }
  set currentDataStoreKey(currentDataStoreKey: DataStoreKey) {
    this._currentDataStoreKey = currentDataStoreKey;
    this.container.notifyUrlChange();
    /*mettre à jour le datastorekey des editTabs */
    if (this.editTabs && this.editTabs.length > 0) {
      for (const editTab of this.editTabs) editTab.dataStoreKey = currentDataStoreKey;
    }
  }
  gridTabIdSelected: number;
  stepItems: MenuItem[] = [];

  displayEmailEditor = false;
  displayExportDataModal = false;
  exportDataOptions: any[] = [];
  showAuditsPanel = false;

  displayProcessUI = false;
  displayProcessUI_sourceComponentData: any;
  displayFormUI = false;
  processId: number;
  formId: number;

  isStepperEmpty: boolean;

  isSplitView = false;
  specificWindowTitle: string;
  table_id: number;
  isProcessLaunch = false;
  priceHistoryFormId: number;
  modalClass = 'p-col-10 p-md-10 p-lg-10';

  searchPanelValidation: string;

  //#region enum for template
  ModalContentType = ModalContentType;
  DatastoreStatus = DataStoreStatus;
  //#endregion
  sideBladeButtons = [];
  isResizing = false;
  constructor(
    private store: DataStoreService,
    private socketService: SocketService,
    private printReportManager: PrintReportManager,
    private renderer: Renderer2,
    private connectorService: SecurityManagerService,
    private translateService: TranslateService,
    private uiCreatorService: UICreatorService,
    private keybindStateManager: KeybindStateManagerService,
    private docServerService: DocServerService,
    private messageManager: MessageManagerService,
    private riManager: RecentItemsManagerService,
    private config: AppConfig,
    private callInProgressService: CallInProgressService,
    private cacheManager: CacheManagerService,
    private cd: ChangeDetectorRef,
    private dynamicComponentManager: DynamicComponentManagerService
  ) {
    super();
  }
  ngOnInit() {
    this.cssClass += ' ui-g';
    this.scrollPanelid = 'scrollPanelEditView' + this.tabId;
    this.keybindStateManager.splitViewEmitter.subscribe((value: boolean) => (this.isSplitView = value));
    moment.locale(this.connectorService.getIupicsDefaultLanguage().iso_code.replace(/_/g, '-'));
    this.sideBladeButtons = [
      {
        tooltipOptions: {
          tooltipLabel: this.translateService.instant('generic.openFileInFloatingPanel'),
        },
        icon: 'fa fa-window-restore',
        command: () => {
          this.openInDraggableWindow();
        },
      },
      {
        tooltipOptions: {
          tooltipLabel: this.translateService.instant('generic.close'),
        },
        icon: 'fa fa-window-minimize',
        command: () => {
          this.closeSideBarAndBlade();
        },
      },
    ];
    this.onResizeSideBladeEnd = debounce(this.onResizeSideBladeEnd, 100);
  }

  openAdditionalInfo() {
    const item: DynamicComponent = {
      container: this.container,
      DOMParentComponent: this,
      linkedComponents: this.linkedComponents,
      component: 'AdditionalInfoUiComponent',
      cssClass: 'p-col-12',
      isCssOnComponent: true,
      windowId: this.data.AD_Window_ID,
      tabId: this.tabId,
    };
    this.componentEmitter.emit({
      type: IupicsTypeEvent.showAdditionalInfo,
      item: item,
    });
  }

  getCurrentDataStoreKey() {
    return this.currentDataStoreKey;
  }

  minimize() {
    this.isAdditionalOpened = false;
    this.additionalInfoWidth = '2em';
    this.refreshSmartButtons();
    this.scrollTo();
  }

  resize() {
    this.isAdditionalOpened = true;
    if (Global.isMobile()) {
      this.additionalInfoWidth = '100%';
    } else {
      this.additionalInfoWidth = this.additionalInfoWidthExpanded + 'em';
    }
    if (!this.isAdditionalGenerated) {
      this.openAdditionalInfo();
      this.isAdditionalGenerated = true;
    } else {
      for (const editTab of this.additionalInfoComponent.editTabs) {
        if (!editTab.DOMChildrenComponent[0]?.data) {
          continue;
        }

        const gridTabInfinityScroll = (<GridViewUiComponent>editTab.DOMChildrenComponent[0])
          .GridTabInfinityScrollUiComponent;
        if (gridTabInfinityScroll) {
          setTimeout(() => {
            gridTabInfinityScroll.grid.api.refreshHeader();
            gridTabInfinityScroll.columnResize();
          }, 0);
        }
      }
    }
    this.refreshSmartButtons();
    this.scrollTo();
  }
  scrollTo() {
    if (this?.container?.scrollToScrollingPosition)
      (<BladeUiComponent>this.container).scrollToScrollingPosition(this.editViewElement.nativeElement);
  }

  ngAfterViewInit() {
    if (this.zoomInfo) {
      this.generateZoom();
    }
    if (this.zoomTarget) {
      this.filterZoomTarget.push(this.zoomTarget.record_id);
      this.isZoomTarget = true;
    }
    if (!this.zoomTarget && !this.zoomInfo) {
      this.buildChildren();
    }
    document.body.style.cursor = 'auto';
  }
  onZoomTargetGridAfterViewInit(gridTab: GridTabInfinityScrollUiComponent) {
    if (this.zoomTarget && this.isZoomTarget) {
      const compiereRequest = cloneDeep(gridTab.currentStateRequest);
      compiereRequest.startRow = 0;
      compiereRequest.endRow = 1;
      const request: DataStoreRequest = {
        windowId: (<BladeUiComponent>this.container).infoComponent.windowId,
        parent_constraint: this.zoomTarget.record_id.replace(/,/g, '='),
        compiereRequest,
      };
      this.subscriptions.push(
        this.store.getWindowSingleData(request).subscribe((dataStore: DataStore) => {
          if (dataStore) {
            this.currentDataStoreKey = this.store.generateDataStoreKey(
              (<BladeUiComponent>this.container).infoComponent.windowId,
              this.tabId,
              dataStore.data['Data_UUID'],
              this.zoomTarget.record_id.replace(/,/g, '=')
            );

            this.refreshSmartButtons(dataStore);
            this.updateNbFileAttached(dataStore);
            this.buildChildren();
          } else {
            this.removeComponent();
            throw new Error(this.translateService.instant('notification.zoomInfoNotValid'));
          }
        })
      );
    }
  }

  buildChildren() {
    for (const child of this.children) {
      WindowFactoryUtils.addContainerComponent({
        parent: this,
        item: child,
        isCssOnComponent: child.container ? child.isCssOnComponent : false,
      });
    }

    if (<BladeUiComponent>this.container) {
      (<BladeUiComponent>this.container).updateBlade();
    }
  }

  addTabToEditView(editTabUi: EditTabUiComponent) {
    this.editTabs.push(editTabUi);
    if (this.editTabs.length === 1) {
      // cacher la légende du premier edittab
      this.editTabs[0].fieldset.isLegendVisible = false;
      this.editTabs[0].isTopEditTab = true;
      this.isStepperVisible = this.editTabs[0].data.isDocumentTab;
      if (
        (<EditViewUiComponent>(
          this.DOMParentComponent.DOMChildrenComponent.find((child) => child instanceof EditViewUiComponent)
        )).isStepperVisible === true &&
        <EditViewUiComponent>(
          this.DOMParentComponent.DOMChildrenComponent.find((child) => child instanceof EditViewUiComponent)
        ) !== this
      ) {
        this.isStepperVisible = true;
        this.isStepperEmpty = true;
      }
    }
  }

  checkData(): boolean {
    return this.store.isWindowNewData(this.currentDataStoreKey)
      ? false
      : this.store.isWindowDataSYNC(this.currentDataStoreKey);
  }

  checkBeforeClose(event?: Event, onlyEditView = true, callback?: Function) {
    let isOK = false;
    try {
      isOK = this.checkData();
    } catch (e) {
      console.error(e);
      isOK = true;
    }
    if (!this.isReadOnly && !isOK) {
      Global.infoDialog.message = {
        summary: this.translateService.instant('infodialog.dialogs.checkBefore.close.title'),
        detail: this.translateService.instant('infodialog.dialogs.checkBefore.close.message'),
      };
      Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
      Global.infoDialog.showInfoDialog();
      const confirm = Global.infoDialog.confirm.subscribe((e: any) => {
        this.beforeSave(event).then(() => {
          if (
            this.conflictsResult &&
            this.conflictsResult.hasConflicts === false &&
            this.conflictsResult.mustRefresh === false
          ) {
            if (onlyEditView) {
              this.removeComponent();
            } else {
              if (callback) {
                callback();
              }
            }
          }
        });
        confirm.unsubscribe();
        cancel.unsubscribe();
      });
      const cancel = Global.infoDialog.cancel.subscribe((e) => {
        if (this.editTabs[0].dataStored.status !== DataStoreStatus.NEWRECORD) {
          this.subscriptions.push(
            this.store.syncWithRemoteWindowData(this.currentDataStoreKey).subscribe((res) => {
              this.notifierLinkedComponent.next({
                type: IupicsTypeEvent.REFRESH_GRID,
              });
              if (onlyEditView) {
                this.removeComponent();
              } else {
                if (callback) {
                  callback();
                }
              }
            })
          );
        } else {
          this.store.deleteDataFromStoreOnly(this.currentDataStoreKey);
          if (onlyEditView) {
            this.removeComponent();
          } else {
            if (callback) {
              callback();
            }
          }
        }
        confirm.unsubscribe();
        cancel.unsubscribe();
      });
    } else if (onlyEditView) {
      this.removeComponent();
    } else if (callback) {
      callback();
    }
    event?.stopPropagation();
  }

  removeComponent(fromSelectChange = false) {
    if (!fromSelectChange) {
      if (this.isZoomEditView && this.linkedComponents?.[0]?.isZoom) {
        this.linkedComponents[0].isZoom = false;
      }

      if (this.isZoomTarget && this.linkedComponents?.[0]?.smartButtons) {
        this.linkedComponents[0].smartButtons.toggleButton(this.zoomTarget.buttonId);
      }
    }

    const itemToRemove: DynamicComponent = {
      container: this.container,
      DOMParentComponent: this.DOMParentComponent,
      component: this,
      tabId: this.tabId,
      windowId: (<BladeUiComponent>this.container).infoComponent.windowId,
    };

    this.componentEmitter.emit({
      type: IupicsTypeEvent.removeChildEvent,
      item: itemToRemove,
    });

    this.notifierLinkedComponent.next({
      type: IupicsTypeEvent.removeChildEvent,
    });
  }

  onChildUpdate(event): void {
    switch (event.type) {
      case IupicsTypeEvent.collapseEvent:
        for (const child of this.DOMChildrenComponent) child.onChildUpdate(event);
        this.collapseElement(event);
        break;

      case IupicsTypeEvent.expandEvent:
        for (const child of this.DOMChildrenComponent) child.onChildUpdate(event);
        this.expandElement(event);
        break;

      case IupicsTypeEvent.showAdditionalInfo: {
        const additionalInfo = this.DOMChildrenComponent.find((child) => child instanceof AdditionalInfoUiComponent);
        event.item.dataStoreKey = this.currentDataStoreKey;
        additionalInfo.onChildUpdate(event);
        break;
      }

      case IupicsTypeEvent.selectDataChange:
        if (
          this.DOMChildrenComponent?.[0]?.DOMChildrenComponent?.[0]?.DOMChildrenComponent?.[0]
            ?.DOMChildrenComponent?.[0] instanceof SpecificWindowUiComponent
        ) {
          this.DOMChildrenComponent?.[0].DOMChildrenComponent?.[0].DOMChildrenComponent?.[0].DOMChildrenComponent?.[0].onSiblingUpdate(
            event
          );
        }
        break;
    }
  }

  onSiblingUpdate(event: IupicsEvent) {
    switch (event.type) {
      case IupicsTypeEvent.collapseEvent:
      case IupicsTypeEvent.expandEvent:
        if (!this.isAdditionalGenerated) {
          // keep last change so additionnalInfo can have the same state as edit
          this.lastEvent = cloneDeep(event);
        }

        for (const editTab of this.editTabs) {
          if (editTab.DOMChildrenComponent[0]) {
            editTab.DOMChildrenComponent[0].onSiblingUpdate(event);
          }
        }

        break;

      case IupicsTypeEvent.selectZoomChange:
        this.refreshZoom(event.item.zoomInfo);
        break;

      case IupicsTypeEvent.selectDataChange:
        this.retrieveData(event.item.dataStoreKey);
        this.getFirstFileAttached();
        break;

      case IupicsTypeEvent.checkBeforeChange:
        if (this.currentDataStoreKey) {
          this.checkBeforeChange(event);
        } else {
          this.notifierLinkedComponent.next({
            type: IupicsTypeEvent.triggerAfterChange,
            item: event.item,
          });
        }
        break;
    }

    this.onChildUpdate(event);
  }

  checkBeforeChange(event: IupicsEvent) {
    const isOK = this.checkData();
    if (!isOK) {
      const ctx = this.connectorService.getIupicsUserContext();
      if (has(ctx, 'AutoCommit') && ctx.AutoCommit === 'Y' && !this.store.isWindowNewData(this.currentDataStoreKey)) {
        this.beforeSave(document.createEvent('Event')).then(() => {
          if (
            this.conflictsResult &&
            this.conflictsResult.hasConflicts === false &&
            this.conflictsResult.mustRefresh === false
          ) {
            this.notifierLinkedComponent.next({
              type: IupicsTypeEvent.triggerAfterChange,
              item: event.item,
            });
          }
        });
      } else {
        Global.infoDialog.message = {
          summary: this.translateService.instant('infodialog.dialogs.checkBefore.close.title'),
          detail: this.translateService.instant('infodialog.dialogs.checkBefore.close.message'),
        };
        Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
        Global.infoDialog.showInfoDialog();
        const confirm = Global.infoDialog.confirm.subscribe((e: any) => {
          this.beforeSave(document.createEvent('Event')).then(() => {
            if (
              this.conflictsResult &&
              this.conflictsResult.hasConflicts === false &&
              this.conflictsResult.mustRefresh === false
            ) {
              this.notifierLinkedComponent.next({
                type: IupicsTypeEvent.triggerAfterChange,
                item: event.item,
              });
            }
          });
          confirm.unsubscribe();
          cancel.unsubscribe();
        });
        const cancel = Global.infoDialog.cancel.subscribe((e) => {
          if (this.editTabs[0].dataStored.status !== DataStoreStatus.NEWRECORD) {
            this.subscriptions.push(this.store.syncWithRemoteWindowData(this.currentDataStoreKey).subscribe());
          } else {
            this.store.deleteDataFromStoreOnly(this.currentDataStoreKey);
          }
          this.notifierLinkedComponent.next({
            type: IupicsTypeEvent.triggerAfterChange,
            item: event.item,
          });
          confirm.unsubscribe();
          cancel.unsubscribe();
        });
      }
    } else {
      this.notifierLinkedComponent.next({
        type: IupicsTypeEvent.triggerAfterChange,
        item: event.item,
      });
    }
  }

  collapseElement(event: IupicsEvent) {
    // this.menuBarDetailComponent.setVisibleButton(false);
    for (const tab of this.editTabs) {
      tab.collapseTab();
      if (event.item && tab.tabId !== event.item.tabId) {
        tab.isCollapsed = true;
      }
    }
    /*dont close if additional is just opened for the first time */
    if (
      this.closeButtonElement &&
      (!event.item || !(event.item.DOMParentComponent instanceof AdditionalInfoUiComponent))
    ) {
      this.closeButtonElement.nativeElement.click();
    }
    this.smartButtons.resizeSmartButton(IupicsTypeEvent.collapseEvent);
  }

  expandElement(event: IupicsEvent) {
    for (const tab of this.editTabs) tab.expandTab();
    this.smartButtons.resizeSmartButton(IupicsTypeEvent.expandEvent);
  }

  updateNbFileAttached(dataStored?: DataStore) {
    let dataStoredUsed = dataStored;
    if (!dataStoredUsed) {
      dataStoredUsed = <DataStore>this.store.getStore(this.editTabs[0].dataStoreKey, DataStoreName.CURRENT);
    }
    if (dataStoredUsed && dataStoredUsed.status !== DataStoreStatus.NEWRECORD) {
      const samples = this.docServerService.initBaseSearch(this.currentDataStoreKey, this.data, this.table_id);
      const sub = this.docServerService.getNbDocuments(samples, MongoSearchQueryOperator.OR).subscribe((nb) => {
        this.nbUploadedFiles = nb;
        sub.unsubscribe();
      });
    } else {
      this.nbUploadedFiles = 0;
    }
  }

  refreshSmartButtons(dataStored?: DataStore) {
    let dataStoredUsed = dataStored;
    if (!dataStoredUsed) {
      dataStoredUsed = <DataStore>this.store.getStore(this.currentDataStoreKey, DataStoreName.CURRENT);
    }
    if (dataStoredUsed && dataStoredUsed.status !== DataStoreStatus.NEWRECORD) {
      this.smartButtons.refreshSmartButtons(this.tabId, dataStoredUsed.key.recordId);
    } else {
      this.smartButtons.refreshSmartButtons(this.tabId);
    }
  }

  getFirstFileAttached() {
    if (!(this.data?.fileOpening && this.data?.fileOpeningFilters) || Global.isMobile()) {
      return;
    }
    let fileOpeningFiltersParsed = JSON.parse(this.data.fileOpeningFilters);
    if (!Array.isArray(fileOpeningFiltersParsed)) {
      fileOpeningFiltersParsed = [fileOpeningFiltersParsed];
    }
    const samples = this.docServerService.initBaseSearch(this.currentDataStoreKey, this.data, this.table_id);
    const searchQuery = this.docServerService.createQuery(samples, MongoSearchQueryOperator.OR);
    for (const filter of fileOpeningFiltersParsed) {
      searchQuery.queryPart.addExpression(
        MongoSearchQueryPart.createQueryPart(filter.Key, filter.Value, MongoSearchQueryOperator.EQUALS)
      );
    }
    searchQuery.queryPart.addExpression(
      MongoSearchQueryPart.createQueryPart(
        'META|FILENAME',
        '.*.(pdf|xml|png|jpeg|jpg|bmp|gif|webp|svg|ico|xlsx|sh|txt|csv)',
        MongoSearchQueryOperator.REGEX
      )
    );

    let recordId: any = this.currentDataStoreKey.recordId.split(',');
    recordId = recordId.length > 1 ? (isNaN(parseInt(recordId[1], 10)) ? recordId[1] : parseInt(recordId[1], 10)) : -1;
    const sub = this.docServerService
      .advancedSearchDocuments(
        searchQuery,
        { limit: 1, start: 0, attachmentInteraction: true },
        this.tabId,
        this.table_id,
        recordId
      )
      .subscribe((response: DocserverWsResponse) => {
        if (response?.entries[0]) {
          const hit: DocserverEntry = response?.entries[0];
          if (hit && this.docServerService.hasPreviewByName(hit.fileName)) {
            this.showPreview({
              fileName: hit.fileName,
              urlFile: hit.url,
            });
          }
          sub.unsubscribe();
        } else {
          this.closeSideBarAndBlade();
        }
      });
  }

  // clic on grid: OLD and WS
  retrieveData(dataStoreKey: DataStoreKey) {
    if (this.currentDataStoreKey !== undefined && environment.constant.mergeLevel !== 0) {
      this.socketService.closeDataChannel(this.currentDataStoreKey.windowId + '-' + this.currentDataStoreKey.recordId);
    }
    this.currentDataStoreKey = cloneDeep(dataStoreKey);
    const request: DataStoreRequest = {
      windowId: this.currentDataStoreKey.windowId,
      record_id: this.currentDataStoreKey.recordId,
      parent_constraint: this.currentDataStoreKey.parentId,
      compiereRequest: {
        windowType: CompiereDataGridType.WINDOW,
        entityId: this.currentDataStoreKey.tabId,
        startRow: 0,
        endRow: 1,
        validation: this.getTabWhereclause(),
        windowCtx: this.getCurrentContext(),
      },
    };

    if (this.store.isWindowNewData(this.currentDataStoreKey)) {
      this.subscriptions.push(
        this.store.newRecord(this.currentDataStoreKey, this.getCurrentContext()).subscribe((dataStored) => {
          this.updateEditTabsData(dataStored);
        })
      );
    } else {
      new Promise<void>((resolve) => {
        this.subscriptions.push(
          this.store.getWindowSingleData(request).subscribe((dataStored: DataStore) => {
            this.updateEditTabsData(dataStored);

            this.refreshSmartButtons(dataStored);

            resolve();
          })
        );
      })
        .then(() => {
          this.store.setStateVisibleOnWindowData(this.currentDataStoreKey);
          if (this.editTabs && this.editTabs.length > 0 && this.editTabs[0].data && !this.editTabs[0].data.isView) {
            this.subscriptions.push(
              this.store
                .syncWithRemoteWindowData(
                  this.currentDataStoreKey,
                  true,
                  this,
                  this.connectorService.getIupicsUserContext()
                )
                .subscribe()
            );
          }
        })
        .then(() => {
          if (environment.constant.mergeLevel !== 0) {
            (async () => {
              for await (const response of this.socketService.openDataChannel<any>(
                this.currentDataStoreKey.windowId + '-' + this.currentDataStoreKey.recordId
              )) {
                if (response.from !== this.socketService.getSocketId()) {
                  this.subscriptions.push(
                    this.store
                      .syncWithRemoteWindowData(
                        this.currentDataStoreKey,
                        true,
                        this,
                        this.connectorService.getIupicsUserContext()
                      )
                      .subscribe()
                  );
                }
              }
            })();
          }
        });
    }
  }

  updateEditTabsData(dataStored: DataStore, tabIdToExclude?: number) {
    this.updateNbFileAttached(dataStored);
    this.refreshSmartButtons(dataStored);

    // const tabsToUpdate = this.editTabs.filter(
    //   (tab, index) => index === 0 || !tabIdToExclude || tabIdToExclude !== tab.tabId
    // );
    for (let i = 0; i < this.editTabs.length; i++) {
      const tab = this.editTabs[i];

      if (tabIdToExclude && tabIdToExclude === tab.tabId) {
        continue;
      }

      if (i > 0) {
        if (tab.DOMChildrenComponent[0]) {
          if (!tab.data.isSingleRow) {
            EditViewUtils.updateEditViewData(tab, dataStored, this.container, this.tabId);
          } else {
            // Si on est en SingleRow (vue Edit) il faut récupérer les données
            if (tab.updateDisplayLogic(dataStored)) {
              if (dataStored.key.tabId !== tab.tabId) {
                let mapfilter = LogicEvaluator.parseLogic(
                  dataStored.data,
                  tab.gridTabFilter[0],
                  this.connectorService.getIupicsUserContext()
                );
                if (mapfilter.replace(/=/g, ',') === dataStored.key.recordId.toString()) {
                  mapfilter = undefined;
                }
                const request: DataStoreRequest = {
                  windowId: dataStored.key.windowId,
                  record_id: dataStored.key.recordId.toString(),
                  parent_constraint: mapfilter,
                  compiereRequest: {
                    windowType: CompiereDataGridType.WINDOW,
                    entityId: tab.tabId,
                    startRow: 0,
                    endRow: 1,
                    validation: tab.getTabWhereClause(),
                    windowCtx: tab.getCurrentContext(),
                  },
                };
                this.subscriptions.push(
                  this.store.getWindowSingleData(request, true).subscribe((data) => {
                    tab.updateData(data);
                  })
                );
              }
            }
          }
          // retrieve Record_ID for the component select/order
          if (tab.DOMChildrenComponent[0].data) {
            tab.DOMChildrenComponent[0].data.recordID = dataStored.key.recordId;
          }
        } else if (tab.children && tab.children.length > 0) {
          EditViewUtils.checkDisplayTabCollapse(
            tab,
            tab.getCurrentContext(dataStored),
            tab.data ? tab.data.displayLogic : tab.children[0].data.displayLogic
          );
          tab.updateData(dataStored);
        }
      } else {
        tab.updateData(dataStored);
        this.changingStepper.next(dataStored);
      }
    }
    EditViewUtils.updateOtherEditView(this.container, this.tabId, this.editTabs, dataStored);
  }
  /**
   * change la visibilité de l'onglet et de ses champs en fonction du nouveau datastore
   * @param dataStored
   */
  updateEditTabsVisibility(dataStored: DataStore) {
    for (const tab of this.editTabs) {
      if (tab.DOMChildrenComponent[0]) {
        tab.updateDisplayLogic(dataStored);
      }
    }
  }

  onRemoveComponent(event: IupicsEvent) {
    if (event.type === IupicsTypeEvent.removeChildEvent) {
      event.type = IupicsTypeEvent.removeBreadcrumbItem;
      this.DOMParentComponent.onChildUpdate(event);
      // notify parent about the closure of this edit view
      for (const linkedComponent of this.linkedComponents) {
        if (linkedComponent instanceof AbstractDynamicComponent) {
          linkedComponent.deleteLinkedEditView();
        }
      }
      this.notifierLinkedComponent.next({
        type: IupicsTypeEvent.expandEvent,
        item: undefined,
      });
    }
  }

  transmitDataChange(dataStoreKey: DataStoreKey, tabId: number): boolean {
    let find = false;
    let myIndex = -1;
    // on cherche l'index de son parent
    myIndex = this.container.DOMChildrenComponent.findIndex((component) => {
      if ((<EditViewUiComponent>component.DOMComponent.instance).currentDataStoreKey) {
        const recordIdSplit = (<EditViewUiComponent>component.DOMComponent.instance).currentDataStoreKey.recordId;
        if (recordIdSplit === dataStoreKey.parentId) {
          return component;
        }
      }
    });
    let i = 1;
    while (!find && this.container.DOMChildrenComponent.length > i) {
      if (
        this.container.DOMChildrenComponent[i].DOMComponent.instance instanceof EditViewUiComponent &&
        this.container.DOMChildrenComponent[i].tabId === tabId
      ) {
        (<EditViewUiComponent>this.container.DOMChildrenComponent[i].DOMComponent.instance).retrieveData(dataStoreKey);
        find = true;
      } else if (this.container.DOMChildrenComponent[i].tabId === tabId) {
        myIndex = i;
      }
      i++;
    }
    if (!find && myIndex + 1 < this.container.DOMChildrenComponent.length) {
      (<EditViewUiComponent>this.container.DOMChildrenComponent[myIndex + 1].DOMComponent.instance).removeComponent();
    }
    return find;
  }

  // on save: WS
  /**
   * On prépare la vérification des données avant la sauvegarde
   * @param event
   */
  beforeSave(event: Event): Promise<any> {
    event?.stopPropagation();
    return new Promise((resolve, reject) => {
      if (
        this.callInProgressService.isSubscriptionDone(this.uuid, 'editview.beforesave') &&
        this.callInProgressService.isSubscriptionDone(this.uuid, 'editview.saveData')
      ) {
        if (this.editTabs.length > 0) {
          const fieldsMandatory = this.editTabs[0].isConstraintMandatoryRespected();
          const fieldsFormat = this.editTabs[0].isVFormatRespected();
          if (fieldsMandatory.length > 0) {
            this.messageManager.newMessage(
              new IupicsMessage(
                this.translateService.instant('generic.warning'),
                this.translateService.instant('editView.fillMandatoryField') + ' [ ' + fieldsMandatory + ' ]',
                'error'
              )
            );
          } else if (fieldsFormat.length > 0) {
            this.messageManager.newMessage(
              new IupicsMessage(
                this.translateService.instant('generic.warning'),
                this.translateService.instant('editView.invalidFormat') + ' [ ' + fieldsFormat + ' ]',
                'error'
              )
            );
          } else {
            if (this.currentDataStoreKey.recordId.split(',').length > 1) {
              this.callInProgressService.setSubscription(
                this.uuid,
                'editview.beforesave',
                this.store
                  .syncWithRemoteWindowData(
                    this.currentDataStoreKey,
                    true,
                    this,
                    this.connectorService.getIupicsUserContext(),
                    this.checkDataBeforeSave.bind(this),
                    resolve
                  )
                  .subscribe()
              );
            } else {
              this.checkDataBeforeSave(resolve);
            }
          }
        } else {
          if (this.currentDataStoreKey.recordId.split(',').length > 1) {
            this.callInProgressService.setSubscription(
              this.uuid,
              'editview.beforesave',
              this.store
                .syncWithRemoteWindowData(
                  this.currentDataStoreKey,
                  true,
                  this,
                  this.connectorService.getIupicsUserContext(),
                  this.checkDataBeforeSave.bind(this),
                  resolve
                )
                .subscribe()
            );
          } else {
            this.checkDataBeforeSave(resolve);
          }
        }
      }
    });
  }

  /**
   * On vérifie les données avant de les sauver
   */
  checkDataBeforeSave(resolve?: Function): void {
    if (!this.conflictsResult || (!this.conflictsResult.hasConflicts && !this.conflictsResult.mustRefresh)) {
      this.saveData(resolve);
    }
  }

  /**
   * On sauve les données en DB et on mets à jour le OLD
   */
  saveData(resolve?: Function): void {
    let status: DataStoreStatus;
    const request: DataStoreRequest = {
      windowId: this.currentDataStoreKey.windowId,
      record_id: this.currentDataStoreKey.recordId,
      parent_constraint: this.currentDataStoreKey.parentId,
      compiereRequest: {
        windowType: CompiereDataGridType.WINDOW,
        entityId: this.currentDataStoreKey.tabId,
        startRow: 0,
        endRow: 1,
        validation: this.getTabWhereclause(),
        windowCtx: this.getCurrentContext(),
      },
    };
    if (this.callInProgressService.isSubscriptionDone(this.uuid, 'editview.saveData')) {
      this.callInProgressService.setSubscription(
        this.uuid,
        'editview.saveData',
        this.store
          .getWindowSingleData(request)
          .pipe(
            switchMap((datastore: DataStore) => {
              status = datastore.status;
              this.isLoading = true;
              if (datastore.calloutStates.size > 0) {
                return datastore.calloutStackEmptied.pipe(
                  switchMap((response) => {
                    return this.store.saveWindowData([this.currentDataStoreKey], this.getCurrentContext());
                  })
                );
              } else {
                return this.store.saveWindowData([this.currentDataStoreKey], this.getCurrentContext());
              }
            })
          )
          .subscribe({
            next: (response: Map<DataStoreKey, DataStore>) => {
              this.isLoading = false;
              if (response) {
                const res = Array.from(response.values())[0];
                const prevKey = { ...this.currentDataStoreKey };
                if (res.key) {
                  this.currentDataStoreKey = res.key;
                }
                if (this.editTabs[0].data.tabLevel === 0) {
                  this.DOMParentComponent.notifyUrlChange(res.data.Data_UUID);
                }
                if (res && res.data) {
                  const gridElement: GridViewUiComponent = this.linkedComponents[0];
                  if (gridElement && this.currentDataStoreKey.recordId && prevKey.recordId.split(',').length === 1) {
                    gridElement.selectedRecordId = this.currentDataStoreKey.recordId;
                  }
                  if (this.isZoomEditView) {
                    this.notifierLinkedComponent.next({
                      refreshZoom: true,
                      id: res.data['Data_UUID'].split(',')[1],
                    });
                  } else {
                    if (status === DataStoreStatus.NEWRECORD) {
                      if (prevKey) {
                        this.store.deleteDataFromStoreOnly(prevKey);
                      }
                      this.notifierLinkedComponent.next({
                        type: IupicsTypeEvent.REFRESH_GRID,
                      });
                      if (this.data?.tabLevel === 0) {
                        const recentItem_request: DataStoreRequest = {
                          windowId: (<BladeUiComponent>this.container).infoComponent.windowId,
                          record_id: res.key.recordId,
                          parent_constraint: this.currentDataStoreKey.parentId,
                          compiereRequest: {
                            windowType: CompiereDataGridType.WINDOW,
                            entityId: this.tabId,
                            startRow: 0,
                            endRow: 1,
                            validation: this.getTabWhereclause(),
                            windowCtx: this.getCurrentContext(),
                          },
                        };
                        this.subscriptions.push(
                          this.riManager
                            .addRecentItem(
                              recentItem_request,
                              this.editTabs.find((editTab) => editTab.tabId === this.tabId).data.label
                            )
                            .subscribe()
                        );
                      }
                    } else {
                      this.notifierLinkedComponent.next({
                        type: IupicsTypeEvent.UPDATE_ROWSAVED,
                        item: { dataStoreKey: prevKey },
                      });
                    }
                  }
                  this.updateEditTabsData(res);
                  if (resolve) {
                    resolve();
                  }
                  if (environment.constant.mergeLevel !== 0) {
                    this.socketService.broadcast(
                      this.currentDataStoreKey.windowId + '-' + this.currentDataStoreKey.recordId,
                      {
                        from: this.socketService.getSocketId(),
                        windowId: this.currentDataStoreKey.windowId,
                        recordId: this.currentDataStoreKey.recordId,
                      }
                    );
                  }
                }
              }
              this.callInProgressService.completeSubscription(this.uuid, 'editview.saveData');
            },
            error: (err) => {
              this.callInProgressService.completeSubscription(this.uuid, 'editview.saveData');
            },
          })
      );
    }
  }

  deleteData(event: MouseEvent): void {
    if (!this.checkAndExecuteOverride('delete')) {
      Global.infoDialog.message = {
        summary: this.translateService.instant('infodialog.dialogs.delete.title'),
        detail: this.translateService.instant('infodialog.dialogs.delete.message'),
      };
      Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
      Global.infoDialog.showInfoDialog();
      const confirm = Global.infoDialog.confirm.subscribe((e: any) => {
        const dataUUIDs = this.store.getDataUUIDFromTabID(this.currentDataStoreKey.tabId);
        const recordIds = [];
        const recordId = {};
        for (const dataUUID of dataUUIDs) {
          const data = this.editTabs[0].dataStored.data[dataUUID];
          if (data instanceof Object) {
            recordId[dataUUID] = data.id;
          } else {
            recordId[dataUUID] = data;
          }
        }
        recordIds.push(recordId);
        this.isLoading = true;
        this.subscriptions.push(
          this.store
            .deleteWindowData(this.currentDataStoreKey, recordIds)
            .pipe(
              switchMap((res) =>
                res.success > 0 && this.data?.tabLevel === 0
                  ? this.riManager.deleteRecentItems(this.currentDataStoreKey, recordIds).pipe(map(() => res))
                  : of(res)
              )
            )
            .subscribe({
              next: (res) => {
                if (res) {
                  if (this.isZoomEditView) {
                    this.notifierLinkedComponent.next({
                      refreshZoom: true,
                      id: null,
                    });
                  }
                  this.notifierLinkedComponent.next({
                    type: IupicsTypeEvent.REFRESH_GRID,
                  });
                  this.removeComponent();
                  this.messageManager.newMessage(
                    new IupicsMessage(
                      this.translateService.instant('editView.deleteMessageTitle'),
                      res.success > 0
                        ? this.translateService.instant('editView.deleteMessage')
                        : res.errors.length > 0
                          ? res.errors[0].message
                          : this.translateService.instant('editView.deleteErrorMessage'),
                      res.success > 0 ? 'success' : 'error'
                    )
                  );
                } else {
                  throw new Error(this.translateService.instant('editView.deleteErrorMessage'));
                }
              },
              error: (error) => {
                this.isLoading = false;
                if (error && error.error) {
                  throw new Error(error.error.message);
                } else {
                  throw new Error(error.message);
                }
              },
            })
        );
        confirm.unsubscribe();
        cancel.unsubscribe();
      });
      const cancel = Global.infoDialog.cancel.subscribe((e) => {
        confirm.unsubscribe();
        cancel.unsubscribe();
      });
    }
    event.stopPropagation();
  }

  /**
   * On refresh OLD dans WS
   * @param event
   */
  refreshData(showMessage: boolean = true, event?: MouseEvent): void {
    if (event) {
      event.stopPropagation();
    }
    if (!this.checkAndExecuteOverride('refresh')) {
      if (this.editTabs[0].dataStored.status !== DataStoreStatus.NEWRECORD) {
        this.updateAll(showMessage);
      } else {
        this.openNew();
      }
    }
  }

  updateAll(showMessage: boolean, tabToExlude = null, callBack?: Function) {
    if (this.callInProgressService.isSubscriptionDone(this.uuid, 'editview.updateAll')) {
      this.callInProgressService.setSubscription(
        this.uuid,
        'editview.updateAll',
        this.store
          .syncSingleDataWithRemote(this.currentDataStoreKey, false, this, this.connectorService.getIupicsUserContext())
          .subscribe({
            next: (dataStore: DataStore) => {
              this.notifierLinkedComponent.next({
                type: IupicsTypeEvent.UPDATE_ROWSAVED,
                item: { dataStoreKey: this.currentDataStoreKey },
              });
              this.updateEditTabsData(dataStore, tabToExlude);
              this.conflictsResult.mustRefresh = false;
              this.conflictsResult.hasConflicts = false;
              this.conflictsResult.refreshAuto = false;
              if (showMessage) {
                this.messageManager.newMessage(
                  new IupicsMessage(
                    this.translateService.instant('editView.refreshMessageTitle'),
                    this.translateService.instant('editView.refreshMessage'),
                    'success'
                  )
                );
              }
              this.callInProgressService.completeSubscription(this.uuid, 'editview.updateAll');
              if (callBack) {
                callBack();
              }
            },
            error: ({ error, message }) => {
              this.messageManager.newMessage(
                new IupicsMessage(
                  this.translateService.instant('editView.refreshMessageTitle'),
                  message,
                  'error',
                  error
                )
              );
              this.callInProgressService.completeSubscription(this.uuid, 'editview.updateAll');
              if (callBack) {
                callBack();
              }
            },
          })
      );
    }
  }

  updateCurrSymbol() {
    const editViews: EditViewUiComponent[] = this.DOMParentComponent.DOMChildrenComponent.filter(
      (c: any) => c instanceof EditViewUiComponent
    );
    const dataContainers: AbstractDataContainer[] = [].concat(
      [],
      editViews.filter((editTab) => editTab.tabId !== this.tabId).map((e) => e.editTabs[0].dataContainers)
    );
    const moneyFields = dataContainers.filter((dc) => dc.isMoneyField);
    for (const moneyField of moneyFields) moneyField.initCurrency(moneyField.dataStored);
  }

  onMerge(mergedData: { dataMerged: {}; mergedInfo: {} }): void {
    this.wantToMergeConflicts = false;
    this.conflictsResult.hasConflicts = false;
    const request: DataStoreRequest = {
      windowId: this.currentDataStoreKey.windowId,
      record_id: this.currentDataStoreKey.recordId,
      parent_constraint: this.currentDataStoreKey.parentId,
      compiereRequest: {
        windowType: CompiereDataGridType.WINDOW,
        entityId: this.currentDataStoreKey.tabId,
        startRow: 0,
        endRow: 1,
        validation: this.getTabWhereclause(),
        windowCtx: this.getCurrentContext(),
      },
    };
    this.subscriptions.push(
      this.store.getWindowSingleData(request).subscribe((data) => {
        new Promise((resolve) => {
          this.store.syncDataChanges(data, mergedData.dataMerged, true);
          this.store.copyWindowDataToOldStore(data);
          const columnNames = Object.keys(mergedData.mergedInfo);
          for (const columnName of columnNames) {
            if (mergedData.mergedInfo[columnName].who === 'local') {
              this.store.copyRemoteWindowDataToOldStore(data.key, columnName);
            }
          }
          resolve(undefined);
        }).then(() => {
          this.beforeSave(document.createEvent('MouseEvent'));
        });
      })
    );
  }

  /**
   * Lance l'impression du report et gestion du retour
   * @param event
   */
  printReport(event: MouseEvent) {
    if (this.callInProgressService.isSubscriptionDone(this.uuid, 'editview.printReport')) {
      this.callInProgressService.setSubscription(
        this.uuid,
        'editview.printReport',
        this.printReportManager.printAndDownloadReport(
          this.currentDataStoreKey,
          this.connectorService.getIupicsUserAccount().session_id,
          this.store.getStore(this.editTabs[0].dataStoreKey, DataStoreName.CURRENT).data,
          () => {
            this.updateNbFileAttached();
          }
        )
      );
    }
    event.stopPropagation();
  }

  /**
   * On déplace la vue au point d'ancrage
   * @param editTab
   */
  goToAnchor(event: MouseEvent, editTab: EditTabUiComponent) {
    event.stopPropagation();
    const shouldWaitResizeBeforeScroll =
      !editTab.childrenCreated && editTab.children[0] && editTab.children[0].component === 'GridViewUiComponent';
    if (editTab.collapsed) {
      editTab.collapsed = false;
    }
    if (shouldWaitResizeBeforeScroll) {
      this.isWaitingScroll = true;
    } else {
      const elem = editTab.elementRef.nativeElement;
      const topPos = elem.offsetTop;
      this.scrollPanel.scrollTop(topPos);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.sideComponentRef) this.sideComponentRef.destroy();
    if (environment.constant.mergeLevel !== 0) {
      if (this.currentDataStoreKey) {
        this.socketService.closeDataChannel(
          this.currentDataStoreKey.windowId + '-' + this.currentDataStoreKey.recordId
        );
      }
    }
  }

  changeGridViewVisibility($event) {
    this.GridViewVisibility = 'visible';
    this.gridViewUi.GridTabInfinityScrollUiComponent.onlyOneColumn();
    if (this.gridViewUi.GridTabInfinityScrollUiComponent.grid.api.getSelectedNodes().length === 0) {
      // si Aucune ligne n'est sélectionné, on sélectionne la première par défault()
      this.gridViewUi.GridTabInfinityScrollUiComponent.grid.api.getRowAtIndex(0).setSelected(true);
    }
    // cache la checkbox
    this.gridViewUi.GridTabInfinityScrollUiComponent.grid.columnApi.setColumnVisible('Data_UUID', false);
  }

  onSmartButtonClick(button) {
    this.subscriptions.push(
      this.uiCreatorService.getWindow(button.val.key).subscribe((tabUI) => {
        const item: DynamicComponent = {
          container: this.container,
          DOMParentComponent: this.container,
          linkedComponents: [this],
          component: 'EditViewUiComponent',
          cssClass: 'iupics-blade-content',
          isCssOnComponent: false,
          tabId: tabUI.tabId,
          windowId: button.val.key,
          zoomTarget: { buttonId: button.val.key, record_id: this.currentDataStoreKey.recordId },
          zoomTargetData: [tabUI.firstTab],
        };
        this.componentEmitter.emit({
          type: IupicsTypeEvent.showEditView,
          item: item,
        });
      })
    );
  }

  gridViewCellClicked($event) {
    const request: DataStoreRequest = {
      windowId: (<BladeUiComponent>this.container).infoComponent.windowId,
      record_id: $event.recordId,
      parent_constraint: this.zoomTarget.record_id.replace(/,/g, '='),
      compiereRequest: {
        windowType: CompiereDataGridType.WINDOW,
        entityId: this.tabId,
        startRow: 0,
        endRow: 1,
        validation: this.getTabWhereclause(),
        windowCtx: this.getCurrentContext(),
      },
    };
    this.subscriptions.push(
      this.store.getWindowSingleData(request).subscribe((dataStore) => {
        this.currentDataStoreKey = this.store.generateDataStoreKey(
          (<BladeUiComponent>this.container).infoComponent.windowId,
          this.tabId,
          dataStore.data['Data_UUID'],
          this.zoomTarget.record_id.replace(/,/g, '=')
        );
        this.updateEditTabsData(dataStore);
        this.GridViewVisibility = 'hidden';
      })
    );
  }

  openExportDataModal(event: Event) {
    event.stopPropagation();

    if (!this.checkAndExecuteOverride('export')) {
      this.displayExportDataModal = true;
    }
  }

  viewRecordChangeLog(e: MouseEvent) {
    const formID = this.config.getConstant('ChangeLogWindowUIComponent#FormID');
    this.showAuditsPanel = true;

    setTimeout(() => {
      this.createSpecificWindow(formID, undefined, this.vcrAudit, () => {
        this.showAuditsPanel = false;
      });
    }, 5);
  }

  openNew(event?: Event) {
    event?.stopPropagation();

    if (this.checkAndExecuteOverride('new')) {
      return;
    }

    let i = 1;
    let find = false;
    while (i < this.container.DOMChildrenComponent.length && !find) {
      if (this.container.DOMChildrenComponent[i] === this && ++i < this.container.DOMChildrenComponent.length) {
        (<EditViewUiComponent>this.container.DOMChildrenComponent[i]).removeComponent();
        find = true;
      } else {
        i++;
      }
    }
    const gridElement = <GridViewUiComponent>this.linkedComponents[0];
    if (gridElement?.checkEditViewCreated?.()) {
      const parentComp = gridElement.getFirstEditViewParent(gridElement.DOMParentComponent);
      if (parentComp) {
        parentComp.gridTabIdSelected = this.tabId;
      }
    }
    if (this.checkData()) {
      // check si c'est l'edit view de premier niveau
      if (this.currentDataStoreKey.parentId === '') {
        this.DOMParentComponent.notifyUrlChange('newRecord');
      }
      const key = this.store.newWindowData(
        this.container.infoComponent.windowId,
        this.tabId,
        null,
        EditViewUtils.getParentDatastoreKey(this)
      ).key;
      this.retrieveData(key);
    } else {
      const ctx = this.connectorService.getIupicsUserContext();
      if (ctx['AutoCommit'] === 'Y') {
        this.beforeSave(document.createEvent('Event')).then(() => {
          if (
            this.conflictsResult &&
            this.conflictsResult.hasConflicts === false &&
            this.conflictsResult.mustRefresh === false
          ) {
            const key = this.store.newWindowData(
              this.container.infoComponent.windowId,
              this.tabId,
              null,
              EditViewUtils.getParentDatastoreKey(this)
            ).key;
            this.retrieveData(key);
          }
        });
      } else {
        Global.infoDialog.message = {
          summary: this.translateService.instant('infodialog.dialogs.checkBefore.change.title'),
          detail: this.translateService.instant('infodialog.dialogs.checkBefore.change.message'),
        };
        Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
        Global.infoDialog.showInfoDialog();
        const confirm = Global.infoDialog.confirm.subscribe((e: any) => {
          this.beforeSave(document.createEvent('Event')).then(() => {
            if (
              this.conflictsResult &&
              this.conflictsResult.hasConflicts === false &&
              this.conflictsResult.mustRefresh === false
            ) {
              const key = this.store.newWindowData(
                this.container.infoComponent.windowId,
                this.tabId,
                null,
                EditViewUtils.getParentDatastoreKey(this)
              ).key;
              this.retrieveData(key);
            }
          });
          confirm.unsubscribe();
          cancel.unsubscribe();
        });
        const cancel = Global.infoDialog.cancel.subscribe((e) => {
          if (this.editTabs[0].dataStored.status !== DataStoreStatus.NEWRECORD) {
            this.subscriptions.push(this.store.syncWithRemoteWindowData(this.currentDataStoreKey).subscribe());
          } else {
            this.store.deleteDataFromStoreOnly(this.currentDataStoreKey);
          }
          const key = this.store.newWindowData(
            this.container.infoComponent.windowId,
            this.tabId,
            null,
            EditViewUtils.getParentDatastoreKey(this)
          ).key;
          this.retrieveData(key);
          confirm.unsubscribe();
          cancel.unsubscribe();
        });
      }
    }
  }

  /*
   * emailEditor: { display: { key: 'displayEmailEditor', value: displayEmailEditor }}
   * joinFilesPanel: { display: { key: 'displayJoinFilesPanel', value: displayJoinFilesPanel }}
   * processModal: { display: { key: 'displayProcessUI', value: displayProcessUI }, id: { key: 'processId', value: processId } }
   * formModal: { display: { key: 'displayFormUI', value: displayFormUI }, id: { key: 'formId', value: formId } }
   */
  updateModalDisplay(
    display: { key: string; value: boolean; sourceComponent?: any },
    id?: { key: string; value: number }
  ) {
    if (display?.sourceComponent?.columnName) {
      this.modalClass = 'p-col-10 p-md-6 p-lg-4';
    } else {
      this.modalClass = 'p-col-10 p-md-10 p-lg-10';
    }
    this.customFormModalBodyCss =
      display.key && display.key === 'displayFormUI'
        ? { 'iu-modal-body': { overflow: 'auto', height: 'calc(100% - 25px)' } }
        : undefined;
    const isButton = display?.sourceComponent?.itemData?.component === 'ButtonUiComponent';
    if (isButton && !this.store.checkDataBeforeNewLine(this.currentDataStoreKey)) {
      this.beforeSave(null).then((result) => {
        this.displayModal(display, id);
      });
    } else {
      this.displayModal(display, id);
    }
    if (!display.value && this.isProcessLaunch) {
      this.refreshEditViews(true); // 132675
      this.isProcessLaunch = false;
    }
  }

  displayModal(display: { key: string; value: boolean; sourceComponent?: any }, id?: { key: string; value: number }) {
    this[display.key] = false;
    this[`${display.key}_sourceComponentData`] = display.sourceComponent;
    // Récupération du table_id pour les fichiers joints
    if (this.editTabs.length > 0) {
      this.table_id = this.editTabs[0].data.AD_Table_ID;
    }
    if (id && display.value === true) {
      this[id.key] = id.value;
      if (id.key !== 'processId') {
        this.createSpecificWindow(id.value, display.sourceComponent, this.vcrSpecific, null, () => {
          this[display.key] = display.value;
        });
      } else {
        this[display.key] = display.value;
      }
    } else {
      this[display.key] = display.value;
      this.customFormModalBodyCss = undefined;
    }
  }

  /**
   * Copie les data de l'enregistrement courant, les vérifie et les enregistre dans le datastore
   * @param {Event}event
   */
  copyData(event: Event) {
    if (!this.checkAndExecuteOverride('copy')) {
      let i = 1;
      let find = false;
      while (i < this.container.DOMChildrenComponent.length && !find) {
        if (this.container.DOMChildrenComponent[i] === this && ++i < this.container.DOMChildrenComponent.length) {
          (<EditViewUiComponent>this.container.DOMChildrenComponent[i]).removeComponent();
          find = true;
        } else {
          i++;
        }
      }

      event.stopPropagation();
      let dataStore = this.store.newWindowData(
        this.container.infoComponent.windowId,
        this.tabId,
        null,
        EditViewUtils.getParentDatastoreKey(this)
      );

      const request: DataStoreRequest = {
        windowId: this.currentDataStoreKey.windowId,
        record_id: this.currentDataStoreKey.recordId,
        parent_constraint: this.currentDataStoreKey.parentId,
        compiereRequest: {
          windowType: CompiereDataGridType.WINDOW,
          entityId: this.currentDataStoreKey.tabId,
          startRow: 0,
          endRow: 1,
          validation: this.getTabWhereclause(),
          windowCtx: this.getCurrentContext(),
        },
      };

      this.subscriptions.push(
        this.store.getWindowSingleData(request).subscribe((dataStoreSource: DataStore) => {
          /*pour récupérer les champs invisible dans le store */
          dataStore['data'] = Object.assign(dataStore['data'], dataStoreSource.data);
          const isOneKeyCol = dataStore.data.Data_UUID.split(',').length == 2;
          dataStore.data.Data_UUID = dataStore.data.Data_UUID.split(',')[0] + ',' + dataStore.key.recordId;
          dataStore = this.editTabs[0].checkForCopy(dataStore);
          if (isOneKeyCol) {
            dataStore.data[dataStore.data.Data_UUID.split(',')[0]] = null;
          }
          dataStore.isCopied = true;
          this.updateEditTabsData(dataStore);
          this.currentDataStoreKey = dataStore.key;
          this.DOMParentComponent.notifyUrlChange(dataStore.data.Data_UUID);
        })
      );
    }
  }

  createSpecificWindow(
    formId: number,
    sourceComponent?: any,
    vcr = this.vcrSpecific,
    closeModal?: Function,
    afterLoaded?: Function
  ) {
    this.subscriptions.push(
      this.uiCreatorService.getSpecificWindow(formId).subscribe((specificWindow) => {
        if (afterLoaded) {
          afterLoaded();
        }

        vcr.clear();
        const componentRef = createComponent<SpecificWindowUiComponent>(
          vcr,
          specificWindow?.angularClass?.length > 0 && specificWindow.angularClass !== 'default'
            ? specificWindow.angularClass
            : 'default'
        );
        this.specificWindowTitle = specificWindow.name;
        componentRef.instance.name = specificWindow.name;
        componentRef.instance.title = specificWindow.title;
        componentRef.instance.description = specificWindow.description;
        componentRef.instance.help = specificWindow.help;
        componentRef.instance.componentRef = componentRef;
        componentRef.instance.isModal = true;
        componentRef.instance.formId = formId;
        componentRef.instance.vcrwindow = vcr;
        componentRef.instance.parentComponent = this;
        componentRef.instance.index = vcr.length - 1;
        componentRef.instance.sourceModal = this.specificModal;
        componentRef.instance.sourceComponentData = sourceComponent?.itemData ?? sourceComponent;
        componentRef.instance.sourceComponent =
          sourceComponent; /**dupliqué si pas d'itemdata mais trop de refactoring sur scout */
        componentRef.instance.closeModalEmitter.subscribe(() => {
          if (closeModal) {
            closeModal();
          } else {
            this.updateModalDisplay({ key: 'displayFormUI', value: false, sourceComponent: this });
          }
        });
        this.componentRefs.push(componentRef);
      })
    );
  }
  /**
   * permet de passer à la ligne suivante ou précedente
   * @param step avancement ou recul à faire
   */
  changeGridElement(step: number) {
    if (this.linkedComponents[0] instanceof GridViewUiComponent) {
      const gridElement = this.linkedComponents[0];
      this.simulateClickOnCellUsingArrow(gridElement, step);
    }
  }
  /**
   * simule un clic sur la ligne précedant ou suivant la ligne courante
   * @param gridElement grid sur laquelle on simule un clic
   * @param step avancement ou recul à faire
   */
  simulateClickOnCellUsingArrow(gridElement: GridViewUiComponent, step: number) {
    const api = gridElement.GridTabInfinityScrollUiComponent.grid.api;
    const currentNodeSelected = api.getSelectedNodes()[0];
    if (currentNodeSelected) {
      const nbRowPerPage = api.getPageSize();
      const pageId = Math.trunc((+currentNodeSelected.rowIndex + step) / nbRowPerPage);
      // set des données de la ligne courante
      gridElement.GridTabInfinityScrollUiComponent.currentSelectIndex = +currentNodeSelected.rowIndex + step;
      gridElement.GridTabInfinityScrollUiComponent.currentSelectPageIndex = pageId;
      const nodeToSelect = api.getRowAtIndex(gridElement.GridTabInfinityScrollUiComponent.currentSelectIndex);
      if (nodeToSelect) {
        api.deselectAll();
        api.goToPage(pageId);
        const cell: Partial<IupicsCellEvent> = {
          colDef: { field: 'name' },
          data: nodeToSelect.getRowData(),
          row: nodeToSelect,
          origin: 'edit',
        };

        gridElement.GridTabInfinityScrollUiComponent.onCellClicked({ type: ApizEventType.cellClicked, data: cell });
      } else {
        // simulation du clic après que le loading soit fini
        setTimeout(() => {
          const loadedNode = api.getRowAtIndex(+currentNodeSelected.rowIndex + step);
          if (loadedNode) {
            api.deselectAll();
            api.goToPage(pageId);
            const cell: Partial<IupicsCellEvent> = {
              colDef: { field: 'name' },
              data: loadedNode.getRowData(),
              row: loadedNode,
              origin: 'edit',
            };
            gridElement.GridTabInfinityScrollUiComponent.onCellClicked({ type: ApizEventType.cellClicked, data: cell });
          }
        }, 500);
      }
    }
  }

  generateZoom() {
    this.isZoomEditView = true;
    let newData;
    let sub = null;
    if (this.zoomInfo.record_id === -1) {
      newData = this.store.newWindowData(this.container.infoComponent.windowId, this.tabId);
      this.currentDataStoreKey = newData.key;
      sub = this.store.newRecord(this.currentDataStoreKey, this.getCurrentContext());
    } else {
      this.zoomInfo.dataUUID =
        this.zoomInfo.dataUUID && this.zoomInfo.dataUUID.split('.').length > 1
          ? this.zoomInfo.dataUUID.split('.')[1]
          : this.zoomInfo.dataUUID;
      this.currentDataStoreKey = this.store.generateDataStoreKey(
        this.zoomInfo.windowId,
        this.tabId,
        this.zoomInfo.dataUUID,
        this.zoomInfo.parentId ? this.zoomInfo.parentId : null
      );
      const request: DataStoreRequest = {
        windowId: this.zoomInfo.windowId,
        record_id: this.zoomInfo.dataUUID,
        parent_constraint: this.zoomInfo.parentId ? this.zoomInfo.parentId : '',
        compiereRequest: {
          windowType: CompiereDataGridType.WINDOW,
          entityId: this.tabId,
          startRow: 0,
          endRow: 1,
          validation: this.getTabWhereclause(),
          windowCtx: this.getCurrentContext(),
        },
      };
      sub = this.store.getWindowSingleData(request, false, true);
    }

    this.subscriptions.push(
      sub.subscribe((data: DataStore) => {
        this.buildChildren();
        if (newData) {
          this.updateEditTabsData(data);
        } else {
          this.refreshSmartButtons(data);
          this.updateNbFileAttached(data);
        }
      })
    );
  }

  refreshZoom(zoomInfo) {
    this.zoomInfo = zoomInfo;
    this.currentDataStoreKey = this.store.generateDataStoreKey(
      (<BladeUiComponent>this.container).infoComponent.windowId,
      this.tabId,
      this.zoomInfo.dataUUID
    );
    this.retrieveData(this.currentDataStoreKey);
  }

  getCurrentContext(withCtx = true): any {
    let currentParent;
    if (this.linkedComponents?.[0]?.editViewParent?.editTabs?.[0]?.dataStored?.data) {
      currentParent = this.linkedComponents[0].editViewParent;
    }

    return EditViewUtils.getCurrentContext(
      currentParent, // parent
      this.editTabs?.[0]?.dataStored, // store
      withCtx ? this.connectorService.getIupicsUserContext() : {}
    );
  }

  showPriceHistory(gridTabInfinityScrollUiComponent: GridTabInfinityScrollUiComponent) {
    if (!this.priceHistoryFormId) {
      const request_form: DataStoreRequest = {
        windowId: null,
        parent_constraint: '',
        compiereRequest: {
          startRow: 0,
          endRow: 0,
          tableName: 'AD_Form',
          filterModel: {
            NAME: {
              filterType: CompiereDataGridFilterType.SET,
              values: ['Price history'],
              operators: [OperatorFilterType.EQUALS],
            },
          },
          windowCtx: this.getCurrentContext(),
        },
      };
      const $sub = this.store.getDataGrid(request_form, true).subscribe((formWS) => {
        this.priceHistoryFormId = formWS.data[0]['AD_FORM_ID'];
        this.showPriceHistoryForm(gridTabInfinityScrollUiComponent);
        $sub.unsubscribe();
      });
    } else {
      this.showPriceHistoryForm(gridTabInfinityScrollUiComponent);
    }
  }

  showPriceHistoryForm(gridTabInfinityScrollUiComponent: GridTabInfinityScrollUiComponent) {
    if (gridTabInfinityScrollUiComponent?.grid?.api?.getSelectedRows()?.length > 0) {
      this.updateModalDisplay(
        { key: 'displayFormUI', value: true, sourceComponent: gridTabInfinityScrollUiComponent },
        { key: 'formId', value: this.priceHistoryFormId }
      );
    } else {
      this.messageManager.newMessage(
        new IupicsMessage(
          this.translateService.instant('infodialog.dialogs.noLineSelected.title'),
          this.translateService.instant('infodialog.dialogs.noLineSelected.message'),
          'warning'
        )
      );
    }
  }

  /**
   * permet d'overrider l'action si il existe un process ou une form. La méthode retourne true si c'est le cas.
   * @param source indique quelle action est à overrider
   */
  checkAndExecuteOverride(source: string) {
    let formIdToOpen;
    let processIdToOpen;
    const ad_window_id = this.currentDataStoreKey ? '' + this.currentDataStoreKey.windowId : '';

    switch (source) {
      case 'new':
        formIdToOpen = this.config.getConstant(EditViewGetFormIDByTabID.OPEN_NEW + this.tabId);
        processIdToOpen = this.config.getConstant(EditViewGetProcessIDByTabID.OPEN_NEW + this.tabId);
        if (formIdToOpen === undefined && processIdToOpen === undefined) {
          formIdToOpen = this.config.getConstant(EditViewGetFormIDByWindowID.OPEN_NEW + ad_window_id);
          processIdToOpen = this.config.getConstant(EditViewGetProcessIDByWindowID.OPEN_NEW + ad_window_id);
        }
        break;

      case 'copy':
        formIdToOpen = this.config.getConstant(EditViewGetFormIDByTabID.COPY_DATA + this.tabId);
        processIdToOpen = this.config.getConstant(EditViewGetProcessIDByTabID.COPY_DATA + this.tabId);
        if (formIdToOpen === undefined && processIdToOpen === undefined) {
          formIdToOpen = this.config.getConstant(EditViewGetFormIDByWindowID.COPY_DATA + ad_window_id);
          processIdToOpen = this.config.getConstant(EditViewGetProcessIDByWindowID.COPY_DATA + ad_window_id);
        }
        break;

      case 'export':
        formIdToOpen = this.config.getConstant(EditViewGetFormIDByTabID.EXPORT_DATA + this.tabId);
        processIdToOpen = this.config.getConstant(EditViewGetProcessIDByTabID.EXPORT_DATA + this.tabId);
        if (formIdToOpen === undefined && processIdToOpen === undefined) {
          formIdToOpen = this.config.getConstant(EditViewGetFormIDByWindowID.EXPORT_DATA + ad_window_id);
          processIdToOpen = this.config.getConstant(EditViewGetProcessIDByWindowID.EXPORT_DATA + ad_window_id);
        }
        break;

      case 'refresh':
        formIdToOpen = this.config.getConstant(EditViewGetFormIDByTabID.REFRESH_DATA + this.tabId);
        processIdToOpen = this.config.getConstant(EditViewGetProcessIDByTabID.REFRESH_DATA + this.tabId);
        if (formIdToOpen === undefined && processIdToOpen === undefined) {
          formIdToOpen = this.config.getConstant(EditViewGetFormIDByWindowID.REFRESH_DATA + ad_window_id);
          processIdToOpen = this.config.getConstant(EditViewGetProcessIDByWindowID.REFRESH_DATA + ad_window_id);
        }
        break;

      case 'delete':
        formIdToOpen = this.config.getConstant(EditViewGetFormIDByTabID.DELETE_DATA + this.tabId);
        processIdToOpen = this.config.getConstant(EditViewGetProcessIDByTabID.DELETE_DATA + this.tabId);
        if (formIdToOpen === undefined && processIdToOpen === undefined) {
          formIdToOpen = this.config.getConstant(EditViewGetFormIDByWindowID.DELETE_DATA + ad_window_id);
          processIdToOpen = this.config.getConstant(EditViewGetProcessIDByWindowID.DELETE_DATA + ad_window_id);
        }
        break;

      default:
        break;
    }

    if (formIdToOpen !== undefined) {
      this.updateModalDisplay(
        { key: 'displayFormUI', value: true, sourceComponent: { columnName: source } },
        { key: 'formId', value: formIdToOpen }
      );
    } else if (processIdToOpen !== undefined) {
      this.updateModalDisplay(
        { key: 'displayProcessUI', value: true, sourceComponent: source },
        { key: 'processId', value: processIdToOpen }
      );
    }

    return formIdToOpen !== undefined || processIdToOpen !== undefined;
  }

  refreshEditViews(showMessage = true) {
    let editViews: EditViewUiComponent[];
    editViews = <EditViewUiComponent[]>(
      this.container.DOMChildrenComponent.slice().filter((child) => child instanceof EditViewUiComponent)
    );
    this.subscriptions.push(
      this.store.syncWithRemoteWindowData(editViews[0].currentDataStoreKey).subscribe((dataStore) => {
        for (let i = 0; i < editViews.length; i++) {
          if (i !== 0) {
            editViews[i].menuBarDetailComponent.updateButtonLists();
          }
          editViews[i].refreshData(showMessage);
        }
      })
    );
  }

  showPreview(additionalInfo: any) {
    this.updateModalDisplay({ key: 'displayJoinFilesPanel', value: false });
    if (Global.isMobile()) {
      this.openDraggableWindow(PreviewDocComponent, additionalInfo);
    } else {
      this.openSideBlade(PreviewDocComponent, additionalInfo);
    }
  }

  showNotes() {
    if (Global.isMobile()) {
      this.openDraggableWindow(NotesPanelUiComponent);
    } else {
      this.openSideBlade(NotesPanelUiComponent);
    }
  }

  openInDraggableWindow() {
    if (Global.isMobile()) {
      this.sideBarVcr.detach();
    } else {
      this.sideBladeVcr.detach();
    }
    let options;
    const left = this.sideBladeContainerEl.nativeElement.getBoundingClientRect().left + 'px';
    const top = this.sideBladeContainerEl.nativeElement.getBoundingClientRect().top - 41 + 'px';
    const width = this.sideBladeContainerEl.nativeElement.getBoundingClientRect().width + 'px';
    const height = this.sideBladeContainerEl.nativeElement.getBoundingClientRect().height + 31 + 'px';
    if (this.sideComponentRef.instance instanceof PreviewDocComponent) {
      options = {
        iconClass: 'icon-attach3x',
        title: this.sideComponentRef.instance.fileName,
        left: left,
        top: top,
        width: width,
        height: height,
      };
    } else {
      options = {
        iconClass: 'icon-notes',
        title: `${this?.data?.label} - ${this.getInfoToDisplayInWindow()}`,
        left: left,
        top: top,
        width: width,
        height: height,
      };
    }
    this.dynamicComponentManager.moveIntoDraggableWindow(options, this.sideComponentRef);
    this.closeSideBarAndBlade();
  }

  getInfoToDisplayInWindow() {
    if (this.editTabs[0]?.dataContainers) {
      const dataContainersFound = this.editTabs[0].dataContainers.filter((d) =>
        ['DocumentNo', 'Value', 'Name'].includes(d.data.columnName)
      );
      let dataContainerFound = dataContainersFound.find((d) => d.data.columnName === 'DocumentNo');
      if (dataContainerFound) {
        return `${dataContainerFound?.fieldValue}`;
      }
      dataContainerFound = dataContainersFound.find((d) => d.data.columnName === 'Name');
      if (dataContainerFound) {
        return `${dataContainerFound?.fieldValue}`;
      }
      dataContainerFound = dataContainersFound.find((d) => d.data.columnName === 'Value');
      if (dataContainerFound) {
        return `${dataContainerFound?.fieldValue}`;
      }
    }
    return `${this?.currentDataStoreKey?.recordId}`;
  }

  createOptions(type: Type<any>) {
    if (type === NotesPanelUiComponent) {
      return {
        table_id: this.table_id,
        currentDataStoreKey: this.currentDataStoreKey,
        parentEdit: this,
        parentTab: this.editTabs[0],
      };
    } else {
      return {
        parentComponent: this,
      };
    }
  }

  openSideBar(type: Type<any>, additionalInfo?: any) {
    this.isSidebarOpen = true;
    let options = this.createOptions(type);
    if (additionalInfo) {
      options = { ...options, ...additionalInfo };
    }
    this.sideComponentRef = this.dynamicComponentManager.clearAndCreateComponent(this.sideBarVcr, {
      type: type,
      options: options,
    });
  }

  closeSideBarAndBlade() {
    this.sideBarVcr.clear();
    this.sideBladeVcr.clear();
    this.sideComponentRef = null;
    this.renderer.setStyle(this.sideBladeContainerEl.nativeElement, 'width', null);
  }

  openSideBlade(type: Type<any>, additionalInfo?: any) {
    if (type.name === 'PreviewDocComponent') {
      this.sideBladeTitle = additionalInfo.fileName;
      this.sideBladeIconClass = 'icon-attach3x';
    } else {
      this.sideBladeTitle = this.translateService.instant('detailsNote.title');
      this.sideBladeIconClass = 'icon-notes';
    }
    if (this.sideBladeVcr.length > 0 && this.sideComponentRef?.instance instanceof type) {
      if (this.sideComponentRef?.instance instanceof PreviewDocComponent) {
        this.sideComponentRef.instance.onPreview(null, additionalInfo.fileName, additionalInfo.urlFile);
      } else {
        this.closeSideBarAndBlade();
      }
    } else {
      let options = this.createOptions(type);
      if (additionalInfo) {
        options = { ...options, ...additionalInfo };
      }
      this.sideComponentRef = this.dynamicComponentManager.clearAndCreateComponent(this.sideBladeVcr, {
        type: type,
        options: options,
      });
    }
  }

  openDraggableWindow(type: Type<any>, additionalInfo?: any) {
    let windowOptions;
    if (type.name === 'PreviewDocComponent') {
      windowOptions = {
        iconClass: 'icon-attach3x',
        title: additionalInfo.fileName,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
      };
    } else {
      windowOptions = {
        iconClass: 'icon-notes',
        title: `${this?.data?.label} - ${this.getInfoToDisplayInWindow()}`,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
      };
    }
    let options = this.createOptions(type);
    if (additionalInfo) {
      options = { ...options, ...additionalInfo };
    }
    const draggableWindow = this.dynamicComponentManager.createDraggableWindow(windowOptions);
    this.dynamicComponentManager.createComponent(draggableWindow.instance.contentVcr, {
      type: type,
      options: options,
    });
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.isResizing) {
      this.resizeSideBlade(event.clientX);
    }
  }
  @HostListener('document:touchmove', ['$event'])
  onTouchMove(event: TouchEvent) {
    if (this.isResizing) {
      const touchX = event.changedTouches[0].clientX;
      this.resizeSideBlade(touchX);
    }
  }
  @HostListener('document:touchend', ['$event'])
  @HostListener('document:mouseup', ['$event'])
  onResizeSideBladeEnd() {
    if (this.isResizing) {
      this.isResizing = false;
      this.scrollTo();
    }
  }

  startResizeSideBlade(event: Event) {
    this.isResizing = true;
  }

  resizeSideBlade(x: number) {
    if (this.isResizing) {
      if (this.sideBladeContainerEl) {
        const newWidth = x - this.sideBladeContainerEl.nativeElement.getBoundingClientRect().left;
        this.renderer.setStyle(this.sideBladeContainerEl.nativeElement, 'width', `${newWidth}px`);
      } else {
        console.log("couldn't resize");
      }
    }
  }

  getTabWhereclause() {
    let validation = '';
    const element = this.linkedComponents[0];
    if (element instanceof GridViewUiComponent) {
      validation = element.getTabWhereclause();
    }
    return validation;
  }
}

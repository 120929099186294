import { Component, forwardRef } from '@angular/core';
import InputSwitchUiComponent from '@iupics-components/standard/fields/input-switch-ui/input-switch-ui.component';
import { CellEditorComp, CellEditorParams } from '@iupics/apiz-grid';

@Component({
  selector: 'iu-checkbox-editor',
  template: `
    <iu-input-switch-ui
      [isStandalone]="true"
      [fieldValue]="this.fieldValue"
      [data]="this.templates"
      (fieldValueModified)="onFieldModified($event)">
    </iu-input-switch-ui>
  `,
  styles: [],
  standalone: true,
  imports: [forwardRef(() => InputSwitchUiComponent)],
})
export default class CheckboxEditorComponent implements CellEditorComp {
  params: CellEditorParams;
  label: string;
  templates: any;
  fieldValue = null;

  gridInit(params: CellEditorParams) {
    this.params = params;
    this.label = this.params.label || null;
    this.templates = this.params.templates;
    if (params.value !== undefined) {
      this.fieldValue = params.value;
    }
  }

  refresh(params: CellEditorParams): boolean {
    if (params.value !== undefined) {
      this.fieldValue = params.value;
    }
    return true;
  }

  getValue() {
    return this.fieldValue;
  }

  onFieldModified(value: any) {
    const oldValue = this.params.row.getData(this.templates.columnName);
    this.params.row.setData({ [this.templates.columnName]: value });

    if (!this.params.row.isSelected()) {
      this.params.row.setSelected(true); // select row
    }

    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: value,
        rowData: this.params.row.getRowData(),
        columnName: this.params.colDef.field,
        oldValue,
        newValue: value,
        // ...something
      };
      this.params.onClick({ ...this.params, ...params });
    }
  }
}

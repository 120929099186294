import { Injectable, signal } from '@angular/core';
import { TypeEnum } from '@iupics-util/models/types.util';
import { groupBy } from '@iupics-util/tools/array.utils';
import { buffer, debounceTime, map, Subject, tap } from 'rxjs';

@Injectable()
export class SKWMessageService {
  #message = new Subject<SKWMessageData>();
  #message$ = this.#message.pipe(debounceTime(500));
  #message$$ = this.#message.pipe(buffer(this.#message$));
  messages = signal<SKWMessageData[]>([]);

  // TODO: maybe this can be a user preference ?
  isMessageActive = signal(true);

  constructor() {
    this.#message$$
      .pipe(
        map((messages) => {
          const data = groupBy(messages, 'key');
          const parsedMessages: SKWMessageData[] = [];
          for (const key in data) {
            const msgs = data[key];
            const msgForKey = msgs[0];

            parsedMessages.push({
              ...msgForKey,
              content: typeof msgForKey.content === 'function' ? msgForKey.content(msgs) : msgForKey.content,
            });
          }

          return parsedMessages;
        }),
        tap((messages) => {
          this.messages.update((msgs) => {
            return [...msgs, ...messages];
          });

          setTimeout(() => {
            this.messages.update((msgs) => [...msgs.filter((m) => !messages.includes(m))]);
          }, 3000);
        })
      )
      .subscribe();
  }

  addMessage<T = unknown>(message: SKWMessageData<T>) {
    if (!this.isMessageActive()) return;

    this.#message.next(message);
  }
}

export type SKWMessageType = 'error' | 'success' | 'info';
export const SKWMessageType: TypeEnum<SKWMessageType> = {
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success',
};

export type SKWMessageData<T = unknown> = {
  key: string;
  data?: T;
  type: SKWMessageType;
  title: string;
  content: string | ((data: SKWMessageData<T>[]) => string);
};

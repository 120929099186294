import { Component, input, signal } from '@angular/core';

@Component({
  selector: 'iu-storekeeper-navbar',
  standalone: true,
  imports: [],
  templateUrl: './storekeeper-navbar.component.html',
  styleUrl: './storekeeper-navbar.component.scss',
})
export class StorekeeperNavbarComponent {
  canExpand = input.required<boolean>();

  isMenuOpen = signal(false);

  toggleMenu(event: MouseEvent) {
    this.isMenuOpen.update((v) => !v);
  }
}

import { CompiereDataGridFilterType, CompiereDataGridType, DataStoreRequest } from "@compiere-ws/models/compiere-data-json";
import { OperatorFilterType } from "@iupics-components/models/universal-filter";
import moment from "moment";

export function getChartData(data,isCheckLimit:boolean):any[] {
    const dates = [];
    const limit = [];
    const values = [];
  data = aggregateResourceWorkingHours(data);
  data.forEach((obj) => {
    for (const key in obj) {
      switch (key) {
        case 'working_date':
          dates.push(obj[key]);
          break;
          
        case 'resource_working_hours':
          values.push(obj[key]);
          break;
          
        case 'calendar_working_hours':
          limit.push(obj[key]);
          break;
          
        default:
          break;
      }
    }
  });

  if(isCheckLimit){
    for (let i = 0; i < values.length; i++) {
      if (values[i] <= limit[i]) {
        values.splice(i, 1);
        limit.splice(i, 1);
        dates.splice(i, 1);
        i--; // adjust the index because we removed an element
      }
    }
  }
  
    return [
        { type: "dates", data : dates }, 
        { type: "limit", data : limit }, 
        { type: "values", data : values}
    ];
  }


export function getManufacturingResourcesRequest(
    formDetailId: number,
    parentFormDetailId: number,
    pageNumber = 1,
    paginationStep = 50,
    resourceID,
    startDate,
    endDate,
    isDrafted
): DataStoreRequest {

  const stDate = formatResourceDate(startDate) + "T00:00:00.000";
  const ndDate: string = formatResourceDate(endDate) + "T23:59:59.999";
  let filterModel = {
    "Z_ProductionRessource_ID": {
        filterType: CompiereDataGridFilterType.SET,
        operators: [OperatorFilterType.EQUALS],
        values: [[0,resourceID]],
    },
    "working_date": {
        filterType: CompiereDataGridFilterType.DATE,
        operators: [OperatorFilterType.BETWEEN, OperatorFilterType.BETWEEN],
        values: [stDate, ndDate],
    },
    "docstatus": {
        filterType: CompiereDataGridFilterType.SET,
        operators: [OperatorFilterType.NOT_EQUALS],
        values: ['DR'],
    }
  };

  if(isDrafted) {
    // Remove the "docstatus" attribute
    delete filterModel.docstatus;
  }

  let dataStore: DataStoreRequest = {
    windowId: 0,
    compiereRequest: undefined
  };
  
  dataStore = {
    windowId: -1,
        parent_constraint: undefined,
        compiereRequest: {
            entityId: formDetailId,
            parent_formDetail_id: parentFormDetailId,
            windowType: CompiereDataGridType.FORM,
          startRow: (pageNumber - 1) * paginationStep,
            filterModel: filterModel
        }
  };  

    return dataStore;
}

export function aggregateResourceWorkingHours(dataList:any) {
  const aggregatedData : any = {};

  dataList.forEach(item => {
      const key = item.working_date;
      if (!aggregatedData[key]) {
          aggregatedData[key] = { ...item }; 
      } else {
          aggregatedData[key].resource_working_hours += item.resource_working_hours; 
      }
  });
  return Object.values(aggregatedData); 
}


export function formatResourceDate(date: any):string {
  return moment(date).format('YYYY-MM-DD');
}

<div class="contact-container">
  <div class="contact-title">
    <h4>{{ (content?.greetingData?.Name ?? '') + ' ' }}{{ content?.Name ?? content?.Value }}</h4>
  </div>
  <div class="contact-content">
    @if (content?.jobData?.Name) {
      <i class="icon-user" aria-hidden="true"></i>
      <span class="label">{{ content?.jobData?.Name }}</span>
    }

    @if (content?.EMail) {
      <i class="icon-email" aria-hidden="true"></i>
      <span class="label">{{ content?.EMail }}</span>
    }

    @if (content?.Phone) {
      <i class="icon-phone" aria-hidden="true"></i>
      <span class="label">{{ content?.Phone }}</span>
    }

    @if (content?.Phone2) {
      <i class="icon-phone" aria-hidden="true"></i>
      <span class="label">{{ content?.Phone2 }}</span>
    }
  </div>
</div>

<iu-storekeeper-input
  [type]="'textarea'"
  [label]="'Description' | SKWTranslate"
  [value]="data()?.description ?? ''"
  (valueChange)="changeValue('Description', $event)" />

<iu-storekeeper-input
  [type]="'textarea'"
  [label]="'comment' | SKWTranslate"
  [value]="data()?.comment ?? ''"
  (valueChange)="changeValue('comment', $event)" />

@if (addPalette() || data()?.palette) {
  <div class="column">
    <span>{{ 'Destination' | SKWTranslate }}</span>
    <div class="row">
      <iu-storekeeper-input
        class="flex"
        [type]="'text'"
        [label]="'Location' | SKWTranslate"
        [value]="data()?.emplacement"
        [readOnly]="!isCreation()"
        (valueChange)="changeValue('emplacement', $event)" />
      <iu-storekeeper-input
        [type]="'text'"
        [label]="'Pallet' | SKWTranslate"
        [value]="data()?.palette"
        [readOnly]="!isCreation()"
        (valueChange)="changeValue('palette', $event)" />
    </div>
  </div>
} @else if (canAddPalette()) {
  <iu-storekeeper-button [label]="'AddPallet' | SKWTranslate" />
}

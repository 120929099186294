import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';

@Injectable({
  providedIn: 'root',
})
export class XlsxParser {
  constructor() {}

  async readExcel(fileContent: string, options: any) {
    const workbook = new ExcelJS.Workbook();
    const arrayBuffer = this.convertBinaryStringToArrayBuffer(fileContent);
    await workbook.xlsx.load(arrayBuffer, options);
    return workbook;
  }

  private convertBinaryStringToArrayBuffer(binaryString: string): ArrayBuffer {
    const length = binaryString.length;
    const buffer = new ArrayBuffer(length);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < length; i++) {
      view[i] = binaryString.charCodeAt(i) & 0xff;
    }
    return buffer;
  }
}

import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, output, signal } from '@angular/core';
import InputStarsUiComponent from '@iupics-components/standard/fields/input-stars-ui/input-stars-ui.component';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { ImgFallbackDirective } from '@iupics-util/directives/img-fallback.directive';
import { TranslateModule } from '@ngx-translate/core';
import { DocServerUrlPipe } from '../../../../../../iupics-util/pipes/doc-server-url/doc-server-url.pipe';
import { LoadingDirective } from '../../../../../directives/loading.directive';
import { CustomerProfileData } from './customer-profile-data';

@Component({
  selector: 'iu-customer-profile',
  templateUrl: './customer-profile.component.html',
  styleUrls: ['./customer-profile.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    LoadingDirective,
    InputStarsUiComponent,
    AsyncPipe,
    TranslateModule,
    DocServerUrlPipe,
    ImgFallbackDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class CustomerProfileComponent {
  protected uiCreator = inject(UICreatorService);

  data = input.required<CustomerProfileData>();

  editButtonEmitter = output<MouseEvent>();

  isLoading = signal(true);
  imgError = signal(false);

  hasImg = computed(() => (this.data()?.profilePicture?.trim()?.length ?? 0) > 0);

  editBtnClick(event: MouseEvent) {
    this.editButtonEmitter.emit(event);
  }

  changeLoadingState(state: boolean) {
    this.isLoading.set(state);
  }

  changeImgError(state?: boolean) {
    this.imgError.update((v) => state ?? !v);
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataStoreKey } from '@compiere-ws/models/compiere-data-json';
import { DocServerService } from '@compiere-ws/services/doc-server/doc-server.service';
import InputFileUiComponent from '@iupics-components/standard/fields/input-file-ui/input-file-ui.component';

@Component({
  selector: 'iu-join-file-ui',
  templateUrl: './join-file-ui.component.html',
  styleUrls: ['./join-file-ui.component.scss'],
  standalone: true,
  imports: [InputFileUiComponent],
})
export default class JoinFileUiComponent implements OnInit {
  @Input()
  adTable_ID: number;
  @Input()
  adTab_ID: number;
  record_id: any;
  @Input()
  dsKey: DataStoreKey;
  @Input()
  nbUploadedFiles = 0;
  @Input()
  data: any;
  @Input()
  fromNotes: boolean = false;

  @Output()
  nbUploadedFilesChange = new EventEmitter<number>();
  @Output()
  cancelEmitter = new EventEmitter<any>();
  @Output() uploadEnd = new EventEmitter<any>();
  @Output() openFileOnSideEmitter = new EventEmitter<any>();

  baseSearch: any;
  tags: any;

  constructor(private docServerService: DocServerService) {}

  public ngOnInit() {
    const recordId = this.dsKey.recordId.split(',');
    this.record_id =
      recordId.length > 1 ? (isNaN(parseInt(recordId[1], 10)) ? recordId[1] : parseInt(recordId[1], 10)) : -1;

    this.baseSearch = this.docServerService.initBaseSearch(this.dsKey, this.data, this.adTable_ID);
    this.tags = this.docServerService.initTags(this.dsKey, this.data, this.adTable_ID, this.fromNotes);
  }
  public cancel(event: Event) {
    event.stopPropagation();
    this.cancelEmitter.emit();
  }

  public updateNbUploadedFiles(nb: number) {
    this.nbUploadedFiles = nb;
    this.nbUploadedFilesChange.emit(this.nbUploadedFiles);
  }

  public openFileOnSide(additionalInfo: any) {
    this.openFileOnSideEmitter.emit(additionalInfo);
  }
}

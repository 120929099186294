<div class="iu-specific-window-container">
  @if (!state.loaded()) {
    <p-progressBar [mode]="'indeterminate'" [styleClass]="'loading-progressbar'" />
  }

  <div class="container">
    <iu-storekeeper-toolbar class="title-bar" />

    <iu-storekeeper-window-messages />

    <div class="content">
      @switch (page()) {
        @case ('list') {
          <iu-storekeeper-window-list />
        }
        @case ('transfer') {
          <iu-storekeeper-transfer />
        }
        @case ('new_transfer') {
          <iu-storekeeper-transfer [create]="true" />
        }
        @case ('transfer_line') {
          <iu-storekeeper-transfer-line-details />
        }
        @case ('inventory') {
          <iu-storekeeper-inventory />
        }
        @default {
          Une erreur est survenue...
        }
      }
    </div>
  </div>
</div>

<!-- TODO: remove this after dev -->
@if (isDev()) {
  <iu-storekeeper-window-dev-console />
}

<div
  #specificContainer
  class="iu-specific-window iu-specific-window-container operation-management-window no-overflow-x"
  [attr.data-cy-specific-window-name]="name">
  <button pButton class="p-button settings" (click)="isSidebarOpen = !isSidebarOpen" icon="fas fa-cog">
    <!-- <i class="fas fa-cog" aria-hidden="true"></i> -->
  </button>
  <div #dynContainer>
    @if (treeData?.treeNodes) {
      <button
        pButton
        tooltipPosition="top"
        showDelay="250"
        [life]="3000"
        [hideDelay]="250"
        pTooltip="{{ 'menuBarDetails.save' | translate }}"
        type="button"
        (mouseup)="$event.button === 0 ? saveTree($event) : null"
        data-cy="button-saveTree"
        class="p-button tree-maintenance-main-btn"
        icon="icon-save3x">
        <!-- <i class="icon-save3x" style="padding: 0" aria-hidden="true"></i> -->
      </button>
      <button
        pButton
        tooltipPosition="top"
        showDelay="250"
        [life]="3000"
        [hideDelay]="250"
        pTooltip="{{ 'menuBarDetails.refresh' | translate }}"
        type="button"
        (mouseup)="$event.button === 0 ? refresh($event) : null"
        data-cy="button-refreshData"
        class="p-button tree-maintenance-main-btn"
        icon="icon-refresh3x">
        <!-- <i class="icon-refresh3x" style="padding: 0" aria-hidden="true"></i> -->
      </button>
    }
    <ng-template iuDynamicContainer />
  </div>

  <div class="split-view" [style.height]="splitHeight">
    <as-split [unit]="'percent'" [gutterSize]="8">
      <as-split-area [size]="50" [lockSize]="false">
        <div class="tree-maintenance-left-container">
          <div [style.height]="'100%'" [style.width]="splitTreeWidth">
            @if (treeID && treeID.id > 0) {
              <div class="tree-maintenance-left-search-zone" #searchTreeDiv>
                <iu-input-text-ui
                  #searchTreeInputComponent
                  [cssGrid]="'p-col-6'"
                  [overridedCSS]="{ width: '250px' }"
                  [isStandalone]="true"
                  [label]="'tree-maintenance.search-in-tree' | translate"
                  [data]="{ columnName: 'searchInTree' }"
                  [fieldValue]="treeSearchValue"
                  (fieldValueChange)="!isLetterByLetterSearch ? searchFromFieldValue($event) : null" />
              </div>
            }
            <div class="tree-maintenance-left-tree" [style.height]="treeHeight">
              @if (treeNodes && treeNodes.length) {
                <p-tree
                  [loading]="isTreeLoading"
                  [loadingIcon]="'tree-maintenance-spinner-loading fas fa-circle-notch'"
                  [value]="treeNodes"
                  [trackBy]="trackByFn"
                  [style]="{ overflow: 'auto', height: '100%', width: '100%' }"
                  [droppableNodes]="true"
                  [draggableNodes]="true"
                  [(selection)]="selectedTreeNodes"
                  [selectionMode]="'multiple'"
                  (onNodeDrop)="dropNode($event)"
                  (onNodeExpand)="expandNode($event)"
                  (onNodeCollapse)="collapseNode($event)" />
              }
            </div>
          </div>
          <div #leftBtnContainer class="tree-maintenance-left-btn-container">
            <button
              pButton
              data-cy="tree-maintenance-btn-double-up"
              class="p-button tree-maintenance-left-btn"
              (click)="stepUp($event, '*')"
              icon="fas fa-angle-double-up">
              <!-- <i class="fas fa-angle-double-up" aria-hidden="true"></i> -->
            </button>
            <button
              pButton
              data-cy="tree-maintenance-btn-up"
              class="p-button tree-maintenance-left-btn"
              (click)="stepUp($event)"
              [disabled]="isMovementDisabled"
              icon="fas fa-angle-up">
              <!-- <i class="fas fa-angle-up" aria-hidden="true"></i> -->
            </button>
            <button
              pButton
              data-cy="tree-maintenance-btn-step-in"
              class="p-button tree-maintenance-left-btn"
              (click)="stepIn($event)"
              [disabled]="isMovementDisabled"
              icon="fas fa-indent">
              <!-- <i class="fas fa-indent" aria-hidden="true"></i> -->
            </button>
            <button
              pButton
              data-cy="tree-maintenance-btn-right"
              class="p-button tree-maintenance-left-btn"
              (click)="deleteFromTree($event)"
              icon="fas fa-angle-right">
              <!-- <i class="fas fa-angle-right" aria-hidden="true"></i> -->
            </button>
            <button
              pButton
              data-cy="tree-maintenance-btn-left"
              class="p-button tree-maintenance-left-btn"
              (click)="addInTree($event)"
              icon="fas fa-angle-left">
              <!-- <i class="fas fa-angle-left" aria-hidden="true"></i> -->
            </button>
            <button
              pButton
              data-cy="tree-maintenance-btn-step-out"
              class="p-button tree-maintenance-left-btn"
              (click)="stepOut($event)"
              [disabled]="isMovementDisabled"
              icon="fas fa-outdent">
              <!-- <i class="fas fa-outdent" aria-hidden="true"></i> -->
            </button>
            <button
              pButton
              data-cy="tree-maintenance-btn-down"
              class="p-button tree-maintenance-left-btn"
              (click)="stepDown($event)"
              [disabled]="isMovementDisabled"
              icon="fas fa-angle-down">
              <!-- <i class="fas fa-angle-down" aria-hidden="true"></i> -->
            </button>
            <button
              pButton
              data-cy="tree-maintenance-btn-double-down"
              class="p-button tree-maintenance-left-btn"
              (click)="stepDown($event, '*')"
              icon="fas fa-angle-double-down">
              <!-- <i class="fas fa-angle-double-down" aria-hidden="true"></i> -->
            </button>
          </div>
        </div>
      </as-split-area>
      <as-split-area [size]="50" [lockSize]="false">
        @if (colTableHeaders && colTableHeaders.length) {
          <div class="tree-maintenance-right-container">
            <div class="tree-maintenance-right-search-zone" #searchGridDiv>
              <div class="tree-maintenance-right-warning">
                <i
                  class="fas fa-exclamation-triangle"
                  [life]="3000"
                  [hideDelay]="250"
                  pTooltip="{{ 'tree-maintenance.warning-search' | translate }}"
                  tooltipPosition="bottom"
                  aria-hidden="true"></i>
              </div>
              <div class="tree-maintenance-right-search-field">
                <iu-input-text-ui
                  [cssGrid]="'p-col-12'"
                  [overridedCSS]="{ width: '250px' }"
                  [isStandalone]="true"
                  [label]="'tree-maintenance.search-in-grid' | translate"
                  [data]="{ columnName: 'searchInGrid' }"
                  [fieldValue]="gridSearchValue"
                  (fieldValueChange)="searchInGrid($event)" />
              </div>
            </div>
            <div [style.height]="gridHeight">
              <iu-grid-tab-infinity-scroll-ui
                #gridTab
                [data]="{
                  frameworkComponents: frameworkComponents,
                  columnsTableHeader: colTableHeaders,
                  AD_FormDetail_ID: undefined
                }"
                [forcePaginationAutoPageSize]="true"
                [isSearch]="true"
                [windowType]="IupicsMenuType.FORM"
                [overridedTableHeight]="true"
                [tableHeight]="'calc(100% - 5px)'"
                (multiSelectEmitter)="onSelectOnGrid($event)"
                [rowClassRules]="rowClassRules"
                data-cy-grid-tablename="tree-maintenance-search-grid" />
            </div>
          </div>
        }
      </as-split-area>
    </as-split>
  </div>

  <p-sidebar
    [(visible)]="isSidebarOpen"
    [baseZIndex]="0"
    position="right"
    styleClass="p-sidebar-md optionalFilterPanel">
    <h1 class="iu-notification-center-title">
      {{ 'specificWindow.optionalFilters' | translate }}
    </h1>
    <div class="optionalFields">
      <ng-template #optional />
      <div>
        <iu-input-switch-ui
          [isStandalone]="true"
          [label]="'tree-maintenance.letter-by-letter' | translate"
          [fieldValue]="isLetterByLetterSearch ? 'Y' : 'N'"
          (fieldValueModified)="setConfig('isLetterByLetterSearch', $event)" />
      </div>
    </div>
  </p-sidebar>
</div>

<div [ngClass]="[!displayFormUI ? 'hidden' : '', 'specificModal']">
  <iu-modal-ui
    [title]="specificWindowTitle"
    [isModalDisplay]="displayFormUI"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false, sourceComponent: this })"
    [angularStyle]="customFormModalBodyCss">
    <ng-template #vcrSpecific />
  </iu-modal-ui>
</div>

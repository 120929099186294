import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, input, output } from '@angular/core';

@Component({
  selector: 'iu-storekeeper-navbar-button',
  standalone: true,
  imports: [NgClass, NgTemplateOutlet],
  templateUrl: './storekeeper-navbar-button.component.html',
  styleUrl: './storekeeper-navbar-button.component.scss',
})
export class StorekeeperNavbarButtonComponent {
  icon = input.required<string>();
  label = input.required<string>();
  type = input<'normal' | 'confirm'>('normal');
  active = input(false);

  count = input<number | undefined>();
  hideCount = input<boolean>();

  onClick = output<MouseEvent>();

  click(event: MouseEvent) {
    this.onClick.emit(event);
  }
}

import { Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[iuDraggable]',
  standalone: true,
})
export class DraggableDirective {
  private isDragging = false;
  private offsetX: number = 0;
  private offsetY: number = 0;
  private unlisteners = [];
  @Input('iuDraggable-has-drag-handle') hasDragHandle: boolean;
  @Input('iuDraggable-drag-handle-id') dragHandleId: string = 'iu-drag-handle';
  @Output('iuDraggable-dragStart') dragStart: EventEmitter<Event> = new EventEmitter();
  @Output('iuDraggable-dragEnd') dragEnd: EventEmitter<Event> = new EventEmitter();
  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  @HostListener('mousedown', ['$event'])
  @HostListener('touchstart', ['$event'])
  onDragStart(event: Event): void {
    if (this.isDragHandle(event) || (!this.hasDragHandle && this.el.nativeElement.contains(event.target as Node))) {
      this.isDragging = true;
      const { clientX, clientY } = this.getEventCoordinates(event);
      this.offsetX = clientX - this.el.nativeElement.getBoundingClientRect().left;
      this.offsetY = clientY - this.el.nativeElement.getBoundingClientRect().top;
      this.setupListeners();

      this.dragStart.emit(event);
    }
  }

  onDragMove(event: Event): void {
    event.preventDefault();
    const { clientX, clientY } = this.getEventCoordinates(event);

    if (this.isDragging) {
      this.moveElementWithinViewport(clientX - this.offsetX, clientY - this.offsetY);
    }
  }

  onDragEnd(event: Event): void {
    this.isDragging = false;
    this.removeListeners();
    this.dragEnd.emit(event);
  }

  private isDragHandle(event: Event): boolean {
    const target = event.target as HTMLElement;
    return (
      target &&
      ((this.hasDragHandle && this.containsDragHandle(target)) ||
        (!this.hasDragHandle && this.el.nativeElement.contains(target)))
    );
  }
  private containsDragHandle(target: HTMLElement): boolean {
    while (target !== null) {
      if (target.classList.contains(this.dragHandleId)) {
        return true;
      }
      target = target.parentElement;
    }
    return false;
  }
  private getEventCoordinates(event: Event): { clientX: number; clientY: number } {
    if (event instanceof TouchEvent) {
      return { clientX: event.changedTouches[0].clientX, clientY: event.changedTouches[0].clientY };
    } else if (event instanceof MouseEvent) {
      return { clientX: event.clientX, clientY: event.clientY };
    } else {
      return { clientX: 0, clientY: 0 };
    }
  }

  private moveElementWithinViewport(x: number, y: number): void {
    const rect = this.el.nativeElement.getBoundingClientRect();
    const maxX = window.innerWidth - rect.width;
    const maxY = window.innerHeight - rect.height;

    x = Math.min(Math.max(0, x), maxX);
    y = Math.min(Math.max(0, y), maxY);

    this.renderer.setStyle(this.el.nativeElement, 'left', `${x}px`);
    this.renderer.setStyle(this.el.nativeElement, 'top', `${y}px`);
  }

  private setupListeners(): void {
    this.unlisteners.push(this.renderer.listen('document', 'touchmove', this.onDragMove.bind(this)));
    this.unlisteners.push(this.renderer.listen('document', 'touchend', this.onDragEnd.bind(this)));
    this.unlisteners.push(this.renderer.listen('document', 'mousemove', this.onDragMove.bind(this)));
    this.unlisteners.push(this.renderer.listen('document', 'mouseup', this.onDragEnd.bind(this)));
  }

  private removeListeners(): void {
    this.unlisteners.forEach((unlistener) => unlistener());
    this.unlisteners = [];
  }
}

export enum EditViewGetFormIDByWindowID {
  OPEN_NEW = 'EditView#OpenNew#GetFormIDByWindowID#',
  COPY_DATA = 'EditView#CopyData#GetFormIDByWindowID#',
  EXPORT_DATA = 'EditView#ExportData#GetFormIDByWindowID#',
  DELETE_DATA = 'EditView#DeleteData#GetFormIDByWindowID#',
  REFRESH_DATA = 'EditView#RefreshData#GetFormIDByWindowID#',
}
export enum EditViewGetProcessIDByWindowID {
  OPEN_NEW = 'EditView#OpenNew#GetProcessIDByWindowID#',
  COPY_DATA = 'EditView#CopyData#GetProcessIDByWindowID#',
  EXPORT_DATA = 'EditView#ExportData#GetProcessIDByWindowID#',
  DELETE_DATA = 'EditView#DeleteData#GetProcessIDByWindowID#',
  REFRESH_DATA = 'EditView#RefreshData#GetProcessIDByWindowID#',
}
export enum EditViewGetFormIDByTabID {
  OPEN_NEW = 'EditView#OpenNew#GetFormIDByTabID#',
  COPY_DATA = 'EditView#CopyData#GetFormIDByTabID#',
  EXPORT_DATA = 'EditView#ExportData#GetFormIDByTabID#',
  DELETE_DATA = 'EditView#DeleteData#GetFormIDByTabID#',
  REFRESH_DATA = 'EditView#RefreshData#GetFormIDByTabID#',
}
export enum EditViewGetProcessIDByTabID {
  OPEN_NEW = 'EditView#OpenNew#GetProcessIDByTabID#',
  COPY_DATA = 'EditView#CopyData#GetProcessIDByTabID#',
  EXPORT_DATA = 'EditView#ExportData#GetProcessIDByTabID#',
  DELETE_DATA = 'EditView#DeleteData#GetProcessIDByTabID#',
  REFRESH_DATA = 'EditView#RefreshData#GetProcessIDByTabID#',
}
export enum GridViewGetFormIDByWindowID {
  OPEN_NEW = 'GridView#OpenNew#GetFormIDByWindowID#',
  COPY_DATA = 'GridView#CopyData#GetFormIDByWindowID#',
  EXPORT_DATA = 'GridView#ExportData#GetFormIDByWindowID#',
  DELETE_DATA = 'GridView#DeleteData#GetFormIDByWindowID#',
  REFRESH_DATA = 'GridView#RefreshData#GetFormIDByWindowID#',
}
export enum GridViewGetProcessIDByWindowID {
  OPEN_NEW = 'GridView#OpenNew#GetProcessIDByWindowID#',
  COPY_DATA = 'GridView#CopyData#GetProcessIDByWindowID#',
  EXPORT_DATA = 'GridView#ExportData#GetProcessIDByWindowID#',
  DELETE_DATA = 'GridView#DeleteData#GetProcessIDByWindowID#',
  REFRESH_DATA = 'GridView#RefreshData#GetProcessIDByWindowID#',
}
export enum GridViewGetProcessIDByTabID {
  OPEN_NEW = 'GridView#OpenNew#GetProcessIDByTabID#',
  COPY_DATA = 'GridView#CopyData#GetProcessIDByTabID#',
  EXPORT_DATA = 'GridView#ExportData#GetProcessIDByTabID#',
  DELETE_DATA = 'GridView#DeleteData#GetProcessIDByTabID#',
  REFRESH_DATA = 'GridView#RefreshData#GetProcessIDByTabID#',
}
export enum GridViewGetFormIDByTabID {
  OPEN_NEW = 'GridView#OpenNew#GetFormIDByTabID#',
  COPY_DATA = 'GridView#CopyData#GetFormIDByTabID#',
  EXPORT_DATA = 'GridView#ExportData#GetFormIDByTabID#',
  DELETE_DATA = 'GridView#DeleteData#GetFormIDByTabID#',
  REFRESH_DATA = 'GridView#RefreshData#GetFormIDByTabID#',
}

export enum ExportDataOptionByProcessID {
  EXPORT_TYPE = 'ExportOptions#ProcessID#',
}

export class MenuUtils {}

/* eslint-disable deprecation/deprecation */
import { DestroyRef, Directive, ElementRef, Input, OnInit, inject, input, output } from '@angular/core';

@Directive({
  selector: '[iuLoading]',
  standalone: true,
})
export class LoadingDirective implements OnInit {
  #el: ElementRef<HTMLElement> = inject(ElementRef);
  #destroyRef = inject(DestroyRef);

  private ATTRIBUTE_NAME = 'data-loading';

  private _isLoading = true;
  @Input()
  public get isLoading() {
    return this._isLoading;
  }
  public set isLoading(value: boolean) {
    this._isLoading = value;
  }

  private _dataToWatch: any;
  @Input()
  public get dataToWatch() {
    return this._dataToWatch;
  }
  public set dataToWatch(value: any) {
    this._dataToWatch = value;
    this._isLoading = this.#checkDataToWatch(this.dataToWatch);
    this.#toggleLoading(this.isLoading);
  }

  timeout = input(5000);
  timeoutRef: ReturnType<typeof setTimeout>;
  onTimeout = output<boolean>();

  constructor() {
    this.#destroyRef.onDestroy(() => {
      clearTimeout(this.timeoutRef);
    });
  }

  ngOnInit(): void {
    if (this.timeout()) {
      this.timeoutRef = setTimeout(() => {
        this._isLoading = false;
        this.#toggleLoading(false);
      }, this.timeout());
    }
  }

  #toggleLoading(loading: boolean) {
    this.#el.nativeElement.setAttribute(this.ATTRIBUTE_NAME, `${loading}`);
    if (!loading) {
      clearTimeout(this.timeoutRef);
      this.onTimeout.emit(loading);
    }
  }

  #checkDataToWatch(data: any) {
    let dataLoading = false;

    const checkIfDataEmpty = (d: any) => {
      for (const key in d) {
        if (d[key] instanceof Object) {
          checkIfDataEmpty(d[key]);
        } else if (d[key] === null || d[key] === undefined) {
          dataLoading = true;
          break;
        }
      }
    };

    checkIfDataEmpty(data);

    return dataLoading;
  }
}

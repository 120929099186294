import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AvatarInfos } from '@iupics-manager/models/user-account';
import { TranslateModule } from '@ngx-translate/core';
import AvatarUiComponent from '../../../../user/avatar-ui/avatar-ui.component';

@Component({
  selector: 'iu-tag-list-ui',
  templateUrl: './tag-list-item-ui.component.html',
  styleUrls: ['./tag-list-item-ui.component.scss'],
  standalone: true,
  imports: [NgIf, AvatarUiComponent, TranslateModule],
})
export default class TagListItemUiComponent implements OnInit {
  @Input() value: string;
  @Input() pictureUrl: string;
  @Input() userId: number;
  @Input() noMatch: boolean;

  avatarInfos: AvatarInfos;

  constructor() {}

  ngOnInit(): void {
    if (!this.noMatch) {
      this.setAvatarInfos();
    }
  }

  setAvatarInfos() {
    if (this.userId && this.value) {
      this.avatarInfos = {
        id: this.userId,
        fullname: this.value,
        pictureURL: this.pictureUrl,
      };
    }
  }
}

<span
  class="iu-checkbox"
  [ngClass]="{
    'iu-checkbox-disabled': disabled,
    'iu-checkbox-tri': tristate
  }"
  (click)="onClick($event)">
  @if (tristate) {
    <ng-container [ngTemplateOutlet]="tristateTpl" />
  } @else {
    <ng-container [ngTemplateOutlet]="bistateTpl" />
  }
</span>

<ng-template #tristateTpl>
  <i
    tabindex="0"
    [ngClass]="{
      'icon-checkbox-checked': checked === true,
      'icon-checkbox-tri-false': checked === false,
      'icon-checkbox-unchecked': checked === null
    }"
    aria-hidden="true"></i>
</ng-template>

<ng-template #bistateTpl>
  <i
    tabindex="0"
    [ngClass]="{
      'icon-checkbox-checked': checked,
      'icon-checkbox-unchecked': !checked
    }"
    aria-hidden="true"></i>
</ng-template>

import { AsyncPipe, CurrencyPipe, DecimalPipe } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { CompiereDataGridFilterType, DataStoreRequest } from '@compiere-ws/models/compiere-data-json';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import SpecificWindowUiComponent from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-pos-ui',
  templateUrl: './pos-ui.component.html',
  styleUrls: ['./pos-ui.component.scss'],
  standalone: true,
  imports: [ProgressBarModule, ScrollPanelModule, AsyncPipe, DecimalPipe, CurrencyPipe, TranslateModule],
})
export default class PosUiComponent extends SpecificWindowUiComponent implements OnInit {
  products$: Observable<{}[]>;
  prices = {};
  orderLines = [];
  grandtotal = 0;

  private securityManager = inject(SecurityManagerService);

  isModal: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    const productRequest: DataStoreRequest = {
      windowId: this.formId,
      parent_constraint: '',
      compiereRequest: {
        startRow: 0,
        endRow: 15,
        tableName: 'M_Product',
        filterModel: {
          AD_CLIENT_ID: {
            filterType: CompiereDataGridFilterType.SET,
            values: [this.securityManager.getIupicsUserContext()['#AD_Client_ID']],
            operators: [OperatorFilterType.EQUALS],
          },
        },
      },
    };
    this.products$ = this.store.getDataGrid(productRequest).pipe(
      map((productResult) => {
        return productResult.data;
      })
    );
  }

  getPrice(product_id: number) {
    if (!this.prices[product_id]) {
      this.prices[product_id] = Math.floor(Math.random() * (1000 - 1) + 1);
    }
    return this.prices[product_id];
  }

  addToBasket(product) {
    this.orderLines.push({ product: product, price: this.prices[product['M_PRODUCT_ID']] });
    this.grandtotal += this.prices[product['M_PRODUCT_ID']];
  }

  validateOrder() {
    this.orderLines = [];
    this.grandtotal = 0;
  }
}

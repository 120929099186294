import { ChangeDetectionStrategy, Component, computed, inject, model, OnInit } from '@angular/core';
import { iif, map, of, switchMap, tap } from 'rxjs';
import {
  SKWStateActionType,
  SKWTransferHeaderData,
  SKWTransferLineData,
  SKWTransferLineFormData,
} from '../../../models/storekeeper-window.model';
import { SKWTranslatePipe } from '../../../pipes/storekeeper-window-translate.pipe';
import { SKWContextService } from '../../../services/storekeeper-window-context.service';
import { SKWMessageService, SKWMessageType } from '../../../services/storekeeper-window-message.service';
import { SKWNavigationService } from '../../../services/storekeeper-window-navigation.service';
import { SKWDataService } from '../../../services/strokeeper-window-data.service';
import { StorekeeperButtonComponent } from '../../storekeeper-button/storekeeper-button.component';
import { StorekeeperInputComponent } from '../../storekeeper-input/storekeeper-input.component';
import { StorekeeperNavbarButtonComponent } from '../../storekeeper-navbar/storekeeper-navbar-button/storekeeper-navbar-button.component';
import { StorekeeperWindowInputScanComponent } from '../../storekeeper-window-input-scan/storekeeper-window-input-scan.component';
import { StorekeeperWindowPanelComponent } from '../../storekeeper-window-panel/storekeeper-window-panel.component';
import { StorekeeperTransferDetailsComponent } from '../storekeeper-transfer-details/storekeeper-transfer-details.component';

@Component({
  selector: 'iu-storekeeper-transfer-line-details',
  standalone: true,
  imports: [
    StorekeeperInputComponent,
    StorekeeperButtonComponent,
    StorekeeperTransferDetailsComponent,
    StorekeeperNavbarButtonComponent,
    StorekeeperWindowPanelComponent,
    StorekeeperWindowInputScanComponent,
    SKWTranslatePipe,
  ],
  templateUrl: './storekeeper-transfer-line-details.component.html',
  styleUrl: './storekeeper-transfer-line-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StorekeeperTransferLineDetailsComponent implements OnInit {
  #SKWNavigationService = inject(SKWNavigationService);
  #SKWContextService = inject(SKWContextService);
  #SKWDataService = inject(SKWDataService);
  #SKWMessageService = inject(SKWMessageService);

  headerData = model<SKWTransferHeaderData>(this.#SKWNavigationService.transferActive());

  data = model<SKWTransferLineData>(this.#SKWNavigationService.detailSelectedLine());
  tabType = this.#SKWNavigationService.detailTabActive;
  isCreation = computed(() => this.#SKWNavigationService.isCreation() && !this.data()?.DocStatus);

  inputData = model<SKWTransferLineFormData>({
    locator: undefined,
    palette: undefined,
    Product: undefined,
    Qty: undefined,
    ...this.#SKWNavigationService.searchData(),
  });

  isLocatorCorrect = computed(() => {
    if (this.tabType() === 'TL') {
      return (
        (!this.data()?.locator_source && this.inputData()?.locator !== undefined) ||
        (this.data()?.locator_source && this.inputData()?.locator?.id === this.data()?.locator_source?.id)
      );
    }

    return (
      (!this.data()?.locator_destination && this.inputData()?.locator !== undefined) ||
      (this.data()?.locator_destination && this.inputData()?.locator?.id === this.data()?.locator_destination?.id)
    );
  });

  isPaletteCorrect = computed(() => {
    if (this.tabType() === 'TL') {
      return (
        (!this.data()?.palette_source && this.inputData()?.palette !== undefined) ||
        (this.data()?.palette_source && this.inputData()?.palette?.id === this.data()?.palette_source?.id)
      );
    }

    return (
      (!this.data()?.palette_destination && this.inputData()?.palette !== undefined) ||
      (this.data()?.palette_destination !== undefined &&
        this.inputData()?.palette?.id === this.data()?.palette_destination?.id)
    );
  });

  isProductCorrect = computed(() => {
    if (this.data()?.M_Product_ID !== undefined) {
      return this.inputData()?.Product?.id === this.data()?.M_Product_ID?.id;
    }

    return this.inputData()?.Product !== undefined;
  });

  isOpen = model(false);

  ngOnInit(): void {
    this.#SKWNavigationService.searchData.set({});
  }

  cancelNewLine(event: MouseEvent) {
    this.#SKWNavigationService.selectLine(undefined);
  }

  save(event: MouseEvent, skip = false) {
    if (!this.inputData()?.Qty) {
      this.#SKWMessageService.addMessage({
        type: SKWMessageType.ERROR,
        key: 'SaveTransferNoQtyError',
        title: 'MissingData',
        content: 'MissingDataNoQty',
      });
      return;
    }

    if (
      !skip &&
      (!this.isLocatorCorrect() ||
        ((this.data()?.palette_source?.id ?? this.data()?.palette_destination?.id) !== undefined &&
          !this.isPaletteCorrect()))
    ) {
      this.isOpen.set(true);
      return;
    }

    if (this.isCreation()) {
      this.#saveTransferAndLine(this.headerData(), this.inputData());
      return;
    }

    this.#updateLine();
  }

  #saveTransferAndLine(transfer: SKWTransferHeaderData, line: SKWTransferLineFormData) {
    this.#SKWContextService.newAction({
      type: SKWStateActionType.SAVE,
      clearData: false,
      isLoading: true,
      source: this.#SKWDataService.saveTransfer(transfer, line).pipe(
        switchMap(({ result, request }) =>
          iif(
            () => !result.success,
            of({ result, request, transfer: undefined }),
            this.#SKWDataService.getTransfer(result.Record_ID).pipe(map((res) => ({ result, request, transfer: res })))
          )
        ),
        tap(({ result, transfer }) => {
          if (!result.success) {
            this.#SKWMessageService.addMessage({
              type: SKWMessageType.ERROR,
              key: 'saveTransferLineError',
              // TODO: translate
              title: 'SaveTransferLineError',
              content: result.message,
            });
            return;
          }

          this.#SKWNavigationService.goToTransferAfterSave(transfer);
        }),
        map(({ request, transfer }) =>
          transfer && request.transfer.M_Movement_ID === 0
            ? {
                data: {
                  ...this.#SKWContextService.state.data(),
                  tasks: {
                    ...(this.#SKWContextService.state.data()?.tasks ?? {}),
                    AS: [...(this.#SKWContextService.state.data()?.tasks?.AS ?? []), transfer],
                  },
                },
              }
            : undefined
        )
      ),
    });
  }

  #updateLine() {
    if (!this.isProductCorrect()) {
      this.#SKWMessageService.addMessage({
        type: SKWMessageType.ERROR,
        key: 'updateTransferLineError',
        title: 'ProductError',
        content: 'ProductErrorMsg',
      });
      return;
    }

    this.#SKWContextService.newAction({
      type: SKWStateActionType.SAVE,
      isLoading: true,
      source: this.#SKWDataService.updateTransferLine(this.data(), this.inputData()).pipe(
        tap((result) => {
          if (result?.error) {
            this.#SKWMessageService.addMessage({
              type: SKWMessageType.ERROR,
              key: 'updateTransferLineError',
              title: 'SaveTransferLineError',
              content: result.error,
            });
            return;
          }

          if (result?.data) {
            this.data.set(result.data);
            this.#SKWNavigationService.selectLine(undefined);
          }
        }),
        map(() => undefined)
      ),
    });
  }

  cancel(event: MouseEvent) {
    this.isOpen.set(false);
  }

  changeValue(key: keyof SKWTransferLineFormData, value: unknown) {
    this.inputData.update((d) => ({ ...d, [key]: value }));
  }
}

<span
  #container
  [ngClass]="[
    'p-calendar',
    showIcon ? 'p-calendar-w-btn' : '',
    timeOnly ? 'p-calendar-timeonly' : '',
    styleClass || ''
  ]"
  [ngStyle]="style">
  @if (!inline) {
    @if (isLabelDisplay && label !== undefined && label !== null && label.trim().length > 0) {
      <label for="float-input" [ngClass]="{ 'iu-field-only-label': isFieldOnly }" [title]="label">
        {{ label | textLimit: this.el.nativeElement.firstElementChild.firstElementChild.clientWidth / 7 }}
      </label>
    }
    <div class="calendar-input-container">
      <input
        #inputfield
        (blur)="onInputBlur($event)"
        (change)="onInputBlur($event)"
        (click)="onInputClick()"
        (focus)="!readonlyInput ? onInputFocus($event) : null"
        (input)="onUserInput($any($event))"
        (keydown)="onInputKeydown($event)"
        (mousedown)="cancelFocus($event)"
        [attr.data-cy-columnName]="columnName"
        [attr.id]="inputId"
        [attr.name]="name"
        [attr.required]="required"
        [attr.tabindex]="tabindex"
        [attr.value]="inputFieldValue"
        [disabled]="readonlyInput"
        [ngClass]="['p-inputtext ui-corner-all', mandatoryCss || '', inputStyleClass || '']"
        [ngStyle]="inputStyle"
        [placeholder]="placeholder || ''"
        [readonly]="readonlyInput"
        [style.width]="'calc(100% - ' + (hasTodayBtn && !timeOnly ? 26 : 0) + 'px - ' + (showIcon ? 26 : 0) + 'px)'"
        [tabindex]="readonlyInput ? '-1' : null"
        autocomplete="no"
        data-cy="input-text"
        tabindex="-1"
        type="text" />
      @if (hasTodayBtn && !timeOnly) {
        <i
          type="button"
          (click)="!readonlyInput && onTodayModeChange(!calendarConfig.todayMode)"
          [ngClass]="[
            disabled ? 'p-disabled' : '',
            calendarConfig.todayMode ? 'icon-today-full' : 'icon-today',
            'iconeCalendar'
          ]"
          tabindex="-1"
          disabled="readonlyInput"
          aria-hidden="true"
          data-cy="today-mode"
          [attr.data-cy-today-mode]="calendarConfig.todayMode ? 'on' : 'off'"></i>
      }
      @if (showIcon) {
        <i
          type="button"
          (click)="!readonlyInput && onButtonClick($event, inputfield)"
          [ngClass]="[
            timeOnly ? 'icon-hours' : 'icon-calendar',
            disabled || calendarConfig.todayMode ? 'p-disabled' : '',
            'iconeCalendar p-datepicker-trigger'
          ]"
          tabindex="-1"
          disabled="readonlyInput"
          aria-hidden="true"></i>
      }
    </div>
  }

  @if ((inline || overlayVisible) && calendarConfig.todayMode) {
    <div
      [ngStyle]="panelStyle"
      style="top: 48px"
      [ngClass]="[
        'p-datepicker ui-corner-all',
        inline ? 'p-datepicker-inline' : 'ui-shadow',
        disabled ? 'p-disabled' : '',
        timeOnly ? 'p-datepicker-timeonly' : '',
        this.numberOfMonths > 1 ? 'p-datepicker-multiple-month' : '',
        view === 'month' ? 'p-datepicker-monthpicker' : '',
        touchUI ? 'p-datepicker-touch-ui' : '',
        panelStyleClass || ''
      ]"
      [@overlayAnimationCalendar]="
        touchUI
          ? {
              value: 'visibleTouchUI',
              params: { showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions }
            }
          : {
              value: 'visible',
              params: { showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions }
            }
      "
      [@.disabled]="inline === true"
      (@overlayAnimationCalendar.start)="onOverlayAnimationStart($event)"
      (@overlayAnimationCalendar.done)="onOverlayAnimationDone($event)">
      <ng-container
        [ngTemplateOutlet]="todayModeSuggestionsTpl"
        [ngTemplateOutletContext]="{ $implicit: todayModeSuggestions }" />
    </div>
  }
  @if ((inline || overlayVisible) && !calendarConfig.todayMode) {
    <div
      [ngStyle]="panelStyle"
      style="top: 48px"
      [ngClass]="[
        'p-datepicker ui-corner-all',
        inline ? 'p-datepicker-inline' : 'ui-shadow',
        disabled ? 'p-disabled' : '',
        timeOnly ? 'p-datepicker-timeonly' : '',
        this.numberOfMonths > 1 ? 'p-datepicker-multiple-month' : '',
        view === 'month' ? 'p-datepicker-monthpicker' : '',
        touchUI ? 'p-datepicker-touch-ui' : '',
        panelStyleClass || ''
      ]"
      [@overlayAnimationCalendar]="
        touchUI
          ? {
              value: 'visibleTouchUI',
              params: { showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions }
            }
          : {
              value: 'visible',
              params: { showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions }
            }
      "
      [@.disabled]="inline === true"
      (@overlayAnimationCalendar.start)="onOverlayAnimationStart($event)"
      (@overlayAnimationCalendar.done)="onOverlayAnimationDone($event)">
      @if (!timeOnly) {
        @for (month of months; track i; let i = $index) {
          <div class="p-datepicker-group">
            <div class="p-datepicker-header ui-corner-all">
              <ng-content select="p-header" />
              @if (i === 0) {
                <a
                  class="p-datepicker-prev ui-corner-all"
                  href="#"
                  (click)="$event.preventDefault(); navBackward($event)"
                  tabindex="-1">
                  <span class="pi pi-chevron-left"></span>
                </a>
              }
              @if (numberOfMonths === 1 ? true : i === numberOfMonths - 1) {
                <a
                  class="p-datepicker-next ui-corner-all"
                  href="#"
                  (click)="$event.preventDefault(); navForward($event)"
                  tabindex="-1">
                  <span class="pi pi-chevron-right"></span>
                </a>
              }
              <div class="p-datepicker-title">
                @if (!monthNavigator && view !== 'month') {
                  <span class="p-datepicker-month">{{ locale.monthNames[month.month] }}</span>
                }
                @if (monthNavigator && view !== 'month' && numberOfMonths === 1) {
                  <select
                    tabindex="-1"
                    class="p-datepicker-month"
                    (click)="$event.stopPropagation()"
                    (change)="onMonthDropdownChange($any($event).target.value)">
                    @for (monthName of locale.monthNames; track j; let j = $index) {
                      <option [value]="j" [selected]="j === month.month">
                        {{ monthName }}
                      </option>
                    }
                  </select>
                }
                @if (yearNavigator && numberOfMonths === 1) {
                  <select
                    tabindex="-1"
                    class="p-datepicker-year"
                    (click)="$event.stopPropagation()"
                    (change)="onYearDropdownChange($any($event).target.value)">
                    @for (year of yearOptions; track $index) {
                      <option [value]="year" [selected]="year === currentYear">
                        {{ year }}
                      </option>
                    }
                  </select>
                }
                @if (!yearNavigator) {
                  <span class="p-datepicker-year">{{ view === 'month' ? currentYear : month.year }}</span>
                }
              </div>
            </div>
            @if (view === 'date') {
              <div class="p-datepicker-calendar-container">
                <table class="p-datepicker-calendar" aria-label="datepicker calendar table">
                  <thead>
                    <tr>
                      @for (weekDay of weekDays; track $index; let begin = $first; let end = $last) {
                        <th scope="col">
                          <span>{{ weekDay }}</span>
                        </th>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    @for (week of month.dates; track $index) {
                      <tr>
                        @for (date of $any(week); track $index) {
                          <td
                            [ngClass]="[
                              date.otherMonth ? 'p-datepicker-other-month' : '',
                              isSelected(date) ? 'p-datepicker-current-day' : '',
                              date.today ? 'p-datepicker-today' : ''
                            ]">
                            @if (date.otherMonth ? showOtherMonths : true) {
                              @if (date.selectable) {
                                <a
                                  [ngClass]="[isSelected(date) ? 'p-highlight' : '', date.today ? 'p-highlight' : '']"
                                  (click)="onDateSelect($event, date)"
                                  draggable="false"
                                  tabindex="-1">
                                  @if (!dateTemplate) {
                                    {{ date.day }}
                                  } @else {
                                    <ng-container
                                      [ngTemplateOutlet]="dateTemplate"
                                      [ngTemplateOutletContext]="{ $implicit: date }" />
                                  }
                                </a>
                              } @else {
                                <span class="p-disabled"> {{ date.day }} </span>
                              }
                            }
                          </td>
                        }
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            }
          </div>
        }
        @if (view === 'month') {
          <div class="p-monthpicker">
            @for (m of monthPickerValues(); track i; let i = $index) {
              <a
                href="#"
                (click)="onMonthSelect($event, i)"
                [ngClass]="[isMonthSelected(i) ? 'p-highlight' : '', 'p-monthpicker-month']"
                tabindex="-1">
                {{ m }}
              </a>
            }
          </div>
        }
      }

      @if (showTime || timeOnly) {
        <div class="p-timepicker ui-corner-all">
          <div class="p-hour-picker">
            <a
              (mousedown)="onTimePickerElementMouseDown($event, 0, 1)"
              (mouseup)="onTimePickerElementMouseUp($event)"
              tabindex="-1">
              <span class="pi pi-chevron-up"></span>
            </a>
            <span [ngStyle]="{ display: currentHour < 10 ? 'inline' : 'none' }">0</span>
            <span>{{ currentHour }}</span>
            <a
              tabindex="-1"
              (mousedown)="onTimePickerElementMouseDown($event, 0, -1)"
              (mouseup)="onTimePickerElementMouseUp($event)">
              <span class="pi pi-chevron-down"></span>
            </a>
          </div>
          <div class="p-separator">
            <a tabindex="-1">
              <span class="pi pi-chevron-up"></span>
            </a>
            <span>{{ timeSeparator }}</span>
            <a tabindex="-1">
              <span class="pi pi-chevron-down"></span>
            </a>
          </div>
          <div class="p-minute-picker">
            <a
              tabindex="-1"
              (mousedown)="onTimePickerElementMouseDown($event, 1, 1)"
              (mouseup)="onTimePickerElementMouseUp($event)">
              <span class="pi pi-chevron-up"></span>
            </a>
            <span [ngStyle]="{ display: currentMinute < 10 ? 'inline' : 'none' }">0</span>
            <span>{{ currentMinute }}</span>
            <a
              tabindex="-1"
              (mousedown)="onTimePickerElementMouseDown($event, 1, -1)"
              (mouseup)="onTimePickerElementMouseUp($event)">
              <span class="pi pi-chevron-down"></span>
            </a>
          </div>
          @if (showSeconds) {
            <div class="p-separator">
              <a tabindex="-1">
                <span class="pi pi-chevron-up"></span>
              </a>
              <span>{{ timeSeparator }}</span>
              <a tabindex="-1">
                <span class="pi pi-chevron-down"></span>
              </a>
            </div>
            <div class="p-second-picker">
              <a
                tabindex="-1"
                (mousedown)="onTimePickerElementMouseDown($event, 2, 1)"
                (mouseup)="onTimePickerElementMouseUp($event)">
                <span class="pi pi-chevron-up"></span>
              </a>
              <span [ngStyle]="{ display: currentSecond < 10 ? 'inline' : 'none' }">0</span>
              <span>{{ currentSecond }}</span>
              <a
                tabindex="-1"
                (mousedown)="onTimePickerElementMouseDown($event, 2, -1)"
                (mouseup)="onTimePickerElementMouseUp($event)">
                <span class="pi pi-chevron-down"></span>
              </a>
            </div>
          }
          @if (hourFormat === '12') {
            <div class="p-ampm-picker">
              <a tabindex="-1" (click)="toggleAMPM($event); $event.stopPropagation()">
                <span class="pi pi-chevron-up"></span>
              </a>
              <span>{{ pm ? 'PM' : 'AM' }}</span>
              <a tabindex="-1" (click)="toggleAMPM($event); $event.stopPropagation()">
                <span class="pi pi-chevron-down"></span>
              </a>
            </div>
          }
        </div>
      }
      @if (showButtonBar) {
        <div class="p-datepicker-buttonbar">
          <div class="p-grid">
            <div class="p-col-6">
              <button
                tabindex="-1"
                type="button"
                [label]="_locale.today"
                (click)="onTodayButtonClick($event)"
                style="width: 100%"
                pButton
                [ngClass]="[todayButtonStyleClass || '', 'p-button-primary']"></button>
            </div>
            <div class="p-col-6">
              <button
                tabindex="-1"
                type="button"
                [label]="_locale.clear"
                (click)="onClearButtonClick($event)"
                style="width: 100%"
                pButton
                [ngClass]="[clearButtonStyleClass || '', 'p-button-alert']"></button>
            </div>
            @if (showTime || timeOnly) {
              <div class="p-col-6">
                <button
                  tabindex="-1"
                  type="button"
                  [label]="'OK'"
                  (click)="onOKButtonClick($event)"
                  style="width: 100%"
                  pButton
                  [ngClass]="[todayButtonStyleClass || '', 'p-button-info']"></button>
              </div>
            }
          </div>
        </div>
      }
      <ng-content select="p-footer" />
    </div>
  }
</span>

<ng-template #todayModeSuggestionsTpl let-suggestions>
  @if (suggestions.length > 0) {
    <div class="p-calendar-today-suggestions">
      @for (suggestion of suggestions; track $index) {
        <a tabindex="-1" class="p-calendar-today-suggestions-item" (click)="onSuggestionClick($event, suggestion)">
          {{ suggestion }}
        </a>
      }
    </div>
  }
</ng-template>

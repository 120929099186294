import { AsyncPipe, NgClass } from '@angular/common';
import { Component, computed, inject, input, output, signal } from '@angular/core';
import { CustomerProfileData } from '@iupics-components/specific/window/customer-360/components/customer-profile/customer-profile-data';
import InputStarsUiComponent from '@iupics-components/standard/fields/input-stars-ui/input-stars-ui.component';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { TranslateModule } from '@ngx-translate/core';
import { DocServerUrlPipe } from '../../../../../../iupics-util/pipes/doc-server-url/doc-server-url.pipe';
import { LoadingDirective } from '../../../../../directives/loading.directive';

@Component({
  selector: 'iu-vendor-profile',
  standalone: true,
  imports: [NgClass, LoadingDirective, InputStarsUiComponent, AsyncPipe, TranslateModule, DocServerUrlPipe],
  templateUrl: './vendor-profile.component.html',
  styleUrl: './vendor-profile.component.scss',
})
export default class VendorProfileComponent {
  protected uiCreator = inject(UICreatorService);

  data = input.required<CustomerProfileData>();

  editButtonEmitter = output<MouseEvent>();

  isLoading = signal(true);
  imgError = signal(false);

  hasImg = computed(() => (this.data()?.profilePicture?.trim()?.length ?? 0) > 0);

  loadingState: boolean;

  editBtnClick(event: MouseEvent) {
    this.editButtonEmitter.emit(event);
  }

  changeLoadingState(state: boolean) {
    this.isLoading.set(state);
  }

  changeImgError(state?: boolean) {
    this.imgError.update((v) => state ?? !v);
  }
}

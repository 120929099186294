import { NgClass } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { LoadingDirective } from '../../../../../directives/loading.directive';
import { QuickActionData, QuickActionSubData } from './quick-action-data';

@Component({
  selector: 'iu-quick-action',
  templateUrl: './quick-action.component.html',
  styleUrls: ['./quick-action.component.scss'],
  standalone: true,
  imports: [LoadingDirective, NgClass],
})
export default class QuickActionComponent implements OnInit, AfterViewInit {
  @Input() data: QuickActionData;

  @Output() clickEmitter = new EventEmitter<QuickActionData | QuickActionSubData>();

  @ViewChild('qaElement', { read: ElementRef<HTMLElement> }) qaElement: ElementRef<HTMLElement>;

  isExpanded: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.handleEvents();
  }

  changeExpansionState(value: boolean) {
    if (this.data?.subActions?.length > 0 && !this.data?.readonly) {
      this.isExpanded = value;
    }
  }

  applyFunction(target: QuickActionSubData | QuickActionSubData) {
    if (this.data?.readonly) {
      return;
    }

    if (this.data?.action) {
      this.data.action(target);
    } else {
      this.clickEmitter.emit(this.data);
    }

    this.changeExpansionState(false);
  }

  @HostListener('window:resize', ['$event'])
  handleEvents() {
    if (window.innerWidth >= 1024) {
      this.handleMouseEvents();
    } else {
      this.handleTouchEvents();
    }
  }

  handleMouseEvents() {
    let timer = null;
    const duration = 0;

    this.qaElement.nativeElement.onmouseenter = () => {
      if (timer) {
        clearTimeout(timer);
      }

      this.changeExpansionState(true);
    };

    this.qaElement.nativeElement.onmouseleave = () => {
      timer = setTimeout(() => this.changeExpansionState(false), duration);
    };
  }

  handleTouchEvents() {
    let timer = null;
    const touchDuration = 200;

    this.qaElement.nativeElement.ontouchstart = () => {
      timer = setTimeout(() => {
        this.changeExpansionState(true);
        window.navigator.vibrate(10);
      }, touchDuration);
    };

    this.qaElement.nativeElement.ontouchend = () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }
}

<span
  [style.display]="displayCss"
  [life]="3000"
  [hideDelay]="250"
  pTooltip="{{ tooltip }}"
  [escape]="false"
  [showDelay]="500"
  tooltipPosition="top"
  tooltipZIndex="2100"
  [style.margin-top.px]="data && data.marginTop > 0 ? data.marginTop : null"
  [style.backgroundColor]="backgroundColorCss">
  @if (isLabelDisplay && label !== undefined && label !== null && label.trim().length > 0) {
    <label [style.font-size]="overridedCSS?.labelFontSize" [ngClass]="{ 'iu-field-only-label': data?.isFieldOnly }">
      {{ label | textLimit: this.elementRef.nativeElement.clientWidth / 7 }}
    </label>
  }
  <div
    [ngClass]="[
      'input-json-body',
      (!chips || !chips.length) && data.isMandatory ? 'mandatory' : '',
      isReadOnly ? 'disabled' : ''
    ]">
    <div [ngClass]="['json-chip-list']">
      @for (chip of chips; track i; let i = $index) {
        <div class="json-chip">
          <span class="json-chip-label" (click)="showCreationPanel($event, chip)">
            @if (fieldDisplayeds?.length === 1) {
              <p>{{ chip[fieldDisplayeds[0].name] }}</p>
            } @else {
              @for (fieldDisplayed of fieldDisplayeds; track $index) {
                <p class="multiLabel">{{ fieldDisplayed.nameToShow }} = {{ chip[fieldDisplayed.name] }}</p>
              }
            }
          </span>
          <span class="remove-json-chip" (click)="removeJsonChip($event, i)">x</span>
        </div>
      }
      <div
        class="json-input"
        [ngStyle]="!this.uniqueProp || (!hasMultiValues && chips.length == 1) ? { display: 'none' } : {}">
        <input
          #inputtext
          type="text"
          [ngStyle]="this.focused && inputtext.value.trim() !== '' ? { width: '100%' } : {}"
          [attr.placeholder]="chips && chips.length ? null : placeholder"
          (keydown)="onKeydown($event)"
          (focus)="!isReadOnly ? onInputFocus($event) : null"
          [tabindex]="isReadOnly ? '-1' : null"
          (blur)="onInputBlur($event)"
          [readonly]="isReadOnly"
          [disabled]="isReadOnly"
          [ngStyle]="inputStyle"
          [ngClass]="[
            'chip-input',
            chips?.length > 0 && inputtext.value.trim() === '' ? 'chip-input-small' : '',
            isNotAuthorized ? 'redText' : ''
          ]"
          autocomplete="no"
          data-cy="input-json"
          [attr.data-cy-columnName]="columnName" />
      </div>
    </div>
    @if (!this.uniqueProp && !isReadOnly && (hasMultiValues || chips.length == 0)) {
      <button
        pButton
        type="button"
        class="button p-button-secondary add-json-chip"
        icon="icon-add"
        (click)="showCreationPanel($event)"></button>
    }
  </div>
  @if (hasConflict && dataStored) {
    <i
      #spanInput
      class="rtconflict fas fa-exclamation-triangle iconClass"
      (click)="showConflictPanel($event)"
      aria-hidden="true"></i>
  }

  @if (showValuePrefPanel) {
    <iu-prime-overlay #opValuePref [dismissable]="false" appendTo="body">
      <iu-value-preference-panel
        [overlayPanel]="opValuePref"
        [fieldValue]="fieldValue"
        [dataStored]="this.dataStored"
        [sourceComponent]="this"
        [data]="this.data"
        (closeEmitter)="toggleValuePreferencePanel($event)" />
    </iu-prime-overlay>
  }
</span>

<iu-prime-overlay #opCreation appendTo="body">
  <div class="vcrJsonCreationContainer">
    <div #jsonFieldsContainer class="jsonFields">
      <ng-template #vcrCreation />
    </div>

    @if (isFree) {
      <div class="inputjson-split-button">
        <div class="inputjson-split-button-toggle-button" [ngClass]="{ 'split-button-open': isSplitButtonOpen }">
          <button
            pButton
            type="button"
            class="button p-button-secondary add-panel-line"
            icon="icon-add"
            [label]="'inputJson.addString' | translate"
            (click)="addLineToPanel($event)"></button>
          <button
            pButton
            type="button"
            class="button p-button-secondary add-panel-line"
            (click)="isSplitButtonOpen = !isSplitButtonOpen"
            [icon]="isSplitButtonOpen ? 'icon-open' : 'icon-next'"></button>
        </div>
        @if (isSplitButtonOpen) {
          <div class="inputjson-split-button-list">
            <button
              pButton
              class="p-col-12 p-button p-button-info"
              (click)="addLineToPanel($event, true)"
              icon="icon-add"
              [label]="'inputJson.addObject' | translate"></button>
          </div>
        }
      </div>
    }
  </div>
</iu-prime-overlay>

<iu-prime-overlay #opConflict>
  <a class="data-conflict" (click)="chooseData($event, (dataStored?.data)[data.columnName], true); opConflict.hide()">{{
    (dataStored?.data)[data.columnName]
  }}</a>
  |
  <a class="data-conflict" (click)="chooseData($event, conflictedData, false); opConflict.hide()">{{
    conflictedData
  }}</a>
</iu-prime-overlay>

import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  HostBinding,
  input,
  model,
  output,
  viewChild,
} from '@angular/core';
import { StorekeeperButtonComponent } from '../storekeeper-button/storekeeper-button.component';

@Component({
  selector: 'iu-storekeeper-window-panel',
  standalone: true,
  imports: [StorekeeperButtonComponent],
  templateUrl: './storekeeper-window-panel.component.html',
  styleUrl: './storekeeper-window-panel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StorekeeperWindowPanelComponent {
  panel = viewChild<ElementRef>('panel');

  @HostBinding('style.--_panel-height')
  get _panelHeight() {
    return `${this.panel()?.nativeElement?.getBoundingClientRect()?.height}px`;
  }

  isOpen = model(false);
  displayDefaultButtons = model(true);

  title = input<string>(undefined);
  hasTitle = computed(() => this.title()?.length > 0);

  cancelButtonLabel = input('Annuler'); // TODO: translate
  cancel = output<MouseEvent>();
  confirmButtonLabel = input('Confirmer'); // TODO: translate
  confirm = output<MouseEvent>();

  onCancel(event: MouseEvent) {
    this.cancel.emit(event);
  }

  onConfirm(event: MouseEvent) {
    this.confirm.emit(event);
  }
}

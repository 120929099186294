<div class="cards-body">
  <div class="cards-container" [attr.data-cards-container]="uuid">
    @if (cardsDisplayed.length > 0) {
      <button
        class="cards-button"
        data-position="left"
        type="button"
        [disabled]="cardsElement[0] === cardsIntoView[0]"
        (click)="prev()"
        data-small>
        <i class="icon-back" aria-hidden="true"></i>
      </button>
    }
    <div class="cards-wrapper" data-cards-wrapper>
      @for (card of cardsDisplayed; track $index) {
        <div class="card-container" [attr.data-card]="$index" (click)="clickCard(card)">
          @switch (type) {
            @case ('A') {
              <iu-address-card [content]="card" />
            }
            @case ('C') {
              <iu-contact-card [content]="card" />
            }
          }
        </div>
      } @empty {
        <h3 class="title-no-data">{{ 'cardsUi.noData' | translate }}</h3>
      }
    </div>
    @if (cardsDisplayed.length > 0) {
      <button
        class="cards-button"
        data-position="right"
        type="button"
        [disabled]="cardsElement[cardsElement?.length - 1] === cardsIntoView[cardsIntoView?.length - 1]"
        (click)="next()"
        data-small>
        <i class="icon-next" aria-hidden="true"></i>
      </button>
    }
  </div>
  @if (cardsDisplayed?.length !== cards?.length) {
    <button class="cards-button" type="button" data-large (click)="addMoreCards()">
      {{ 'cardsUi.cardsPlusBtn' | translate }}
    </button>
  }
</div>

import { ChangeDetectionStrategy, Component, ElementRef, signal, viewChild } from '@angular/core';

@Component({
  selector: 'iu-process-logs-ui',
  templateUrl: './process-logs-ui.component.html',
  styleUrls: ['./process-logs-ui.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class ProcessLogsUiComponent {
  logsContainerElt = viewChild('logsContainer', { read: ElementRef });
  logs = signal([]);

  addLog(...logs: string[]) {
    this.logs.update((previous) => [...previous, ...logs]);
    const el = this.logsContainerElt();
    if (el) setTimeout(() => this.#goToBottom(el.nativeElement), 50);
  }

  clearLogs() {
    this.logs.set([]);
  }

  #goToBottom(el: HTMLElement) {
    el.scrollTop = el.scrollHeight - el.clientHeight;
  }
}

import { animate, state, style, transition, trigger } from '@angular/animations';
import { DOCUMENT, NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { Global } from '@iupics-manager/models/global-var';
import { OverlayService, PrimeNGConfig } from 'primeng/api';
import { DomHandler } from 'primeng/dom';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ZIndexUtils } from 'primeng/utils';
@Component({
  selector: 'iu-prime-overlay',
  animations: [
    trigger('animation', [
      state(
        'void',
        style({
          transform: 'scaleY(0.8)',
          opacity: 0,
        })
      ),
      state(
        'close',
        style({
          opacity: 0,
        })
      ),
      state(
        'open',
        style({
          transform: 'translateY(0)',
          opacity: 1,
        })
      ),
      transition('void => open', animate('{{showTransitionParams}}')),
      transition('open => close', animate('{{hideTransitionParams}}')),
    ]),
  ],
  styleUrls: ['./prime-overlay.component.scss'],
  templateUrl: './prime-overlay.component.html',
  standalone: true,
  imports: [NgClass, NgStyle, NgTemplateOutlet],
})
export default class PrimeOverlayComponent extends OverlayPanel implements AfterViewInit, OnDestroy {
  @Input() appendTo: any = 'body';
  @Input() handleAlign: (context: PrimeOverlayComponent) => void;
  @Input() fixedZIndex = false;

  backdropZIndex = -1;

  constructor(
    @Inject(DOCUMENT) document: Document,
    @Inject(PLATFORM_ID) platformId: any,
    public el: ElementRef,
    public renderer: Renderer2,
    cd: ChangeDetectorRef,
    zone: NgZone,
    public config: PrimeNGConfig,
    public overlayService: OverlayService
  ) {
    super(document, platformId, el, renderer, cd, zone, config, overlayService);
  }

  ngAfterViewInit() {
    if (this.appendTo) {
      if (this.appendTo === 'body') {
        Global.activeOverlays.push(this);
      }
    }

    if (this.fixedZIndex && this.container) {
      this.container.style.zIndex = this.baseZIndex ? `${this.baseZIndex}` : '2500';
    }
  }

  hide() {
    ZIndexUtils.clear(this.container);
    this.overlayVisible = false;
    this.render = false;
    this.cd.markForCheck();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (Global.activeOverlays && Global.activeOverlays.length > 0) {
      const index = Global.activeOverlays.indexOf(this);
      if (index > -1) {
        Global.activeOverlays.splice(index, 1);
      }
    }
    ZIndexUtils.clear(this.container);
  }

  positionAbsolute() {
    const element = this.container;
    const target = this.target;

    const elementDimensions = element.offsetParent
      ? { width: element.offsetWidth, height: element.offsetHeight }
      : DomHandler.getHiddenElementDimensions(element);

    const elementOuterHeight = elementDimensions.height;
    const elementOuterWidth = elementDimensions.width;
    const targetOuterHeight = target.offsetHeight;
    const targetOuterWidth = target.offsetWidth;
    const targetOffset = target.getBoundingClientRect();
    const windowScrollTop = DomHandler.getWindowScrollTop();
    const windowScrollLeft = DomHandler.getWindowScrollLeft();
    const viewport = DomHandler.getViewport();

    let top: number, left: number;

    // Custom handling for left out of the viewport
    if (element?.children[0]?.children[0]) {
      const contentElement: HTMLDivElement = <HTMLDivElement>element?.children[0]?.children[0];
      const contentElementDimensions = contentElement.offsetParent
        ? { width: contentElement.offsetWidth, height: contentElement.offsetHeight }
        : DomHandler.getHiddenElementDimensions(contentElement);

      const contentHeight = contentElementDimensions?.height || 0;
      const contentWidth = contentElementDimensions?.width || 0;

      top = targetOuterHeight + targetOffset.top + windowScrollTop;

      // Check if the content height extends beyond the viewport
      if (contentHeight + top > viewport.height) {
        element.style.transformOrigin = 'bottom';
        top -= elementOuterHeight;

        // Adjust top position to stay within viewport
        if (targetOffset.top - contentHeight >= 0) top = targetOffset.top - contentHeight;
        else top = windowScrollTop;
      } else {
        element.style.transformOrigin = 'top';
      }

      // Check if the element overflows on the right
      if (targetOffset.left + elementOuterWidth > viewport.width)
        left = Math.max(0, targetOffset.left + windowScrollLeft + targetOuterWidth - elementOuterWidth);
      else {
        left = targetOffset.left + windowScrollLeft;
        if (contentWidth > left) left = contentWidth;
      }

      // Check if the element overflows at the bottom
      if (targetOffset.top + elementOuterHeight > viewport.height)
        top = Math.max(0, windowScrollTop + viewport.height - elementOuterHeight);

      element.style.top = top + 'px';
      element.style.left = left + 'px';
    }

    // Fullscreen handling if the element is out of the viewport
    if (
      Global.isMobile() &&
      (left + elementOuterWidth > viewport.width || top + elementOuterHeight > viewport.height)
    ) {
      element.style.top = '0px';
      element.style.left = '0px';
      element.style.width = '100%';
      element.style.height = '100%';
    }
  }

  align(): void {
    if (this.autoZIndex) {
      ZIndexUtils.set('overlay', this.container, this.baseZIndex + this.config.zIndex.overlay);
      this.backdropZIndex = ZIndexUtils.get(this.container) - 1;
    }
    this.positionAbsolute();

    const containerOffset = DomHandler.getOffset(this.container);
    const targetOffset = DomHandler.getOffset(this.target);
    let arrowLeft = 0;

    if (containerOffset.left < targetOffset.left) {
      arrowLeft = targetOffset.left - containerOffset.left;
    }
    this.container?.style.setProperty('--overlayArrowLeft', `${arrowLeft}px`);

    if (containerOffset.top < targetOffset.top) {
      DomHandler.addClass(this.container, 'p-overlaypanel-flipped');

      if (this.showCloseIcon) {
        this.renderer.setStyle(this.container, 'margin-top', '-30px');
      }
    }

    if (this.handleAlign) this.handleAlign(this);
  }
}

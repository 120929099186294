import { TypeEnum } from '@iupics-util/models/types.util';
import { IAutocomplete } from '@iupics/apiz-grid';
import { Observable } from 'rxjs';

export type SKWPage = 'list' | 'inventory' | 'transfer' | 'new_transfer' | 'transfer_line';
export const SKWPage: TypeEnum<SKWPage> = {
  LIST: 'list',
  INVENTORY: 'inventory',
  TRANSFER: 'transfer',
  NEW_TRANSFER: 'new_transfer',
  TRANSFER_LINE: 'transfer_line',
};

export type SKWListTabType = 'tasks' | 'transfers';
export const SKWListTabType: TypeEnum<SKWListTabType> = {
  TASKS: 'tasks',
  TRANSFERS: 'transfers',
};

export type SKWTaskStatus = 'CO' | 'AS';
export const SKWTaskStatus: TypeEnum<SKWTaskStatus> = {
  AS: 'AS',
  CO: 'CO',
};

export type SKWLineStatus = 'TL' | 'UL';
export const SKWLineStatus: TypeEnum<SKWLineStatus> = {
  TL: 'TL',
  UL: 'UL',
};

export type SKWTransferHeaderData = {
  AD_Client_ID?: {
    id: number;
    displayValue: string;
  };
  AD_Org_ID?: {
    id: number;
    displayValue: string;
  };
  M_Movement_ID: number; // id
  Data_UUID?: string;
  DocumentNo?: string;
  transferNO?: string;
  Storekeeper_ID: number | null; // AD_User_ID
  CreatedBy?: {
    id: number;
    displayValue: string;
  };
  Updated?: string;
  Created?: string;
  EndDate?: string;
  DocStatus?: string;
  isEX?: string;
  isOF?: string;
  isPalette?: string;
  isRA?: string;
  Priority?: string;
  Description?: string | undefined;
  comment?: string | undefined;
};

export type SKWTransferDetail = {
  lines: SKWTransferLineData[] | undefined;
  emplacement: string; // id displayValue
  palette: string; // id displayValue
};

export type SKWTransferData = SKWTransferHeaderData & SKWTransferDetail;
export type SKWTransferDataPartialDetails = SKWTransferHeaderData & Partial<SKWTransferDetail>;

export type SKWTransferLineFormData = {
  locator: IAutocomplete;
  palette?: IAutocomplete;
  Product: IAutocomplete;
  Qty: number;
};

export type SKWTransferLineData = {
  AD_Client_ID?: number;
  AD_Org_ID?: number;
  comment?: string;
  Created?: string;
  CreatedBy?: number;
  Description?: string;
  Product_Description?: string;
  DocStatus?: string;
  Line?: number;
  M_Movement_ID: number;
  M_MovementLine_ID?: any;
  transferNO?: string;
  Updated?: string;
  locator_source_barcode?: string;
  locator_destination_barcode?: string;
  palette_destination_barcode?: null;
  palette_source_barcode?: null;
  product_barcode?: string;
  M_Product_ID: IAutocomplete;
  locator_source: IAutocomplete;
  locator_destination: IAutocomplete;
  palette_source?: IAutocomplete;
  palette_destination?: IAutocomplete;
  Qty: number;
};

export type SKWInventoryLine = {
  M_Locator_ID: IAutocomplete;
  M_Product_ID: IAutocomplete;
  Quantity: number;
};

//#region State
export type SKWStateActionType = 'refresh' | 'save' | 'add' | 'assign' | 'remove' | 'get' | 'get_more';

export const SKWStateActionType: TypeEnum<SKWStateActionType> = {
  REFRESH: 'refresh',
  SAVE: 'save',
  ADD: 'add',
  ASSIGN: 'assign',
  REMOVE: 'remove',
  GET: 'get',
  GET_MORE: 'get_more',
};

export type SKWStateAction = {
  type: SKWStateActionType;
  source?: Observable<Partial<SKWContextState> | undefined>;
  clearData?: boolean;
  after?: (state: SKWContextState) => void;
  isLoading: boolean;
};

export type SKWListData<T = SKWTransferDataPartialDetails[]> = {
  tasks?: {
    CO?: T;
    AS?: T;
  };
  transfers?: T;
};

export type SKWListDataParams = SKWListData<{
  nextStartRow: number;
  endRow: number;
}>;

export type SKWDataListParams = {
  startRow: number;
  endRow: number;
};

export type SKWContextStateData = {
  data: SKWListData | undefined;
  params: SKWListDataParams;
};

export type SKWContextState = SKWContextStateData & {
  pending: SKWStateActionType;
  loaded: boolean;
  error: string | undefined;
};
//#endregion

export type SKWFilter = {
  label: string;
  value: string;
};

import { Component, forwardRef } from '@angular/core';
import InputTextUiComponent from '@iupics-components/standard/fields/input-text-ui/input-text-ui.component';
import { CellEditorComp, CellEditorParams } from '@iupics/apiz-grid';

@Component({
  selector: 'iu-text-editor',
  template: `
    <iu-input-text-ui
      [cssClass]="'textEditor'"
      [isStandalone]="true"
      [isLabelDisplay]="false"
      [fieldValue]="this.fieldValue"
      [data]="this.templates"
      (fieldValueChange)="onFieldModified($event)"
      (enterKey)="onEnter($event)">
    </iu-input-text-ui>
  `,
  styles: [],
  standalone: true,
  imports: [forwardRef(() => InputTextUiComponent)],
})
export default class TextEditorComponent implements CellEditorComp {
  params: CellEditorParams;
  label: string;
  templates: any;
  fieldValue = null;

  gridInit(params: CellEditorParams) {
    this.params = params;
    this.label = this.params.label || null;
    this.templates = this.params.templates;
    if (params.value !== undefined) {
      this.fieldValue = params.value;
    }
  }

  refresh(params: CellEditorParams): boolean {
    if (params.value !== undefined) {
      this.fieldValue = params.value;
    }
    return true;
  }

  getValue() {
    return this.fieldValue;
  }

  onFieldModified(value: any) {
    const oldValue = this.params.row.getData(this.templates.columnName);
    this.params.row.setData({ [this.templates.columnName]: value });

    if (!this.params.row.isSelected()) {
      this.params.row.setSelected(true); // select row
    }

    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: value,
        rowData: this.params.row.getRowData(),
        columnName: this.params.colDef.field,
        oldValue,
        newValue: value,
        // ...something
      };
      this.params.onClick({ ...this.params, ...params });
    }
  }

  onEnter(event: Event) {
    event.stopPropagation();
    (<HTMLElement>event.currentTarget).blur();
  }
}

<div *ngIf="parsedXml" class="xmlContent">
  <div
    *ngFor="let node of nodes"
    [class]="'xmlNode node-level-' + indentLevel"
    [style.color]="childColor"
    [style.marginLeft.px]="indentLevel * 10">
    <ng-container *ngIf="node.nodeType === 1 && node.nodeName != 'html'">
      <div
        (click)="toggleTag(node)"
        class="xmlNodeTag openingTag"
        [ngClass]="node.classList.contains('closed') ? 'pi-chevron-right' : 'pi-chevron-down'">
        &lt;<span class="xmlNodeName">{{ node.tagName }}</span>
        <ng-container *ngIf="node.attributes > 0">
          <div class="xmlNodeAttr" *ngFor="let attribute of node.attributes">
            <ng-container *ngIf="attribute.name != 'class'"
              ><span class="xmlNodeAttrName">{{ ' ' + attribute.name + ' = ' }}</span>
              <span class="xmlNodeAttrValue">{{ '"' + attribute.value + '"' }}</span></ng-container
            >
          </div></ng-container
        >
        &gt;
      </div>
      <div [ngClass]="{ closed: node.classList.contains('closed') }">
        <iu-xml-viewer [node]="node" [indentLevel]="indentLevel"></iu-xml-viewer>
      </div>
      <div
        *ngIf="!node.classList.contains('closed')"
        (click)="toggleTag(node)"
        class="xmlNodeTag"
        [class.parent]="node.children.length > 0">
        &lt;/<span class="xmlNodeName">{{ node.tagName }}</span
        >&gt;
      </div>
    </ng-container>
    <div *ngIf="node?.nodeType == 3" class="xmlNodeText">{{ node.textContent }}</div>
  </div>
</div>
<div *ngIf="!parsedXml">
  <!-- Display error message if any -->
  <p>{{ 'generic.loadingOoo' | translate }}</p>
</div>

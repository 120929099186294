<div
  #specificContainer
  class="iu-specific-window iu-specific-window-container"
  [attr.data-cy-specific-window-name]="name">
  @if (!isModal && isLoading) {
    <p-progressBar [mode]="'indeterminate'" [value]="0" [showValue]="false" [styleClass]="'loading-progressbar'" />
  }
  <h1>{{ name }}</h1>
  <p-scrollPanel [style]="{ width: '100%', height: 'calc(100% - 30px)' }">
    <iu-process-in-progress-ui [activeTab]="activeTab" />
  </p-scrollPanel>
</div>

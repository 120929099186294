<ul class="smartbutton p-grid">
  <ng-container #smartButtonsVCR />
</ul>
<div [ngStyle]="{ display: isMoreButton ? 'inline-block' : 'none' }" class="tabManage">
  <ul class="smartbuttonMore">
    <li>
      <a href="#" class="icon-more-title" (click)="$event.preventDefault()">
        <i class="icon-menu-more" aria-hidden="true"></i>
      </a>
      <ul>
        <ng-container #smartButtonsMoreVCR />
      </ul>
    </li>
  </ul>
</div>

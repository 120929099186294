import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RecentItem } from '@compiere-ws/models/compiere-recent-item';
import { RecentItemsManagerService } from '@iupics-manager/managers/recent-items-manager/recent-items-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsMenuType } from '@web-desktop/models/menu-item-ui';
import { Observable, Subscription } from 'rxjs';
import { AbstractWidgetComponent } from '../../abstract-widget.component';

@Component({
  selector: 'iu-recent-item-widget-ui',
  templateUrl: './recent-item-widget-ui.component.html',
  styleUrls: ['./recent-item-widget-ui.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, AsyncPipe],
})
export default class RecentItemWidgetUiComponent extends AbstractWidgetComponent implements OnInit {
  recentItems$: Observable<RecentItem[]>;
  refresherSub: Subscription;

  constructor(
    private riManager: RecentItemsManagerService,
    uiCreatorService: UICreatorService
  ) {
    super(uiCreatorService);
  }

  ngOnInit() {
    this.recentItems$ = this.riManager.getRecentItems();
  }

  openTab(event: Event, item) {
    let recentItem = item as RecentItem;
    event.stopPropagation();
    const source = {
      id: recentItem.entityKey,
      menuType: IupicsMenuType.WINDOW,
    };
    Global.workspace.openTargetSearch({
      zoomInfo: null,
      cat: { id: recentItem.entityID },
      source,
      ctx: null,
    });
  }
}

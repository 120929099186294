import { Injectable } from '@angular/core';
import { AppConfig } from '@iupics-config/app.config';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class PrinterService {
  private urlPrinter: string;
  constructor(
    private http: ApiService,
    private config: AppConfig
  ) {}

  public getCupsPrinters(): Observable<any> {
    this.urlPrinter = this.config.getBackendResource('printer');
    return this.http.get<any>(`${this.urlPrinter}`);
  }
}
// export interface WsVersion {
//   name: string;
//   image: string;
//   version: string;
//   runningTasks: number;
//   replicas: number;
// }

import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { ContextMenuService } from '@web-desktop/components/workspace/controllers/context-menu/context-menu.service';
import { IupicsComponentType, IupicsContextMenuComponent } from '@web-desktop/models/iupics-context-menu';
import { MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { Steps } from 'primeng/steps';

@Component({
  selector: 'iu-prime-steps',
  templateUrl: './prime-steps.component.html',
  styleUrls: ['./prime-steps.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgClass, NgStyle, NgTemplateOutlet, RouterLink, RouterLinkActive, ButtonModule],
})
export default class PrimeStepsComponent extends Steps implements OnInit, IupicsContextMenuComponent, OnDestroy {
  cdRef: ChangeDetectorRef;
  constructor(
    public cmService: ContextMenuService,
    router: Router,
    route: ActivatedRoute,
    cd: ChangeDetectorRef
  ) {
    super(router, route, cd);
    this.cdRef = cd;
  }

  componentType = IupicsComponentType.STEPPER;

  @Input() isIndexNumber = true;
  @Input() preventPrevious = false;
  @Input() cmEventHandler: Function;
  @Input() showCMChecker: Function = () => false;
  model: Partial<{ [P in keyof MenuItem] }>[];

  ngOnInit() {
    this.cdRef.markForCheck();
  }

  itemClick(event: Event, item: MenuItem, i: number) {
    if (this.readonly || item.disabled || (this.preventPrevious && i < this.activeIndex)) {
      event.preventDefault();
      return;
    }

    this.activeIndexChange.emit(i);
    if (!item.url) {
      event.preventDefault();
    }
    if (item.command) {
      item.command({
        originalEvent: event,
        item: item,
        index: i,
      });
    }
  }

  getDisplayIndex(i: number): number | string {
    return this.isIndexNumber ? i + 1 : String.fromCharCode(i + 65);
  }

  onContextMenu(event: MouseEvent) {
    event.preventDefault();
    this.cmService.showContextMenu(this, this.handleEvent.bind(this), event);
  }

  handleEvent(event: { originalEvent: MouseEvent; item: MenuItem }) {
    if (this.cmEventHandler !== undefined) {
      this.cmEventHandler(event);
    }
  }

  ngOnDestroy() {}
}

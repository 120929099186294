import { Component, inject } from '@angular/core';
import { SKWMessageService } from '../../services/storekeeper-window-message.service';
import { StorekeeperButtonComponent } from '../storekeeper-button/storekeeper-button.component';

@Component({
  selector: 'iu-storekeeper-window-dev-console',
  standalone: true,
  imports: [StorekeeperButtonComponent],
  templateUrl: './storekeeper-window-dev-console.component.html',
  styleUrl: './storekeeper-window-dev-console.component.scss',
})
export class StorekeeperWindowDevConsoleComponent {
  #SKWMessageService = inject(SKWMessageService);

  isMessageActive = this.#SKWMessageService.isMessageActive;

  activateMessage(event: MouseEvent) {
    this.#SKWMessageService.isMessageActive.update((v) => !v);
  }
}

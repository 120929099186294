import { AfterViewInit, Component, OnInit } from '@angular/core';
import AutocompleteUiComponent from '@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component';
import ButtonUiComponent from '@iupics-components/standard/fields/button-ui/button-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { WindowFactoryUtils } from '@iupics-manager/managers/ui-creator/window-factory/window-factory-utils';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsEvent } from '@iupics-manager/models/iupics-event';
import { ProcessUI } from '@iupics-manager/models/processUI';
import { DynamicContainerDirective } from '../../../../iupics-util/directives/dynamic-container.directive';

@Component({
  selector: 'iu-row-ui',
  templateUrl: './row-ui.component.html',
  styleUrls: ['./row-ui.component.css'],
  standalone: true,
  imports: [DynamicContainerDirective],
})
export default class RowUiComponent extends AbstractDynamicComponent implements OnInit, AfterViewInit {
  constructor(private dataStoreService: DataStoreService) {
    super();
  }

  ngOnInit() {
    Global.startPerf(this);
  }

  ngAfterViewInit() {
    for (const child of this.children) {
      const componentRef = WindowFactoryUtils.addContainerComponent({
        parent: this,
        item: child,
        isCssOnComponent: child.container ? child.isCssOnComponent : false,
      });

      if (componentRef.instance instanceof ButtonUiComponent) {
        (<ButtonUiComponent>componentRef.instance).itemData = child;

        if (child.processId > 0) {
          const processUI: ProcessUI = {
            tabId: this.parentTab.tabId,
            componentRef: componentRef,
            display: false,
            readOnly: false,
            fieldID: child.data.fieldId
          };
          this.dataStoreService.addProcess.next(processUI);
        }
      }
      if (componentRef.instance instanceof AutocompleteUiComponent) {
        (<AutocompleteUiComponent>componentRef.instance).itemData = child;
      }
    }
    Global.endPerf(this, '');
  }

  onChildUpdate(event: IupicsEvent) {}

  onSiblingUpdate(event: IupicsEvent) {}

  onRemoveComponent(event: IupicsEvent) {}
}

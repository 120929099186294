@if (source$ | async; as source) {
  <ng-container
    [ngTemplateOutlet]="favoriteFilter"
    [ngTemplateOutletContext]="{
      columnFilters: source.columnFilters,
      operatorFilters: source.operatorFilters,
      $implicit: selected
    }" />
} @else {
  <ng-container
    [ngTemplateOutlet]="favoriteFilter"
    [ngTemplateOutletContext]="{ columnFilters: { items: [] }, operatorFilters: { items: [] }, data: selected }" />
}

<ng-template #favoriteFilter let-columnFilters="columnFilters" let-operatorFilters="operatorFilters" let-selected>
  <div class="search" [attr.data-cy-ufilter-tablename]="tableName">
    <iu-prime-chips
      [(ngModel)]="filterChips"
      [buildItemOnSave]="buildChipsOnSave"
      [conditionToAdd]="conditionToAdd"
      [hasScroll]="hasScroll"
      (onAdd)="addFilterByChips($event.originalEvent, $event.value)"
      (onRemove)="removeFilterByChips($event.originalEvent, $event.value)"
      (addError)="onAddError()"
      [widthChipsContainer]="widthChipsContainer"
      data-cy="universal-filter-chips"
      class="prime-chips-toolbar">
      <ng-template let-item pTemplate="item">
        @if (item) {
          <span class="{{ item?.icon }}" [attr.data-cy-chips-columnName]="item?.columnName" style="margin-right: 3px">
          </span>
        }
        {{ item?.displayValue }}
      </ng-template>
    </iu-prime-chips>

    <span [ngClass]="[isDisplayGroupFilter ? 'opened' : '', 'group']">
      <button
        #buttonGroupFilter
        tooltipPosition="top"
        [showDelay]="250"
        [life]="3000"
        [hideDelay]="250"
        pTooltip="{{ 'universalFilter.filter' | translate }}"
        pButton
        type="button"
        icon="icon-advanced-search-2"
        (click)="updatePanelFilter($event, 'GroupFilter', 'group-filter-panel')"
        class="p-menu-bar-button group"
        data-cy="universal-filter-setting"></button>
      <iu-prime-overlay #overlayFilter [appendTo]="'body'" [dismissable]="false">
        @if (isDisplayGroupFilter) {
          <div id="group-filter-panel" class="group-list ui-overlaypanel {{ isMobile ? 'mobile' : '' }}">
            @if (selected.filters.length > 0) {
              <p [ngStyle]="{ order: 'unset' }">
                {{ 'universalFilter.filterTitle' | translate }}
              </p>
              <ul
                #filtersContainer
                [ngStyle]="{ order: 0 }"
                cdkDropList
                (cdkDropListDropped)="moveItem($event, 'filters')"
                data-uf="filters">
                @for (
                  filter of selected.filters;
                  track filter.column.id;
                  let isFirst = $first;
                  let isLast = $last;
                  let i = $index
                ) {
                  <div cdkDrag data-cy="universal-filter-line" [attr.data-cy-index]="i">
                    <div class="ui-row-drag-preview" *cdkDragPreview>
                      <!-- Preview du drag -->{{ 'universalFilter.filterPreview' | translate
                      }}{{ filter.column.displayValue || '[ColumnName]' }}
                      {{ filter.operator.displayValue || '[Operator]' }}
                      {{ filter.filter || '[Filter]' }}
                      {{
                        filter.filterTo !== undefined && filter.filterTo !== null && filter.filterTo !== ''
                          ? ' - ' + filter.filterTo
                          : ''
                      }}
                    </div>
                    <div class="ui-row-drag-placeholder" *cdkDragPlaceholder><!-- Placeholder du drag --></div>
                    <li class="ui-row" [attr.data-cy-row]="filter.column.id">
                      <span cdkDragHandle class="icon-grab" style="font-size: 17px; cursor: move"></span>
                      <div class="filtres">
                        <div
                          class="lev-1 filter-field {{ filter?.column?.id === -1 ? 'valueEmpty' : '' }}"
                          data-cy="universal-filter-column-autocomplete">
                          <iu-autocomplete-ui
                            [fieldValue]="filter.column"
                            (fieldValueModified)="setColumnFilter($any($event), i)"
                            [isStandalone]="true"
                            [data]="
                              columnFilters
                                | universalFilterColFilterType: 0 : i : CompiereDataGridFilterType.NONE
                                | autocompleteSuggestionsFilter: usedFilterColumnNames : filter.column.id
                            "
                            [isInUniversalFilter]="true"
                            [isInsideOverflow]="true" />
                        </div>

                        <div
                          class="lev-2 filter-field {{ filter?.column?.id === -1 ? 'valueEmpty' : '' }}"
                          data-cy="universal-filter-operator-autocomplete">
                          <iu-autocomplete-ui
                            [fieldValue]="filter.operator"
                            (fieldValueModified)="setOperatorFilter($any($event), i)"
                            [isStandalone]="true"
                            [data]="operatorFilters | operatorFilter: filter?.column?.columnInfo?.filterType"
                            [isInUniversalFilter]="true"
                            [isInsideOverflow]="true" />
                        </div>

                        <div
                          class="lev-3 filter-field {{ filter?.operator?.operator?.isRange ? 'isRange' : '' }}"
                          data-cy="universal-filter-value">
                          <ng-template #vcrFilter />
                        </div>
                        <div
                          class="lev-4 filter-field {{ filter?.operator?.operator?.isRange ? 'isRange' : '' }}"
                          data-cy="universal-filter-value-to">
                          <!-- <ng-template #vcrFilterTo/> -->
                          @if (filter?.operator?.operator?.isRange) {
                            @switch (filter?.column?.columnInfo?.filterType) {
                              @case ('number') {
                                <iu-input-number-ui
                                  [isStandalone]="true"
                                  [fieldValue]="filter.filterTo"
                                  (fieldValueModified)="setFilterTo($event, i)" />
                              }
                              @case ('date') {
                                <iu-calendar-ui
                                  [hasTodayBtn]="true"
                                  [isStandalone]="true"
                                  [data]="{
                                    needTime: filter.column.columnInfo.fieldEntity.field.AD_Reference_ID === 16
                                  }"
                                  [fieldValue]="
                                    filter.filterTo
                                      | toDate: filter.column.columnInfo.fieldEntity.field.AD_Reference_ID === 16
                                  "
                                  [calendarConfig]="filter.configsTo?.calendarConfig || { todayMode: false }"
                                  (calendarConfigChange)="onCalendarConfigChange($event, i, true)"
                                  (fieldValueModified)="setFilterTo($event, i)" />
                              }
                              @case ('time') {
                                <iu-input-time-ui
                                  [isStandalone]="true"
                                  [fieldValue]="filter.filterTo | toDate: true"
                                  [calendarConfig]="filter.configsTo?.calendarConfig || { todayMode: false }"
                                  (calendarConfigChange)="onCalendarConfigChange($event, i, true)"
                                  (fieldValueModified)="setFilterTo($event, i)" />
                              }
                            }
                          }
                        </div>
                      </div>
                      <div class="delete" data-cy="universal-filter-remove-filter">
                        <button
                          tooltipPosition="top"
                          [showDelay]="250"
                          [life]="3000"
                          [hideDelay]="250"
                          pTooltip="{{ 'generic.delete' | translate }}"
                          pButton
                          icon="icon-delete"
                          class="hover"
                          (click)="removeFilter(i, $event)"></button>
                      </div>
                    </li>
                  </div>
                }
              </ul>
              <ul class="buttonAdd" [ngStyle]="{ order: 0 }">
                <li class="ui-row">
                  <div class="p-col-12">
                    <!-- au clic créaction d'un nouvel Li au-dessus de celui-ci et contenant un pemier auto-complete-->
                    @if (canFilter) {
                      <button
                        pButton
                        id="addrow"
                        class="button p-button-secondary"
                        style="cursor: pointer"
                        (click)="addFilter($event)"
                        label="{{ 'universalFilter.addFilter' | translate }}"
                        icon="icon-add"
                        data-cy="universal-filter-add-filter-one"></button>
                    }
                  </div>
                </li>
              </ul>
            }

            @if (selected.groups.length > 0) {
              <p [ngStyle]="{ order: 1 }" style="padding-top: 5px">
                {{ 'universalFilter.groupTitle' | translate }}
              </p>

              <ul
                #groupsContainer
                [ngStyle]="{ order: 1 }"
                cdkDropList
                (cdkDropListDropped)="moveItem($event, 'groups')"
                data-uf="groups">
                @for (
                  group of selected.groups;
                  track group.id;
                  let isFirst = $first;
                  let isLast = $last;
                  let i = $index
                ) {
                  <div data-cy="universal-filter-group" [attr.data-cy-index]="i" cdkDrag>
                    <div class="ui-row-drag-preview" *cdkDragPreview>
                      <!-- Preview du drag -->{{ 'universalFilter.groupPreview' | translate
                      }}{{ group.displayValue || '[ColumnName]' }}
                    </div>
                    <div class="ui-row-drag-placeholder" *cdkDragPlaceholder><!-- Placeholder du drag --></div>
                    <li class="ui-row">
                      <div class="filtres">
                        <span cdkDragHandle class="icon-grab" style="font-size: 17px; cursor: move"></span>
                        <div class="lev-1 filter-field" data-cy="universal-filter-group-autocomplete">
                          <iu-autocomplete-ui
                            [fieldValue]="group"
                            (fieldValueModified)="setGroup($any($event), i)"
                            [isStandalone]="true"
                            [data]="columnFilters | columnGroupFilter"
                            [isInUniversalFilter]="true"
                            [isInsideOverflow]="true" />
                        </div>
                        <div class="delete" data-cy="universal-filter-remove-group">
                          <button
                            tooltipPosition="top"
                            [showDelay]="250"
                            [life]="3000"
                            [hideDelay]="250"
                            pTooltip="{{ 'generic.delete' | translate }}"
                            pButton
                            icon="icon-delete"
                            class="hover"
                            (click)="removeGroup(i, $event)"></button>
                        </div>
                      </div>
                    </li>
                  </div>
                }
              </ul>

              <ul class="buttonAdd" [ngStyle]="{ order: 1 }" style="padding-top: 5px">
                <li class="ui-row">
                  <div class="p-col-12">
                    @if (canGroup) {
                      <!-- au clic créaction d'un nouvel Li au-dessus de celui-ci et contenant un pemier auto-complete-->
                      <button
                        pButton
                        class="button p-button-secondary"
                        style="cursor: pointer"
                        (click)="addGroup($event)"
                        label="{{ 'universalFilter.addGroup' | translate }}"
                        icon="icon-add"
                        data-cy="universal-filter-add-group-one"></button>
                    }
                  </div>
                </li>
              </ul>
            }

            @if (selected.sortings.length > 0) {
              <p [ngStyle]="{ order: 2 }" style="padding-top: 5px">
                {{ 'universalFilter.sortingTitle' | translate }}
              </p>

              <ul
                #sortsContainer
                [ngStyle]="{ order: 2 }"
                cdkDropList
                (cdkDropListDropped)="moveItem($event, 'sortings')"
                data-uf="sortings">
                @for (
                  sorting of selected.sortings;
                  track sorting.column.id;
                  let isFirst = $first;
                  let isLast = $last;
                  let i = $index
                ) {
                  <div data-cy="universal-filter-sort" [attr.data-cy-index]="i" cdkDrag>
                    <div class="ui-row-drag-preview" *cdkDragPreview>
                      <!-- Preview du drag -->{{ 'universalFilter.sortingPreview' | translate
                      }}{{ sorting.column.displayValue || '[ColumnName]' }} {{ sorting.sortingType }}
                    </div>
                    <div class="ui-row-drag-placeholder" *cdkDragPlaceholder><!-- Placeholder du drag --></div>
                    <li class="ui-row">
                      <div class="filtres">
                        <span cdkDragHandle class="icon-grab" style="font-size: 17px; cursor: move"></span>
                        <div class="lev-1 filter-field" data-cy="universal-filter-sort-autocomplete">
                          <iu-autocomplete-ui
                            [fieldValue]="sorting.column"
                            (fieldValueModified)="setSorting($any($event), i)"
                            [disable]="true"
                            [isStandalone]="true"
                            [data]="columnFilters"
                            [isInUniversalFilter]="true"
                            [isInsideOverflow]="true" />
                        </div>
                        <li class="asc-desc filter-field">
                          <div class="p-col-2 asc">
                            <input
                              type="radio"
                              name="sorting_{{ i }}"
                              value="asc"
                              checked="{{ sorting.sortingType === 'asc' ? 'checked' : null }}"
                              [(ngModel)]="sorting.sortingType"
                              label="asc"
                              data-cy="universal-filter-sort-type-asc" /><label id="asc">asc</label>
                          </div>
                          <div class="p-col-2 desc">
                            <input
                              type="radio"
                              name="sorting_{{ i }}"
                              value="desc"
                              checked="{{ sorting.sortingType === 'desc' ? 'checked' : null }}"
                              [(ngModel)]="sorting.sortingType"
                              label="desc"
                              data-cy="universal-filter-sort-type-desc" /><label id="desc">desc</label>
                          </div>
                        </li>
                        <div class="delete" data-cy="universal-filter-remove-sort">
                          <button
                            tooltipPosition="top"
                            [showDelay]="250"
                            [life]="3000"
                            [hideDelay]="250"
                            pTooltip="{{ 'generic.delete' | translate }}"
                            pButton
                            icon="icon-delete"
                            class="hover"
                            (click)="removeSorting(i, $event)"></button>
                        </div>
                      </div>
                    </li>
                  </div>
                }
              </ul>

              <ul class="buttonAdd" [ngStyle]="{ order: 2 }">
                <li class="ui-row">
                  <div class="p-col-12">
                    <!-- au clic créaction d'un nouvel Li au-dessus de celui-ci et contenant un pemier auto-complete-->
                    @if (canSort) {
                      <button
                        pButton
                        class="button p-button-secondary"
                        style="cursor: pointer"
                        (click)="addSorting($event)"
                        label="{{ 'universalFilter.addSorting' | translate }}"
                        icon="icon-add"
                        data-cy="universal-filter-add-sort-one"></button>
                    }
                  </div>
                </li>
              </ul>
            }
            <div class="actions ui-row">
              <div class="action">
                @if (canFilter && selected.filters.length <= 0) {
                  <button
                    class="button p-button-secondary p-button action-btn"
                    style="cursor: pointer"
                    (click)="addFilter($event)"
                    data-cy="universal-filter-add-filter-none">
                    <span class="icon-add"></span>{{ 'universalFilter.addFilter' | translate }}
                  </button>
                }
                @if (canGroup && selected.groups.length <= 0) {
                  <button
                    class="button p-button-secondary p-button action-btn"
                    style="cursor: pointer"
                    (click)="addGroup($event)"
                    data-cy="universal-filter-add-group-none">
                    <span class="icon-add"></span>{{ 'universalFilter.addGroup' | translate }}
                  </button>
                }
                @if (canSort && selected.sortings.length <= 0) {
                  <button
                    class="button p-button-secondary p-button action-btn"
                    style="cursor: pointer"
                    (click)="addSorting($event)"
                    data-cy="universal-filter-add-sort-none">
                    <span class="icon-add"></span>{{ 'universalFilter.addSorting' | translate }}
                  </button>
                }
              </div>
              <div class="action right">
                <button
                  type="button"
                  pButton
                  label="{{ 'generic.apply' | translate }}"
                  class="p-button p-button-primary"
                  (click)="applyFilter($event)"
                  data-cy="apply-filter-btn"></button>
                <button
                  type="button"
                  pButton
                  label="{{ 'universalFilter.resetFilters' | translate }}"
                  class="p-button p-button-primary"
                  (click)="resetFilters($event)"
                  data-cy="reset-filter-btn"></button>
              </div>
            </div>
          </div>
        }
      </iu-prime-overlay>
    </span>
  </div>
</ng-template>

// Prime 10.0.3
import { DOCUMENT, NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MenuItem, SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { PlusIcon } from 'primeng/icons/plus';
import { RippleModule } from 'primeng/ripple';
import { SpeedDial } from 'primeng/speeddial';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'iu-prime-speedDial',
  templateUrl: './prime-speeddial.component.html',
  styleUrls: ['./prime-speeddial.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgClass,
    TooltipModule,
    NgStyle,
    NgTemplateOutlet,
    PlusIcon,
    RippleModule,
    SharedModule,
    ButtonModule,
    RouterLink,
    RouterLinkActive,
  ],
})
export class PrimeSpeedDialComponent extends SpeedDial {
  @ViewChild('container') container: ElementRef | undefined;
  constructor(
    @Inject(PLATFORM_ID) platformId: any,
    el: ElementRef,
    public cd: ChangeDetectorRef,
    @Inject(DOCUMENT) document: Document,
    renderer: Renderer2
  ) {
    super(platformId, el, cd, document, renderer);
  }
  calculatePointStyle(index: number) {
    const type = this.type;

    if (type !== 'linear') {
      const length = (this.model as MenuItem[]).length;
      const radius = this.radius || length * 20;

      if (type === 'circle') {
        const step = (2 * Math.PI) / length;

        return {
          left: `calc(${radius * Math.cos(step * index)}px + var(--item-diff-x, 0px))`,
          top: `calc(${radius * Math.sin(step * index)}px + var(--item-diff-y, 0px))`,
        };
      } else if (type === 'semi-circle') {
        const direction = this.direction;
        const step = Math.PI / (length - 1);
        const x = `calc(${radius * Math.cos(step * index)}px + var(--item-diff-x, 0px))`;
        const y = `calc(${radius * Math.sin(step * index)}px + var(--item-diff-y, 0px))`;
        if (direction === 'up') {
          return { left: x, bottom: y };
        } else if (direction === 'down') {
          return { left: x, top: y };
        } else if (direction === 'left') {
          return { right: y, top: x };
        } else if (direction === 'right') {
          return { left: y, top: x };
        }
      } else if (type === 'quarter-circle') {
        const direction = this.direction;
        const step = Math.PI / (2 * (length - 1));
        const x = `calc(${radius * Math.cos(step * index)}px + var(--item-diff-x, 0px))`;
        const y = `calc(${radius * Math.sin(step * index)}px + var(--item-diff-y, 0px))`;
        if (direction === 'up-left') {
          return { right: x, bottom: y };
        } else if (direction === 'up-right') {
          return { left: x, bottom: y };
        } else if (direction === 'down-left') {
          return { right: y, top: x };
        } else if (direction === 'down-right') {
          return { left: y, top: x };
        }
      }
    }

    return {};
  }
}

<div class="changelog-header">
  <div class="close">
    <button
      pButton
      (click)="closeModalEmitter.emit()"
      icon="icon-close"
      class="p-button p-button-default p-button-icon-only">
      <!-- <span class="p-button-icon-left ui-clickable icon-close" aria-hidden="true"></span> -->
    </button>
  </div>
  <div class="title">{{ 'editView.registeredInformation' | translate }}</div>
</div>
<div class="infos">
  @if (!parentComponent?.editTabs?.[0]?.data?.isView) {
    <div class="info">
      <label>{{ 'editView.createdBy' | translate }}</label> {{ auditsInfo.created_by }}
    </div>
    <div class="info">
      <label>{{ 'editView.updatedBy' | translate }}</label> {{ auditsInfo.updated_by }}
    </div>
  }
  <div class="info">
    @if (auditsInfo.id_txt !== '') {
      <button
        pButton
        class="p-button p-button-secondary"
        (click)="copyToClipBoard(auditsInfo.id_txt)"
        [life]="3000"
        [hideDelay]="250"
        pTooltip="{{ 'editView.copyToClipBoard' | translate }}"
        icon="icon-copy"></button>
    }
    <div>
      <label>ID ({{ auditsInfo.tableName }})</label>
      <p style="white-space: initial">{{ auditsInfo.id_txt }}</p>
    </div>
  </div>
</div>

@if (isCopiedToClipBoard) {
  <div class="changelog-copied-to-clipboard">
    {{ 'editView.copyToClipBoardMsg' | translate }}
  </div>
}

<div class="table">
  <ng-template #vcrGrid />
</div>

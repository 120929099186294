import { LowerCasePipe, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Global } from '@iupics-manager/models/global-var';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'iu-calendar-toolbar-ui',
  templateUrl: './calendar-toolbar-ui.component.html',
  styleUrls: ['./calendar-toolbar-ui.component.scss'],
  standalone: true,
  imports: [NgIf, ButtonModule, TooltipModule, LowerCasePipe, TranslateModule],
})
export default class CalendarToolbarUiComponent implements OnInit {
  @Input() title: string;

  @Output() changeView = new EventEmitter<DayGridType>();
  @Output() next = new EventEmitter();
  @Output() prev = new EventEmitter();
  @Output() today = new EventEmitter();

  isMobile = Global.isMobile() && Global.isMobileWidth();

  private cycles = {
    dayGridMonth: { current: 'dayGridMonth', next: 'dayGridWeek' },
    dayGridWeek: { current: 'dayGridWeek', next: 'dayGridDay' },
    dayGridDay: { current: 'dayGridDay', next: 'dayGridMonth' },
  };
  cycle = this.cycles.dayGridMonth;

  constructor() {}

  ngOnInit() {}

  /**
   *
   * @param {Event}$event MouseEvent
   * @param {String}who désigne quel bouton a été appuyé
   */
  click($event: Event, who: string): void {
    $event.stopPropagation();
    this[who].emit(who);
  }

  switchMobileView($event: MouseEvent, current: string) {
    $event.stopPropagation();
    this.cycle = this.cycles[this.cycles[current].next];
    this.changeView.emit(this.cycle.current as DayGridType);
  }
}

export type DayGridType = 'dayGridDay' | 'dayGridWeek' | 'dayGridMonth';

@if (data) {
  <iu-grid-tab-infinity-scroll-ui
    (columnStateChanged)="onColumnStateChanged($event)"
    (multiSelectEmitter)="onRowSelectedOnGridTab($event)"
    [container]="container"
    [data]="data"
    [DOMParentComponent]="DOMParentComponent"
    [gridPaginator]="gridPaginator"
    [gridTabFilter]="gridTabFilter"
    [isTabTopLevel]="isTabTopLevel"
    [overridedTableHeight]="true"
    [tabId]="tabId"
    [tableHeight]="'100%'"
    [initRequest]="request"
    class="gridWidgetContent" />
}

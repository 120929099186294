import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
  selector: 'iu-storekeeper-loading',
  standalone: true,
  imports: [NgClass],
  templateUrl: './storekeeper-loading.component.html',
  styleUrl: './storekeeper-loading.component.scss',
})
export class StorekeeperLoadingComponent {
  text = input.required<string>();
  icon = input('icon-truck-fast');
  animation = input(true);
}

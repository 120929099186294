import { Injectable, Type, inject } from '@angular/core';
import { CompierePrecisionData } from '@compiere-ws/models/compiere-precision-json';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { PrecisionDataService } from '@compiere-ws/services/precision/precision.service';
import SpecificWindowUiComponent from '@iupics-components/specific/window/specific-window-ui/specific-window-ui.component';
import { IupicsField, IupicsProcess } from '@iupics-manager/models/iupics-data';
import { Observable, finalize, of, share } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CacheManagerService {
  static process_Params_caching: Map<number, any> = new Map();

  static productAttributes_caching: Map<string, IupicsField[]> = new Map();

  static iupics_components: Map<string, Type<any>> = new Map();
  static iupics_specific_window: Map<string, Type<SpecificWindowUiComponent>> = new Map();
  static iupics_widgets: Map<string, Type<any>> = new Map();

  static chatTags: Map<number, any> = new Map();

  private _obsCache: Map<string, Observable<any>> = new Map<string, Observable<any>>();
  private _cache: Map<string, Map<any, any>> = new Map<string, Map<any, any>>();

  #precisionDataService = inject(PrecisionDataService);
  #processService = inject(CompiereProcessService);

  // Default Cache
  getCache(cacheName: CacheName | string): Map<number, any> {
    if (!this._cache.has(cacheName)) {
      this._cache.set(cacheName, new Map<any, any>());
    }
    return this._cache.get(cacheName);
  }

  setCache(cacheName: CacheName | string, value: any) {
    this._cache.set(cacheName, value);
  }

  getCacheValueById(cacheName: CacheName | string, id: any): any {
    return this.getCache(cacheName).get(id);
  }
  setCacheValueById(cacheName: CacheName | string, id: any, value: any) {
    this.getCache(cacheName).set(id, value);
  }

  /**
   *
   * @param obs Observable to share
   * @param obsIdentifier Unique identifier of observable
   * @param options {
   * id : Unique identifier within the cache defined,
   * cacheName : cache used,
   * getObsResultProcessing: Function which override getObsResultProcessing behaviour  }
   * @returns
   */
  getSharableObs(
    obs: Observable<any>,
    obsIdentifier: string,
    options: SharableObsOptions = {
      cached: false,
    }
  ): Observable<any> {
    options = {
      ...{
        cached: false,
        cacheName: CacheName.DEFAULT,
      },
      ...options,
    };
    let observable: Observable<any>;
    const cached = options.cached;
    const cacheName = options.cacheName;
    const id = options.id;
    const getObsResultProcessing = options.getObsResultProcessing;

    if (cached && id === undefined) {
      throw new Error('Observable result could not be cached: id not defined.');
    }
    if (cached && this.getCacheValueById(cacheName, id) !== undefined) {
      observable = of([this.getCacheValueById(cacheName, id)]);
    } else if (this._obsCache.has(obsIdentifier)) {
      observable = this._obsCache.get(obsIdentifier);
    } else {
      observable = obs.pipe(
        switchMap((res) => {
          if (getObsResultProcessing) {
            return getObsResultProcessing(res, options);
          } else {
            return this.getObsResultProcessing(res, options);
          }
        }),
        share(),
        finalize(() => {
          this._obsCache.delete(obsIdentifier);
        })
      );
      this._obsCache.set(obsIdentifier, observable);
    }
    return observable;
  }
  private getObsResultProcessing(res: any, options: SharableObsOptions) {
    if (options.cached) {
      this.setCacheValueById(options.cacheName, options.id, res);
    }
    return of(res);
  }

  getCurrencySymbol(precisionDataRequest: CompierePrecisionData) {
    return this.getPrecisionDatas([precisionDataRequest]).pipe(
      switchMap((precisions: CompierePrecisionData[]) => {
        let symbol = '';
        if (precisions && precisions[0]) {
          symbol = precisions[0].curSymbol;
        }
        return of(symbol);
      })
    );
  }

  getPrecision(precisionDataRequest: CompierePrecisionData) {
    return this.getPrecisionDatas([precisionDataRequest]).pipe(
      switchMap((precisions: CompierePrecisionData[]) => {
        let precision = 2;
        if (precisions && precisions[0]) {
          if (['M_PriceList', 'M_PriceList_Version'].includes(precisions[0].tableName)) {
            precision = precisions[0].pricePrecision;
          } else {
            precision = precisions[0].stdPrecision;
          }
        }
        return of(precision);
      })
    );
  }

  getPrecisionDatas(precisionDataRequests: CompierePrecisionData[]): Observable<{}> {
    const cacheId = this.generatePrecisionDataId(precisionDataRequests[0].tableName, precisionDataRequests[0].id);
    const obsIdentifier = CacheName.PRECISION_CURRENCY + '_' + cacheId;
    const listToGet = precisionDataRequests.filter(
      (pr) => !this.getCacheValueById(CacheName.PRECISION_CURRENCY, this.generatePrecisionDataId(pr.tableName, pr.id))
    );
    return this.getSharableObs(
      listToGet.length > 0 ? this.#precisionDataService.getPrecisionDatas(listToGet) : of([]),
      obsIdentifier,
      {
        cached: precisionDataRequests.length === 1,
        cacheName: CacheName.PRECISION_CURRENCY,
        id: cacheId,
        getObsResultProcessing: (precisions: CompierePrecisionData[], options: SharableObsOptions) => {
          if (precisions) {
            for (const precision of precisions) {
              this.setCacheValueById(
                CacheName.PRECISION_CURRENCY,
                this.generatePrecisionDataId(precision.tableName, precision.id),
                precision
              );
            }
          }
          let list = [];
          for (let pr of precisionDataRequests) {
            list.push(
              this.getCacheValueById(CacheName.PRECISION_CURRENCY, this.generatePrecisionDataId(pr.tableName, pr.id))
            );
          }
          return of(list);
        },
      }
    );
  }
  generatePrecisionDataId(tableName: string, id: number) {
    return tableName + '_' + id;
  }
  loadProcessInCache(processId: number, source?: Observable<IupicsProcess>) {
    this.getProcess(processId, source).subscribe();
  }
  getProcess(processId: number, source?: Observable<IupicsProcess>) {
    if (!source) {
      source = this.#processService.getProcess(processId);
    }
    const process = CacheManagerService.process_Params_caching.get(processId);
    let observable: Observable<any>;
    let obsIdentifier = 'Process_' + processId;
    if (process) {
      observable = of(process);
    } else if (this._obsCache.has(obsIdentifier)) {
      observable = this._obsCache.get(obsIdentifier);
    } else {
      observable = source.pipe(
        switchMap((res) => {
          CacheManagerService.process_Params_caching.set(processId, res);
          return of(res);
        }),
        share(),
        finalize(() => {
          this._obsCache.delete(obsIdentifier);
        })
      );
      this._obsCache.set(obsIdentifier, observable);
    }
    return observable;
  }
  // Tools
  clearCache() {
    CacheManagerService.productAttributes_caching = new Map();
    CacheManagerService.process_Params_caching = new Map();
    CacheManagerService.chatTags = new Map();
    this._cache = new Map<string, Map<any, any>>();
  }
}
export enum CacheName {
  DEFAULT = 'DEFAULT',
  PRECISION_CURRENCY = 'PRECISION_CURRENCY',
}
export enum TableName {
  CURRENCY = 'C_Currency',
  PRICE_LIST_VERSION = 'M_PriceList_Version',
  PRICE_LIST = 'M_PriceList',
  ACCT_SCHEMA = 'C_AcctSchema',
  UOM = 'C_UOM',
}
export interface SharableObsOptions {
  cached: boolean;
  id?: any;
  cacheName?: CacheName | string;
  getObsResultProcessing?: Function;
}

import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, enableProdMode, importProvidersFrom } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HttpInterceptorService } from '@compiere-ws/http-interceptor.service';
import { IuErrorHandler } from '@compiere-ws/iu-error-handler.service';
import { FilterComponent } from '@iupics-components/standard/grid/filters/apiz-filter/filter.component';
import { AppConfig } from '@iupics-config/app.config';
import { KeybindConfigService } from '@iupics-config/keybind.config.service';
import { COMPONENT_FILTERS_REGISTRY, DEFAULT_COMPONENT_FILTERS_REGISTRY, TRANSLATIONS } from '@iupics/apiz-grid';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import 'hammerjs';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import {
  HttpLoaderFactory,
  MyHammerConfig,
  clearConsole,
  initializeKeybinding,
  initializeKeycloak,
  setupApizGridTranslations,
} from 'main.utils';
import { MessageService } from 'primeng/api';
import { AppComponent } from './app/app.component';
import routes from './app/app.route';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      // Angular
      BrowserModule,
      HammerModule,
      KeycloakAngularModule,
      // Projects Modules
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
      environment.production ? ServiceWorkerModule.register('ngsw-worker.js') : []
    ),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(routes),
    AppConfig,
    KeybindConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: clearConsole,
      multi: true,
      deps: [],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [AppConfig, KeycloakService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeybinding,
      deps: [KeybindConfigService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: IuErrorHandler,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    MessageService,
    {
      provide: COMPONENT_FILTERS_REGISTRY,
      useValue: {
        ...DEFAULT_COMPONENT_FILTERS_REGISTRY,
        text: FilterComponent,
        number: FilterComponent,
        date: FilterComponent,
        time: FilterComponent,
        set: FilterComponent,
      },
    },
    {
      provide: TRANSLATIONS,
      useFactory: setupApizGridTranslations,
    },
  ],
}).catch((err) => console.log(err));

import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { XmlParser } from '@iupics-manager/managers/xml-parser/xml-parser';
import { TranslateModule } from '@ngx-translate/core';
@Component({
  selector: 'iu-xml-viewer',
  templateUrl: './xml-viewer.component.html',
  styleUrls: ['./xml-viewer.component.scss'],
  standalone: true,
  imports: [TranslateModule, NgFor, NgIf, NgClass, XmlViewerComponent],
})
export default class XmlViewerComponent implements OnInit {
  @Input() blob: Blob;
  @Input() node: Node;
  @Input() xmlContent: string;
  @Input() indentLevel = 0;
  parsedXml: Document | null = null;
  nodes: Node[] = [];
  constructor(private xmlParser: XmlParser) {}
  ngOnInit(): void {
    if (this.blob) {
      this.xmlParser.readBlobAsText(this.blob).subscribe((xmlText) => {
        this.parsedXml = this.xmlParser.parseXml(xmlText);
        this.nodes = Array.from(this.parsedXml.children);
      });
    } else {
      if (this.xmlContent) {
        this.parsedXml = this.xmlParser.parseXml(this.xmlContent);
      } else {
        this.parsedXml = this.xmlParser.parseXml(new XMLSerializer().serializeToString(this.node));
      }
      if (this.parsedXml?.children?.item(0)?.children) {
        this.indentLevel++;
        this.nodes = Array.from(this.parsedXml?.children.item(0).children);
        if (this.nodes.length == 0) {
          this.nodes = Array.from(this.parsedXml?.children.item(0).childNodes);
        }
      }
    }
  }
  toggleTag(element: Element) {
    element.classList.toggle('closed');
  }
}

import { Injectable } from '@angular/core';
import { CompiereMenu } from '@compiere-ws/models/compiere-menu-json';
import { AppConfig } from '@iupics-config/app.config';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class CompiereMenuFavoritesService {
  private readonly urlMenu: string;
  private readonly urlMenuEdit: string;
  private readonly urlMenuDelete: string;

  constructor(
    private http: ApiService,
    private config: AppConfig
  ) {
    this.urlMenu = this.config.getBackendResource('menuFavorites');
    this.urlMenuEdit = this.config.getBackendResource('menuFavorites');
    this.urlMenuDelete = this.config.getBackendResource('menuFavorites');
  }

  getMenuFavorites(): Observable<CompiereMenu[]> {
    return this.http.get<CompiereMenu[]>(this.urlMenu);
  }

  setMenuFavorites(compiereMenu: CompiereMenu[]): Observable<CompiereMenu[]> {
    const isSummary = compiereMenu[0].isSummary;
    return this.http.post<CompiereMenu[]>(this.urlMenuEdit + '?isCategory=' + isSummary, compiereMenu);
  }

  deleteMenuFavorites(parentId: number, menuId: number, isSummary: boolean): Observable<boolean> {
    return this.http.delete<boolean>(
      this.urlMenuDelete + '?isCategory=' + isSummary + '&menuID=' + menuId + '&parentId=' + parentId
    );
  }
}

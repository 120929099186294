import {SlicePipe} from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {DataStoreStatus} from '@compiere-ws/models/compiere-data-json';
import {PoService} from '@compiere-ws/services/po/po.service';
import {ViewType} from '@iupics-components/models/view-type.enum';
import PrimeBreadcrumbComponent from '@iupics-components/overrided/prime-breadcrumb/prime-breadcrumb.component';
import EditViewUiComponent from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import {CacheManagerService} from '@iupics-manager/managers/cache-manager/cache-manager.service';
import {DataStoreService} from '@iupics-manager/managers/data-store/data-store.service';
import {SecurityManagerService} from '@iupics-manager/managers/security-manager/security-manager.service';
import {UICreatorService} from '@iupics-manager/managers/ui-creator/ui-creator.service';
import {AbstractDynamicComponent} from '@iupics-manager/models/abstract-dynamic-component';
import {Global} from '@iupics-manager/models/global-var';
import {IupicsEvent, IupicsTypeEvent} from '@iupics-manager/models/iupics-event';
import {ProcessUI} from '@iupics-manager/models/processUI';
import {TranslateModule} from '@ngx-translate/core';
import {ContextMenuService} from '@web-desktop/components/workspace/controllers/context-menu/context-menu.service';
import {cloneDeep} from 'lodash';
import {ButtonModule} from 'primeng/button';
import {ToolbarModule} from 'primeng/toolbar';
import {TooltipModule} from 'primeng/tooltip';
import {Subject} from 'rxjs';
import {KeybindingDirective} from '../../../directives/keybinding.directive';

/** la menu-bar des edit-view. */
@Component({
    selector: 'iu-menu-bar-detail-ui',
    templateUrl: './menu-bar-detail-ui.component.html',
    styleUrls: ['./menu-bar-detail-ui.component.css'],
    standalone: true,
    imports: [ToolbarModule, ButtonModule, KeybindingDirective, TooltipModule, SlicePipe, TranslateModule],
})
export default class MenuBarDetailUiComponent
    extends AbstractDynamicComponent
    implements OnInit, AfterViewInit, OnChanges {
    @Input() activateKeybind = true;
    @Input() activeTabID: string;
    @Input() arrowsDisplayed = true;
    @Input() breadcrumb: PrimeBreadcrumbComponent;
    @Input() changingMenuBar: Subject<any>;
    @Input() isDeleteable = true;
    @Input() isInsertRecord = true;
    @Input() isReadOnly = false;
    @Input() isSplitView = false;
    @Input() isZoomTarget = false;
    @Input() nbUploadedFiles: number;
    @Input() noData = false;
    @Input() status: DataStoreStatus;
    @Input() processed: string;
    @Input() viewRecordChangeLogLabel: string;
    @Input() visibleButton = true;
    @Input() isZoomEditView = true;
    @Input() linkedComponents = [];

    @Output() changeGridElementEvent = new EventEmitter<any>();
    @Output() changeGridViewVisibility = new EventEmitter<any>();
    @Output() closeEvent = new EventEmitter<any>();
    @Output() copyEvent = new EventEmitter<any>();
    @Output() deleteEvent = new EventEmitter<any>();
    @Output() emailEvent = new EventEmitter<any>();
    @Output() exportDataEvent = new EventEmitter<any>();
    @Output() joinFilesEvent = new EventEmitter<any>();
    @Output() newEvent = new EventEmitter<any>();
    @Output() printEvent = new EventEmitter<any>();
    @Output() refreshEvent = new EventEmitter<any>();
    @Output() saveEvent = new EventEmitter<any>();
    @Output() undoEvent = new EventEmitter<any>();
    @Output() noteEvent = new EventEmitter<any>();
    @Output() viewRecordChangeLog = new EventEmitter<any>();

    showMoreActionEmitter = new EventEmitter<any>();
    isMobile = Global.isMobile();
    showMoreAction = false;
    isSync = DataStoreStatus.SYNC;

    mailBtnCondition: boolean;
    joinFileBtnCondition: boolean;
    exportBtnCondition: boolean;
    printBtnCondition: boolean;
    closeBtnCondition: boolean;
    newBtnCondition: boolean;
    saveBtnCondition: boolean;
    refreshBtnCondition: boolean;
    deleteBtnCondition: boolean;
    copyBtnCondition: boolean;
    showMoreBtnCondition: boolean;
    noteBtnCondition: boolean;

    processes: ProcessUI[] = [];
    processesDisplayed: ProcessUI[] = [];
    @ViewChild('processListContainer') processListContainer: ElementRef<HTMLElement>;
    availableProcesses: boolean = false;
    hoverProcessContainer: boolean = false;

    constructor(
        public elementRef: ElementRef<HTMLElement>,
        public store: DataStoreService,
        protected connectorService: SecurityManagerService,
        public cmService: ContextMenuService,
        public uiCreatorService: UICreatorService,
        protected po: PoService,
        protected cacheService: CacheManagerService
    ) {
        super();
    }

    ngOnInit() {
        this.updateButtonLists();
        this.showMoreActionEmitter.subscribe(() => {
            this.showMoreAction = !this.showMoreAction;
        });

        this.store.addProcess.subscribe((process: ProcessUI) => {

            if (process) {
                if (process.tabId == this.tabId) {
                    this.processes.push(process);
                    this.availableProcesses = true;
                }
            }

        });

        this.store.processCheck.subscribe((data: boolean) => {
            if (data)
                this.processesDisplayed = this.checkProcessDisplayLogic();
        });

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.status) {
            const statusChange = changes.status;
            if (statusChange.previousValue !== statusChange.currentValue) {
                this.isSync = statusChange.currentValue;
            }
            this.updateButtonLists();
        } else if (
            (changes.processed && changes.processed.previousValue !== changes.processed.currentValue) ||
            (changes.isDeleteable && changes.isDeleteable.previousValue !== changes.isDeleteable.currentValue) ||
            (changes.isInsertRecord && changes.isInsertRecord.previousValue !== changes.isInsertRecord.currentValue) ||
            (changes.isReadOnly && changes.isReadOnly.previousValue !== changes.isReadOnly.currentValue)
        ) {
            this.updateButtonLists();
        }
    }

    updateButtonLists() {
        if (this.linkedComponents) {
            const grid = this.linkedComponents.find((l) => l.viewType);
            this.arrowsDisplayed = grid ? grid.viewType === ViewType.GRID : false;
        }
        this.newBtnCondition = this.visibleButton && !this.noData && !this.isReadOnly && this.isInsertRecord;
        this.saveBtnCondition =
            this.visibleButton &&
            !this.noData &&
            ((this.processed !== 'Y' && !this.isReadOnly) || this.isSync === DataStoreStatus.NOTSYNC);
        this.refreshBtnCondition = this.visibleButton && !this.noData && this.isSync !== DataStoreStatus.NEWRECORD;
        this.deleteBtnCondition =
            this.visibleButton &&
            !this.noData &&
            !this.isReadOnly &&
            this.processed !== 'Y' &&
            this.isDeleteable &&
            this.isSync !== DataStoreStatus.NEWRECORD;
        this.copyBtnCondition =
            this.visibleButton &&
            !this.noData &&
            !this.isZoomEditView &&
            !this.isReadOnly &&
            this.isSync !== DataStoreStatus.NEWRECORD;
        this.mailBtnCondition = this.visibleButton && !this.noData && this.isSync !== DataStoreStatus.NEWRECORD;
        this.noteBtnCondition = this.visibleButton && !this.noData && this.isSync !== DataStoreStatus.NEWRECORD;
        this.joinFileBtnCondition = this.visibleButton && !this.noData && this.isSync !== DataStoreStatus.NEWRECORD;
        this.exportBtnCondition = this.visibleButton && !this.noData && this.isSync !== DataStoreStatus.NEWRECORD;
        this.printBtnCondition = this.visibleButton && !this.noData && this.isSync !== DataStoreStatus.NEWRECORD;
        this.closeBtnCondition = this.visibleButton && !this.noData && !this.isZoomEditView;
        this.showMoreBtnCondition = this.visibleButton && !this.noData && !this.isZoomEditView;
    }

    ngAfterViewInit() {
        // init keybind
        if (this.breadcrumb !== undefined && this.breadcrumb.model !== undefined && this.breadcrumb.model.length > 0) {
            const activeElement = this.breadcrumb.model.find((model) => model.disabled === false);
            if (activeElement !== undefined) {
                this.activateKeybind = parseInt(activeElement.id, 10) === this.tabId;
            }
        }
        // update keybind
        this.subscriptions.push(
            this.breadcrumb.breadcrumbEventEmitter.subscribe((item) => {
                if (
                    item.event === IupicsTypeEvent.clickBreadCrumbItem ||
                    item.event === IupicsTypeEvent.updatedActiveItemBreadcrumb
                ) {
                    if (parseInt(item.tabId, 10) === this.tabId) {
                        this.activateKeybind = true;
                    } else {
                        this.activateKeybind = false;
                    }
                }
            })
        );
        setTimeout(() => {
            this.updateButtonLists();
        }, 200);
    }

    setVisibleButton(visible: boolean) {
        this.visibleButton = visible;
    }

    showProcessList() {

        if (this.processesDisplayed.length > 0) {
            this.processListContainer.nativeElement.classList.add("show-process-list");
        }
    }

    checkProcessDisplayLogic() {

        const checkedProcesses = cloneDeep(this.store.processesDisplay);

        /* @processes: all processes of this tab
             @checkedProcesses: processes with display and readOnly values checked and updated
             @processesDisplayed: processes to be displayed in the Tab
           */
        return this.processes.filter((process: ProcessUI) => {
            return checkedProcesses.some((checkedProcess: any) => {

                if (process.fieldID == checkedProcess["fieldID"] && checkedProcess["displayed"]
                    && !checkedProcess["readOnly"] && checkedProcess["tabID"] == this.tabId) {

                    process.display = true;
                    process.readOnly = false;
                    return true;
                }
                process.display = checkedProcess["displayed"];
                process.readOnly = checkedProcess["readOnly"];
                return false;
            })
        });

    }

    hideProcessList(type: string) {
        if (type == 'button') {
            setTimeout(() => {
                if (!this.hoverProcessContainer) this.processListContainer.nativeElement.classList.remove('show-process-list');
            }, 100);
        }

        if (type == 'container') {
            this.processListContainer.nativeElement.classList.remove('show-process-list');
            this.hoverProcessContainer = false;
        }
    }

    runProcess(index: number) {

        const editViewCp: EditViewUiComponent = this.processesDisplayed[index].componentRef.instance.editViewParent;
        editViewCp.updateModalDisplay(
            {
                key: 'displayProcessUI',
                value: true,
                sourceComponent: this.processesDisplayed[index].componentRef.instance
            },
            {key: 'processId', value: this.processesDisplayed[index].componentRef.instance.data['processId']}
        );
    }

    onChildUpdate(event) {
    }

    onSiblingUpdate(event: IupicsEvent) {
    }

    onRemoveComponent(event: IupicsEvent) {
    }

    ngOnDestroy(): void {
        this.processes = [];
        const remainingProcesses = this.store.processesDisplay.filter(process => process["tabID"] != this.tabId);
        this.store.processesDisplay = remainingProcesses;
    }
}

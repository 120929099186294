<div class="vendor-wrapper">
  <!-- Section - vendor profile -->
  <section class="vendor-profile pd-inline">
    <iu-vendor-profile [data]="vendor" (editButtonEmitter)="goToEditVendor()" />
  </section>
  <!-- Section - vendor profile -->

  <!-- Section - badge buttons -->
  <section class="badges pd-inline">
    <div class="badges-container">
      @for (badge of badges; track i; let i = $index) {
        <iu-badge-button [data]="badge" (clickEmitter)="badgeButtonClick(i)" />
      }
    </div>

    @if (lastRefreshData) {
      <div class="last-refresh">
        @if (isSync) {
          <span>{{ 'specificWindow.vendor360.lastRefresh' | translate }} {{ lastRefreshData }}</span>
        } @else {
          <span class="not-sync-data" (click)="syncData()">
            {{ 'specificWindow.vendor360.dataNotSync' | translate }} <i class="icon-refresh3x" aria-hidden="true"></i>
          </span>
        }
      </div>
    }
  </section>
  <!-- Section - badge buttons -->

  <!-- Section - quick actions -->
  <section class="quick-actions pd-inline">
    <div>
      @for (qa of quickActions; track $index) {
        <iu-quick-action [data]="qa" />
      }
    </div>
    <div>
      @for (qa of quickActionsProcess; track $index) {
        <iu-quick-action [data]="qa" (clickEmitter)="doQuickActionProcess($event)" />
      }
    </div>
  </section>
  <!-- Section - quick actions -->

  <!-- Section - statistics cards -->
  <section class="statistics">
    @for (stats of statistics; track $index) {
      <iu-statistics-card [data]="stats" [C_BPartner_ID]="C_BPartner_ID" />
    }
  </section>
  <!-- Section - statistics cards -->

  <!-- Section - Text fields -->
  <section class="text-fields pd-inline pd-block">
    @for (textfield of textfields; track $index) {
      <iu-text-field-ui
        [ngClass]="{ 'col-1': !textfield.field?.data?.isSameLine }"
        [data]="textfield.field"
        [container]="this"
        [parentTab]="parentTab"
        [parentStore]="parentStore"
        [formatter]="textfield.formatter"
        [loadingAnimationTimeout]="1250" />
    }
    <iu-text-field-ui
      [container]="this"
      [label]="calculatedIndicator?.label"
      [value]="calculatedIndicator?.displayValue"
      [withColorIndicator]="true"
      [colorIndicatorCondition]="calculatedIndicator?.value >= 0"
      [loadingAnimationTimeout]="1250"
      #calculatedIndicatorRef />
  </section>
  <!-- Section - Text fields -->
</div>

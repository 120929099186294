import { NgClass } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import { SKWListTabType, SKWPage } from '../../models/storekeeper-window.model';
import { SKWTranslatePipe } from '../../pipes/storekeeper-window-translate.pipe';
import { SKWContextService } from '../../services/storekeeper-window-context.service';
import { SKWNavigationService } from '../../services/storekeeper-window-navigation.service';
import { StorekeeperFilterPanelComponent } from '../storekeeper-filter-panel/storekeeper-filter-panel.component';

@Component({
  selector: 'iu-storekeeper-toolbar',
  standalone: true,
  imports: [StorekeeperFilterPanelComponent, NgClass, SKWTranslatePipe],
  templateUrl: './storekeeper-toolbar.component.html',
  styleUrl: './storekeeper-toolbar.component.scss',
})
export class StorekeeperToolbarComponent {
  #SKWNavigationService = inject(SKWNavigationService);
  #SKWContextService = inject(SKWContextService);

  state = this.#SKWContextService.state;
  page = this.#SKWNavigationService.page;

  isFilterOpen = this.#SKWNavigationService.isFilterPanelOpen;

  title = computed(() => {
    if (this.isFilterOpen()) {
      return 'Filters';
    }

    switch (this.#SKWNavigationService.page()) {
      case SKWPage.LIST:
        return this.#SKWNavigationService.listTabActive() === SKWListTabType.TASKS ? 'Tasks' : 'Transfer';
      case SKWPage.INVENTORY:
        return 'Inventory';
      case SKWPage.TRANSFER:
      case SKWPage.TRANSFER_LINE:
      case SKWPage.NEW_TRANSFER:
        return 'Transfer';
    }
  });

  subTitle = computed(() => {
    switch (this.page()) {
      case SKWPage.TRANSFER:
      case SKWPage.TRANSFER_LINE:
        return this.#SKWNavigationService.transferActive()?.transferNO;
      default:
        return undefined;
    }
  });

  hasFilter = computed(() => this.#SKWContextService.filters()?.length > 0);

  back(event: MouseEvent) {
    if (this.#abortIfLoading()) {
      return;
    }

    this.#SKWNavigationService.previousPage();
  }

  refresh(event: MouseEvent) {
    if (this.#abortIfLoading()) {
      return;
    }

    this.#SKWContextService.refresh();
  }

  toggleFilterPanel(event: MouseEvent) {
    this.#SKWNavigationService.toggleFilterPanel();
  }

  #abortIfLoading() {
    return !this.state.loaded();
  }
}

<div
  #specificContainer
  class="iu-specific-window iu-specific-window-container"
  [attr.data-cy-specific-window-name]="name">
  <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
    <div class="p-col-12">
      <div class="fields p-grid">
        <ng-template iuDynamicContainer />
      </div>
    </div>
    <div class="iu-specific-btn-container loginAs p-grid" [ngClass]="[shouldAddPadding ? 'shouldAddPadding' : '']">
      @if (gridViews && gridViews.length === 1 && hasInfoWindowGrid) {
        <iu-button-ui
          class="p-col-12 p-md-3 p-lg-2"
          (click)="$event.preventDefault(); $event.stopPropagation(); zoomOnLineSelected()"
          label="{{ 'contextmenu.zoom' | translate }}"
          icon="fa fa-search-plus"
          data-cy="form-button-zoom" />
      }
      <ng-template #vcrButtons />
    </div>
  </p-scrollPanel>
</div>

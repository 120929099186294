<div
  #container
  [ngClass]="containerClass()"
  [class]="className"
  [ngStyle]="style"
  [attr.data-pc-name]="'speeddial'"
  [attr.data-pc-section]="'root'">
  <button
    pRipple
    pButton
    class="p-button-icon-only"
    [style]="buttonStyle"
    [icon]="buttonIconClass"
    [ngClass]="buttonClass()"
    [disabled]="disabled"
    [attr.aria-expanded]="visible"
    [attr.aria-haspopup]="true"
    [attr.aria-controls]="id + '_list'"
    [attr.aria-label]="ariaLabel"
    [attr.aria-labelledby]="ariaLabelledBy"
    (click)="onButtonClick($event)"
    (keydown)="onTogglerKeydown($event)"
    [attr.data-pc-name]="'button'">
    @if (!buttonTemplate) {
      @if (showIcon) {
        <PlusIcon />
      }
    } @else {
      <ng-container [ngTemplateOutlet]="buttonTemplate" />
    }
  </button>
  <ul
    #list
    class="p-speeddial-list"
    role="menu"
    [id]="id + '_list'"
    (focus)="onFocus($event)"
    (focusout)="onBlur($event)"
    (keydown)="onKeyDown($event)"
    [attr.aria-activedescendant]="focused ? focusedOptionId : undefined"
    [tabindex]="-1"
    [attr.data-pc-section]="'menu'">
    @for (item of model; track i; let i = $index) {
      <li
        [ngStyle]="getItemStyle(i)"
        class="p-speeddial-item"
        pTooltip
        [tooltipOptions]="item.tooltipOptions"
        [ngClass]="{ 'p-hidden': item.visible === false, 'p-focus': focusedOptionId == id + '_' + i }"
        [id]="id + '_' + i"
        [attr.aria-controls]="id + '_item'"
        role="menuitem"
        [attr.data-pc-section]="'menuitem'">
        @if (_visible) {
          @if (isClickableRouterLink(item)) {
            <a
              pRipple
              [routerLink]="item.routerLink"
              [queryParams]="item.queryParams"
              class="p-speeddial-action"
              [ngClass]="{ 'p-disabled': item.disabled }"
              role="menuitem"
              [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }"
              (click)="onItemClick($event, item)"
              (keydown.enter)="onItemClick($event, item, i)"
              [attr.target]="item.target"
              [attr.tabindex]="item.disabled || readonly || !visible ? null : item.tabindex ? item.tabindex : '0'"
              [fragment]="item.fragment"
              [queryParamsHandling]="item.queryParamsHandling"
              [preserveFragment]="item.preserveFragment"
              [skipLocationChange]="item.skipLocationChange"
              [replaceUrl]="item.replaceUrl"
              [state]="item.state"
              [attr.aria-label]="item.label"
              [attr.data-pc-section]="'action'">
              @if (item.icon) {
                <span class="p-speeddial-action-icon" [ngClass]="item.icon"></span>
              }
            </a>
          } @else {
            <a
              [attr.href]="item.url || null"
              class="p-speeddial-action"
              role="menuitem"
              pRipple
              (click)="onItemClick($event, item)"
              [ngClass]="{ 'p-disabled': item.disabled }"
              (keydown.enter)="onItemClick($event, item, i)"
              [attr.target]="item.target"
              [attr.data-pc-section]="'action'"
              [attr.aria-label]="item.label"
              [attr.tabindex]="
                item.disabled || (i !== activeIndex && readonly) || !visible
                  ? null
                  : item.tabindex
                    ? item.tabindex
                    : '0'
              ">
              @if (item.icon) {
                <span class="p-speeddial-action-icon" [ngClass]="item.icon"></span>
              }
            </a>
          }
        }
      </li>
    }
  </ul>
</div>

@if (mask && visible) {
  <div
    [ngClass]="{ 'p-speeddial-mask': true, 'p-speeddial-mask-visible': visible }"
    [class]="maskClassName"
    [ngStyle]="maskStyle"></div>
}

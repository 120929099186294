<button
  #buttonFavoriteFilter
  tooltipPosition="top"
  [showDelay]="250"
  [life]="3000"
  [hideDelay]="250"
  pTooltip="{{ 'universalFilter.favorite' | translate }}"
  pButton
  type="button"
  icon="icon-favorite"
  class="p-menu-bar-button favorite"
  (click)="updatePanelFilter($event)"
  data-cy="universal-filter-favorite"></button>
<iu-prime-overlay #overlayFavourite [appendTo]="'body'" [dismissable]="false">
  @if (isDisplayFavoriteFilter) {
    <div
      data-cy="universal-filter-favorite-panel"
      id="favorite-filter-panel"
      [ngStyle]="{ right: gridView?.isZoomTargetGrid ? 'unset' : null }"
      class="favorite-options ui-overlaypanel">
      <div class="save-favorite">
        <input
          #filterFavorite
          [placeholder]="'universalFilter.saveFilterPlaceHolder' | translate"
          data-cy="universal-filter-favorite-input"
          type="text"
          class="name"
          class="save-favorite-input p-inputtext ui-corner-all" />
        <button
          tooltipPosition="top"
          [showDelay]="250"
          [life]="3000"
          [hideDelay]="250"
          pTooltip="{{ 'generic.save' | translate }}"
          pButton
          type="button"
          class="save p-button-default p-button-secondary"
          data-cy="universal-filter-favorite-save-button"
          (click)="saveFilter($event, filterFavorite.value)">
          {{ 'generic.save' | translate }}
        </button>
      </div>
      @if (gridPreferences.length > 0) {
        <div data-cy="universal-filter-favorite-list" class="saved-list">
          <h6 class="saved-list-title">{{ 'universalFilter.savedFilters' | translate }}</h6>
          <ul>
            @for (gridPreference of gridPreferences; track gridPreference) {
              <li
                [ngClass]="[selectedGridPref?.name === gridPreference.name ? 'selected' : '']"
                data-cy="universal-filter-favorite-item"
                [attr.data-cy-favorite-item]="gridPreference.name"
                (click)="selectAndApply($event, gridPreference)">
                <input
                  class="favorite-input"
                  type="radio"
                  name="{{ gridPreference.name }}"
                  value="{{ gridPreference.name }}"
                  id="favorite-selected"
                  [checked]="selectedGridPref?.name === gridPreference.name" />
                <label for="{{ gridPreference.name }}" class="label">{{ gridPreference.name }}</label>
                @if (this?.gridView?.isTabTopLevel) {
                  <button
                    tooltipPosition="top"
                    [showDelay]="250"
                    [life]="3000"
                    [hideDelay]="250"
                    pTooltip="{{ 'universalFilter.isWidget' | translate }}"
                    pButton
                    type="button"
                    icon="icon-table"
                    class="p-button-default no-background"
                    [ngClass]="{ active: gridPreference.widgetID > 0 }"
                    (click)="showWidgetEditor($event, gridPreference)"></button>
                }
                <button
                  tooltipPosition="top"
                  [showDelay]="250"
                  [life]="3000"
                  [hideDelay]="250"
                  pTooltip="{{ 'universalFilter.byDefault' | translate }}"
                  pButton
                  type="button"
                  [icon]="gridPreference.isDefault ? 'icon-bookmark' : 'icon-bookmark-o'"
                  class="bookmark-button p-button-default no-background"
                  [ngClass]="{ active: gridPreference.isDefault }"
                  (click)="updateDefault($event, gridPreference)"></button>
                <button
                  tooltipPosition="top"
                  [showDelay]="250"
                  [life]="3000"
                  [hideDelay]="250"
                  pTooltip="{{ 'generic.edit' | translate }}"
                  pButton
                  type="button"
                  class="p-button-default no-background"
                  icon="icon-edit-pencil"
                  (click)="editFilter($event, gridPreference)"></button>
                <button
                  tooltipPosition="top"
                  [showDelay]="250"
                  [life]="3000"
                  [hideDelay]="250"
                  pTooltip="{{ 'generic.delete' | translate }}"
                  pButton
                  type="button"
                  class="p-button-default no-background"
                  icon="icon-delete"
                  (click)="deleteFilter($event, gridPreference)"></button>
              </li>
            }
          </ul>
        </div>
      }
      <div class="footer-favorite">
        <button pButton class="p-button-default p-button-primary close-btn" (click)="updatePanelFilter($event)">
          {{ 'generic.close' | translate }}
        </button>
      </div>
    </div>
  }
</iu-prime-overlay>

@if (displayWidgetEditorModal) {
  <iu-modal-ui
    [isModalDisplay]="displayWidgetEditorModal"
    (closeModalEmitter)="this.displayWidgetEditorModal = false"
    [hasCloseBtn]="true"
    [appendTo]="'body'"
    [angularStyle]="{ 'iu-modal-body': { padding: 0 } }">
    <ng-template #vcrWidgetEditor />
  </iu-modal-ui>
}

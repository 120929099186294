<div class="boards-list" cdkDropListGroup>
  @for (kanbanGroup of kanbanGroups; track $index) {
    <iu-kanban-board-ui
      [tabId]="tabId"
      [tableName]="tableName"
      [container]="this.container"
      [kanbanGroup]="kanbanGroup"
      [isFlexDesign]="kanbanGroups.length === 1"
      [columnsDisplayAD]="this.columnsDisplayAD"
      [kanbanImageColumn]="this.kanbanImageColumn"
      [(draggedElement)]="draggedElement"
      (changeGroupEmitter)="queryDataStore()"
      (clickEmitter)="clickEmitter.emit($event)" />
  }
</div>

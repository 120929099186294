import { Injectable } from '@angular/core';
import { AvatarFullInfos, AvatarInfos } from '@iupics-manager/models/user-account';
import { getAvatarTwoLetters, getColorsForString } from './avatar-utils';

@Injectable({
  providedIn: 'root',
})
export class AvatarService {
  private avatarInfosMap: Map<number, AvatarFullInfos> = new Map();

  getAvatarInfos(user: AvatarInfos): AvatarFullInfos {
    if (this.avatarInfosMap.has(user.id)) {
      return this.avatarInfosMap.get(user.id);
    }

    return this.generateAvatarInfos(user);
  }

  private generateAvatarInfos({ id, fullname, pictureURL }: AvatarInfos): AvatarFullInfos {
    const { bg, fg } = getColorsForString(fullname /* , this.themeColors */);

    const infos: AvatarFullInfos = {
      id,
      fullname,
      backgroundColor: bg,
      foregroundColor: fg,
      abbreviation: getAvatarTwoLetters(fullname),
      pictureURL,
    };

    this.avatarInfosMap.set(id, infos);

    return infos;
  }
}

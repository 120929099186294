<li class="listSmartButtonMore">
  <a [style.width.px]="buttonWidth" href="#" (click)="$event.preventDefault()" (click)="onSmartButtonClick()">
    <span>
      <i *ngIf="iconClass" [ngClass]="[iconClass]" aria-hidden="true"></i>
      {{ label | textLimit : buttonWidth / 8 }}
    </span>
    <span>
      <i class="icon-next" aria-hidden="true"></i>
    </span>
  </a>
</li>

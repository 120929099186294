import { Injectable, computed, inject, signal } from '@angular/core';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import {
  SKWLineStatus,
  SKWListTabType,
  SKWPage,
  SKWTaskStatus,
  SKWTransferData,
  SKWTransferHeaderData,
  SKWTransferLineData,
  SKWTransferLineFormData,
} from '../models/storekeeper-window.model';

@Injectable()
export class SKWNavigationService {
  userInfos = inject(SecurityManagerService).getIupicsUserAccount();

  #page = signal(SKWPage.LIST);
  #previousPages: SKWPage[] = [];
  page = this.#page.asReadonly();

  #listTabActive = signal<SKWListTabType>(SKWListTabType.TASKS);
  listTabActive = this.#listTabActive.asReadonly();

  #listStatusTabActive = signal<SKWTaskStatus>(SKWTaskStatus.AS);
  listStatusTabActive = this.#listStatusTabActive.asReadonly();

  transferActive = signal<SKWTransferHeaderData>(undefined);

  #detailTabActive = signal<SKWLineStatus>(SKWLineStatus.TL);
  detailTabActive = this.#detailTabActive.asReadonly();

  #detailSelectedLine = signal<SKWTransferLineData>(undefined);
  detailSelectedLine = this.#detailSelectedLine.asReadonly();
  searchData = signal<Partial<SKWTransferLineFormData>>({});

  isFilterPanelOpen = signal(false);

  isCreation = computed(() => {
    if (this.#page() === SKWPage.NEW_TRANSFER) {
      return true;
    }

    // We can use Storekeeper_ID here because transfer details (where line creation is accessible)
    // is only reachable when assigned, if this functioning is changed, this will need to be changed too.
    return this.userInfos.id === this.transferActive()?.Storekeeper_ID;
  });

  //#region Page
  changePage(page: SKWPage) {
    if (page === SKWPage.NEW_TRANSFER) {
      if (this.#page() === SKWPage.LIST) {
        this.#previousPages.push(SKWPage.NEW_TRANSFER);
      }
      this.goToNewTransferLine();
      return;
    }

    this.#previousPages.push(this.#page());
    this.#page.set(page);
  }

  previousPage(fallback: SKWPage = SKWPage.LIST) {
    let next = this.#previousPages.pop();
    if (!next || next === this.#page()) {
      next = fallback;
    }

    this.#page.set(next);
  }
  //#endregion

  //#region Tab
  changeListTabActive(tab: SKWListTabType) {
    this.#listTabActive.set(tab);
  }

  changeListStatusTabActive(status: SKWTaskStatus) {
    this.#listStatusTabActive.set(status);
  }

  changeDetailTabActive(tab: SKWLineStatus, changePage = true) {
    this.#detailTabActive.set(tab);
    if (changePage) {
      this.changePage(SKWPage.TRANSFER);
    }
  }

  goToDetail(transfer: SKWTransferHeaderData, tabType: SKWLineStatus) {
    this.#detailTabActive.set(tabType);
    this.transferActive.set(transfer);
    this.changePage(SKWPage.TRANSFER);
    // this.#SKWContextService.getTransferDetails(transfer);
  }
  //#endregion

  selectLine(line: SKWTransferLineData | undefined, data?: Partial<SKWTransferLineFormData>) {
    this.#detailSelectedLine.set(line);
    if (line) {
      if (data) {
        this.searchData.set(data);
      }

      this.changePage(SKWPage.TRANSFER_LINE);
    } else {
      this.previousPage();
    }
  }

  goToNewTransferLine(M_Movement_ID?: number) {
    if (!M_Movement_ID) {
      this.transferActive.set({
        M_Movement_ID: 0,
        Storekeeper_ID: this.userInfos.id,
        comment: undefined,
        Description: undefined,
      });
    }

    this.selectLine({
      M_Movement_ID: M_Movement_ID ?? 0,
      locator_source: undefined,
      locator_destination: undefined,
      M_Product_ID: undefined,
      Qty: null,
    });
  }

  goToTransferAfterSave(transfer: SKWTransferData) {
    if (!transfer) {
      this.selectLine(undefined);
    }

    // Set Navigation to match transfer selection flux
    this.#previousPages = [SKWPage.LIST, SKWPage.TRANSFER];
    this.#listTabActive.set(SKWListTabType.TASKS);
    this.#listStatusTabActive.set(SKWTaskStatus.AS);
    this.#page.set(SKWPage.TRANSFER);
    this.transferActive.set(transfer);
    this.#detailSelectedLine.set(undefined);
  }

  //#region Filter Panel
  toggleFilterPanel() {
    this.isFilterPanelOpen.update((v) => !v);
  }
  //#endregion
}

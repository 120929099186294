<!-- #region body -->
@if (isLoading) {
  <p-progressBar [mode]="'indeterminate'" [value]="0" [showValue]="false" [styleClass]="'loading-progressbar'" />
}

<div class="iu-export-data-body">
  @if (exportDataOptions$ | async; as edo) {
    <!-- #region footer -->
    <div class="iu-export-data-footer">
      <div class="p-col-12 p-md-6">
        <iu-autocomplete-ui
          #printFormatsAutocomplete
          [cssClass]="''"
          tooltip="{{ 'exportData.printFormats' | translate }}"
          label="{{ 'exportData.printFormats' | translate }}"
          (fieldValueModified)="setPrintFormat($any($event))"
          [isStandalone]="true"
          [data]="edo.printFormats" />
      </div>
      <div class="p-col-12 p-md-6">
        <iu-autocomplete-ui
          #fileTypesAutocomplete
          [cssClass]="''"
          tooltip="{{ 'exportData.fileTypes' | translate }}"
          label="{{ 'exportData.fileTypes' | translate }}"
          (autoCompleteEmitter)="setFileType($any($event))"
          [isStandalone]="true"
          [data]="edo.fileTypes" />
      </div>
    </div>
    <div class="iu-export-data-footer-buttons">
      <button
        pButton
        class="p-button p-button-alert"
        (click)="cancelExportDataEmitter.emit()"
        [label]="'exportData.cancel' | translate"></button>
      <button
        pButton
        class="p-button p-button-primary"
        (click)="exportData($event, edo.recordIds)"
        [label]="'exportData.exportData' | translate"></button>
    </div>
  } @else {
    <div class="iu-export-data-footer">
      <div class="p-col-12 p-md-6">
        <iu-autocomplete-ui
          #printFormatsAutocomplete
          tooltip="{{ 'exportData.printFormats' | translate }}"
          label="{{ 'exportData.printFormats' | translate }}"
          [data]="{ items: [] }" />
      </div>
      <div class="p-col-12 p-md-6">
        <iu-autocomplete-ui
          #fileTypesAutocomplete
          tooltip="{{ 'exportData.fileTypes' | translate }}"
          label="{{ 'exportData.fileTypes' | translate }}"
          [data]="{ items: [] }" />
      </div>
    </div>
    <div class="iu-export-data-footer-buttons">
      <button pButton class="p-button p-button-alert" [label]="'exportData.cancel' | translate"></button>
      <button pButton class="p-button p-button-primary" [label]="'exportData.exportData' | translate"></button>
    </div>
  }
</div>
<!-- #endregion -->

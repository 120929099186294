import { Injectable, inject } from '@angular/core';
import {
  CompiereLocationCity,
  CompiereLocationCountry,
  CompiereLocationRegion,
} from '@compiere-ws/models/compiere-location';
import { SpecificWindowCompiereWS } from '@compiere-ws/models/specific-window-json';
import { AppConfig } from '@iupics-config/app.config';
import { interpolate } from '@iupics-manager/models/global-var';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private http = inject(ApiService);
  private config = inject(AppConfig);

  private urlCountries = this.config.getBackendResource('countries');
  private urlCities = this.config.getBackendResource('cities');
  private urlLocation = this.config.getBackendResource('location');
  private urlLocationUI = this.config.getBackendResource('locationUI');
  private urlLocationUIByCountry = this.config.getBackendResource('locationUIByCountry');

  /**
   * @returns {Observable<any>} L'ensemble des pays de compiere
   */
  getLocation(c_location_id: number): Observable<any> {
    return this.http.get<any>(this.urlLocation + '/' + c_location_id);
  }

  /**
   * @returns {Observable<CompiereLocationCountry[]>} L'ensemble des pays de compiere
   */
  getCountries(): Observable<CompiereLocationCountry[]> {
    return this.http.get<CompiereLocationCountry[]>(this.urlCountries);
  }

  /**
   *
   * @param {number} country_id L'ID du pays
   * @returns {Observable<CompiereLocationRegion>} L'ensemble des regions du pays renseigné
   */
  getRegions(country_id: number): Observable<CompiereLocationRegion> {
    return this.http.get<CompiereLocationRegion>(this.urlCountries + `/${country_id}`);
  }

  /**
   *
   * @param {number} country_id L'ID du pays
   * @returns {Observable<CompiereLocationCity[]>} L'ensemble des villes du pays renseigné
   */
  getCities(country_id: number): Observable<CompiereLocationCity[]> {
    return this.http.get<CompiereLocationCity[]>(this.urlCities + `/${country_id}`);
  }
  /**
   *
   * @param {number} city_id L'ID de la ville
   * @returns {Observable<CompiereLocationCity[]>} ville trouvée
   */
  getCity(city_id: number): Observable<CompiereLocationCity[]> {
    return this.http.get<CompiereLocationCity[]>(this.urlCities + `/${city_id}`);
  }

  /**
   * Permet de récupérer le masque de saisie demandé dans le nouveau format normalisé.
   * (Voir [redmine #154724](https://helpdesk.audaxis.com/issues/show/154724))
   * @param {number} ad_form_id L'ID de la form qui servira de container pour l'appel WS
   * @param {number} c_country_id L'ID du pays dont on veut le masque de saisie. Si manquant, le masque de saisie par défaut sera retourné
   * @returns {Observable<SpecificWindowCompiereWS>}
   */
  public getLocationUI(ad_form_id: number, c_country_id: number = -1): Observable<SpecificWindowCompiereWS> {
    if (c_country_id == -1) {
      return this.http.get<SpecificWindowCompiereWS>(`${this.urlLocationUI}/${ad_form_id}`);
    } else {
      const url = interpolate(this.urlLocationUIByCountry, { formID: ad_form_id });
      return this.http.get<SpecificWindowCompiereWS>(`${url}/${c_country_id}`);
    }
  }
}

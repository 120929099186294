import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  IupicsCookieService,
  LocalStorageIupics,
} from '@iupics-manager/managers/security-manager/cookies/iupics-cookie.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { Global } from '@iupics-manager/models/global-var';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { CompiereLanguage } from './models/compiere-language-json';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  #connectorService = inject(SecurityManagerService);
  #cookieService = inject(IupicsCookieService);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    Global.activeXHRRequest++;

    if (this.#cookieService.checkLS(LocalStorageIupics.default_language)) {
      request = request.clone({
        setHeaders: {
          'X-Lang': `${
            (
              JSON.parse(
                this.#cookieService.getDecryptedLocalStorage(LocalStorageIupics.default_language)
              ) as CompiereLanguage
            ).iso_code
          }`,
        },
      });
    } else if (this.#connectorService.getIupicsUserAccount()) {
      if (this.#connectorService.getIupicsUserAccount().default_language) {
        request = request.clone({
          setHeaders: {
            'X-Lang': `${this.#connectorService.getIupicsUserAccount().default_language.iso_code}`,
          },
        });
      }
    }
    Global.lastActivity = new Date();
    return next.handle(request).pipe(
      map((event) => {
        return event;
      }),
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            environment.config.backend.docserver;
            // if (
            //   !request.url.startsWith(environment.config.backend.auth + environment.config.backend.auth.paths.login) &&
            //   !request.url.startsWith(environment.config.backend.docserver.url)
            // ) {
            //   this.cookieService.clearLsCookies();
            //   this.connectorService.disconnect().subscribe(
            //     () => location.reload(),
            //     () => location.reload()
            //   );
            // }
          }
        }
        return throwError(() => err);
      }),
      finalize(() => {
        if (Global.activeXHRRequest > 0) {
          Global.activeXHRRequest--;
        }
      })
    );
  }
}

import {
  CompiereDataGridFilterType,
  CompiereDataGridType,
  DataStoreRequest,
} from '@compiere-ws/models/compiere-data-json';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';

export function getCountryRequest(C_Country_ID: number, ad_language: string): DataStoreRequest {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'C_Country',
      ad_language,
      filterModel: {
        C_Country_ID: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.EQUALS],
          values: [[C_Country_ID]],
        },
      },
    },
  };
}

export function getCityRequest(C_Country_ID: number, Postal: string, ad_language: string): DataStoreRequest {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'C_City',
      ad_language,
      filterModel: {
        Postal: {
          filterType: CompiereDataGridFilterType.TEXT,
          operators: [OperatorFilterType.EQUALS],
          values: [Postal],
        },
        C_Country_ID: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.EQUALS],
          values: [[C_Country_ID]],
        },
      },
    },
  };
}

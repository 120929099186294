<div
  class="quick-action-container"
  [attr.aria-expanded]="isExpanded"
  [attr.aria-readonly]="data?.readonly ?? false"
  #qaElement>
  <div
    class="quick-action-label"
    (click)="applyFunction(data?.action ?? null)"
    iuLoading
    [dataToWatch]="data"
    role="button">
    <span>{{ data.label }}</span>
    @if (data?.type) {
      <i [ngClass]="[data.type]" aria-hidden="true"></i>
    }
  </div>
  @if (data.subActions?.length > 0) {
    <div class="quick-action-subactions">
      <div class="quick-action-subactions-wrapper" role="list">
        @for (sa of data.subActions; track $index) {
          <span
            class="quick-action-subactions-label"
            (click)="applyFunction(sa)"
            role="listitem"
            title="{{ sa.displayValue }}">
            {{ sa.displayValue }}
          </span>
        }
      </div>
    </div>
  }
</div>

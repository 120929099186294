<!-- TODO: add translations -->
@if (mustRefresh) {
  <iu-banner type="message"> Vos données doivent être rafraîchie. </iu-banner>
}

@if (hasConflicts && remoteData && localData) {
  <iu-banner type="error">
    Des conflits sont présents dans vos données.
    <a style="text-decoration: underline; cursor: pointer" (click)="wantToMergeConflicts = true">Vérifier</a> et
    sauvegarder.
  </iu-banner>
}

<div id="{{ scrollPanelId }}" class="p-col dynamicContainer">
  <ng-template iuDynamicContainer />
</div>

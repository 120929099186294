export namespace DocUtils {
  export const fileExtensionRegex = /\.[^.]+$/;

  export function getFileExtension(fileName, slicePoint = true) {
    if (!slicePoint) {
      return fileExtensionRegex.exec(fileName)?.[0];
    }

    return fileExtensionRegex.exec(fileName)?.[0].slice(1);
  }

  export function getFileIcon(fileName) {
    switch (getFileExtension(fileName, false)) {
      case '.pdf':
        return 'icon-pdf';
      case '.csv':
        return 'icon-csv';
      case '.txt':
        return 'icon-txt';
      case '.xls':
      case '.xlsx':
        return 'icon-excel';
      case '.doc':
      case '.docx':
        return 'icon-word';
      case '.sql':
        return 'icon-sql';
      case '.zip':
        return 'icon-zip';
      case '.html':
        return 'icon-html';
      default:
        return 'icon-file';
    }
  }

  export function isFileExtensionImage(fileName) {
    switch (getFileExtension(fileName, false)) {
      case '.png':
      case '.jpg':
      case '.jpeg':
      case '.gif':
      case '.bmp':
      case '.ico':
      case '.webp':
      case '.svg':
        return true;
      default:
        return false;
    }
  }
}

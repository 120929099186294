<span
  [style.display]="displayCss"
  [life]="3000"
  [hideDelay]="250"
  pTooltip="{{ tooltip }}"
  [escape]="false"
  tooltipPosition="top"
  [showDelay]="500"
  tooltipZIndex="2100"
  [style.margin-top.px]="data && data.marginTop > 0 ? data.marginTop : null"
  #input
  (contextmenu)="onContextMenu($event)"
  (press)="onContextMenu($any($event))"
  [style.backgroundColor]="backgroundColorCss">
  <iu-prime-calendar
    #calendar
    [label]="label"
    [isLabelDisplay]="this.isLabelDisplay"
    [iupicsLocale]="locale"
    [hourFormat]="hourFormat"
    [showTime]="true"
    [showIcon]="true"
    (fieldChange)="fieldChange($event)"
    [timeOnly]="true"
    [readonlyInput]="isReadOnly"
    [columnName]="data?.columnName"
    [isFieldOnly]="data?.isFieldOnly" />

  @if (showValuePrefPanel) {
    <iu-prime-overlay #opValuePref [dismissable]="false" appendTo="body">
      <iu-value-preference-panel
        [overlayPanel]="opValuePref"
        [fieldValue]="fieldValue"
        [dataStored]="this.dataStored"
        [sourceComponent]="this"
        [data]="this.data"
        (closeEmitter)="toggleValuePreferencePanel($event)" />
    </iu-prime-overlay>
  }
</span>

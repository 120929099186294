export enum IupicsMenuType {
  INFO_WINDOW = 'Info Window',
  WINDOW = 'Window',
  SUMMARY = 'Summary',
  REPORT = 'Report',
  PROCESS = 'Process',
  WORKFLOW = 'Workflow',
  FORM = 'Form',
  TASK = 'Task',
}

export interface MenuCategoryUI {
  /**
   * `-1` = Tous les menus\
   * `0` = Les plus pertinent\
   * `0 < id > Infinity` = Les catégories\
   * `Infinity` = (Sans catégorie)
   */
  id: number;
  name: string;
  isSelected?: boolean;
  icon: string;
}

export interface MenuFavoritesCategoryUI {
  id: number;
  name: string;
  seqNo?: number;
}

export class MenuItemUI {
  menuId: number;
  angularClass?: string;
  description?: string;
  menuCategory?: MenuCategoryUI;
  menuType: IupicsMenuType;
  actionID: number;
  iconClass: string;
  color: string;
  name: string;
  tags: string[];
  isSummary: boolean;
  parentId: number;
  id?: string;
  seqNo?: number;
  width?: number;
}

import { inject, Pipe, PipeTransform } from '@angular/core';
import { SKWTranslateService } from '../services/storekeeper-window-translate.service';

@Pipe({ name: 'SKWTranslate', standalone: true })
export class SKWTranslatePipe implements PipeTransform {
  #SKWTranslateService = inject(SKWTranslateService);

  transform(key: string): string {
    return this.#SKWTranslateService.getTranslation(key);
  }
}

import { DragDropModule } from '@angular/cdk/drag-drop';
import { AsyncPipe, NgClass } from '@angular/common';
import { Component, OnInit, forwardRef, inject } from '@angular/core';
import { CompiereDataJSON2 } from '@compiere-ws/models/compiere-data-json';
import { CustomDesignItem, CustomDesignItemType } from '@iupics-components/models/custom-design';
import ButtonUiComponent from '@iupics-components/standard/fields/button-ui/button-ui.component';
import InputTextUiComponent from '@iupics-components/standard/fields/input-text-ui/input-text-ui.component';
import GridViewUiComponent from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { DynamicContainerDirective } from '@iupics-util/directives/dynamic-container.directive';
import { TranslateModule } from '@ngx-translate/core';
import { has } from 'lodash';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { BehaviorSubject } from 'rxjs';
import SpecificWindowUiComponent from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-location-migration',
  standalone: true,
  imports: [
    DynamicContainerDirective,
    NgClass,
    ProgressBarModule,
    ScrollPanelModule,
    TranslateModule,
    InputTextUiComponent,
    AsyncPipe,
    DragDropModule,
    forwardRef(() => ButtonUiComponent),
    forwardRef(() => ModalUiComponent),
  ],
  templateUrl: './location-migration.component.html',
  styleUrls: ['./location-migration.component.scss'],
})
export class LocationMigrationComponent extends SpecificWindowUiComponent implements OnInit {
  readonly oldColumns: LocationColumnDef[] = [
    { columnName: 'Address1', label: 'LocationMigrationComponent.Address1' },
    { columnName: 'Address2', label: 'LocationMigrationComponent.Address2' },
    { columnName: 'Address3', label: 'LocationMigrationComponent.Address3' },
    { columnName: 'Address4', label: 'LocationMigrationComponent.Address4' },
  ];
  readonly newColumns: LocationColumnDef[] = [
    { columnName: 'NL_Street', label: 'LocationMigrationComponent.NL_Street' },
    { columnName: 'NL_ExtraStreet', label: 'LocationMigrationComponent.NL_ExtraStreet' },
    { columnName: 'NL_Number', label: 'LocationMigrationComponent.NL_Number' },
    { columnName: 'NL_BoxNumber', label: 'LocationMigrationComponent.NL_BoxNumber' },
    { columnName: 'NL_ExtraInfo', label: 'LocationMigrationComponent.NL_ExtraInfo' },
    { columnName: 'NL_SpecialMention', label: 'LocationMigrationComponent.NL_SpecialMention' },
  ];

  customDesignArray: CustomDesignItem[] = [
    {
      vcr: 'vcr',
      type: CustomDesignItemType.GRID,
      tableName: 'Location Migration (Table)',
      cssClass: 'p-col-12',
      rowSelection: 'single',
    },
  ];

  #selectedLocation: {} = {};
  selectedLocation$ = new BehaviorSubject<{}>(this.#selectedLocation);
  #dragColumnName: string;
  #messageManager = inject(MessageManagerService);

  ngOnInit(): void {
    this.showSpecificWindow();
  }

  notifyFromGridAfterViewInit(gridView: GridViewUiComponent) {
    this.#updateTranslation(gridView);
    gridView.GridTabInfinityScrollUiComponent.rowSelection = 'single';
    gridView.GridTabInfinityScrollUiComponent.suppressRowClickSelection = false;
    super.notifyFromGridAfterViewInit(gridView);
  }

  #updateTranslation(gridView: GridViewUiComponent) {
    console.log(gridView);
    const headers = gridView.GridTabInfinityScrollUiComponent.data.columnsTableHeader;
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i];
      this.translateService.set(`LocationMigrationComponent.${header.field}`, `${header.headerName} [${header.field}]`);
    }
  }

  notifySelect(gridView: GridViewUiComponent, rowSelected?: { selected: boolean; data: {}[] }) {
    this.#selectedLocation = rowSelected.data[0] ?? {};
    this.selectedLocation$.next(this.#selectedLocation);
  }

  onDragStart(e: DragEvent, column: LocationColumnDef) {
    e.stopPropagation();
    this.#dragColumnName = column.columnName;
  }

  onDragEnd(e: DragEvent) {
    e.stopPropagation();
    // si on veut jouer avec les styles on peut le faire avec le start et le end
    // le drop sert pour le traitement
  }

  onDrop(e: DragEvent, column: LocationColumnDef) {
    e.stopPropagation();
    if (this.newColumns.find((col) => col.columnName === column.columnName)) {
      this.#selectedLocation[column.columnName] = this.#selectedLocation[this.#dragColumnName];
      this.selectedLocation$.next(this.#selectedLocation);
    }
  }

  updateNLLocation(column: LocationColumnDef, value: any) {
    this.#selectedLocation[column.columnName] = value;
    this.selectedLocation$.next(this.#selectedLocation);
  }

  saveNLLocation(e: MouseEvent) {
    e.stopPropagation();
    const columnNames = Object.keys(this.#selectedLocation);
    let data = {};
    for (let i = 0; i < columnNames.length; i++) {
      const columnName = columnNames[i];
      const datastoreValue = this.#selectedLocation[columnName];
      data[columnName] = datastoreValue;
      if (has(datastoreValue, 'id')) data[columnName] = datastoreValue.id;
    }

    data = { ...data, IsActive: 'Y', NL_IsNLFormat: 'Y' };

    const dataWs: CompiereDataJSON2 = {
      data: [data],
      data_UUID: ['C_Location_ID'],
      displayData: {},
      secondaryColumnFields: [],
      lastRow: 0,
      tab_id: 154,
    };
    this.store.saveLocation(dataWs).subscribe((compiereData) => {
      if (compiereData?.data[0]?.apiz_dataResult?.responseError) {
        throw new Error(compiereData.data[0].apiz_dataResult.responseError[0].message);
      } else {
        this.refreshGrids();
        this.#selectedLocation = {};
        this.selectedLocation$.next(this.#selectedLocation);
        this.#messageManager.newMessage(
          new IupicsMessage(
            this.translateService.instant('editView.saveMessageTitle'),
            this.translateService.instant('editView.saveMessage'),
            'success'
          )
        );
      }
    });
  }
}

type LocationColumnDef = { columnName: string; label: string };

import { Injectable } from '@angular/core';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessageManagerService {
  public static messageSource = new Subject<IupicsMessage | IupicsMessage[]>();

  public static newMessageStatic(messages: IupicsMessage | IupicsMessage[]) {
    this.messageSource.next(messages);
  }

  newMessage(messages: IupicsMessage | IupicsMessage[]) {
    MessageManagerService.messageSource.next(messages);
  }
}

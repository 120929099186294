<span
  [style.display]="displayCss"
  [life]="3000"
  [hideDelay]="250"
  pTooltip="{{ tooltip }}"
  [escape]="false"
  [showDelay]="500"
  tooltipPosition="top"
  tooltipZIndex="2100"
  [style.margin-top.px]="data && data.marginTop > 0 ? data.marginTop : null"
  [style.backgroundColor]="backgroundColorCss">
  <label
    *ngIf="this.inputRef && isLabelDisplay && label !== undefined && label !== null && label.trim().length > 0"
    [style.font-size]="overridedCSS?.labelFontSize"
    [ngClass]="{ 'iu-field-only-label': data?.isFieldOnly }"
    [title]="label"
    >{{ label | textLimit: this.inputRef.nativeElement.clientWidth / 7 }}</label
  >
  <div class="input-number-body">
    <input
      #input
      *ngIf="!multiple"
      class="iu-number-input"
      onkeydown="event.keyCode !== 69"
      type="text"
      [attr.value]="fieldValue"
      (keypress)="isNumber($event)"
      (change)="dataChange(_parseFloat(input.value))"
      (contextmenu)="onContextMenu($event)"
      (keydown.enter)="enterKey.emit($any($event))"
      (press)="onContextMenu($any($event))"
      (blur)="inputBlur.emit($event)"
      [ngModel]="fieldValue | number: numberFormat : lang"
      [ngModelOptions]="{ updateOn: 'blur' }"
      (ngModelChange)="fieldValue = $event"
      [readonly]="isReadOnly ? 'disabled' : null"
      [tabindex]="isReadOnly ? '-1' : null"
      [max]="this.max"
      [min]="this.min"
      [ngClass]="[
        hasConflict ? 'iu-field-conflict-text' : '',
        'quantity',
        'ui-corner-all input-field p-inputtext',
        mandatoryCss || ''
      ]"
      [step]="fieldStep"
      autocomplete="no"
      [style.font-size]="overridedCSS?.inputFontSize"
      [style.height]="overridedCSS?.inputHeight"
      data-cy="input-text"
      [attr.data-cy-columnName]="data ? data.columnName : columnName"
      placeholder="{{ placeHolder }}"
      lang="{{ lang }}"
      (wheel)="OnWheel($event)" />
    <iu-input-chips-ui
      #inputChips
      *ngIf="multiple"
      [cssClass]="'iu-number-input'"
      [inputType]="'number'"
      [addOnSpace]="true"
      [addOnEnter]="true"
      [isLabelDisplay]="false"
      [addOnBlur]="true"
      [editOnBackspace]="true"
      (fieldValueChange)="dataChange($event)"
      [(fieldValue)]="fieldValue"
      [matchPattern]="regexNB"
      [columnName]="data ? data.columnName : columnName"></iu-input-chips-ui>
    <i
      [attr.disabled]="isReadOnly ? 'disabled' : null"
      *ngIf="!this.isCurrencyDisplay"
      #currencySpan
      class="icon-number iconCurrency"
      [style.height]="overridedCSS?.inputHeight"
      aria-hidden="true">
    </i>
    <i
      [attr.disabled]="isReadOnly ? 'disabled' : null"
      *ngIf="this.isCurrencyDisplay"
      #currencySpan
      class="iconCurrency"
      >{{ this.currency }}</i
    >
  </div>
  <i
    *ngIf="hasConflict && dataStored"
    #spanInput
    class="rtconflict fas fa-exclamation-triangle iconClass"
    (click)="showConflictPanel($event)"
    aria-hidden="true"></i>
  <iu-prime-overlay #opValuePref *ngIf="showValuePrefPanel" [dismissable]="false" appendTo="body">
    <iu-value-preference-panel
      [overlayPanel]="opValuePref"
      [fieldValue]="fieldValue"
      [dataStored]="this.dataStored"
      [sourceComponent]="this"
      [data]="this.data"
      (closeEmitter)="toggleValuePreferencePanel($event)"></iu-value-preference-panel>
  </iu-prime-overlay>
</span>

<iu-prime-overlay #opConflict>
  <a class="data-conflict" (click)="chooseData($event, (dataStored?.data)[data.columnName], true); opConflict.hide()">{{
    (dataStored?.data)[data.columnName]
  }}</a>
  |
  <a class="data-conflict" (click)="chooseData($event, conflictedData, false); opConflict.hide()">{{
    conflictedData
  }}</a>
</iu-prime-overlay>

import { Component, inject, model, signal } from '@angular/core';
import { SKWTransferData } from '../../../models/storekeeper-window.model';
import { SKWTranslatePipe } from '../../../pipes/storekeeper-window-translate.pipe';
import { SKWNavigationService } from '../../../services/storekeeper-window-navigation.service';
import { StorekeeperButtonComponent } from '../../storekeeper-button/storekeeper-button.component';
import { StorekeeperInputComponent } from '../../storekeeper-input/storekeeper-input.component';

@Component({
  selector: 'iu-storekeeper-transfer-details',
  standalone: true,
  imports: [StorekeeperInputComponent, StorekeeperButtonComponent, SKWTranslatePipe],
  templateUrl: './storekeeper-transfer-details.component.html',
  styleUrl: './storekeeper-transfer-details.component.scss',
})
export class StorekeeperTransferDetailsComponent {
  #SKWNavigationService = inject(SKWNavigationService);

  canAddPalette = signal(false);

  data = model<SKWTransferData>();

  isCreation = this.#SKWNavigationService.isCreation;

  addPalette = signal(false);

  changeValue(key: keyof SKWTransferData, newValue: unknown) {
    if (this.isCreation()) {
      this.data.update((d) => ({ ...d, [key]: newValue }));
    }
  }

  toggleAddPalette(event: MouseEvent) {
    this.addPalette.update((v) => !v);
  }
}

import { NgClass } from '@angular/common';
import { Component, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import SpecificWindowUiComponent from '@iupics-components/specific/window/specific-window-ui/specific-window-ui.component';
import ButtonUiComponent from '@iupics-components/standard/fields/button-ui/button-ui.component';
import EditViewUiComponent from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { DynamicContainerDirective } from '@iupics-util/directives/dynamic-container.directive';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';

@Component({
  selector: 'iu-create-from-statement-window-ui',
  templateUrl: './create-from-statement-window-ui.component.html',
  styleUrls: ['./create-from-statement-window-ui.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    ProgressBarModule,
    ScrollPanelModule,
    DynamicContainerDirective,
    ButtonUiComponent,
    ModalUiComponent,
    // UniversalFilterUiComponent,
    // GridTabInfinityScrollUiComponent,
    TranslateModule,
  ],
})
export default class CreateFromStatementWindowUiComponent extends SpecificWindowUiComponent implements OnInit {
  @ViewChild('left', { read: ViewContainerRef, static: true })
  vcrLeft: ViewContainerRef;
  @ViewChild('middle', { read: ViewContainerRef, static: true })
  vcrMiddle: ViewContainerRef;
  @ViewChild('right', { read: ViewContainerRef, static: true })
  vcrRight: ViewContainerRef;
  @ViewChild('bot', { read: ViewContainerRef, static: true })
  @Output()
  vcrBot: ViewContainerRef;
  SumSelectedRows = 0;
  countOfRows = 0;

  constructor() {
    super();
    this.isModal = false;
    this.customDesignArray.push(
      {
        vcr: 'vcrLeft',
        type: CustomDesignItemType.FIELD,
        columnName: 'ZSubPaymentRule_ID',
        cssClass: 'p-col-12',
      },
      {
        vcr: 'vcrMiddle',
        type: CustomDesignItemType.FIELD,
        columnName: 'DateAcct',
        cssClass: 'p-col-12',
      },
      {
        vcr: 'vcrRight',
        type: CustomDesignItemType.FIELD,
        columnName: 'IsPaymentDates',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrRight',
        type: CustomDesignItemType.FIELD,
        columnName: 'Z_LoadCamt54_ID',
        cssClass: 'p-col-12',
      },
      {
        vcr: 'vcrLeft',
        type: CustomDesignItemType.FIELD,
        columnName: 'Amount',
        cssClass: 'p-col-12',
      },
      {
        vcr: 'vcrMiddle',
        type: CustomDesignItemType.FIELD,
        columnName: 'EcartAmt',
        cssClass: 'p-col-12',
      },
      {
        vcr: 'vcrBot',
        type: CustomDesignItemType.GRID,
        tableName: 'Create From Statement Table',
        cssClass: 'p-col-12',
        shouldSelectFirst: false,
      },
      {
        vcr: 'vcrBot',
        type: CustomDesignItemType.FIELD,
        columnName: 'Select_All',
        cssClass: 'p-col-12',
      },
      {
        vcr: 'vcrBot',
        type: CustomDesignItemType.FIELD,
        columnName: 'Total',
        cssClass: 'p-col-12',
      },
      {
        vcr: 'vcrButtons',
        type: CustomDesignItemType.FIELD,
        columnName: 'Processing',
        cssClass: 'p-col-12 p-md-3 p-lg-2',
      },
      {
        vcr: 'vcrRight',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_BankStatement_ID',
        cssClass: 'p-col-12',
      },
      {
        vcr: 'vcrRight',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_Currency_ID',
        cssClass: 'p-col-12',
      }
    );
  }
  ngOnInit() {
    if (!this.dataStore) {
      this.dataStore = this.store.newSpecificWindowData(this.formId);
    }
    if (this.parentComponent instanceof EditViewUiComponent) {
      this.setFormStoreData(
        'DateAcct',
        (<EditViewUiComponent>this.parentComponent).editTabs[0].dataStored.data['StatementDate']
      );
      this.setFormStoreData(
        'C_BankStatement_ID',
        (<EditViewUiComponent>this.parentComponent).editTabs[0].dataStored.data['C_BankStatement_ID']
      );

      this.showSpecificWindow();
    }
  }
  notifyFromDataChange(item: any) {
    if (
      (item.data.columnName === 'Z_LoadCamt54_ID' || item.data.columnName === 'DateAcct') &&
      this.dataStore.data.ZSubPaymentRule_ID === null
    ) {
      return;
    }
    // if (this.dataStore.data.ZSubPaymentRule_ID === null) {
    //   this.messageManager.newMessage(
    //     new IupicsMessage(
    //       this.translateService.instant('specificWindow.fillMandatory') +
    //         this.getField('ZSubPaymentRule_ID').data.label,
    //       'error'
    //     )
    //   );
    // }
    if (this.gridViews[0].GridTabInfinityScrollUiComponent !== undefined) {
      if (this.dataStore.data.Select_All === 'Y') {
        this.selectAllGridLines();
      } else if (
        this.dataStore.data.Select_All === 'N' &&
        this.gridViews[0].GridTabInfinityScrollUiComponent.grid.api.getSelectedRows().length === 1
      ) {
        this.clearAllGridsSelection();
      } else {
        this.dataStore.data.Select_All = 'N';
      }
      this.setDataContainersValueWithChangedStore();
    }

    if (this.SumSelectedRows !== 0) {
      this.dataStore.data.EcartAmt = this.SumSelectedRows - this.dataStore.data.Amount;
      this.dataStore.data.Total = this.dataStore.data.Amount + this.dataStore.data.EcartAmt;
    }
    if (
      item.data.columnName === 'ZSubPaymentRule_ID' ||
      item.data.columnName === 'Z_LoadCamt54_ID' ||
      item.data.columnName === 'DateAcct'
    ) {
      this.refreshGrids(this.dataStore, false);
    }
    if (item.data.columnName === 'Select_All') {
      this.totalOfAmount();
      if (this.dataStore.data.Select_All == 'N') {
        this.clearAllGridsSelection();
      }
    }
  }
  totalOfAmount() {
    // if (this.dataStore.data.ZSubPaymentRule_ID === null) {
    //   this.messageManager.newMessage(
    //     new IupicsMessage(
    //       this.translateService.instant('specificWindow.fillMandatory') +
    //         this.getField('ZSubPaymentRule_ID').data.label,
    //       'error'
    //     )
    //   );
    // } else {
      this.SumSelectedRows = 0;
      this.dataStore.data.EcartAmt = this.getField('EcartAmt').data.defaultValue;
      if (this.dataStore.data.selections[0].selection.length > 0) {
        this.getField('Amount').data.readOnlyLogic = null; // je ne sais pas pq on fait ça car on ne le reset plus par après
        for (let i = 0; i < this.dataStore.data.selections[0].selection.length; i++) {
          this.SumSelectedRows += parseFloat(this.dataStore.data.selections[0].selection[i].Amount);
        }
        this.dataStore.data.Amount = this.SumSelectedRows;
        this.dataStore.data.Total = this.SumSelectedRows;
      }
      if (this.dataStore.data.Select_All === 'N' && this.dataStore.data.selections[0].selection.length === 0) {
        this.dataStore.data.Amount = this.getField('Amount').data.defaultValue;
        this.dataStore.data.Total = this.getField('Total').data.defaultValue;
        this.dataStore.data.EcartAmt = this.getField('EcartAmt').data.defaultValue;
      }
      this.setDataContainersValueWithChangedStore();
   // }
  }
  notifyFromRowSelected(rowSelected: any) {
    this.countOfRows = this.gridViews[0].GridTabInfinityScrollUiComponent.grid.api.getRowCount();

    if (rowSelected.length === this.countOfRows) {
      this.dataStore.data.Select_All = 'Y';
    } else {
      this.dataStore.data.Select_All = 'N';
    }
    if (rowSelected.length === 0) {
      this.dataStore.data.Amount = this.getField('Amount').data.defaultValue;
      this.dataStore.data.Total = this.getField('Total').data.defaultValue;
      this.dataStore.data.EcartAmt = this.getField('EcartAmt').data.defaultValue;
      this.dataStore.data.Select_All = 'N';
    }
    this.dataContainers.forEach((datacontainer) => {
      if (this.dataStore.data && datacontainer.data && datacontainer.data.columnName) {
        datacontainer.updateStore(this.dataStore.data[datacontainer.data.columnName]);
      }
    });
    this.totalOfAmount();
  }

  checkBeforeProcessing() {
    const totalAmount = parseFloat(this.dataStore.data.EcartAmt) + parseFloat(this.dataStore.data.Amount);
    if (this.dataStore.data.ZSubPaymentRule_ID === null) {
      this.messageManager.newMessage(
        new IupicsMessage(
          this.translateService.instant('specificWindow.fillMandatory') +
            this.getField('ZSubPaymentRule_ID').data.label,
          'error'
        )
      );
    } else if (parseFloat(this.dataStore.data.Amount) === 0) {
      this.messageManager.newMessage(
        new IupicsMessage(
          this.translateService.instant('specificWindow.createFromStatement.sortieCaisseAmtZeroERR'),
          'error'
        )
      );
    } else if (totalAmount !== this.dataStore.data.Total) {
      this.messageManager.newMessage(
        new IupicsMessage(
          this.translateService.instant('specificWindow.createFromStatement.sortieCaisseEcartAmtZeroERR'),
          'error'
        )
      );
    } else {
      return true;
    }
  }
}

<div
  #specificContainer
  class="iu-specific-window iu-specific-window-container"
  [attr.data-cy-specific-window-name]="name">
  @if (!isModal && isLoading) {
    <p-progressBar [mode]="'indeterminate'" [value]="0" [showValue]="false" [styleClass]="'loading-progressbar'" />
  }
  <div class="fields p-grid" style="margin-bottom: 10px">
    <ng-template iuDynamicContainer />
  </div>
  <p-tabView (onChange)="onResize($event)">
    <p-tabPanel header="{{ 'productPrice.priceHistory' | translate }}">
      <div class="top-container-PriceHistory">
        <div class="p-col-12">
          <ng-template #vcrHistoriqueDesPrix />
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="{{ 'productPrice.qtyReserved' | translate }}">
      <div class="top-container-PriceHistory">
        <div class="p-col-12">
          <ng-template #vcrQtyReserved />
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="{{ 'productPrice.qtyOrdered' | translate }}">
      <div class="top-container-PriceHistory">
        <div class="p-col-12">
          <ng-template #vcrOrdered />
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="{{ 'productPrice.unconQty' | translate }}">
      <div class="top-container-PriceHistory">
        <div class="p-col-12">
          <ng-template #vcrUnconfirmed />
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel
      [headerStyle]="{ display: !isProductView ? 'none' : 'block' }"
      header="{{ 'productPrice.avalaibaleSales' | translate }}">
      <div class="top-container-PriceHistory">
        <div class="p-col-12">
          <ng-template #vcrATP />
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="{{ 'productPrice.salesContract' | translate }}">
      <div class="top-container-PriceHistory">
        <div class="p-col-12">
          <ng-template #vcrBlanketSO />
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="{{ 'productPrice.purchaseContract' | translate }}">
      <div class="top-container-PriceHistory">
        <div class="p-col-12">
          <ng-template #vcrBlanketPO />
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>
</div>
<div [ngClass]="[!displayFormUI ? 'hidden' : '', 'specificModal']">
  <iu-modal-ui
    [title]="specificWindowTitle"
    [isModalDisplay]="displayFormUI"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false })">
    <ng-template #vcrSpecific />
  </iu-modal-ui>
</div>

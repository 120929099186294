import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Directive, effect, viewChild } from '@angular/core';
import { debounce } from 'lodash';
import { debounceTime, tap } from 'rxjs';
import { SKWDataListParams } from '../models/storekeeper-window.model';

@Directive({
  standalone: true,
})
export class SKWVirtualScrollDirective {
  readonly DEFAULT_TRANSFER_FETCH_LENGTH = 30;

  viewport = viewChild(CdkVirtualScrollViewport);

  currentParams: SKWDataListParams = {
    startRow: 0,
    endRow: this.DEFAULT_TRANSFER_FETCH_LENGTH,
  };

  constructor() {
    this.handleScrollProgress = debounce(this.handleScrollProgress);
    effect(() => {
      const debounce$ = this.viewport()
        ?.elementScrolled()
        ?.pipe(
          debounceTime(100),
          tap(() => this.handleScrollProgress())
        );
      debounce$?.subscribe();
    });
  }

  handleScrollProgress() {
    const end = this.viewport().getRenderedRange().end;
    const total = this.viewport().getDataLength();
    if (end >= total * 0.75) {
      this.fetchMoreData();
    }
  }

  fetchMoreData(force = false) {
    // implement this...
  }

  trackByFn(index: number, item: any) {
    return index;
  }
}

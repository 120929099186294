import { DataKanban, DataKanbanType } from '@iupics-components/models/kanban-interface';
import { IupicsColumnKanban } from '@iupics-manager/models/iupics-data';

export class KanbanUtils {
  // TODO Change this with AD Definition
  // public columns_display_AD: IupicsColumnKanban[];

  static transformDataForKanbanView(
    data: any,
    columns_display_AD: IupicsColumnKanban[],
    kanbanImageColumn: string
  ): DataKanban | DataKanban[] {
    if (Array.isArray(data)) {
      return data.reduce(
        (acc, d) => [...acc, this.transformData(d, this.getColumnsDisplayAD(columns_display_AD, d), kanbanImageColumn)],
        []
      );
    } else {
      return this.transformData(data, this.getColumnsDisplayAD(columns_display_AD, data), kanbanImageColumn);
    }
  }

  private static getColumnsDisplayAD(columns_display_AD: IupicsColumnKanban[], data: any) {
    if (
      !columns_display_AD ||
      (columns_display_AD &&
        columns_display_AD[0].dataAlignLeft.length === 0 &&
        columns_display_AD[0].dataAlignright.length === 0 &&
        columns_display_AD[1].dataAlignLeft.length === 0 &&
        columns_display_AD[1].dataAlignright.length === 0)
    ) {
      return [
        {
          dataAlignLeft: data['DocumentNo'] === undefined ? (data['Name'] ? ['Name'] : ['Value']) : ['DocumentNo'],
          dataAlignright: [],
        },
        {
          dataAlignLeft: data['GrandTotal'] !== undefined ? ['GrandTotal'] : ['Description'],
          dataAlignright: [],
        },
      ];
    }

    return [...columns_display_AD];
  }

  private static transformData(data: any, columns_display_AD: IupicsColumnKanban[], kanbanImageColumn: string) {
    const dataKanban: DataKanban = {
      data: data,
      image: null,
      rows: [],
    };

    if (kanbanImageColumn) {
      dataKanban.image =
        data[kanbanImageColumn] && data[kanbanImageColumn]['id']
          ? data[kanbanImageColumn]['displayValue']
          : data[kanbanImageColumn];
      dataKanban.image = dataKanban.image ? dataKanban.image : '';
    }

    for (const row of columns_display_AD) {
      const dataAlignLeft = row.dataAlignLeft.map((columnName) => {
        return {
          type: columnName === 'ImageUrl' ? DataKanbanType.IMG : DataKanbanType.TEXT,
          value: data[columnName] && data[columnName]['id'] ? data[columnName]['displayValue'] : data[columnName],
        };
      });

      const dataAlignright = row.dataAlignright.map((columnName) => {
        return {
          type: columnName === 'ImageUrl' ? DataKanbanType.IMG : DataKanbanType.TEXT,
          value: data[columnName] && data[columnName]['id'] ? data[columnName]['displayValue'] : data[columnName],
        };
      });

      dataKanban.rows.push({
        dataAlignLeft,
        dataAlignright,
      });
    }

    return dataKanban;
  }
}

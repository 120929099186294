import { Subscription } from 'rxjs';

export namespace SubscriptionsUtils {
  /**
   * Unsubscribe from all subscriptions passed in parameter.
   * @param subscriptions
   */
  export function unsubscribe(...subscriptions: Subscription[]): void {
    for (const sub of subscriptions) sub?.unsubscribe();
  }
}

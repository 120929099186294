<div class="iu-calendar-toolbar-ui">
  <div class="iu-calendar-toolbar-ui-left">
    <ng-container *ngIf="!isMobile; else mobileView">
      <button
        pButton
        [life]="3000"
        [hideDelay]="250"
        pTooltip="{{ 'calendar.month' | translate }}"
        tooltipPosition="top"
        [showDelay]="1000"
        class="p-button"
        label="{{ 'calendar.month' | translate }}"
        (click)="changeView.emit('dayGridMonth')"></button>
      <button
        pButton
        [life]="3000"
        [hideDelay]="250"
        pTooltip="{{ 'calendar.week' | translate }}"
        tooltipPosition="top"
        [showDelay]="1000"
        class="p-button"
        label="{{ 'calendar.week' | translate }}"
        (click)="changeView.emit('dayGridWeek')"></button>
      <button
        pButton
        [life]="3000"
        [hideDelay]="250"
        pTooltip="{{ 'calendar.day' | translate }}"
        tooltipPosition="top"
        [showDelay]="1000"
        class="p-button p-button-default"
        label="{{ 'calendar.day' | translate }}"
        (click)="changeView.emit('dayGridDay')"></button>
    </ng-container>

    <ng-template #mobileView>
      <button
        pButton
        [life]="3000"
        [hideDelay]="250"
        pTooltip="{{ 'calendar.' + cycle.current.replace('dayGrid', '') | lowercase | translate }}"
        tooltipPosition="top"
        [showDelay]="1000"
        class="p-button"
        label="{{ 'calendar.' + cycle.current.replace('dayGrid', '') | lowercase | translate }}"
        (click)="switchMobileView($event, cycle.current)"></button>
    </ng-template>
  </div>
  <div class="iu-calendar-toolbar-ui-right">
    <button
      pButton
      [life]="3000"
      [hideDelay]="250"
      pTooltip="{{ 'calendar.today' | translate }}"
      tooltipPosition="top"
      [showDelay]="1000"
      class="p-button"
      label="{{ 'calendar.today' | translate }}"
      (click)="click($event, 'today')"></button>
  </div>
  <div class="iu-calendar-toolbar-ui-center">
    <button
      pButton
      [life]="3000"
      [hideDelay]="250"
      pTooltip="prev"
      tooltipPosition="top"
      [showDelay]="1000"
      class="p-button"
      icon="fa fa-angle-left"
      (click)="click($event, 'prev')"></button>
    <div class="iu-calendar-toolbar-ui-title">{{ title }}</div>
    <button
      pButton
      [life]="3000"
      [hideDelay]="250"
      pTooltip="next"
      tooltipPosition="top"
      [showDelay]="1000"
      class="p-button"
      icon="fa fa-angle-right"
      (click)="click($event, 'next')"></button>
  </div>
  <div class="iu-calendar-toolbar-ui-clear"></div>
</div>

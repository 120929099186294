import { Pipe, PipeTransform } from '@angular/core';
import { ColumnFilterAutocomplete } from '@iupics-components/models/autocomplete-interfaces';

@Pipe({
  name: 'columnGroupFilter',
  standalone: true,
})
export class ColumnGroupFilterPipe implements PipeTransform {
  transform(groupCols: { items: ColumnFilterAutocomplete[] }): { items: ColumnFilterAutocomplete[] } {
    const output = {
      items: groupCols.items.filter((item) => this.filterOtherGroupCol(item)),
    };
    return output;
  }

  private filterOtherGroupCol(item: ColumnFilterAutocomplete): boolean {
    return item.columnInfo.fieldEntity.field.hasOwnProperty('ColumnSQL') &&
      item.columnInfo.fieldEntity.field.ColumnSQL &&
      (item.columnInfo.fieldEntity.field.ColumnSQL as string).match(/\bselect\b/gim) !== null
      ? false
      : true;
  }
}

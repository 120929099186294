@if (isPreviewDisplayed && modal) {
  <iu-modal-ui
    #modalRef
    (closeModalEmitter)="isPreviewDisplayed = false; showError = false"
    [hasFooter]="true"
    [appendTo]="appendTo"
    [angularStyle]="modalStyle">
    <ng-container
      [ngTemplateOutlet]="mainTemplate"
      [ngTemplateOutletContext]="{
        dataUrl: dataUrl,
        innerHtmlContent: innerHtmlContent,
        isImagePreview: isImagePreview,
        modal: modal,
        imgStyle: imgStyle,
        src: src,
        fileName: fileName,
        showError: showError,
        isDocPreview: isDocPreview,
        mediaType: mediaType
      }" />
    <ng-content />
  </iu-modal-ui>
}

@if (overlay) {
  <iu-prime-overlay #opRef [appendTo]="appendTo" [handleAlign]="handleAlign.bind(this)">
    <div class="usearch-file-preview">
      <ng-container
        [ngTemplateOutlet]="mainTemplate"
        [ngTemplateOutletContext]="{
          dataUrl: dataUrl,
          innerHtmlContent: innerHtmlContent,
          isImagePreview: isImagePreview,
          modal: modal,
          imgStyle: imgStyle,
          src: src,
          fileName: fileName,
          showError: showError,
          isDocPreview: isDocPreview,
          mediaType: mediaType
        }" />
    </div>
  </iu-prime-overlay>
}

@if (isPreviewDisplayed && !modal && !overlay) {
  <ng-container
    [ngTemplateOutlet]="mainTemplate"
    [ngTemplateOutletContext]="{
      dataUrl: dataUrl,
      innerHtmlContent: innerHtmlContent,
      isImagePreview: isImagePreview,
      modal: modal,
      imgStyle: imgStyle,
      src: src,
      fileName: fileName,
      showError: showError,
      isDocPreview: isDocPreview,
      mediaType: mediaType
    }" />
}

<ng-template
  #mainTemplate
  let-dataUrl="dataUrl"
  let-innerHtmlContent="innerHtmlContent"
  let-isImagePreview="isImagePreview"
  let-modal="modal"
  let-imgStyle="imgStyle"
  let-src="src"
  let-fileName="fileName"
  let-showError="showError"
  let-isDocPreview="isDocPreview"
  let-mediaType="mediaType">
  @if (hasPreviewError) {
    <p>
      {{ 'previewDoc.noPreview' | translate }}
    </p>
  }

  @if (dataUrl || innerHtmlContent) {
    @if (isImagePreview) {
      <img
        class="previewImg"
        [data-cy]="modal ? 'modal-preview-data' : 'preview-data'"
        [ngStyle]="imgStyle"
        [src]="dataUrl"
        [alt]="fileName" />
    }

    @if (['sh', 'txt', 'csv', 'xlsx'].includes(extension) && innerHtmlContent) {
      <div class="other-viewer" [innerHTML]="innerHtmlContent"></div>
    } @else {
      @switch (extension) {
        @case ('pdf') {
          <iu-pdf-viewer [dataUrl]="dataUrl" [data-cy]="modal ? 'modal-preview-data' : 'preview-data'" />
        }
        @case (xml) {
          <iu-xml-viewer [blob]="blob" [data-cy]="modal ? 'modal-preview-data' : 'preview-data'" />
        }
      }
    }

    @if (showError) {
      {{ 'previewDoc.noExtension' | translate }}
    }

    <!-- TODO: check if this is necessary -->
    <ng-template #docError>
      @if (isDocPreview) {
        <p>
          {{ 'previewDoc.noPreview' | translate }}
          <a target="_blank" [href]="dataUrl" [download]="fileName"> {{ 'previewDoc.downloadIt' | translate }}</a>
        </p>
      }
    </ng-template>
  }
</ng-template>

import { NgClass, NgIf } from '@angular/common';
import {
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { IupicsJsonField } from '@iupics-manager/models/iupics_json_field';
import { createComponent } from '@iupics-util/tools/component-cache-loader';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'iu-input-json-line-ui',
  templateUrl: './input-json-line-ui.component.html',
  styleUrls: ['./input-json-line-ui.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgIf, NgClass],
})
export default class InputJsonLineUiComponent implements OnInit {
  keyJsonField: IupicsJsonField;
  @Input() nameToShow: any;
  @Input() key: any;
  oldKey: any;
  @Input() value: any;
  keyComponentRef: ComponentRef<any>;
  valueComponentRef: ComponentRef<any>;
  @Output() dataChangeEmitter: EventEmitter<any> = new EventEmitter();
  @ViewChild('vcrValue', { read: ViewContainerRef, static: true })
  vcrValue: ViewContainerRef;
  @ViewChild('vcrKey', { read: ViewContainerRef, static: true })
  vcrKey: ViewContainerRef;
  @Input() jsonField: IupicsJsonField;
  @Input() isFree: boolean = false;
  constructor(private translateService: TranslateService) {}
  ngOnInit() {
    if (this.jsonField) {
      this.keyJsonField = {
        componentName: 'InputTextUiComponent',
        description: this.jsonField.name,
        name: this.jsonField.name,
        nameToShow: this.jsonField.nameToShow,
        numberType: null,
        seqNo: -1,
        jsonFieldId: -1,
        isKey: true,
        isDisplayed: true,
      };
      this.buildComponents(this.jsonField);
    }
  }

  buildComponents(jsonField: IupicsJsonField) {
    this.buildComponent(this.keyJsonField, true);
    this.buildComponent(jsonField, false);
  }
  buildComponent(jsonField: IupicsJsonField, isKey = false) {
    const usedVcr = isKey ? this.vcrKey : this.vcrValue;
    usedVcr.clear();
    const componentRef = createComponent<any>(usedVcr, jsonField.componentName);
    componentRef.instance.name = !this.isFree ? jsonField.name : isKey ? 'Key' : 'Value';
    componentRef.instance.nameToShow = !this.isFree
      ? jsonField.nameToShow
      : isKey
        ? this.translateService.instant('inputJsonLine.key')
        : this.translateService.instant('inputJsonLine.value');
    componentRef.instance.description = jsonField.description;
    componentRef.instance.componentRef = componentRef;
    componentRef.instance.numberType = jsonField.numberType;
    componentRef.instance.isFree = true;
    componentRef.instance.hasMultiValues = false;
    componentRef.instance.data = {};
    componentRef.instance.isStandalone = true;
    componentRef.instance.isFromInputJson = true;
    if (isKey) {
      (<AbstractDataContainer>componentRef.instance).fieldValue = this.nameToShow;
      if (!this.isFree || this.key == '_ID') (<AbstractDataContainer>componentRef.instance).isReadOnly = true;
    } else {
      (<AbstractDataContainer>componentRef.instance).fieldValue = this.value;
      if (this.key == '_ID') (<AbstractDataContainer>componentRef.instance).isReadOnly = true;
    }
    componentRef.instance.parentComponent = this;
    (<AbstractDataContainer>componentRef.instance).fieldValueModified.subscribe((value) => {
      const values: any = {};
      if (isKey) {
        this.oldKey = this.key;
        this.key = value;
      } else {
        this.value = value;
      }
      if (this.oldKey != this.key) {
        values[this.oldKey] = undefined;
      }
      values[this.key] = this.value;
      this.dataChangeEmitter.emit(values);
    });
    if (isKey) this.keyComponentRef = componentRef;
    else {
      this.valueComponentRef = componentRef;
    }
  }
}

import { Injectable } from '@angular/core';
import { AppConfig } from '@iupics-config/app.config';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class ResourceAccessService {
  private accessURL: string;

  constructor(
    private http: ApiService,
    private config: AppConfig
  ) {}

  getAccess(type: string, id: number): Observable<boolean> {
    if (this.accessURL === undefined) {
      this.accessURL = this.config.getBackendResource('resourceAccess');
    }
    return this.http.get<boolean>(`${this.accessURL}/${id}?type=${type}`);
  }
  getAccessRoles(type: string, id: number): Observable<number[]> {
    if (this.accessURL === undefined) {
      this.accessURL = this.config.getBackendResource('resourceAccessRoles');
    }
    return this.http.get<number[]>(`${this.accessURL}/${id}?type=${type}`);
  }
}

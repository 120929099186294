<apiz-toolbar
  class="iu-apiz-toolbar"
  [filterTplRef]="universalFilter"
  [ngStyle]="{ display: hasUniversalFilter ? '' : 'none' }">
  <div azProjectAs="toolbar-container-left" class="p-toolbar-group-left">
    @if (isMobile && isTabTopLevel) {
      <button
        pButton
        data-cy="menuBar-showButtons-btn"
        tooltipPosition="top"
        [showDelay]="250"
        [life]="3000"
        [hideDelay]="250"
        pTooltip="{{ 'menuBarDetails.showButtons' | translate }}"
        type="button"
        icon="icon-menu-bars"
        class="p-menu-bar-button"
        [attr.data-cy-toggle-state]="showMoreAction ? 'opened' : 'closed'"
        (click)="onShowMoreAction($event)"></button>

      @if (showMoreAction) {
        <ng-container [ngTemplateOutlet]="buttons" />

        <ng-container [ngTemplateOutlet]="viewButtons" />
      }
    } @else if (!isMobile || !isTabTopLevel) {
      <ng-container [ngTemplateOutlet]="buttons" />
    }
  </div>

  <div azProjectAs="toolbar-container-right" class="p-toolbar-group-right">
    <iu-favorite-filter [gridView]="gridView" />
    @if (visibleButton && (!isMobile || !isTabTopLevel)) {
      <ng-container [ngTemplateOutlet]="viewButtons" />
    }
  </div>
</apiz-toolbar>

<ng-template #universalFilter>
  <iu-universal-filter-ui [isMobile]="isMobile" [gridView]="gridView" />
</ng-template>

<ng-template #buttons>
  @if (visibleButton) {
    <button
      [disabled]="!newBtnCondition"
      pButton
      tooltipPosition="right"
      [showDelay]="250"
      [life]="3000"
      [hideDelay]="250"
      pTooltip="{{ tooltipLabels.add }}"
      type="button"
      icon="icon-add"
      class="p-menu-bar-button"
      (click)="newEmitter.emit()"
      iuKeybinding
      iuKeybinding-code="menu-bar.new"
      [iuKeybinding-active]="isTabTopLevel && !isSplitView"
      [iuKeybinding-focusTab]="activeTabID"
      data-cy="button-newRecord"></button>

    @if (visibleButtonMultiSelection && isDeleteable && (isReadOnly === undefined || isReadOnly === false)) {
      <button
        pButton
        tooltipPosition="top"
        [showDelay]="250"
        [life]="3000"
        [hideDelay]="250"
        pTooltip="{{ tooltipLabels.delete }}"
        type="button"
        icon="icon-delete"
        class="p-menu-bar-button"
        (click)="removeEmitter.emit($event)"
        iuKeybinding
        [iuKeybinding-active]="isTabTopLevel && !isSplitView"
        iuKeybinding-code="menu-bar.delete"
        [iuKeybinding-focusTab]="activeTabID"></button>
    }
  }

  <button
    pButton
    tooltipPosition="top"
    [showDelay]="250"
    [life]="3000"
    [hideDelay]="250"
    pTooltip="{{ tooltipLabels.refresh }}"
    type="button"
    icon="icon-refresh3x"
    (click)="refreshEmitter.emit()"
    class="p-menu-bar-button"
    iuKeybinding
    [iuKeybinding-active]="isTabTopLevel && !isSplitView"
    iuKeybinding-code="menu-bar.refresh"
    [iuKeybinding-focusTab]="activeTabID"
    data-cy="grid-btn-refresh"></button>
  @if (visibleButton) {
    <button
      pButton
      tooltipPosition="top"
      [showDelay]="250"
      [life]="3000"
      [hideDelay]="250"
      pTooltip="{{ tooltipLabels.export }}"
      type="button"
      icon="icon-export"
      (click)="openExportDataModalEmitter.emit($event)"
      class="p-menu-bar-button"
      iuKeybinding
      [iuKeybinding-active]="isTabTopLevel && !isSplitView"
      iuKeybinding-code="menu-bar.export"
      [iuKeybinding-focusTab]="activeTabID"></button>
  }
</ng-template>

<ng-template #viewButtons>
  @if (isTabTopLevel || visibleTreeButton) {
    <button
      pButton
      tooltipPosition="top"
      [showDelay]="250"
      [life]="3000"
      [hideDelay]="250"
      pTooltip="{{ tooltipLabels.gridView }}"
      type="button"
      icon="icon-table"
      (click)="switchToGridView()"
      class="p-menu-bar-button"
      iuKeybinding
      [iuKeybinding-active]="isTabTopLevel && !isSplitView"
      iuKeybinding-code="menu-bar.grid-view-type"
      [iuKeybinding-focusTab]="activeTabID"></button>
  }
  @if (isTabTopLevel) {
    <button
      pButton
      tooltipPosition="top"
      [showDelay]="250"
      [life]="3000"
      [hideDelay]="250"
      pTooltip="{{ tooltipLabels.kanbanView }}"
      type="button"
      icon="icon-kanban"
      (click)="switchToKanbanView()"
      class="p-menu-bar-button"
      iuKeybinding
      [iuKeybinding-active]="isTabTopLevel && !isSplitView"
      iuKeybinding-code="menu-bar.kanban-view-type"
      [iuKeybinding-focusTab]="activeTabID"></button>
    <button
      pButton
      tooltipPosition="top"
      [showDelay]="250"
      [life]="3000"
      [hideDelay]="250"
      pTooltip="{{ tooltipLabels.calendarView }}"
      type="button"
      icon="icon-calendar"
      (click)="switchToCalendarView()"
      class="p-menu-bar-button"
      iuKeybinding
      [iuKeybinding-active]="isTabTopLevel && !isSplitView"
      iuKeybinding-code="menu-bar.calendar-view-type"
      [iuKeybinding-focusTab]="activeTabID"></button>
    <button
      pButton
      tooltipPosition="top"
      [showDelay]="250"
      [life]="3000"
      [hideDelay]="250"
      pTooltip="{{ tooltipLabels.chartView }}"
      type="button"
      icon="icon-graph-smart"
      (click)="switchToChartView()"
      class="p-menu-bar-button"
      iuKeybinding
      [iuKeybinding-active]="isTabTopLevel && !isSplitView"
      iuKeybinding-code="menu-bar.chart-view-type"
      [iuKeybinding-focusTab]="activeTabID"></button>
  }
  @if (visibleTreeButton) {
    <button
      pButton
      data-cy="menuBar-tree-view-type-btn"
      tooltipPosition="top"
      [showDelay]="250"
      [life]="3000"
      [hideDelay]="250"
      pTooltip="{{ tooltipLabels.treeView }}"
      type="button"
      icon="icon-tree"
      (click)="switchToTreeView()"
      class="p-menu-bar-button"
      iuKeybinding
      [iuKeybinding-active]="isTabTopLevel && !isSplitView"
      iuKeybinding-code="menu-bar.tree-view-type"
      [iuKeybinding-focusTab]="activeTabID"></button>
  }
</ng-template>

import { NgTemplateOutlet } from '@angular/common';
import { Component, computed, inject, input, model } from '@angular/core';
import { map, tap } from 'rxjs';
import {
  SKWLineStatus,
  SKWStateActionType,
  SKWTransferHeaderData,
  SKWTransferLineData,
} from '../../models/storekeeper-window.model';
import { SKWTranslatePipe } from '../../pipes/storekeeper-window-translate.pipe';
import { SKWContextService } from '../../services/storekeeper-window-context.service';
import { SKWMessageService, SKWMessageType } from '../../services/storekeeper-window-message.service';
import { SKWNavigationService } from '../../services/storekeeper-window-navigation.service';
import { SKWDataService } from '../../services/strokeeper-window-data.service';
import { StorekeeperButtonComponent } from '../storekeeper-button/storekeeper-button.component';
import { StorekeeperEmptyDataComponent } from '../storekeeper-empty-data/storekeeper-empty-data.component';
import { StorekeeperInputComponent } from '../storekeeper-input/storekeeper-input.component';
import { StorekeeperNavbarButtonComponent } from '../storekeeper-navbar/storekeeper-navbar-button/storekeeper-navbar-button.component';
import { StorekeeperNavbarComponent } from '../storekeeper-navbar/storekeeper-navbar.component';
import { StorekeeperTransferDetailsComponent } from './storekeeper-transfer-details/storekeeper-transfer-details.component';
import { StorekeeperTransferLineDetailsComponent } from './storekeeper-transfer-line-details/storekeeper-transfer-line-details.component';
import { StorekeeperTransferLinesComponent } from './storekeeper-transfer-lines/storekeeper-transfer-lines.component';

@Component({
  selector: 'iu-storekeeper-transfer',
  standalone: true,
  imports: [
    StorekeeperInputComponent,
    StorekeeperButtonComponent,
    StorekeeperEmptyDataComponent,
    StorekeeperNavbarComponent,
    StorekeeperNavbarButtonComponent,
    StorekeeperEmptyDataComponent,
    StorekeeperTransferLineDetailsComponent,
    StorekeeperTransferDetailsComponent,
    StorekeeperTransferLinesComponent,
    NgTemplateOutlet,
    SKWTranslatePipe,
  ],
  templateUrl: './storekeeper-transfer.component.html',
  styleUrl: './storekeeper-transfer.component.scss',
})
export class StorekeeperTransferComponent {
  #SKWNavigationService = inject(SKWNavigationService);
  #SKWContextService = inject(SKWContextService);
  #SKWDataService = inject(SKWDataService);
  #SKWMessageService = inject(SKWMessageService);

  state = this.#SKWContextService.state;
  tabActive = this.#SKWNavigationService.detailTabActive;
  listStatusTabActive = this.#SKWNavigationService.listStatusTabActive;

  create = input(false);

  data = model<SKWTransferHeaderData>(this.#SKWNavigationService.transferActive());

  dataLines = model<SKWTransferLineData[]>([]);

  lines = computed(() => {
    const lines = this.dataLines();
    return lines?.reduce(
      (acc, curr) => {
        acc[curr.DocStatus] = [...(acc[curr.DocStatus] ?? []), curr];
        return acc;
      },
      { TL: undefined, UL: undefined }
    );
  });

  isCreation = this.#SKWNavigationService.isCreation;

  isComplete = computed(() => this.data().DocStatus === 'CO');

  isLinesComplete = computed(
    () => this.dataLines()?.length > 0 && this.dataLines()?.every((l) => l.DocStatus === 'CO')
  );

  changeActiveTab(event: MouseEvent, tabType: SKWLineStatus) {
    this.#SKWNavigationService.changeDetailTabActive(tabType);
  }

  openNewLine(event: MouseEvent) {
    this.#SKWNavigationService.goToNewTransferLine(this.data().M_Movement_ID);
  }

  save(event: MouseEvent) {
    if (!this.isLinesComplete()) {
      return;
    }

    this.#SKWContextService.newAction({
      type: SKWStateActionType.SAVE,
      isLoading: true,
      source: this.#SKWDataService.finishTask(this.data()).pipe(
        tap((result) => {
          if (result.error) {
            this.#SKWMessageService.addMessage({
              type: SKWMessageType.ERROR,
              title: 'ValidationTransferError',
              content: result.error,
              key: 'finishTaskError',
            });
            return;
          }

          this.#SKWContextService.refresh();
          this.#SKWNavigationService.previousPage();
        }),
        map(() => undefined)
      ),
    });
  }
}

import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { NgTemplateOutlet } from '@angular/common';
import { Component, inject, input, model, output } from '@angular/core';
import { map, tap } from 'rxjs';
import {
  SKWListTabType,
  SKWPage,
  SKWStateActionType,
  SKWTransferLineData,
} from '../../../models/storekeeper-window.model';
import { SKWTranslatePipe } from '../../../pipes/storekeeper-window-translate.pipe';
import { SKWContextService } from '../../../services/storekeeper-window-context.service';
import { SKWNavigationService } from '../../../services/storekeeper-window-navigation.service';
import { SKWDataService } from '../../../services/strokeeper-window-data.service';
import { SKWVirtualScrollDirective } from '../../../utils/storekeeper-window-virtual-scroll.directive';
import { StorekeeperButtonComponent } from '../../storekeeper-button/storekeeper-button.component';
import { StorekeeperEmptyDataComponent } from '../../storekeeper-empty-data/storekeeper-empty-data.component';
import { StorekeeperLoadingComponent } from '../../storekeeper-loading/storekeeper-loading.component';
import { StorekeeperWindowInputScanComponent } from '../../storekeeper-window-input-scan/storekeeper-window-input-scan.component';

@Component({
  selector: 'iu-storekeeper-transfer-lines',
  standalone: true,
  imports: [
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    NgTemplateOutlet,
    StorekeeperEmptyDataComponent,
    StorekeeperLoadingComponent,
    StorekeeperButtonComponent,
    StorekeeperWindowInputScanComponent,
    SKWTranslatePipe,
  ],
  templateUrl: './storekeeper-transfer-lines.component.html',
  styleUrl: './storekeeper-transfer-lines.component.scss',
})
export class StorekeeperTransferLinesComponent extends SKWVirtualScrollDirective {
  #SKWContextService = inject(SKWContextService);
  #SKWDataService = inject(SKWDataService);
  #SKWNavigationService = inject(SKWNavigationService);

  create = input<boolean>(false);

  dataLines = model<SKWTransferLineData[]>([]);

  lines = input<SKWTransferLineData[]>([]);

  state = this.#SKWContextService.state;

  isCreation = this.#SKWNavigationService.isCreation;
  tabActive = this.#SKWNavigationService.detailTabActive;

  isComplete = input<boolean>(false);
  isLinesComplete = input<boolean>(false);

  openNewLine = output<MouseEvent>();

  constructor() {
    super();
    this.fetchMoreData(true);
  }

  selectLine(event: MouseEvent, line: SKWTransferLineData) {
    this.#SKWNavigationService.selectLine(line);
  }

  addNewLine(event?: MouseEvent) {
    this.openNewLine.emit(event);
  }

  fetchMoreData(force = false): void {
    if (!this.#SKWNavigationService.transferActive() || (!force && this.currentParams.endRow !== -1)) return;

    this.#SKWContextService.newAction({
      type: SKWStateActionType.GET_MORE,
      isLoading: true,
      source: this.#SKWDataService
        .getTransferDetails(this.#SKWNavigationService.transferActive(), this.currentParams)
        .pipe(
          tap((response) => {
            this.dataLines.update((lines) => [...(lines ?? []), ...(<SKWTransferLineData[]>response.data)]);
            this.currentParams = {
              startRow: response.compiereRequest.endRow,
              endRow:
                response.compiereRequest.endRow === -1
                  ? -1
                  : response.compiereRequest.endRow + this.DEFAULT_TRANSFER_FETCH_LENGTH,
            };
          }),
          map(() => undefined)
        ),
    });
  }

  trackByFn(index: number, item: SKWTransferLineData) {
    return item.M_MovementLine_ID;
  }

  backToTasks(event: MouseEvent) {
    this.#SKWNavigationService.changeListTabActive(SKWListTabType.TASKS);
    this.#SKWNavigationService.changePage(SKWPage.LIST);
  }
}

import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import EditTabUiComponent from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { AbstractDataContainer, AbstractDataContainerCallout } from '@iupics-manager/models/abstract-datacontainer';
import { IupicsDataField } from '@iupics-manager/models/iupics-data';
import { TextLimitPipe } from '@iupics-util/pipes/text-limit/text-limit.pipe';
import { TooltipModule } from 'primeng/tooltip';
import { DocServerUrlPipe } from '../../../../iupics-util/pipes/doc-server-url/doc-server-url.pipe';
import PrimeOverlayComponent from '../../../overrided/prime-overlay/prime-overlay.component';
import ValuePreferencePanelComponent from '../../value-preference-panel/value-preference-panel.component';

@Component({
  selector: 'iu-input-image-ui',
  styleUrls: ['./input-image-ui.component.scss'],
  templateUrl: './input-image-ui.component.html',
  standalone: true,
  imports: [
    TooltipModule,
    NgIf,
    NgClass,
    FormsModule,
    PrimeOverlayComponent,
    ValuePreferencePanelComponent,
    AsyncPipe,
    TextLimitPipe,
    DocServerUrlPipe,
  ],
})
export default class InputImageUiComponent extends AbstractDataContainer implements OnInit, AfterViewInit {
  @Input() data: IupicsDataField;
  @Input()
  columnName: string;
  @Input()
  height: string;
  @Input()
  width: string;

  @Input()
  set imageURL(url: string) {
    this.fieldValue = url;
  }

  @ViewChild('input', { static: true })
  inputRef: ElementRef;
  dataContainers: AbstractDataContainerCallout;

  ngOnInit() {
    this.isImageField = true;
    super.ngOnInit();
    if (this.cssClass !== undefined) {
      this.cssGrid = this.cssClass;
    }
    this.cssClass = ' ' + this.cssGrid;
    this.setFieldMandatory();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    let parentComp = this.DOMParentComponent;
    while (parentComp && !(parentComp instanceof EditTabUiComponent)) {
      parentComp = parentComp.DOMParentComponent;
    }
    if (parentComp instanceof EditTabUiComponent) {
      this.dataContainers = new AbstractDataContainerCallout(parentComp.dataContainers);
    }
    setTimeout(() => {
      if (this.fieldValue) {
        this.switchField();
      }
    }, 750);
  }

  switchField() {
    if (!this.isReadOnly) {
      this.displayImage = !this.displayImage;
    }
  }
}

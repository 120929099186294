import { TypeEnum } from '@iupics-util/models/types.util';

export type SKWInputType = 'number' | 'text' | 'textarea';
export const SKWInputType: TypeEnum<SKWInputType> = {
  NUMBER: 'number',
  TEXT: 'text',
  TEXTAREA: 'textarea',
};

export type StoreKeeperInputChangeEvent = {
  type: SKWInputType;
  value: string | number;
  event: InputEvent;
};

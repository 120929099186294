import { HttpClient } from '@angular/common/http';
import { Injectable, Signal } from '@angular/core';
import { HammerGestureConfig } from '@angular/platform-browser';
import { AppConfig } from '@iupics-config/app.config';
import { KeybindConfigService } from '@iupics-config/keybind.config.service';
import { GridTranslations, mergeTranslationsWithDefault } from '@iupics/apiz-grid';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'environments/environment';
import 'hammerjs';
import * as Hammer from 'hammerjs';
import { KeycloakService } from 'keycloak-angular';

//#region Keycloak
export function initializeKeycloak(config: AppConfig, keycloak: KeycloakService) {
  return () =>
    new Promise(async (resolve) => {
      await config.loadEnvVariable();
      const result = await keycloak.init({
        config: environment.config.backend.auth,
        initOptions: {
          onLoad: 'login-required',
        },
      });
      resolve(result);
    });
}
//#endregion

//#region TranslateService
// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, 'assets/i18n/', '.json');
}
//#endregion

//#region HammerJS
@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    // override hammerjs default configuration
    swipe: { direction: Hammer.DIRECTION_HORIZONTAL },
    pinch: {
      enable: false,
    },
    rotate: {
      enable: false,
    },
    press: {
      time: 500,
    },
  };
  options = <any>{
    behavior: {
      userSelect: 'auto',
    },
  };
}
//#endregion

//#region Keybinding
export function initializeKeybinding(keybindConfig: KeybindConfigService) {
  return () => keybindConfig.load();
}
//#endregion

//#region Utilities
export function clearConsole() {
  return () => console.clear();
}
//#endregion

//#region Apiz-grid Translations
export function setupApizGridTranslations(): Signal<GridTranslations> {
  return mergeTranslationsWithDefault({
    data: {
      loading: 'gridTools.loadingOoo',
      no_data: 'gridTools.noRowsToShow',
      error: 'gridTools.noRowsToShow',
    },
    grid: {
      pin: 'gridTools.pinLeft',
      unpin: 'gridTools.noPin',
    },
    pagination: {
      all: 'gridTools.pagination.all',
      auto: 'gridTools.pagination.auto',
      firstPage: 'gridTools.pagination.firstPage',
      lastPage: 'gridTools.pagination.lastPage',
      lineSelected: 'gridTools.pagination.lineSelected',
      more: 'gridTools.pagination.more',
      nextPage: 'gridTools.pagination.nextPage',
      of: 'gridTools.pagination.of',
      page: 'gridTools.pagination.page',
      previousPage: 'gridTools.pagination.previousPage',
      rowsPerPage: 'gridTools.pagination.rowsPerPage',
      to: 'gridTools.pagination.to',
    },
    panel: {
      manage: 'gridTools.manage',
      apply: 'gridTools.applyFilter',
      close: 'gridTools.panel.close',
      reset: 'gridTools.panel.reset',
      aggregations: {
        title: 'gridTools.aggregations.title',
        function: 'gridTools.aggregations.function',
        column: 'gridTools.aggregations.column',
        add: 'gridTools.aggregations.add',
      },
      filters: {
        title: 'gridTools.filters.title',
        operators: {
          isNull: 'universalFilter.filterLabels.isNull',
          isNotNull: 'universalFilter.filterLabels.isNotNull',
          between: 'universalFilter.filterLabels.between',
          contains: 'universalFilter.filterLabels.contains',
          notContains: 'universalFilter.filterLabels.notContains',
          startsWith: 'universalFilter.filterLabels.startsWith',
          endsWith: 'universalFilter.filterLabels.endsWith',
          and: 'universalFilter.and',
          biggerThan: 'gridTools.greaterThan',
          biggerThanOrEqual: 'gridTools.greaterThanOrEqual',
          lessThan: 'gridTools.lessThan',
          lessThanOrEqual: 'gridTools.lessThanOrEqual',
          equals: 'gridTools.equals',
          notEqual: 'gridTools.notEquals',
          or: 'gridTools.or',
        },
      },
      groups: {
        title: 'gridTools.groups.title',
        column: 'gridTools.groups.column',
      },
      settings: {
        title: 'gridTools.settings.title',
        columns: 'gridTools.settings.columns',
        searchPlaceholder: 'gridTools.settings.search',
      },
      sorts: {
        title: 'gridTools.sorts.title',
        column: 'gridTools.sorts.column',
      },
    },
  });
}
//#endregion

<div
  [ngClass]="[
    'iu-banner',
    type === 'success' ? 'iu-success' : '',
    type === 'error' ? 'iu-error' : '',
    type === 'message' ? 'iu-message' : '',
    type === 'warning' ? 'iu-warning' : '',
    position === 'top' ? 'iu-top' : '',
    position === 'bottom' ? 'iu-bottom' : ''
  ]"
  (click)="cancelCloseDelay($event)"
  data-cy="banner">
  @if (closeable) {
    <i
      class="icon-close"
      style="cursor: pointer"
      (click)="closeBanner($event)"
      data-cy="banner-close-btn"
      aria-hidden="true"></i>
  }
  <div class="iu-banner-message" data-cy="banner-message">
    <i
      class="{{
        icon !== undefined
          ? 'iu-banner-icon ' + icon
          : type === 'success'
            ? 'iu-banner-icon fa-lg fas fa-check'
            : type === 'message'
              ? 'iu-banner-icon fa-lg far fa-sticky-note'
              : 'iu-banner-icon fa-lg fas fa-exclamation-triangle'
      }}"
      (click)="showHiddenMessage($event)"
      aria-hidden="true"></i>
    <span class="iu-banner-message-text"> <ng-content></ng-content> </span>
  </div>
  @if (hiddenMessageDisplay) {
    <div>
      <pre class="iu-banner-hidden-message" [innerHTML]="hiddenMessage"></pre>
    </div>
  }
</div>

import { Component, HostListener, Injector, OnDestroy, inject } from '@angular/core';

import { createCustomElement } from '@angular/elements';
import { RouterOutlet } from '@angular/router';
import TagContentUiComponent from '@iupics-components/standard/fields/input-mention-ui/components/tag-content-ui/tag-content-ui.component';
import TagListItemUiComponent from '@iupics-components/standard/fields/input-mention-ui/components/tag-list-item-ui/tag-list-item-ui.component';
import { IupicsCookieService } from '@iupics-manager/managers/security-manager/cookies/iupics-cookie.service';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { loadComponentFactory } from '@iupics-util/tools/component-cache-loader';
import { SubscriptionsUtils } from '@iupics-util/tools/subscriptions.util';
import { TranslateService } from '@ngx-translate/core';
import { DomHandler } from 'primeng/dom';

@Component({
  selector: 'iu-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent implements OnDestroy {
  cookieService = inject(IupicsCookieService);
  translateService = inject(TranslateService);
  injector = inject(Injector);

  #subscriptions = [];
  globalEnv = Global;
  successMessage: IupicsMessage;
  clearMessageDelay: NodeJS.Timeout;

  constructor() {
    this.#createCustomElements();
    loadComponentFactory();

    this.#removeLoader('.indexLoaderMsg');
    this.#removeLoader('.indexLoader');

    this.#overridePrimeNGDomHandler();
  }

  ngOnDestroy(): void {
    SubscriptionsUtils.unsubscribe(...this.#subscriptions);
  }

  @HostListener('document:keydown.shift.alt.delete', ['$event'])
  handleKeyPressed(event: KeyboardEvent) {
    if (event.shiftKey && event.altKey && event.key === 'Delete') {
      this.cookieService.clearLsCookies();
      this.successMessage = new IupicsMessage(
        null,
        this.translateService.instant('homepage.clearLocalStorageCookies'),
        'success'
      );
      this.clearMessageDelay = setTimeout(() => this.#closeSuccessMessage(), 1500);
      event.preventDefault();
    }
  }

  #closeSuccessMessage() {
    if (this.clearMessageDelay) {
      clearTimeout(this.clearMessageDelay);
    }

    location.reload(); // charger du server et non pas du cache
    this.successMessage = null;
  }

  #createCustomElements() {
    const customElementsDef = [
      {
        name: 'iu-tag-list-item-element',
        component: TagListItemUiComponent,
      },
      {
        name: 'iu-tag-content-element',
        component: TagContentUiComponent,
      },
    ];
    for (const { name, component } of customElementsDef) {
      const element = createCustomElement(component, { injector: this.injector });
      if (!customElements.get(name)) {
        // Register the custom element with the browser
        customElements.define(name, element);
      }
    }
  }

  #overridePrimeNGDomHandler() {
    DomHandler.addClass = (element: any, className: string): void => {
      if (className.trim()) {
        if (element.classList) {
          element.classList.add(className);
        } else {
          element.className += ' ' + className;
        }
      }
    };

    DomHandler.addMultipleClasses = (element: any, className: string): void => {
      const styles = className.trim().split(/\s+/);
      if (element.classList) {
        for (const style of styles) element.classList.add(style);
      } else {
        for (const style of styles) element.className += ' ' + style;
      }
    };
  }

  #removeLoader(query: string) {
    const element = document.querySelector<HTMLElement>(query);
    if (element) {
      element.remove();
    }
  }
}

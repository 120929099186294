import { Injectable } from '@angular/core';
import {
  CompiereNotification,
  CompiereNotificationType,
  CompiereUINotificationWS,
} from '@compiere-ws/models/compiere-notification-json';
import { AppConfig } from '@iupics-config/app.config';
import { Utils } from '@iupics-util/tools/util';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class CompiereNotificationService {
  private url: string;

  constructor(
    private http: ApiService,
    private config: AppConfig
  ) {}

  getNotificationsByType(
    type: string,
    offset: number,
    limit: number,
    onlyUnread: boolean
  ): Observable<CompiereNotification[]> {
    this.url = this.config.getBackendResource('notifications');

    const query = '?type=' + type + '&limit=' + limit + '&offset=' + offset + '&onlyUnread=' + onlyUnread;

    return this.http.get<CompiereNotification[]>(Utils.formatUrl(`${this.url}${query}`));
  }
  getNotificationsCounts(): Observable<CompiereUINotificationWS> {
    this.url = this.config.getBackendResource('notifications');
    const query = '/counts';
    return this.http.get<CompiereUINotificationWS>(Utils.formatUrl(`${this.url}${query}`));
  }
  sendNotification(notification: CompiereNotification): Observable<CompiereNotification> {
    this.url = this.config.getBackendResource('notifications');
    return this.http.post<CompiereNotification>(this.url, notification);
  }
  handleNotification(notification: CompiereNotification): Observable<CompiereNotification> {
    this.url = this.config.getBackendResource('notifications');
    return this.http.put<CompiereNotification>(this.url + '/' + notification.request_id, {});
  }

  handleAllNotification(type: CompiereNotificationType): Observable<CompiereNotification> {
    this.url = this.config.getBackendResource('notifications');
    return this.http.put<CompiereNotification>(`${this.url}?type=${type}`, {});
  }

  closeNotification(notification: CompiereNotification): Observable<CompiereNotification> {
    this.url = this.config.getBackendResource('notifications');
    return this.http.delete<CompiereNotification>(this.url + '/' + notification.request_id);
  }
  closeAllNotification(type: CompiereNotificationType, onlyUnread: boolean): Observable<CompiereNotification> {
    this.url = this.config.getBackendResource('notifications');
    return this.http.delete<CompiereNotification>(`${this.url}?type=${type}&onlyUnread=${onlyUnread}`);
  }
}

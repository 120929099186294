import { Component, OnInit } from '@angular/core';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import ProcessInProgressUiComponent from '../process-in-progress-ui/process-in-progress-ui.component';
import SpecificWindowUiComponent from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-process-in-progress-window-ui',
  templateUrl: './process-in-progress-window-ui.component.html',
  styleUrls: ['./process-in-progress-window-ui.component.scss'],
  standalone: true,
  imports: [ProgressBarModule, ScrollPanelModule, ProcessInProgressUiComponent],
})
export default class ProcessInProgressWindowUiComponent extends SpecificWindowUiComponent implements OnInit {
  ngOnInit() {}
}

import { Component, ComponentRef, OnDestroy, OnInit, ViewContainerRef, inject, viewChild } from '@angular/core';
import { DataStore } from '@compiere-ws/models/compiere-data-json';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { CalendarConfig } from '@iupics-components/overrided/prime-calendar/prime-calendar.component';
import { ToDatePipe } from '@iupics-components/pipes/to-date/to-date.pipe';
import AutocompleteUiComponent from '@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component';
import CalendarUiComponent from '@iupics-components/standard/fields/calendar-ui/calendar-ui.component';
import InputNumberUiComponent from '@iupics-components/standard/fields/input-number-ui/input-number-ui.component';
import InputTimeUiComponent from '@iupics-components/standard/fields/input-time-ui/input-time-ui.component';
import { FilterToDisplay, UniversalFilterUtils } from '@iupics-components/standard/menu/utils/universal-filter-utils';
import { UICreatorUtils } from '@iupics-manager/managers/ui-creator/utils/ui-creator.utils';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { IupicsData, NumberType } from '@iupics-manager/models/iupics-data';
import { createComponent } from '@iupics-util/tools/component-cache-loader';
import { SubscriptionsUtils } from '@iupics-util/tools/subscriptions.util';
import {
  ColumnApiService,
  FilterInputBaseDirective,
  FilterType,
  GridApiService,
  injectColumnApiService,
  injectGridApiService,
} from '@iupics/apiz-grid';
import { isNil } from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { FilterCtxService, injectFilterCtxService } from '../services/filter-ctx.service';

/** Class used for applied-items not universal-filter. */
@Component({
  selector: 'iu-filter',
  standalone: true,
  imports: [InputNumberUiComponent, CalendarUiComponent, InputTimeUiComponent, ToDatePipe],
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.scss',
})
export class FilterComponent extends FilterInputBaseDirective implements OnInit, OnDestroy {
  api: GridApiService = injectGridApiService();
  columnApi: ColumnApiService = injectColumnApiService();
  filterCtx: FilterCtxService = injectFilterCtxService();
  #ufUtils = inject(UniversalFilterUtils);

  #localDatastore: DataStore | undefined;
  #subscriptions: Subscription[] = [];

  vcrFilter = viewChild('vcrFilter', { read: ViewContainerRef });
  #componentRef: ComponentRef<any> | undefined;

  filterToDisplay: FilterToDisplay;

  ngOnInit(): void {
    const item = this.item();
    const columns = this.filterCtx.provideColumnNames();
    const operators = this.filterCtx.provideOperators();
    this.filterToDisplay = this.#ufUtils.appliedItemsToFilterToDisplay(columns.items, operators.items, [item])[0];
    this.setVCR();
  }

  ngOnDestroy(): void {
    SubscriptionsUtils.unsubscribe(...this.#subscriptions);
  }

  setVCR() {
    if (!this.#localDatastore) this.#localDatastore = this.filterCtx.provideLocalDatastore();

    if (
      this.filterToDisplay?.operator?.operator?.type === OperatorFilterType.IS_NULL ||
      this.filterToDisplay?.operator?.operator?.type === OperatorFilterType.IS_NOT_NULL
    ) {
      // TODO MIGRATION_APIZ_GRID: verify this
    } else {
      let compoRef: ComponentRef<any>;
      if (this.filterToDisplay.column && this.filterToDisplay.column.id !== -1) {
        let componentName = UICreatorUtils.getComponentNameFromReference(
          this.filterToDisplay.column.columnInfo.fieldEntity.field.AD_Reference_ID
        );
        const componentNameToTest = new Set(['InputTextareaUiComponent', 'InputTextHtmlUiComponent']);
        if (componentNameToTest.has(componentName)) {
          componentName = 'InputTextUiComponent';
        } else if (componentName === 'ButtonUiComponent') {
          componentName = 'InputSwitchUiComponent';
        }
        const vcr = this.vcrFilter();
        vcr.clear();
        compoRef = createComponent(vcr, componentName);
        compoRef.instance.isStandalone = true;
        compoRef.instance.isInUniversalFilter = true;
        compoRef.instance.isSetDefaultValue = true;
        if (componentName === 'AutocompleteUiComponent') {
          (<AutocompleteUiComponent>compoRef.instance).isInsideOverflow = true;
        }
        /* Set le fieldType utilisé pour le ws /DataField */
        const container = this.filterCtx.provideContainer();
        if (container?.windowType) {
          (<AbstractDynamicComponent>compoRef.instance).container = container;
          (<AbstractDynamicComponent>compoRef.instance).fieldType = this.filterCtx.getFieldType();
        } else {
          if (componentName === 'AutocompleteUiComponent') {
            compoRef.instance.isForInfoWindow = true;
          }
        }
        this.#subscriptions.push(
          compoRef.instance.fieldValueModified.subscribe((value: any) => {
            this.setFormatedValue(value);
          })
        );
        // ? by default enterKey is Faster than fieldValueModified due to the originalEvent used in both cases
        if (compoRef.instance.hasOwnProperty('enterKey')) {
          this.#subscriptions.push(
            compoRef.instance.enterKey.subscribe((e: Event) => {
              e.stopPropagation();
              this.setFormatedValue(this.#componentRef.instance.inputRef.nativeElement.value);
              this.close.emit(true);
            })
          );
        }
        if (componentName === 'CalendarUiComponent') {
          compoRef.instance.hasTodayBtn = true;
          (compoRef.instance as AbstractDataContainer).calendarConfig = this.filterToDisplay?.configs
            ?.calendarConfig || {
            todayMode: false,
          };
          (compoRef.instance as AbstractDataContainer).calendarConfigChange.subscribe((calendarConfig) => {
            this.onCalendarConfigChange(calendarConfig);
          });
        }
        if (componentName === 'InputSwitchUiComponent') {
          compoRef.instance.isTriState = true;
        }
        if (componentName === 'InputNumberUiComponent') {
          compoRef.instance.multiple = this.filterToDisplay.operator.operator
            ? this.filterToDisplay.operator.operator.type === OperatorFilterType.EQUALS ||
              this.filterToDisplay.operator.operator.type === OperatorFilterType.NOT_EQUALS
            : true;
          compoRef.instance.cssClass = 'UniversalFilterInputNumber';
        }
        // create internal datastore and use datamodified for that
        // it will be used as a local ctx for the universal filter
        if (componentName === 'AutocompleteUiComponent') {
          compoRef.instance.multiple = true;
        }
        const fieldEntity = this.filterToDisplay.column.columnInfo.fieldEntity;
        const field = fieldEntity ? fieldEntity.field : null;
        compoRef.instance.data = <IupicsData>{
          fieldId: field.AD_Field_ID,
          columnId: field.AD_Column_ID,
          formId: field.AD_Form_ID,
          fieldType: field.IsEncryptedColumn || field.IsEncryptedField ? 'password' : 'text',
          isCopy: field.IsCopy,
          items: fieldEntity.data,
          description: field.Description,
          columnName: field.ColumnName,
          details: fieldEntity.details,
          urlList: fieldEntity.urlList,
          urlSearch: fieldEntity.urlSearch,
          tableName: field.tableName,
          tabId: field.AD_Tab_ID,
          isSearch:
            field.AD_Reference_ID === 30 || (fieldEntity.urlSearch !== null && fieldEntity.urlSearch !== undefined),
          isAccount: field.AD_Reference_ID === 25,
          needTime: field.AD_Reference_ID === 16 || field.AD_Reference_ID === 24,
          defaultValue: field.DefaultValue,
          defaultValue2: field.DefaultValue2,
          searchColumns: fieldEntity.details ? fieldEntity.details.searchColumns : null,
          numberType:
            field.AD_Reference_ID === 12 || field.AD_Reference_ID === 37
              ? NumberType.AMOUNT
              : field.AD_Reference_ID === 11
                ? NumberType.INTEGER
                : field.AD_Reference_ID === 22
                  ? NumberType.FLOAT
                  : field.AD_Reference_ID === 29
                    ? NumberType.QUANTITY
                    : null,
          validationCode: field.ValidationCode ? field.ValidationCode.replace(/\n/g, ' ') : null,
          componentName: componentName,
        };
        if (this.#localDatastore && this.#localDatastore.data) {
          compoRef.instance.getCurrentContext = this.filterCtx.provideGetCurrentContext();
          if (compoRef.instance.setNewData) {
            compoRef.instance.setNewData(this.#localDatastore);
          }
        }
        compoRef.instance.lockFilterPanel = () => {
          this.panelService.lockPanel();
        };
        compoRef.instance.unlockFilterPanel = () => {
          this.panelService.unlockPanel();
        };
        this.#componentRef = compoRef;
        this.#componentRef.changeDetectorRef.detectChanges();
      }
    }

    setTimeout(() => {
      if (this.#componentRef?.instance?.inputRef?.nativeElement) {
        (<HTMLElement>this.#componentRef.instance.inputRef.nativeElement).focus();
      }
    }, 0);
  }

  getFormatedValue(value: any) {
    const dateTypeSet = new Set([FilterType.TIME, FilterType.DATE]);
    if (dateTypeSet.has(this.item().filterType as FilterType) && value instanceof Date) {
      value = moment(
        this.filterToDisplay.configs?.calendarConfig?.todayMode
          ? this.#ufUtils.getTodayModeDate(
              this.filterToDisplay.configs.calendarConfig.todayValue,
              this.filterToDisplay.column.columnInfo.fieldEntity
            )
          : value
      ).format('YYYY-MM-DDTHH:mm:ss.SSS');
    } else if (this.item().filterType === FilterType.NUMBER) {
      if (Array.isArray(value)) {
        value = value.map((_v) => {
          if (typeof _v === 'string') _v = _v.replace(',', '.');
          if (!isNaN(parseFloat(_v))) _v = parseFloat(_v);
          else _v = 0;
          return _v;
        });
      } else {
        if (value instanceof String) value = value.replace(',', '.');
        if (!isNaN(parseFloat(value))) value = parseFloat(value);
        else value = 0;
      }
    }
    return value;
  }
  onCalendarConfigChange(calendarConfig: CalendarConfig, configTo = false) {
    if (isNil(this.filterToDisplay.configs)) this.filterToDisplay.configs = {};
    if (isNil(this.filterToDisplay.configsTo)) this.filterToDisplay.configsTo = {};
    if (configTo) this.filterToDisplay.configsTo.calendarConfig = calendarConfig;
    else this.filterToDisplay.configs.calendarConfig = calendarConfig;
  }

  setFormatedValue(value: any, idx?: number) {
    if (idx != null && idx != undefined) {
      this.setValue(this.getFormatedValue(value), idx);
    } else {
      this.setValue(this.getFormatedValue(value));
    }
  }
  setFilterTo(value: any) {
    this.setFormatedValue(value, 1);
  }
}

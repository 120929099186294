@if (!state.loaded()) {
  <div class="wrapper">
    <iu-storekeeper-loading [text]="'LinesLoading' | SKWTranslate" />
  </div>
} @else if (isComplete()) {
  <div class="stamp-container">
    <div class="stamp">{{ 'Finished' | SKWTranslate }}</div>
  </div>
  <div class="wrapper flex">
    <iu-storekeeper-button [label]="'BackToTasks' | SKWTranslate" (onClick)="backToTasks($event)" />
    <iu-storekeeper-button [label]="'Print' | SKWTranslate" [type]="'outlined'" />
  </div>
} @else if (!lines() || lines()?.length === 0) {
  <ng-container [ngTemplateOutlet]="title" />
  <ng-container [ngTemplateOutlet]="linesEmpty" />
} @else {
  <iu-storekeeper-window-input-scan [search]="true" [lines]="lines()" />

  <ng-container [ngTemplateOutlet]="title" />

  <cdk-virtual-scroll-viewport itemSize="150" [minBufferPx]="900" [maxBufferPx]="900" class="list-content">
    <div
      class="line"
      *cdkVirtualFor="let line of lines(); trackBy: trackByFn.bind(this)"
      (click)="selectLine($event, line)">
      <h4 class="title">
        <span class="qty">{{ line.Qty }}</span>
        <span class="separator">X</span>
        <span class="product">{{ line.M_Product_ID.displayValue }}</span>
      </h4>

      @if (line.Product_Description?.length > 0) {
        <p class="description" [innerHTML]="line.Product_Description"></p>
      } @else {
        <p class="description no-description">{{ 'NoDescription' | SKWTranslate }}</p>
      }

      <div class="where">
        <span class="text">{{
          (tabActive() === 'TL' ? line.locator_source : line.locator_destination).displayValue
        }}</span>
      </div>
    </div>
    @if (state.pending() === 'get_more') {
      <span>{{ 'LoadingMoreLines' | SKWTranslate }}</span>
    }
  </cdk-virtual-scroll-viewport>
}

<ng-template #title>
  <div class="title-bar">
    <h3 class="title">{{ 'Lines' | SKWTranslate }}</h3>
    @if ((create() || isCreation()) && tabActive() === 'TL') {
      <iu-storekeeper-button [label]="'Add' | SKWTranslate" [type]="'outlined'" (onClick)="addNewLine($event)" />
    }
  </div>
</ng-template>

<ng-template #linesEmpty>
  <div class="wrapper">
    @if (isLinesComplete()) {
      <iu-storekeeper-empty-data
        [icon]="'icon-dolly-empty'"
        [text]="'LineProcessingComplete' | SKWTranslate"
        [showButton]="false" />
    } @else if (isCreation() && (dataLines()?.length ?? 0) === 0) {
      <iu-storekeeper-empty-data
        [icon]="'icon-dolly-empty'"
        [text]="'NoLineForThisTransfer' | SKWTranslate"
        [showButton]="false" />
    } @else if (tabActive() === 'TL') {
      <iu-storekeeper-empty-data
        [icon]="'icon-dolly-empty'"
        [text]="'NoLineToLoad' | SKWTranslate"
        [showButton]="true" />
    } @else {
      <iu-storekeeper-empty-data [icon]="'icon-truck'" [text]="'NoLineToUnload' | SKWTranslate" [showButton]="true" />
    }
  </div>
</ng-template>

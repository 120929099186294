import { CompiereMenu } from '@compiere-ws/models/compiere-menu-json';
import { WidgetFavoriteCompiereJson } from '@compiere-ws/models/widget-center-json';
import { IupicsMenuType, MenuCategoryUI, MenuFavoritesCategoryUI, MenuItemUI } from '@web-desktop/models/menu-item-ui';
import { cloneDeep, partition } from 'lodash';

export const cssClasses = {
  Window: 'icon-menu-window icon-window-2',
  Process: 'icon-menu-process icon-process-cogs-3',
  Report: 'icon-menu-report icon-graph-3',
  Form: 'icon-menu-form icon-specific-window-2',
  Workflow: 'icon-menu-workflow icon-code-branch-3',
};

export class MenuCreatorUtils {
  public static transformMenus(
    iupicsMenuFavoritesCategories: MenuFavoritesCategoryUI[],
    menus: CompiereMenu[],
    isMenuFavorite = false
  ): [MenuItemUI[], MenuCategoryUI[]] {
    if (menus) {
      const menusTransformed: MenuItemUI[] = [];
      const menuCategories: Map<number, MenuCategoryUI> = new Map();
      let filteredMenus: CompiereMenu[];
      let categories: CompiereMenu[];
      if (!isMenuFavorite) {
        [filteredMenus, categories] = partition(menus, (menu) => menu.level > 0 && !menu.isSummary);
      } else {
        [filteredMenus, categories] = partition(menus, (menu) => menu.level > 0);
      }
      for (let i = 0; i < filteredMenus.length; i++) {
        const menu = filteredMenus[i];
        if (isMenuFavorite && menu.isSummary) {
          // pour les favoris on prépare la liste des catégories
          iupicsMenuFavoritesCategories.push({ id: menu.menuId, name: menu.name, seqNo: menu.seqNo });
        } else if (!menu.isSummary) {
          if (!menu.icon) {
            menu.icon = cssClasses[menu.action.type];
          }

          let menuCategory: MenuCategoryUI;
          if (menu.parentId) {
            menuCategory = menuCategories.get(menu.parentId);
            if (!menuCategory) {
              const cat = categories.find((m) => m.menuId === menu.parentId);
              if (cat != null && cat != undefined) {
                const newCategory: MenuCategoryUI = {
                  id: cat.menuId,
                  name: cat.name,
                  isSelected: false,
                  icon: cat.icon,
                };
                menuCategories.set(cat.menuId, newCategory);
                menuCategory = newCategory;
              }
            }
          }

          const newMenu: MenuItemUI = {
            menuId: menu.menuId,
            menuCategory: menuCategory,
            menuType: menu.action.type as IupicsMenuType,
            actionID: menu.action.id,
            iconClass: menu.icon,
            name: menu.name,
            tags: menu.tags,
            color: menu.cssColor,
            isSummary: menu.isSummary,
            parentId: menu.parentId,
            seqNo: menu.seqNo,
            angularClass: menu.angularClass,
            width: menu.width,
          };
          menusTransformed.push(newMenu);
        }
      }

      if (!isMenuFavorite) {
        return [
          menusTransformed.sort((m1, m2) => (m1.name < m2.name ? -1 : m1.name > m2.name ? 1 : 0)),
          Array.from(menuCategories.values()),
        ];
      } else {
        return [menusTransformed, Array.from(menuCategories.values())];
      }
    } else {
      return [[], []];
    }
  }

  public static transformWidgets(
    iupicsWidgetFavoritesCategories: MenuFavoritesCategoryUI[],
    widgets: WidgetFavoriteCompiereJson[],
    isWidgetFavorite = false
  ): WidgetFavoriteCompiereJson[] {
    if (widgets) {
      const widgetsTransformed: WidgetFavoriteCompiereJson[] = [];
      const menuCategories: Map<number, MenuCategoryUI> = new Map();
      let filteredMenus: WidgetFavoriteCompiereJson[];
      if (!isWidgetFavorite) {
        filteredMenus = widgets.filter((widget) => widget.menu.level > 0 && !widget.menu.isSummary);
      } else {
        filteredMenus = widgets.filter((menu) => menu.menu.level > 0);
      }
      for (const widget of filteredMenus) {
        if (isWidgetFavorite && widget.menu.isSummary) {
          iupicsWidgetFavoritesCategories.push({
            id: widget.menu.menuId,
            name: widget.menu.name,
            seqNo: widget.menu.seqNo,
          });
        } else if (!widget.menu.isSummary) {
          if (!widget.menu.icon) {
            widget.menu.icon = cssClasses[widget.menu.action.type];
          }

          let menuCategory: MenuCategoryUI;
          if (widget.menu.parentId) {
            menuCategory = menuCategories.get(widget.menu.parentId);
            if (!menuCategory) {
              const cat = widgets.find((m) => m.menu.menuId === widget.menu.parentId);
              const newCategory: MenuCategoryUI = {
                id: 0,
                name: cat.menu.name,
                isSelected: false,
                icon: cat.menu.icon,
              };
              menuCategories.set(widget.menu.parentId, newCategory);
              menuCategory = newCategory;
            }
          }

          const newMenu: WidgetFavoriteCompiereJson = cloneDeep(widget);
          widgetsTransformed.push(newMenu);
        }
      }

      if (!isWidgetFavorite) {
        return widgetsTransformed.sort((m1, m2) =>
          m1.menu.name < m2.menu.name ? -1 : m1.menu.name > m2.menu.name ? 1 : 0
        );
      } else {
        return widgetsTransformed;
      }
    } else {
      return [];
    }
  }

  /**
   * Trie les catégories selon les id et leur
   * @param {MenuCategoryUI[]}categories Les catégories à trier
   * @returns {MenuCategoryUI[]} les catégories triées
   */
  public static sortCategories(categories: MenuCategoryUI[]): MenuCategoryUI[] {
    return categories.sort((first: MenuCategoryUI, second: MenuCategoryUI): number => {
      if (first && first.id === Number.POSITIVE_INFINITY) {
        return 1;
      }
      if (second && second.id === Number.POSITIVE_INFINITY) {
        return -1;
      }
      if (first && first.id === 0) {
        return -1;
      }
      if (second && second.id === 0) {
        return 1;
      }
      if (first && first.id === -1) {
        return -1;
      }
      if (second && second.id === -1) {
        return 1;
      }
      if (first.name < second.name) {
        return -1;
      }
      if (first.name > second.name) {
        return 1;
      }
      return 0;
    });
  }
}

export class MongoSearchQuery {
  queryPart: MongoSearchQueryPart;
  ctx: Map<string, any>;
  orgAccess: number[];
  hasOrgSecurity: boolean;

  constructor(
    searchQueryPart: MongoSearchQueryPart,
    ctx: Map<string, any> = new Map(),
    orgAccess: number[] = [],
    hasOrgSecurity: boolean = true
  ) {
    this.queryPart = searchQueryPart;
    this.ctx = ctx;
    this.orgAccess = orgAccess;
    this.hasOrgSecurity = hasOrgSecurity;
  }
}

export class MongoSearchQueryPart {
  expressions: MongoSearchQueryPart[] = [];
  type: string = MongoSearchQueryPartType.TEXT;
  fieldName: string = null;
  fieldValue: any = null;
  operator: string = MongoSearchQueryOperator.EQUALS;

  constructor(type: string | null = null, fieldName?: string, fieldValue?: any, operator?: string) {
    if (type === 'join') {
      this.operator = 'AND';
    } else {
      this.operator = operator || 'EQUALS';
    }
    this.type = type || 'text';

    if (fieldName && fieldValue) {
      this.fieldName = fieldName.toUpperCase();
      this.fieldValue = fieldValue;
    }

    this.expressions = [];
  }

  static createQueryGroup(operator?: string): MongoSearchQueryPart {
    let queryGroup = new MongoSearchQueryPart(MongoSearchQueryPartType.JOIN, null, null, operator);
    if (operator) {
      queryGroup.operator = operator;
    }
    return queryGroup;
  }

  static createQueryPart(
    fieldName?: string,
    fieldValue?: any,
    operator?: string,
    type: string = 'text'
  ): MongoSearchQueryPart {
    if (type === MongoSearchQueryPartType.JOIN) {
      throw new Error('Type JOIN is not allowed.');
    }
    return new MongoSearchQueryPart(type, fieldName, fieldValue, operator);
  }

  addExpression(expression: MongoSearchQueryPart) {
    if (this.type !== MongoSearchQueryPartType.JOIN) {
      throw new Error('Type other than JOIN are not allowed to add expression.');
    }
    this.expressions.push(expression);
  }
}

export interface MongoSearchQueryOptions {
  attachmentInteraction: boolean;
  limit: number;
  start: number;
}

export enum MongoSearchQueryOperator {
  EQUALS = 'eq',
  NOT_EQUALS = 'ne',
  GREATER_THAN = 'gt',
  LESS_THAN = 'lt',
  GREATER_THAN_EQUALS = 'gte',
  LESS_THAN_EQUALS = 'lte',
  IN = 'in',
  NIN = 'nin',
  REGEX = 'regex',
  EXIST = 'exists',
  AND = 'and',
  OR = 'or',
}

export enum MongoSearchQueryPartType {
  TEXT = 'text',
  DATE = 'date',
  JOIN = 'join',
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fakeArray',
    standalone: true,
})
export class FakeArrayPipe implements PipeTransform {
  transform(value: number): any {
    return Array(value);
  }
}

<div
  #specificContainer
  class="iu-specific-window iu-specific-window-container"
  [attr.data-cy-specific-window-name]="name">
  @if (!isModal && isLoading) {
    <p-progressBar [mode]="'indeterminate'" [value]="0" [showValue]="false" [styleClass]="'loading-progressbar'" />
  }
  <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
    <div class="fields">
      <p-tabView>
        <p-tabPanel header="{{ 'specificWindow.mainFilters' | translate }}">
          <div class="top-container-AccountingViewer">
            <div class="p-col-12 p-grid">
              <ng-template #top />
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="{{ 'specificWindow.optionalFilters' | translate }}">
          <div class="second-container-AccountingViewer">
            <div class="p-col-12 p-grid">
              <ng-template #optional />
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="{{ 'specificWindow.accountingViewer.groupBy' | translate }}">
          <div class="top-container-AccountingViewer">
            @if (gridViews?.[0]?.GridTabInfinityScrollUiComponent) {
              <div class="p-col-12 p-md-4 p-lg-2">
                <iu-input-switch-ui
                  [isReadOnly]="
                    !gridViews[0].GridTabInfinityScrollUiComponent ||
                    gridViews[0].GridTabInfinityScrollUiComponent.api.groups().length === 0
                  "
                  [isContextMenuDisabled]="true"
                  #isTotalInput
                  [isStandalone]="true"
                  [fieldValue]="'N'"
                  (fieldValueModified)="
                    !gridViews[0].GridTabInfinityScrollUiComponent ||
                      gridViews[0].GridTabInfinityScrollUiComponent.api.groups().length === 0 ||
                      applySubTotalDebitCredit($event);
                    isExpandedInput.fieldValue = 'N'
                  "
                  label="{{ 'specificWindow.accountingViewer.applySubTotal' | translate }}" />
                <iu-input-switch-ui
                  [isReadOnly]="
                    !gridViews[0].GridTabInfinityScrollUiComponent ||
                    gridViews[0].GridTabInfinityScrollUiComponent.api.groups().length === 0
                  "
                  [isContextMenuDisabled]="true"
                  #isExpandedInput
                  [isStandalone]="true"
                  [fieldValue]="'N'"
                  (fieldValueModified)="
                    !gridViews[0].GridTabInfinityScrollUiComponent ||
                      gridViews[0].GridTabInfinityScrollUiComponent.api.groups().length === 0 ||
                      expandAll($event)
                  "
                  label="{{
                    (isExpandedInput.fieldValue === 'Y'
                      ? 'specificWindow.accountingViewer.collapseAll'
                      : 'specificWindow.accountingViewer.expandAll'
                    ) | translate
                  }}" />
              </div>
            }
          </div>
        </p-tabPanel>
      </p-tabView>
      <div class="p-col-12">
        <ng-template #search />
      </div>
      <div class="p-col-12">
        <ng-template #bot />
      </div>
      <ng-template iuDynamicContainer />
    </div>
  </p-scrollPanel>
</div>

<div [ngClass]="[!displayFormUI ? 'hidden' : '', 'specificModal']">
  <iu-modal-ui
    [title]="specificWindowTitle"
    [isModalDisplay]="displayFormUI"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false })">
    <ng-template #vcrSpecific />
  </iu-modal-ui>
</div>

import { Injectable } from '@angular/core';
import { RecentItem } from '@compiere-ws/models/compiere-recent-item';
import { AppConfig } from '@iupics-config/app.config';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class CompiereRecentItemService {
  private url: string;

  constructor(
    private config: AppConfig,
    private http: ApiService
  ) {
    this.url = this.config.getBackendResource('recentItem');
  }

  getRecentItems(): Observable<RecentItem[]> {
    return this.http.get<RecentItem[]>(this.url);
  }

  addRecentItem(item: RecentItem): Observable<boolean> {
    return this.http.post<boolean>(this.url, item);
  }

  deleteRecentItems(windowId: number, tabId: number, recordIds: number[]): Observable<any> {
    return this.http.request('delete', `${this.url}?windowId=${windowId}&tabId=${tabId}`, {
      body: recordIds,
    });
  }
}

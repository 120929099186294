import { Component, ViewChild, forwardRef } from '@angular/core';
import InputNumberUiComponent from '@iupics-components/standard/fields/input-number-ui/input-number-ui.component';
import { CellEditorComp, CellEditorParams } from '@iupics/apiz-grid';

@Component({
  selector: 'iu-number-editor',
  template: `
    <iu-input-number-ui
      #numberElt
      [fieldValue]="this.fieldValue"
      [cssClass]="'numberEditor'"
      [isLabelDisplay]="false"
      [data]="this.templates"
      [isStandalone]="true"
      (fieldValueModified)="onFieldModified($event)"
      (enterKey)="onEnter($event)"></iu-input-number-ui>
  `,
  standalone: true,
  imports: [forwardRef(() => InputNumberUiComponent)],
})
export default class NumberEditorComponent implements CellEditorComp {
  @ViewChild('numberElt', { read: InputNumberUiComponent }) numberElt: InputNumberUiComponent;
  params: CellEditorParams;
  label: string;
  templates: any;
  fieldValue = null;

  gridInit(params: CellEditorParams): void {
    this.params = params;
    this.label = this.params.label || null;
    this.templates = this.params.templates;

    if (params.value !== undefined) {
      this.fieldValue = params.value;
    }
  }

  refresh(params: CellEditorParams): boolean {
    if (params.value !== undefined) {
      this.fieldValue = params.value;
    }
    return true;
  }

  getValue() {
    return this.fieldValue;
  }

  onFieldModified(value: any) {
    const oldValue = this.params.row.getData(this.templates.columnName);
    this.params.row.setData({ [this.templates.columnName]: value });

    if (!this.params.row.isSelected()) {
      this.params.row.setSelected(true); // select row
    }

    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: value,
        rowData: this.params.row.getRowData(),
        columnName: this.params.colDef.field,
        oldValue,
        newValue: value,
        // ...something
      };
      this.params.onClick({ ...this.params, ...params });
    }
  }

  onEnter(event: KeyboardEvent) {
    event.stopPropagation();
    this.onFieldModified(this.numberElt.fieldValue);
  }
}

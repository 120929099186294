import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[iuDragHandle]',
  standalone: true,
})
export class DragHandleDirective {
  @Input('iuDrag-handle-id') dragHandleId: string = 'iu-drag-handle';
  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    this.addHandle();
  }

  private addHandle(): void {
    this.renderer.addClass(this.el.nativeElement, this.dragHandleId);
    this.renderer.setStyle(this.el.nativeElement, 'cursor', 'move');
  }
}

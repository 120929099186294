export class IupicsMessage {
  name: string;
  message: string;
  type?: 'error' | 'success' | 'message' | 'warning';
  stack?: string;

  constructor(
    name: string,
    message: string,
    type: 'error' | 'success' | 'message' | 'warning' = 'error',
    stack?: string
  ) {
    this.name = name;
    this.message = message;
    this.type = type;
    this.stack = stack;
  }
}

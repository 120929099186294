import { Injectable, signal } from '@angular/core';

@Injectable()
export class SKWTranslateService {
  translations = signal<{ [key: string]: string }>({});

  setTranslations(fields: any[], ignoreColumns: string[]) {
    const trl = {};

    for (const field of fields) {
      if (ignoreColumns.includes(field.data.columnName)) {
        continue;
      }

      trl[field.data.columnName] = field.data.label;
      // Object.assign(trl, { [field.ColumnName]: })
    }

    this.translations.set(trl);
  }

  getTranslation(key: string) {
    return this.translations()?.[key] ?? key;
  }
}

@if (isMenuOpen()) {
  <ng-content select="[expanded]" />
}

<ng-content />

@if (canExpand()) {
  <button class="navbar-middle-btn" (click)="toggleMenu($event)" [attr.data-menu-open]="isMenuOpen()">
    <i class="icon-add" aria-hidden="true"></i>
  </button>
}

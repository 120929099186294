<span
  [life]="3000"
  [hideDelay]="250"
  pTooltip="{{ tooltip }}"
  [escape]="false"
  tooltipPosition="top"
  [showDelay]="500"
  tooltipZIndex="2100"
  style="margin-top: 0.7em"
  [style.display]="displayCss"
  [style.margin-top.px]="data && data.marginTop > 0 ? data.marginTop : null">
  <button
    #btn
    pButton
    [ngClass]="['p-button', 'p-button-' + (btnType || 'secondary'), !isStandalone ? 'button-field' : '']"
    icon="{{ icon || '' }}"
    type="button"
    [label]="label"
    [disabled]="isReadOnly"
    data-cy="input-button"
    [attr.data-cy-columnName]="data ? data.columnName : columnName"
    [attr.data-cy-processId]="data && data['processId'] ? data['processId'] : ''"
    [attr.data-cy-formId]="data && data['formId'] ? data['formId'] : ''"
    [attr.data-cy-channelId]="channelId ? channelId : ''"></button>
</span>

<div
  #specificContainer
  class="iu-specific-window iu-specific-window-container"
  [attr.data-cy-specific-window-name]="name">
  @if (!isModal && isLoading) {
    <p-progressBar [mode]="'indeterminate'" [value]="0" [showValue]="false" [styleClass]="'loading-progressbar'" />
  }
  <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
    <div class="apiz-p-grid">
      <div class="p-col-12 import-file-loader-window-toolbar">
        <h1 class="import-file-loader-window-title">{{ title }}</h1>
        <button
          pButton
          class="import-file-loader-reset-button p-button p-button-alert"
          [title]="'generic.reset' | translate"
          (click)="reset($event)"
          icon="fas fa-trash-restore"></button>
      </div>
      <div class="p-col-4 p-grid import-file-loader-parameters">
        <ng-template #vcrParameters />
      </div>
      <div class="p-col-8" style="height: 315px">
        <iu-grid-tab-rowdata #grid />
      </div>
      <div class="p-col-12 p-grid">
        <ng-template #vcrProcess />
      </div>
    </div>
  </p-scrollPanel>
</div>

<div [ngClass]="[!displayFormUI ? 'hidden' : '', 'specificModal']">
  <iu-modal-ui
    [title]="specificWindowTitle"
    [isModalDisplay]="displayFormUI"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false })">
    <ng-template #vcrSpecific />
  </iu-modal-ui>
</div>

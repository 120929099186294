import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, computed, effect, inject, input, signal } from '@angular/core';
import { AvatarService } from '@iupics-components/standard/user/avatar-ui/avatar-service/avatar.service';
import { AvatarFullInfos, AvatarInfos } from '@iupics-manager/models/user-account';
import { ImgFallbackDirective } from '@iupics-util/directives/img-fallback.directive';

@Component({
  selector: 'iu-avatar-ui',
  templateUrl: './avatar-ui.component.html',
  styleUrls: ['./avatar-ui.component.scss'],
  standalone: true,
  imports: [NgClass, ImgFallbackDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class AvatarUiComponent {
  #elRef: ElementRef<HTMLElement> = inject(ElementRef);
  #avatar = inject(AvatarService);

  avatarInfos = input<AvatarInfos>();

  data = computed<AvatarFullInfos>(() => this.#avatar.getAvatarInfos(this.avatarInfos()));

  displayDefaultAvatar = signal(false);

  constructor() {
    effect(() => {
      this.#elRef.nativeElement.style.setProperty('--_avatar-ui-bg-color', this.data().backgroundColor);
      this.#elRef.nativeElement.style.setProperty('--_avatar-ui-ft-color', this.data().foregroundColor);
    });
  }

  onError() {
    this.displayDefaultAvatar.set(true);
  }
}

export interface QuickActionData {
  type?: QuickActionType;
  label: string;
  action?: Function;
  subActions?: QuickActionSubData[];
  subActionsDataQuery?: {
    columnName: string;
    context?: { [key: string]: any };
  };
  processId?: number;
  readonly?: boolean;
}

export interface QuickActionSubData {
  id: string | number;
  displayValue: string;
}

export enum QuickActionType {
  NEWRECORD = 'icon-add-square',
  PROCESS = 'fa-solid fa-gear',
}

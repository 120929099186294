import { Directive, ElementRef, HostListener, inject, input, output } from '@angular/core';

@Directive({
  selector: '[imgFallback]',
  standalone: true,
})
export class ImgFallbackDirective {
  #el = inject(ElementRef);

  imageUrl = input<string>();

  firstError = input(true);
  errorHandler = output();

  @HostListener('error')
  displayFallback() {
    if (!this.firstError() || !this.imageUrl()) {
      this.errorHandler.emit();
      return;
    }

    const parent = (<HTMLElement>this.#el.nativeElement).parentElement;
    if (!parent) return;

    const imageUrl = this.imageUrl();
    if (imageUrl && this.#el.nativeElement?.src) {
      (<HTMLImageElement>this.#el.nativeElement).src = imageUrl;
    }
  }
}

<div class="buttonActions">
  <button
    *ngIf="chart"
    tooltipPosition="top"
    showDelay="250"
    [life]="3000"
    pTooltip="{{ 'chartWidgetUi.showLegend' | translate }}"
    pButton
    type="button"
    icon="{{ (lastLegendDisplayState ? 'far fa-eye' : 'far fa-eye-slash') || '' }}"
    (click)="changeLegendView()"
    class="p-menu-bar-button"></button>
  <button
    *ngIf="this.widget.windowId"
    tooltipPosition="top"
    showDelay="250"
    [life]="3000"
    pTooltip="{{ 'widgetUi.openWindow' | translate }}"
    pButton
    type="button"
    icon="icon-search-bolder"
    (click)="openTab()"
    class="p-menu-bar-button"></button>
</div>
<p-chart
  #chart
  *ngIf="dataToDisplay"
  [type]="chartType"
  [data]="dataToDisplay"
  [options]="options"
  [plugins]="plugins"
  [height]="'100%'"></p-chart>
<div *ngIf="!dataToDisplay" class="no-data">
  <p class="no-data-label">{{ 'widget.noData' | translate }}</p>
</div>
